/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import InfoBox from '../../components/InfoBox';
import CardGraphic from './CardGraphic';
import api from '../../services/api';
import {CardGraphicProps, DashboardProps} from '../../interfaces';
import FullPageLoader from '../../components/FullPageLoader';
import {useAuth} from '../../hooks/Auth';

const Dashboard: React.FC = () => {
    const {setLoadingDashboard} = useAuth();
    const [loading, setLoading] = useState(false);
    const [dashboard, setDashboard] = useState<DashboardProps>(
        {} as DashboardProps
    );
    const [graphicsData, setGraphicsData] = useState<CardGraphicProps>(
        {} as CardGraphicProps
    );
    const dataProgress = dashboard.planUsage;
    const dataProgressCSS = `${dataProgress}%`;
    const progressColor = () => {
        return dataProgress < 60
            ? 'success'
            : dataProgress < 80
            ? 'warning'
            : 'danger';
    };

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const [dataDaycte, setDataDaycte] = useState(currentYear);
    const [dataDaymdfe, setDataDaymdfe] = useState(currentYear);
    const [dataMonthcte, setDataMonthcte] = useState(currentMonth);
    const [dataMonthmdfe, setDataMonthmdfe] = useState(currentMonth);

    useEffect(() => {
        setLoading(true);
        api.get('dashboard')
            .then((response) => {
                setDashboard(response.data);
            })
            .catch(() =>
                toast.error(
                    'Não foi possível efetuar a consulta das informações do Dashboard'
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        api.post(`cardGraph`, {
            cte_year: dataDaycte,
            mdfe_year: dataDaymdfe,
            cte_month: dataMonthcte,
            mdfe_month: dataMonthmdfe
        })
            .then((response) => {
                setGraphicsData(response.data);
            })
            .catch(() =>
                toast.error(
                    'Não foi possível efetuar a consulta das informações do Dashboard'
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [dataMonthmdfe, dataMonthcte, dataDaymdfe, dataDaycte]);

    return (
        <section className="content pt-3 ">
            <div className="row mb-2">
                <InfoBox
                    className="tour-dashboard--01"
                    hasMonth
                    bgColor="#16a085"
                    color="#FFF"
                    title="Emissões de CT-e"
                    icon="fas fa-file-alt"
                    data={dashboard.ctesCount}
                />
                <InfoBox
                    mobile
                    hasMonth
                    bgColor="#16a085"
                    color="#FFF"
                    title="Emissões de CT-e"
                    icon="fas fa-file-alt"
                    data={dashboard.ctesCount}
                />
                <InfoBox
                    className="tour-dashboard--02"
                    hasMonth
                    bgColor="#2980b9"
                    color="#FFF"
                    title="Emissões de MDF-e"
                    icon="fas fa-file-alt"
                    data={dashboard.mdfesCount}
                />
                <InfoBox
                    mobile
                    hasMonth
                    bgColor="#2980b9"
                    color="#FFF"
                    title="Emissões de MDF-e"
                    icon="fas fa-file-alt"
                    data={dashboard.mdfesCount}
                />
                <InfoBox
                    className="tour-dashboard--03"
                    hasMonth
                    bgColor="#dc3545"
                    color="#FFF"
                    title="MDF-e não encerrados"
                    icon="fas fa-file-alt"
                    data={dashboard.mdfesNotClosed}
                />
                <InfoBox
                    mobile
                    hasMonth
                    bgColor="#dc3545"
                    color="#FFF"
                    title="MDF-e não encerrados"
                    icon="fas fa-file-alt"
                    data={dashboard.mdfesNotClosed}
                />
                <InfoBox
                    className="tour-dashboard--04"
                    hasHelperIcon
                    contract
                    strong
                    bgColor="#FFF"
                    color="#000"
                    title="Plano:"
                    plan={dashboard.planName}
                    documentNumber={dashboard.documentNumber}
                    icon="fas fa-user-alt"
                    data={dataProgress}
                    dataCSS={dataProgressCSS}
                    progress={progressColor()}
                />
                <InfoBox
                    mobile
                    contract
                    strong
                    bgColor="#FFF"
                    color="#000"
                    title="Plano:"
                    plan={dashboard.planName}
                    documentNumber={dashboard.documentNumber}
                    icon="fas fa-user-alt"
                    data={dataProgress}
                    dataCSS={dataProgressCSS}
                    progress={progressColor()}
                />
            </div>
            <CardGraphic
                title="Relatórios mensais"
                data={graphicsData}
                isYear
                setDataDaycte={setDataDaycte}
                setDataDaymdfe={setDataDaymdfe}
                setDataMonthcte={setDataMonthcte}
                setDataMonthmdfe={setDataMonthmdfe}
            />
            <CardGraphic
                title="Relatórios diários"
                data={graphicsData}
                setDataDaycte={setDataDaycte}
                setDataDaymdfe={setDataDaymdfe}
                setDataMonthcte={setDataMonthcte}
                setDataMonthmdfe={setDataMonthmdfe}
            />

            {loading && <FullPageLoader />}
            {loading ? setLoadingDashboard(true) : setLoadingDashboard(false)}
        </section>
    );
};

export default Dashboard;
