import React from 'react';

interface Props {
    title: string;
    responsive?: boolean;
    className?: string;
}

const ListDropdown: React.FC<Props> = ({
    children,
    title,
    responsive,
    className
}) => {
    return (
        <li className={`nav-item dropdown title-main-header ${className}`}>
            <a
                id="dropdownSubMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className={`nav-link dropdown-toggle cursor-pointer ${
                    responsive && 'responsive-screen-mobile'
                }`}
            >
                {title}
            </a>
            <ul
                aria-labelledby="dropdownSubMenu1"
                className="dropdown-menu border-0 shadow"
            >
                {children}
            </ul>
        </li>
    );
};

export default ListDropdown;
