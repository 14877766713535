const columns = [
    {
        text: 'E-mail',
        width: '35%',
        className: 'text-max-sm link-button',
        name: 'UPPER(email)',
        hasOrder: true
    },
    {
        text: 'Nome',
        width: '50%',
        className: 'text-max-sm link-button',
        name: 'UPPER(name)',
        hasOrder: true
    },
    {
        text: 'Telefone',
        width: '15% ',
        className: 'text-max-sm link-button',
        name: 'phone',
        hasOrder: true
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
