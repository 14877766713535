import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: string;
    placeholder: string;
}

const InputGroupPhone: React.FC<Props> = ({
    name,
    icon,
    placeholder,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors}
    } = useFormContext();

    return (
        <>
            <div className="input-group">
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}: any) => (
                        <TextInputMask
                            className={`form-control ${
                                errors && errors[`${name}`]
                                    ? 'is-invalid'
                                    : null
                            }`}
                            placeholder={placeholder}
                            {...otherProps}
                            {...field}
                            kind="cel-phone"
                        />
                    )}
                />

                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className={icon} />
                    </div>
                </div>
            </div>

            {errors && errors[`${name}`] && (
                <span className="span text-danger text-min-sm">
                    {errors[`${name}`].message}
                </span>
            )}
        </>
    );
};

export default InputGroupPhone;
