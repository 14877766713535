/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import Tour from 'reactour';
import {monthObjectData} from '../../data/index';
import {tourStepsInfobox} from '../../data/tour';

import './styles.scss';

interface infoBoxProps {
    mobile?: boolean;
    bgColor: string;
    color: string;
    title: string;
    plan?: string;
    documentNumber?: number;
    strong?: boolean;
    icon: string;
    data?: number;
    dataCSS?: string;
    contract?: boolean;
    progress?: string;
    hasMonth?: boolean;
    hasHelperIcon?: boolean;
    helperClick?: any;
    className?: string;
}

const InfoBox: React.FC<infoBoxProps> = ({
    mobile,
    bgColor,
    color,
    title,
    plan,
    documentNumber,
    strong,
    icon,
    data,
    dataCSS,
    progress,
    contract,
    hasMonth,
    hasHelperIcon,
    helperClick,
    className
}) => {
    const date = new Date();

    const currentMonth = monthObjectData[date.getMonth()].label;
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };

    return (
        <div
            className={`col-12 ${
                mobile
                    ? 'responsive-box-mobile col-md-6'
                    : 'responsive-box-pc col-md-3'
            } ${className}`}
        >
            <div className="info-box">
                <span
                    className="info-box-icon elevation-1"
                    style={{backgroundColor: `${bgColor}`, color: `${color}`}}
                >
                    <i className={icon} />
                </span>
                <div className="info-box-content position-relative">
                    <span
                        className={`info-box-text ${
                            hasMonth && 'd-flex justify-content-between'
                        }`}
                    >
                        {title}{' '}
                        {hasMonth && (
                            <div className="font-weight-normal text-muted">
                                ({currentMonth})
                            </div>
                        )}
                        {strong && (
                            <strong>
                                {plan === undefined
                                    ? 'Não encontrado'
                                    : `${plan} ${
                                          plan !== 'Plano Ilimitado'
                                              ? `- ${documentNumber} docs`
                                              : ''
                                      }`}
                            </strong>
                        )}
                        {hasHelperIcon && (
                            <button
                                type="button"
                                className="btn btn-tool tour-dashboard--06"
                            >
                                <a
                                    className="sign-link question__infobox--icon"
                                    onClick={() => setIsTourOpen(true)}
                                >
                                    <i
                                        className="fas fa-question"
                                        data-tip="Está com dúvidas sobre essas caixas de informações?"
                                        data-for="info-dark-left"
                                    />
                                    <ReactTooltip
                                        id="info-dark-left"
                                        place="left"
                                        backgroundColor="#42494E"
                                        effect="solid"
                                    />
                                </a>
                            </button>
                        )}
                    </span>
                    <span className="info__box--number">
                        {contract ? (
                            <div className="progress-group d-flex align-items-center justify-content-between">
                                {plan && plan !== 'Plano Ilimitado' && (
                                    <>
                                        <div className="progress progress-sm">
                                            <div
                                                className={`progress-bar bg-${progress}`}
                                                style={{width: dataCSS}}
                                                data-tip={
                                                    progress === 'danger'
                                                        ? 'Aconselhável a melhoria do plano de contrato!'
                                                        : progress === 'warning'
                                                        ? 'Fique atento aos limites do plano de contrato!'
                                                        : ''
                                                }
                                                data-for={
                                                    progress === 'danger'
                                                        ? 'statusDanger'
                                                        : progress === 'warning'
                                                        ? 'statusWarning'
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <strong>
                                            {data === undefined ? 0 : data}%
                                        </strong>
                                    </>
                                )}
                            </div>
                        ) : data === undefined ? (
                            0
                        ) : (
                            <div className="d-flex justify-content-between">
                                <div>{data}</div>
                            </div>
                        )}
                    </span>
                </div>
            </div>
            {progress === 'warning' ? (
                <ReactTooltip
                    id="statusWarning"
                    place="bottom"
                    backgroundColor="#FFC107"
                    textColor="black"
                />
            ) : (
                progress === 'statusDanger' && (
                    <ReactTooltip
                        id="statusDanger"
                        place="bottom"
                        backgroundColor="#dc3545"
                    />
                )
            )}
            <Tour
                steps={tourStepsInfobox}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                accentColor="#007BFF"
                maskSpace={8}
                rounded={20}
                startAt={0}
                onAfterOpen={() => {
                    setOverflow(false);
                    disabledOverflow();
                }}
                onBeforeClose={() => {
                    setOverflow(true);
                    disabledOverflow();
                }}
            />
        </div>
    );
};

export default InfoBox;
