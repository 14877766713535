import React, {useEffect, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import logo from '../../assets/logo_cte.png';
import FullPageLoader from '../../components/FullPageLoader';
import api from '../../services/api';

const ConfirmUser: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(false);
    const {url} = useParams<{url: string}>();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    useEffect(() => {
        async function verify() {
            setLoading(true);
            try {
                await api
                    .get(`verify/${url}?signature=${query.get('signature')}`)
                    .then((response) => setVerified(response.data));
            } catch (error) {
                toast.error(
                    'Ocorreu um erro inesperado, verifique a sua conexão'
                );
            }
            setLoading(false);
        }

        verify();
    }, []);

    return (
        <body className="login-page">
            <div style={{height: '100%', width: '100%', position: 'fixed'}}>
                <svg
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    style={{height: '100%', width: '100%'}}
                >
                    <path
                        d="M-5.92,121.88 C152.09,134.70 405.47,59.70 523.98,108.05 L508.74,167.27 L0.00,150.00 Z"
                        style={{stroke: 'none', fill: '#08f'}}
                    />
                </svg>
            </div>
            <div className="login-box">
                <div className="card">
                    <div className="card-body login-card-body">
                        <div className="login-logo">
                            <img
                                src={logo}
                                style={{width: 225}}
                                alt="MS1 Sistemas"
                            />
                        </div>

                        <div className="mt-5 mb-3">
                            {verified ? (
                                <>
                                    <p className="login-box-msg">
                                        Parabéns, sua conta foi verificada!
                                    </p>
                                    <p className="login-box-msg">
                                        Agora você pode
                                        <Link to="/">
                                            <strong>
                                                {' '}
                                                voltar à tela de login{' '}
                                            </strong>
                                        </Link>
                                        para entrar no sistema.
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className="login-box-msg">
                                        Este link é inválido ou está expirado.
                                    </p>
                                    <p className="login-box-msg">
                                        <Link to="/">
                                            <strong>
                                                Retornar à tela de login.
                                            </strong>
                                        </Link>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {loading && <FullPageLoader />}
        </body>
    );
};

export default ConfirmUser;
