/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {useForm, FormProvider, useFieldArray} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/esm/Modal';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import api from '../../../services/api';
import PeopleModal from '../../People/ModalForm';
import VehicleModal from '../../Vehicle/Modal';
import {useAuth} from '../../../hooks/Auth';
import Table from '../../../components/Table';
import nfe_columns from './TablesSchemas/nfe_schema';
import nf_manual_schema from './TablesSchemas/nf_manual_schema';
import others_columns from './TablesSchemas/others_schema';
import previous_docs_columns from './TablesSchemas/previous_docs_schema';
import {
    CFOPProps,
    CTeProps,
    EnvironmentProps,
    PeopleProps,
    ServiceCompositionProps,
    TaxationProps,
    VehicleProps,
    Cte_Services
} from '../../../interfaces';
import validation from './validation';
import SelectInputLabel from '../../../components/SelectInputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import InputLabel from '../../../components/InputLabel';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import InputLabelTextArea from '../../../components/InputLabelTextArea';
import InputLabelCurrency from '../../../components/InputLabelCurrency';
import load_columns from './TablesSchemas/load_schema';
import InputLabelMeasurement from '../../../components/InputLabelMeasurement';
import Label from '../../../components/Label';
import {csts, peopleDefaultValues, vehicleDefaultValues} from '../../../data';
import services_columns from './TablesSchemas/services_schema';
import ModalNfe from './Modals/nfe';
import ModalNfManual from './Modals/nfManual';
import ModalOthers from './Modals/others';
import ModalPreviousDocs from './Modals/previousDocs';
import ModalLoad from './Modals/load';
import CFOPModal from '../../CFOP/ModalForm';
import CardBody from '../../../components/CardBody';
import FullPageLoader from '../../../components/FullPageLoader';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import getChanges from '../../../utils/getChanges';
import helperTooltips from '../../../data/help';
import readXmlFile from '../../../services/readXmlFile';
import DFEsModal from './Modals/dfes';
import CTEsModal from '../../Modals/CTEsModal';
import Button from '../../../components/Button';
import PaginatedAsyncSelectLabel from '../../../components/PaginatedAsyncSelectLabel';

interface Props {
    data: CTeProps;
    hide: () => void;
    refresh: () => void;
}

const ModalCTe: React.FC<Props> = ({data, hide, refresh}) => {
    const {
        cities,
        peopleRefresh,
        setPeopleRefresh,
        enviromentText,
        user
    } = useAuth();
    const [loading, setLoading] = useState(false);
    const [modalNfeVisible, setModalNfeVisible] = useState(false);
    const [modalNfManualVisible, setModalNfManualVisible] = useState(false);
    const [modalLoadVisible, setModalLoadVisible] = useState(false);
    const [modalPreviousDocsVisible, setModalPreviousDocsVisible] = useState(
        false
    );
    const [modalOthersVisible, setModalOthersVisible] = useState(false);
    const [dfesModalVisible, setDfesModalVisible] = useState(false);
    const [importedDfes, setImportedDfes] = useState<string[]>([]);

    const [environments, setEnvironments] = useState<EnvironmentProps[]>([]);
    const [services, setServices] = useState<ServiceCompositionProps[]>([]);

    const [cfop, setCFOP] = useState<CFOPProps[]>([]);
    const [cfopRefresh, setCfopRefresh] = useState<number | null>(null);
    const [cfopModalVisible, setCfopModalVisible] = useState(false);

    const [peopleModalVisible, setPeopleModalVisible] = useState(false);
    const [driver, setDriver] = useState<PeopleProps[]>([]);
    const [peopleModalData, setPeopleModalData] = useState<PeopleProps>(
        peopleDefaultValues
    );

    const [vehicles, setVehicle] = useState<VehicleProps[]>([]);
    const [vehicleRefresh, setVehicleRefresh] = useState<number | null>(null);
    const [vehicleModalVisible, setVehicleModalVisible] = useState(false);
    const [vehicleModalData, setVehicleModalData] = useState<VehicleProps>(
        vehicleDefaultValues
    );

    const [modalCtesVisible, setModalCtesVisible] = useState(false);

    const [selectedPreviousDoc, setSelectedPreviousDoc] = useState('');

    // VALIDAÇÕES

    const methods = useForm<CTeProps>({
        defaultValues: data,
        resolver: yupResolver(validation)
    });
    const {
        reset,
        getValues,
        watch,
        setValue,
        control,
        formState,
        handleSubmit
    } = methods;

    const watched = watch([
        'nature',
        'taker',
        'city_origin_id',
        'city_destination_id',
        'recipient_people_id',
        'receiver_people_id'
    ]);

    const watched_commodity_value = watch('commodity_value');

    const {fields} = useFieldArray({
        name: 'services',
        control
    });

    // CALCULAR VALOR DO SERVIÇO
    function calculateServiceValue(
        service: ServiceCompositionProps,
        value: number,
        item: Cte_Services
    ) {
        if (service?.calc > 0) {
            return item.service_value;
        }

        if (!service?.multiply_charge) {
            return value;
        }

        const multipliedValues = getValues('loads').reduce(
            (previous, current) => previous + current.amount * value,
            0
        );
        return multipliedValues;
    }

    // DEFINIR VALOR DOS CAMPOS DE TRIBUTAÇÃO A PARTIR DO SERVIÇO
    function setServiceValues() {
        setValue('receivable_value', 0);
        setValue('value', 0);
        getValues('services').forEach((item, i) => {
            const value = calculateServiceValue(
                services[i],
                item.service_value,
                item
            );
            switch (services[i]?.action) {
                case 1: // SOMA AO RECEBIDO
                    setValue(
                        'receivable_value',
                        getValues('receivable_value') + value
                    );
                    break;

                case 2: // SUBTRAI AO RECEBIDO
                    setValue(
                        'receivable_value',
                        getValues('receivable_value') - value
                    );
                    break;

                case 3: // SOMA À PRESTAÇÃO
                    setValue('value', getValues('value') + value);
                    break;

                case 4: // SUBTRAI À PRESTAÇÃO
                    setValue('value', getValues('value') - value);
                    break;

                case 5: // SOMA AMBOS
                    setValue(
                        'receivable_value',
                        getValues('receivable_value') + value
                    );
                    setValue('value', getValues('value') + value);
                    break;

                case 6: // SUBTRAI AMBOS
                    setValue(
                        'receivable_value',
                        getValues('receivable_value') - value
                    );
                    setValue('value', getValues('value') - value);
                    break;

                default:
                    break;
            }
        });

        setValue('vlr_pis', 0);
        setValue('vlr_cofins', 0);

        getValues('services').forEach((item, i) => {
            setValue(
                'vlr_pis',
                getValues('vlr_pis') +
                    item.service_value * (services[i]?.pis / 100)
            );
            setValue(
                'vlr_cofins',
                getValues('vlr_cofins') +
                    item.service_value * (services[i]?.cofins / 100)
            );
        });

        setValue('vlr_bc_icms', 0);
        setValue('vlr_icms', 0);

        getValues('services').forEach((item, i) => {
            if (services[i]?.icms) {
                if (services[i]?.action === 4 || services[i]?.action === 6) {
                    setValue(
                        'vlr_bc_icms',
                        getValues('vlr_bc_icms') -
                            item.service_value * (1 - getValues('redbc') / 100)
                    );
                } else {
                    setValue(
                        'vlr_bc_icms',
                        getValues('vlr_bc_icms') +
                            item.service_value * (1 - getValues('redbc') / 100)
                    );
                }
                setValue(
                    'vlr_icms',
                    getValues('vlr_bc_icms') * (getValues('aliq_icms') / 100)
                );
            }
        });
    }

    // DEFINIR VALOR DOS SERVIÇOS COM CÁLCULO SOBRE MERCADORIA
    function setCommodityServicesValue() {
        const newServices: Cte_Services[] = [];
        getValues('services').forEach((item, i) => {
            const hasCommodityValue = services[i]?.calc > 0;
            newServices.push({
                service_id: item.service_id,
                service_value: hasCommodityValue
                    ? watched_commodity_value * (services[i]?.calc / 100)
                    : item.service_value
            });
        });

        reset({...getValues(), services: newServices});

        setServiceValues();
    }
    useEffect(() => setCommodityServicesValue(), [watched_commodity_value]);

    // RECEBER DADOS PARA PREENCHER OS COMBOBOX E INICIALIZAR O FORMULÁRIO
    useEffect(() => {
        setPeopleRefresh(null);
        setLoading(true);
        Promise.all([
            api.get(
                `vehiclesCombo?order=description&type=asc&form_action=${data.form_action}`
            ),
            api.get(
                `peopleCombo?order=name&type=asc&driver=true&form_action=${data.form_action}`
            ),
            api.get(
                `cfops?page=1&limit=total&order=cfop&type=asc&search=&form_action=${data.form_action}`
            ),
            api.get(
                `servicesCte?order=created_at&type=asc&form_action=${data.form_action}`
            ),
            api.get('environmentsCombo?order=corporate_name&type=asc')
        ])
            .then(
                async ([
                    vehicleResponse,
                    driverResponse,
                    cfopsResponse,
                    servicesCteResponse,
                    environmentsResponse
                ]) => {
                    setVehicle(vehicleResponse.data);
                    setDriver(driverResponse.data);
                    setCFOP(cfopsResponse.data.data);
                    setServices(servicesCteResponse.data);
                    setEnvironments(environmentsResponse.data);
                    let config_response = null;
                    if (
                        user.environment?.config_sender &&
                        data.form_action === 'include' &&
                        !data.city_origin_id
                    ) {
                        config_response = await api.get(
                            `peopleCombo/${
                                user.environment?.config_sender
                            }?form_action=${getValues('form_action')}`
                        );
                    }
                    reset({
                        ...data,
                        sender_people_id:
                            data.sender_people_id ||
                            user.environment?.config_sender ||
                            '',
                        city_origin_id: config_response
                            ? config_response.data.city_id
                            : data.city_origin_id,
                        services:
                            data.form_action === 'view'
                                ? data.services
                                : servicesCteResponse.data.map(
                                      (service: ServiceCompositionProps) => ({
                                          service_id: service.id,
                                          service_value:
                                              data.services.find(
                                                  (item) =>
                                                      service.id ===
                                                      item.service_id
                                              )?.service_value || 0
                                      })
                                  )
                    });
                }
            )
            .catch(() => toast.error('Não foi possível efetuar a consulta'))
            .finally(() => {
                setLoading(false);
            });
    }, [data, reset, user]);

    useEffect(() => {
        if (vehicleRefresh) {
            setLoading(true);
            try {
                api.get(
                    `vehiclesCombo?order=description&type=asc`
                ).then((response) => setVehicle(response.data));
            } catch (error) {
                toast.error('Não foi possível realizar a consulta de veívulos');
            }
            setLoading(false);
        }
    }, [vehicleRefresh]); // REFRESH VEHICLES

    useEffect(() => {
        if (peopleRefresh) {
            setLoading(true);
            api.get(`peopleCombo?order=name&type=asc&driver=true`)
                .then((response) => {
                    setDriver(response.data);
                })
                .catch(() =>
                    toast.error(
                        'Não foi possível realizar a consulta de motoristas'
                    )
                )
                .finally(() => setLoading(false));
        }
    }, [peopleRefresh]); // REFRESH PEOPLE

    useEffect(() => {
        if (peopleRefresh) {
            if (
                getValues('dispatcher_people_id') ||
                getValues('sender_people_id')
            ) {
                api.get(
                    `peopleCombo/${
                        getValues('dispatcher_people_id') ||
                        getValues('sender_people_id')
                    }`
                )
                    .then((response) => {
                        setValue('city_origin_id', response.data.city_id);
                    })
                    .catch(() => toast.error('Erro ao obter cidade de origem'));
            }

            if (
                getValues('receiver_people_id') ||
                getValues('recipient_people_id')
            ) {
                api.get(
                    `peopleCombo/${
                        getValues('receiver_people_id') ||
                        getValues('recipient_people_id')
                    }`
                )
                    .then((response) => {
                        setValue('city_destination_id', response.data.city_id);
                    })
                    .catch(() =>
                        toast.error('Erro ao obter cidade de destino')
                    );
            }
        }
    }, [peopleRefresh]); // REFRESH ORIGIN AND DESTINATION CITY

    useEffect(() => {
        if (cfopRefresh) {
            setLoading(true);
            try {
                api.get(
                    `cfops?page=1&limit=total&order=cfop&type=asc&search=`
                ).then((response) => {
                    setCFOP(response.data.data);
                });
            } catch (error) {
                toast.error('Não foi possível realizar a consulta de CFOP');
            }
            setLoading(false);
        }
    }, [cfopRefresh]); // REFRESH CFOP's

    useEffect(() => {
        if (Object.entries(formState.errors).toString() !== '') {
            let errorMessage = '';
            Object.entries(formState.errors)
                .map(([key, value]) => value)
                .forEach((error: any) => {
                    errorMessage = `${errorMessage} <p>${error.message}</p>`;
                });

            Swal.fire({
                icon: 'warning',
                title: 'Verifique os seguintes campos antes de salvar:',
                html: errorMessage
            });
        }
    }, [formState.errors]); // HANDLE FORM ERRORS

    const onSubmit = useCallback(
        async (dataSubmit: CTeProps) => {
            setLoading(true);
            (Object.keys(dataSubmit) as (keyof CTeProps)[]).forEach((key) => {
                if (dataSubmit[key] === '') dataSubmit[key] = null as never;
            });
            switch (dataSubmit.form_action) {
                case 'include':
                    try {
                        await api.post('ctes', {
                            ...dataSubmit,
                            imported_dfes: importedDfes,
                            log: {
                                inssuer_id: dataSubmit.environment_inssuer_id,
                                recipient_id: dataSubmit.recipient_people_id,
                                date_emission: dataSubmit.date_emission
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        setLoading(false);
                        toast.error(
                            error.response?.data?.errors[0]?.message ||
                                'Ocorreu um erro inesperado ao realizar o cadastro, confira a sua conexão e tente novamente'
                        );
                    }
                    break;
                case 'edit':
                    try {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`ctes/${dataSubmit.id}`, {
                            ...dataSubmit,
                            imported_dfes: importedDfes,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        setLoading(false);
                        toast.error(
                            error.response?.data?.errors[0]?.message ||
                                'Ocorreu um erro inesperado ao realizar o cadastro, confira a sua conexão e tente novamente'
                        );
                    }
                    break;
                default:
                    toast.error('Erro ao efetuar cadastro');
                    break;
            }
            setLoading(false);
        },
        [data, importedDfes]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    function getMeasureUnit(unit: string) {
        switch (unit) {
            case '00':
                return 'M³';
            case '01':
                return 'KG';
            case '02':
                return 'TON';
            case '03':
                return 'UN';
            case '04':
                return 'LITROS';
            case '05':
                return 'MMBTU';
            default:
                return 'UNIDADE';
        }
    }

    // OBTER REGRAS DE TRIBUTAÇÃO
    useEffect(() => {
        if (
            getValues('city_origin_id') &&
            getValues('city_destination_id') &&
            (getValues('receiver_people_id') ||
                getValues('recipient_people_id')) &&
            services.length > 0 &&
            getValues('form_action') !== 'view'
        ) {
            setLoading(true);

            const uf_origin = cities.find(
                (city) => city.id === getValues('city_origin_id')
            )?.uf_id;

            const uf_destination = cities.find(
                (city) => city.id === getValues('city_destination_id')
            )?.uf_id;

            api.get(
                `getTaxes?environment_id=${user.environment?.id}&person_id=${
                    watched[4] || watched[5]
                }&uf_origin=${uf_origin}&uf_destination=${uf_destination}`
            )
                .then((response) => {
                    const taxation: TaxationProps = response.data;

                    setValue('cst', taxation.cst);
                    setValue('redbc', taxation.redbc);
                    setValue('aliq_icms', taxation.aliq);
                    setValue('service_obs', taxation.obs);

                    setServiceValues();
                })
                .catch(() => {
                    toast.error('Erro ao calcular impostos');
                })
                .finally(() => setLoading(false));
        }
    }, [watched[2], watched[3], watched[4], watched[5], user, services]);

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        className="responsive-modal"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Modal.Dialog
                            scrollable
                            size="xl"
                            className="mdf-modal"
                            centered
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    {data.form_action === 'include'
                                        ? 'Cadastrar CT-e'
                                        : data.form_action === 'edit'
                                        ? 'Alterar CT-e'
                                        : 'Visualizar CT-e'}
                                </Modal.Title>
                                <span>{enviromentText}</span>
                            </Modal.Header>

                            <Modal.Body className="p-0 m-0">
                                {/* ABAS PRINCIPAIS */}
                                <div className="card-header p-1">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                href="#general-info"
                                                data-toggle="tab"
                                            >
                                                * CT-e
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#documents"
                                                data-toggle="tab"
                                            >
                                                * Documentos / Indicador
                                            </a>
                                        </li>
                                        {getValues('nature') !== 1 &&
                                            getValues('nature') !== 2 && (
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        href="#load"
                                                        data-toggle="tab"
                                                    >
                                                        * Inf. da Carga
                                                    </a>
                                                </li>
                                            )}
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#services"
                                                data-toggle="tab"
                                            >
                                                * Serviços / Impostos
                                            </a>
                                        </li>
                                        {user.environment
                                            ?.config_show_motorists_vehicles && (
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    href="#vehicle_driver"
                                                    data-toggle="tab"
                                                >
                                                    Veículos / Motoristas
                                                </a>
                                            </li>
                                        )}
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#driver"
                                                data-toggle="tab"
                                            >
                                                Comp. Motorista
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#obs"
                                                data-toggle="tab"
                                            >
                                                Observações
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content p-2">
                                    {/* INFORMAÇÕES GERAIS */}
                                    <div
                                        className="tab-pane active"
                                        id="general-info"
                                    >
                                        <div className="row mb-1">
                                            <InputLabelOnlyNumbers
                                                label="Código"
                                                data_tip="Código interno do registro no sistema"
                                                name="cod"
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />

                                            <SelectInputLabel
                                                required
                                                label="Emitente"
                                                name="environment_inssuer_id"
                                                data_tip={
                                                    helperTooltips.environment_inssuer
                                                }
                                                size_label="1"
                                                size_input={
                                                    user.tenant
                                                        .module_endorsement
                                                        ? '5'
                                                        : '9'
                                                }
                                                options={environments.map(
                                                    (item) => ({
                                                        value: item.id,
                                                        label:
                                                            item.corporate_name
                                                    })
                                                )}
                                                isDisabled
                                            />
                                            {user.tenant.module_endorsement && (
                                                <SelectInputLabel
                                                    label="Averbação"
                                                    name="auto_endorsement"
                                                    data_tip="Define se a averbação dos documentos será automática ou manual"
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            label:
                                                                'Averbação manual',
                                                            value: false
                                                        },
                                                        {
                                                            label:
                                                                'Averbação automática',
                                                            value: true
                                                        }
                                                    ]}
                                                />
                                            )}
                                        </div>
                                        <div className="row mb-1">
                                            <InputLabelOnlyNumbers
                                                label="Série"
                                                name="serie"
                                                data_tip={
                                                    helperTooltips.serie_mdfe
                                                }
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />

                                            <InputLabelOnlyNumbers
                                                label="N° CT-e"
                                                name="number"
                                                data_tip={
                                                    helperTooltips.number_mdfe
                                                }
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />
                                            <InputLabelOnlyNumbers
                                                label="Chave"
                                                name="key"
                                                data_tip={helperTooltips.key}
                                                size_label="1"
                                                size_input="7"
                                                disabled
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <SelectInputLabel
                                                required
                                                label="Tipo"
                                                name="type"
                                                data_tip={
                                                    helperTooltips.type_company
                                                }
                                                size_label="1"
                                                size_input="3"
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: 'Normal'
                                                    },
                                                    {
                                                        value: 1,
                                                        label: 'Sub-contratação'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Redespacho'
                                                    },
                                                    {
                                                        value: 3,
                                                        label:
                                                            'Redespacho intermediário'
                                                    },
                                                    {
                                                        value: 4,
                                                        label:
                                                            'Serviço Vinculado a Multimodal'
                                                    }
                                                ]}
                                            />

                                            <SelectInputLabel
                                                required
                                                label="Tomador"
                                                name="taker"
                                                data_tip={helperTooltips.taker}
                                                size_label="1"
                                                size_input="3"
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: 'Remetente'
                                                    },
                                                    {
                                                        value: 1,
                                                        label: 'Expedidor'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Recebedor'
                                                    },
                                                    {
                                                        value: 3,
                                                        label: 'Destinatário'
                                                    },
                                                    {
                                                        value: 4,
                                                        label: 'Outros'
                                                    }
                                                ]}
                                                onChange={(option: any) => {
                                                    if (option.value !== 4) {
                                                        setValue(
                                                            'taker_people_id',
                                                            ''
                                                        );
                                                    }
                                                }}
                                            />

                                            <SelectInputLabel
                                                required
                                                label="Natureza"
                                                name="nature"
                                                data_tip={helperTooltips.nature}
                                                size_label="1"
                                                size_input="3"
                                                options={[
                                                    {
                                                        value: 0,
                                                        label: 'Normal'
                                                    },
                                                    {
                                                        value: 1,
                                                        label:
                                                            'Compl. de valores'
                                                    },
                                                    {
                                                        value: 2,
                                                        label:
                                                            'Anulação de valores'
                                                    },
                                                    {
                                                        value: 3,
                                                        label: 'Substituto'
                                                    }
                                                ]}
                                                onChange={(op: any) => {
                                                    switch (op.value) {
                                                        case 0:
                                                            reset({
                                                                ...getValues(),

                                                                cte_replacement_key:
                                                                    '',
                                                                cte_annulment_date:
                                                                    '',
                                                                cte_annulment_key:
                                                                    '',
                                                                cte_complement_key:
                                                                    '',
                                                                service_additional_feature:
                                                                    '',
                                                                service_additional_transporter:
                                                                    ''
                                                            });
                                                            break;
                                                        case 1:
                                                            reset({
                                                                ...getValues(),
                                                                loads: [],
                                                                nfes: [],
                                                                manual_nfs: [],
                                                                commodity_value: 0,
                                                                predominant_product:
                                                                    '',
                                                                other_features:
                                                                    '',

                                                                cte_replacement_key:
                                                                    '',
                                                                cte_annulment_date:
                                                                    '',
                                                                cte_annulment_key:
                                                                    ''
                                                            });
                                                            break;
                                                        case 2:
                                                            reset({
                                                                ...getValues(),
                                                                loads: [],
                                                                nfes: [],
                                                                manual_nfs: [],
                                                                commodity_value: 0,
                                                                predominant_product:
                                                                    '',
                                                                other_features:
                                                                    '',
                                                                cte_replacement_key:
                                                                    '',
                                                                cte_complement_key:
                                                                    '',
                                                                service_additional_feature:
                                                                    '',
                                                                service_additional_transporter:
                                                                    ''
                                                            });
                                                            break;
                                                        case 3:
                                                            reset({
                                                                ...getValues(),
                                                                cte_annulment_date:
                                                                    '',
                                                                cte_annulment_key:
                                                                    '',
                                                                cte_complement_key:
                                                                    '',
                                                                service_additional_feature:
                                                                    '',
                                                                service_additional_transporter:
                                                                    ''
                                                            });
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <InputLabel
                                                required
                                                label="Emissão"
                                                name="date_emission"
                                                data_tip={
                                                    helperTooltips.emission
                                                }
                                                size_label="1"
                                                size_input="2"
                                                type="date"
                                            />

                                            <InputLabel
                                                label="Prev. entrega"
                                                name="date_delivery"
                                                data_tip={
                                                    helperTooltips.date_delivery
                                                }
                                                size_label="1"
                                                size_input="2"
                                                type="date"
                                            />

                                            <SelectInputLabel
                                                required
                                                label="CFOP"
                                                name="cfop_id"
                                                data_tip={
                                                    helperTooltips.select_cfop
                                                }
                                                size_label="1"
                                                size_input="4"
                                                options={cfop.map((item) => ({
                                                    value: item.id,
                                                    label: `${item.cfop} - ${item.description}`
                                                }))}
                                            />
                                            <div className="col-sm-1">
                                                <div className="row">
                                                    <div className="col-sm-6 pl-1">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-block btn-sm"
                                                            disabled={
                                                                getValues(
                                                                    'form_action'
                                                                ) === 'view'
                                                            }
                                                            onClick={() =>
                                                                setCfopModalVisible(
                                                                    true
                                                                )
                                                            }
                                                            data-tip="Adicionar CFOP"
                                                            data-for="tip"
                                                        >
                                                            <i className="fa fa-plus c-success" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                required={
                                                    getValues('taker') === 4
                                                }
                                                label="Tomador"
                                                name="taker_people_id"
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                data_tip={
                                                    helperTooltips.select_taker
                                                }
                                                size_label="1"
                                                size_input="10"
                                                isDisabled={
                                                    getValues('taker') !== 4
                                                }
                                            />

                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="taker_people_id"
                                                tip="Tomador"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                menuHeight={140}
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                required
                                                label="Remetente"
                                                name="sender_people_id"
                                                data_tip={
                                                    helperTooltips.select_sender_people
                                                }
                                                size_label="1"
                                                size_input="10"
                                                onChange={(option: any) => {
                                                    if (
                                                        !getValues()
                                                            .dispatcher_people_id &&
                                                        option
                                                    ) {
                                                        setValue(
                                                            'city_origin_id',
                                                            option.city_id
                                                        );
                                                    }
                                                }}
                                                isClearable
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="sender_people_id"
                                                tip="Remetente"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                menuHeight={140}
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                required
                                                label="Destinatário"
                                                name="recipient_people_id"
                                                data_tip={
                                                    helperTooltips.select_recipient_people
                                                }
                                                size_label="1 pl-0"
                                                size_input="10"
                                                onChange={(option: any) => {
                                                    if (
                                                        !getValues()
                                                            .receiver_people_id &&
                                                        option
                                                    ) {
                                                        setValue(
                                                            'city_destination_id',
                                                            option.city_id
                                                        );
                                                    }
                                                }}
                                                isClearable
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="recipient_people_id"
                                                tip="Destinatário"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                menuPlace="top"
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                required={
                                                    getValues('taker') === 1
                                                }
                                                label="Expedidor"
                                                name="dispatcher_people_id"
                                                data_tip={
                                                    helperTooltips.select_dispatcher_people
                                                }
                                                size_label="1"
                                                size_input="10"
                                                isClearable={
                                                    getValues('taker') !== 1
                                                }
                                                onChange={(option: any) => {
                                                    if (option) {
                                                        setValue(
                                                            'city_origin_id',
                                                            option.city_id
                                                        );
                                                    }
                                                }}
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="dispatcher_people_id"
                                                tip="Expedidor"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                menuPlace="top"
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                required={
                                                    getValues('taker') === 2
                                                }
                                                label="Recebedor"
                                                name="receiver_people_id"
                                                data_tip={
                                                    helperTooltips.select_receiver_people
                                                }
                                                size_label="1"
                                                size_input="10"
                                                isClearable={
                                                    getValues('taker') !== 2
                                                }
                                                onChange={(option: any) => {
                                                    if (option) {
                                                        setValue(
                                                            'city_destination_id',
                                                            option.city_id
                                                        );
                                                    }
                                                }}
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="receiver_people_id"
                                                tip="Recebedor"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <AsyncSelectLabelCities
                                                required
                                                menuPlace="top"
                                                label="Origem"
                                                name="city_origin_id"
                                                data_tip={
                                                    helperTooltips.select_city_origin
                                                }
                                                size_label="1"
                                                size_input="5"
                                                data={cities}
                                            />

                                            <AsyncSelectLabelCities
                                                required
                                                menuPlace="top"
                                                label="Destino"
                                                name="city_destination_id"
                                                data_tip={
                                                    helperTooltips.select_city_destination
                                                }
                                                size_label="1"
                                                size_input="5"
                                                data={cities}
                                            />
                                        </div>
                                    </div>

                                    {/* DOCUMENTOS / INDICADOR */}
                                    <div className="tab-pane" id="documents">
                                        <div className="col-sm-12">
                                            <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                <div className="card-header p-0 border-bottom-0">
                                                    <ul
                                                        className="nav nav-tabs"
                                                        id="custom-tabs-for-tab"
                                                        role="tablist"
                                                    >
                                                        {getValues('nature') !==
                                                            1 &&
                                                            getValues(
                                                                'nature'
                                                            ) !== 2 && (
                                                                <>
                                                                    <li className="nav-item">
                                                                        <a
                                                                            className="nav-link active"
                                                                            data-toggle="pill"
                                                                            href="#nfe"
                                                                            role="tab"
                                                                            aria-selected="true"
                                                                        >
                                                                            NF-e
                                                                        </a>
                                                                    </li>

                                                                    <li className="nav-item">
                                                                        <a
                                                                            className="nav-link"
                                                                            data-toggle="pill"
                                                                            href="#nf_manual"
                                                                            role="tab"
                                                                            aria-selected="false"
                                                                        >
                                                                            NF
                                                                            Manual
                                                                        </a>
                                                                    </li>

                                                                    <li className="nav-item">
                                                                        <a
                                                                            className="nav-link"
                                                                            data-toggle="pill"
                                                                            href="#others"
                                                                            role="tab"
                                                                            aria-selected="false"
                                                                        >
                                                                            Outros
                                                                        </a>
                                                                    </li>

                                                                    <li className="nav-item">
                                                                        <a
                                                                            className="nav-link"
                                                                            data-toggle="pill"
                                                                            href="#previous_docs"
                                                                            role="tab"
                                                                            aria-selected="false"
                                                                        >
                                                                            Documentos
                                                                            do
                                                                            Transporte
                                                                            Anterior
                                                                        </a>
                                                                    </li>
                                                                </>
                                                            )}

                                                        {getValues('nature') ===
                                                            1 && (
                                                            <li className="nav-item active">
                                                                <a
                                                                    className="nav-link"
                                                                    data-toggle="pill"
                                                                    href="#complement"
                                                                    role="tab"
                                                                    aria-selected="true"
                                                                >
                                                                    Inf. CT-e
                                                                    Complementar
                                                                </a>
                                                            </li>
                                                        )}

                                                        {getValues('nature') ===
                                                            2 && (
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link active"
                                                                    data-toggle="pill"
                                                                    href="#annulment"
                                                                    role="tab"
                                                                    aria-selected="true"
                                                                >
                                                                    Inf. CT-e de
                                                                    Anulação
                                                                </a>
                                                            </li>
                                                        )}

                                                        {getValues('nature') ===
                                                            3 && (
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    data-toggle="pill"
                                                                    href="#replacement"
                                                                    role="tab"
                                                                    aria-selected="true"
                                                                >
                                                                    Inf. CT-e de
                                                                    Substituição
                                                                </a>
                                                            </li>
                                                        )}

                                                        {getValues('nature') !==
                                                            1 &&
                                                            getValues(
                                                                'nature'
                                                            ) !== 2 && (
                                                                <li className="nav-item">
                                                                    <a
                                                                        className="nav-link"
                                                                        data-toggle="pill"
                                                                        href="#indicator"
                                                                        role="tab"
                                                                        aria-selected="false"
                                                                    >
                                                                        Indicador
                                                                    </a>
                                                                </li>
                                                            )}
                                                    </ul>
                                                </div>

                                                <div className="card-body p-2">
                                                    <div className="tab-content">
                                                        {/* NOTAS, OUTROS, ANTERIORES */}
                                                        {getValues('nature') !==
                                                            1 &&
                                                            getValues(
                                                                'nature'
                                                            ) !== 2 && (
                                                                <>
                                                                    {/* NF-e */}
                                                                    <div
                                                                        className="tab-pane fade show active"
                                                                        id="nfe"
                                                                        role="tabpanel"
                                                                    >
                                                                        <div className="row mb-1">
                                                                            <div className="col-sm-12 text-right">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-sm"
                                                                                    disabled={
                                                                                        getValues(
                                                                                            'form_action'
                                                                                        ) ===
                                                                                        'view'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setModalNfeVisible(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-plus mr-1 c-success" />
                                                                                    Incluir
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-1">
                                                                            <CardBody
                                                                                size="12"
                                                                                smallSize="12"
                                                                            >
                                                                                <Table
                                                                                    id="nfe_table"
                                                                                    columns={
                                                                                        nfe_columns
                                                                                    }
                                                                                >
                                                                                    {getValues(
                                                                                        'nfes'
                                                                                    ).map(
                                                                                        (
                                                                                            nfe
                                                                                        ) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    nfe.access_key
                                                                                                }
                                                                                            >
                                                                                                {/* CHAVE */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {
                                                                                                        nfe.access_key
                                                                                                    }
                                                                                                </td>
                                                                                                {/* NÚMERO NFE */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        nfe.nfe_number
                                                                                                    }
                                                                                                </td>
                                                                                                {/* PIN */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        nfe.pin
                                                                                                    }
                                                                                                </td>
                                                                                                {/* REMOVER */}
                                                                                                <td className="text-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-xs"
                                                                                                        disabled={
                                                                                                            getValues(
                                                                                                                'form_action'
                                                                                                            ) ===
                                                                                                            'view'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            reset(
                                                                                                                {
                                                                                                                    ...getValues(),
                                                                                                                    nfes: getValues(
                                                                                                                        'nfes'
                                                                                                                    ).filter(
                                                                                                                        (
                                                                                                                            item
                                                                                                                        ) =>
                                                                                                                            nfe.access_key !==
                                                                                                                            item.access_key
                                                                                                                    )
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-trash" />
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                                </Table>
                                                                            </CardBody>
                                                                        </div>
                                                                    </div>

                                                                    {/* NF MANUAL */}
                                                                    <div
                                                                        className="tab-pane fade"
                                                                        id="nf_manual"
                                                                        role="tabpanel"
                                                                    >
                                                                        <div className="row mb-1">
                                                                            <div className="col-sm-12 text-right">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-sm"
                                                                                    disabled={
                                                                                        getValues(
                                                                                            'form_action'
                                                                                        ) ===
                                                                                        'view'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setModalNfManualVisible(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-plus mr-1 c-success" />
                                                                                    Incluir
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-1">
                                                                            <CardBody
                                                                                size="12"
                                                                                smallSize="12"
                                                                            >
                                                                                <Table
                                                                                    id="nfe_table"
                                                                                    columns={
                                                                                        nf_manual_schema
                                                                                    }
                                                                                >
                                                                                    {getValues(
                                                                                        'manual_nfs'
                                                                                    ).map(
                                                                                        (
                                                                                            manual_nf
                                                                                        ) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    manual_nf.number
                                                                                                }
                                                                                            >
                                                                                                {/* SERIE */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {
                                                                                                        manual_nf.serie
                                                                                                    }
                                                                                                </td>
                                                                                                {/* NÚMERO */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        manual_nf.number
                                                                                                    }
                                                                                                </td>
                                                                                                {/* EMISSAO */}
                                                                                                <td className="pt-2">
                                                                                                    {`${
                                                                                                        manual_nf.date_emission
                                                                                                            .split(
                                                                                                                'T'
                                                                                                            )[0]
                                                                                                            .split(
                                                                                                                '-'
                                                                                                            )[2]
                                                                                                    }/${
                                                                                                        manual_nf.date_emission
                                                                                                            .split(
                                                                                                                'T'
                                                                                                            )[0]
                                                                                                            .split(
                                                                                                                '-'
                                                                                                            )[1]
                                                                                                    }/${
                                                                                                        manual_nf.date_emission
                                                                                                            .split(
                                                                                                                'T'
                                                                                                            )[0]
                                                                                                            .split(
                                                                                                                '-'
                                                                                                            )[0]
                                                                                                    }`}
                                                                                                </td>
                                                                                                {/* BC ICMS */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.bc_icms
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* VLR ICMS */}
                                                                                                <td className="pt-2">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.value_icms
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* BC ST */}
                                                                                                <td className="pt-2">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.bc_st
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* VLR ST */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.vlr_st
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* CFOP */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        manual_nf.cfop
                                                                                                    }
                                                                                                </td>
                                                                                                {/* TOTAL PRODUTOS */}
                                                                                                <td className="pt-2">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.total_value
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* TOTAL NOTA */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        manual_nf.nf_total_value
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* REMOVER */}
                                                                                                <td className="text-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-xs"
                                                                                                        disabled={
                                                                                                            getValues(
                                                                                                                'form_action'
                                                                                                            ) ===
                                                                                                            'view'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            reset(
                                                                                                                {
                                                                                                                    ...getValues(),
                                                                                                                    manual_nfs: getValues(
                                                                                                                        'manual_nfs'
                                                                                                                    ).filter(
                                                                                                                        (
                                                                                                                            item
                                                                                                                        ) =>
                                                                                                                            manual_nf.number !==
                                                                                                                            item.number
                                                                                                                    )
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-trash" />
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                                </Table>
                                                                            </CardBody>
                                                                        </div>
                                                                    </div>

                                                                    {/* OUTROS */}
                                                                    <div
                                                                        className="tab-pane fade"
                                                                        id="others"
                                                                        role="tabpanel"
                                                                    >
                                                                        <div className="row mb-1">
                                                                            <div className="col-sm-12 text-right">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-sm"
                                                                                    disabled={
                                                                                        getValues(
                                                                                            'form_action'
                                                                                        ) ===
                                                                                        'view'
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setModalOthersVisible(
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="fa fa-plus mr-1 c-success" />
                                                                                    Incluir
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-1">
                                                                            <CardBody
                                                                                size="12"
                                                                                smallSize="12"
                                                                            >
                                                                                <Table
                                                                                    id="nfe_table"
                                                                                    columns={
                                                                                        others_columns
                                                                                    }
                                                                                >
                                                                                    {getValues(
                                                                                        'others'
                                                                                    ).map(
                                                                                        (
                                                                                            other
                                                                                        ) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    other.number
                                                                                                }
                                                                                            >
                                                                                                {/* DOC */}
                                                                                                <td className="pt-2 pl-2 ">
                                                                                                    {other.original_document ===
                                                                                                    '00'
                                                                                                        ? 'Declaração'
                                                                                                        : 'Outro'}
                                                                                                </td>
                                                                                                {/* DESCRIÇÃO */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        other.description
                                                                                                    }
                                                                                                </td>
                                                                                                {/* NÚMERO */}
                                                                                                <td className="pt-2">
                                                                                                    {
                                                                                                        other.number
                                                                                                    }
                                                                                                </td>
                                                                                                {/* EMISSÃO */}
                                                                                                <td className="pt-2">
                                                                                                    {`${
                                                                                                        other.date_emission
                                                                                                            .split(
                                                                                                                'T'
                                                                                                            )[0]
                                                                                                            .split(
                                                                                                                '-'
                                                                                                            )[2]
                                                                                                    }
                                                                                /${
                                                                                    other.date_emission
                                                                                        .split(
                                                                                            'T'
                                                                                        )[0]
                                                                                        .split(
                                                                                            '-'
                                                                                        )[1]
                                                                                }
                                                                                /${
                                                                                    other.date_emission
                                                                                        .split(
                                                                                            'T'
                                                                                        )[0]
                                                                                        .split(
                                                                                            '-'
                                                                                        )[0]
                                                                                }
                                                                                `}
                                                                                                </td>
                                                                                                {/* VALOR */}
                                                                                                <td className="pt-2">
                                                                                                    {new Intl.NumberFormat(
                                                                                                        'pt-BR',
                                                                                                        {
                                                                                                            style:
                                                                                                                'currency',
                                                                                                            currency:
                                                                                                                'BRL'
                                                                                                        }
                                                                                                    ).format(
                                                                                                        other.value
                                                                                                    )}
                                                                                                </td>
                                                                                                {/* REMOVER */}
                                                                                                <td className="text-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-xs"
                                                                                                        disabled={
                                                                                                            getValues(
                                                                                                                'form_action'
                                                                                                            ) ===
                                                                                                            'view'
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            reset(
                                                                                                                {
                                                                                                                    ...getValues(),
                                                                                                                    others: getValues(
                                                                                                                        'others'
                                                                                                                    ).filter(
                                                                                                                        (
                                                                                                                            item
                                                                                                                        ) =>
                                                                                                                            other.number !==
                                                                                                                            item.number
                                                                                                                    )
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fas fa-trash" />
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                                </Table>
                                                                            </CardBody>
                                                                        </div>
                                                                    </div>

                                                                    {/* DOC. TRANSPORTE ANTERIOR */}
                                                                    <div
                                                                        className="tab-pane fade"
                                                                        id="previous_docs"
                                                                        role="tabpanel"
                                                                    >
                                                                        <div className="row mb-1">
                                                                            <div className="col-sm-12 text-right">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default btn-sm"
                                                                                    disabled={
                                                                                        getValues(
                                                                                            'form_action'
                                                                                        ) ===
                                                                                        'view'
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setSelectedPreviousDoc(
                                                                                            ''
                                                                                        );
                                                                                        setModalPreviousDocsVisible(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-plus mr-1 c-success" />
                                                                                    Incluir
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-1">
                                                                            <CardBody
                                                                                size="12"
                                                                                smallSize="12"
                                                                            >
                                                                                <Table
                                                                                    id="nfe_table"
                                                                                    columns={
                                                                                        previous_docs_columns
                                                                                    }
                                                                                >
                                                                                    {getValues()
                                                                                        .previous_documents.filter(
                                                                                            (
                                                                                                doc,
                                                                                                index,
                                                                                                self
                                                                                            ) =>
                                                                                                index ===
                                                                                                self.findIndex(
                                                                                                    (
                                                                                                        item
                                                                                                    ) =>
                                                                                                        item.people_id ===
                                                                                                        doc.people_id
                                                                                                )
                                                                                        )
                                                                                        .map(
                                                                                            (
                                                                                                doc
                                                                                            ) => (
                                                                                                <tr
                                                                                                    key={
                                                                                                        doc.key
                                                                                                    }
                                                                                                >
                                                                                                    {/* RAZÃO/NOME */}
                                                                                                    <td className="pt-2 pl-2 ">
                                                                                                        {
                                                                                                            doc.people_name
                                                                                                        }
                                                                                                    </td>
                                                                                                    {/* ALTERAR */}
                                                                                                    <td className="text-center">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-xs"
                                                                                                            disabled={
                                                                                                                getValues(
                                                                                                                    'form_action'
                                                                                                                ) ===
                                                                                                                'view'
                                                                                                            }
                                                                                                            onClick={() => {
                                                                                                                setSelectedPreviousDoc(
                                                                                                                    doc.people_id
                                                                                                                );
                                                                                                                setModalPreviousDocsVisible(
                                                                                                                    true
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-edit" />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    {/* REMOVER */}
                                                                                                    <td className="text-center">
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="btn btn-xs"
                                                                                                            disabled={
                                                                                                                getValues(
                                                                                                                    'form_action'
                                                                                                                ) ===
                                                                                                                'view'
                                                                                                            }
                                                                                                            onClick={() => {
                                                                                                                reset(
                                                                                                                    {
                                                                                                                        ...getValues(),
                                                                                                                        previous_documents: getValues(
                                                                                                                            'previous_documents'
                                                                                                                        ).filter(
                                                                                                                            (
                                                                                                                                item
                                                                                                                            ) =>
                                                                                                                                doc.people_id !==
                                                                                                                                item.people_id
                                                                                                                        )
                                                                                                                    }
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="fas fa-trash" />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        )}
                                                                                </Table>
                                                                            </CardBody>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                        {/* COMPLEMENTAR */}
                                                        {getValues('nature') ===
                                                            1 && (
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="complement"
                                                                role="tabpanel"
                                                            >
                                                                <div className="row mb-1">
                                                                    <InputLabelOnlyNumbers
                                                                        label="Chave"
                                                                        required
                                                                        name="cte_complement_key"
                                                                        size_label="3"
                                                                        size_input="8"
                                                                        digits={
                                                                            44
                                                                        }
                                                                    />
                                                                    <div className="col-sm-1 pl-1">
                                                                        <button
                                                                            type="button"
                                                                            data-tip="Pesquisar chave de CT-e"
                                                                            data-for="tip"
                                                                            className="btn btn-default btn-block btn-sm"
                                                                            onClick={() =>
                                                                                setModalCtesVisible(
                                                                                    true
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                getValues(
                                                                                    'form_action'
                                                                                ) ===
                                                                                'view'
                                                                            }
                                                                        >
                                                                            <i className="fa fa-search" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <InputLabel
                                                                        name="service_additional_transporter"
                                                                        label="Características adicional do transporte"
                                                                        size_label="3"
                                                                        size_input="9"
                                                                    />
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <InputLabel
                                                                        name="service_additional_feature"
                                                                        label="Características adicional do serviço"
                                                                        size_label="3"
                                                                        size_input="9"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* ANULAÇÃO */}
                                                        {getValues('nature') ===
                                                            2 && (
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="annulment"
                                                                role="tabpanel"
                                                            >
                                                                <div className="row mb-1">
                                                                    <InputLabelOnlyNumbers
                                                                        label="Chave"
                                                                        required
                                                                        name="cte_annulment_key"
                                                                        size_label="1"
                                                                        size_input="8"
                                                                        digits={
                                                                            44
                                                                        }
                                                                        data_tip="Chave do CT-e que será anulado"
                                                                    />
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <InputLabel
                                                                        label="Data"
                                                                        required
                                                                        name="cte_annulment_date"
                                                                        size_label="1"
                                                                        size_input="2"
                                                                        type="date"
                                                                        data_tip="Data de emissão do CT-e que será anulado"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* SUBSTITUIÇÃO */}
                                                        {getValues('nature') ===
                                                            3 && (
                                                            <div
                                                                className="tab-pane fade"
                                                                id="replacement"
                                                                role="tabpanel"
                                                            >
                                                                <div className="row mb-1">
                                                                    <InputLabelOnlyNumbers
                                                                        label="Chave do CT-e substituido"
                                                                        name="cte_replacement_key"
                                                                        required
                                                                        size_label="3"
                                                                        size_input="9"
                                                                        digits={
                                                                            44
                                                                        }
                                                                        data_tip="Chave do CT-e que será substituído (do documento original, o mesmo que já foi anulado)"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/* INDICADOR */}
                                                        {getValues('nature') !==
                                                            2 &&
                                                            getValues(
                                                                'nature'
                                                            ) !== 1 && (
                                                                <div
                                                                    className="tab-pane fade"
                                                                    id="indicator"
                                                                    role="tabpanel"
                                                                >
                                                                    <div className="row mb-1">
                                                                        <PaginatedAsyncSelectLabel
                                                                            optionSearch="name_search"
                                                                            optionLabel="name_combo"
                                                                            label="Indicador"
                                                                            name="indicator_people_id"
                                                                            size_label="1"
                                                                            size_input="10"
                                                                        />
                                                                        <EditIncludeButtons
                                                                            setLoading={
                                                                                setLoading
                                                                            }
                                                                            setModalData={
                                                                                setPeopleModalData
                                                                            }
                                                                            setModalVisible={
                                                                                setPeopleModalVisible
                                                                            }
                                                                            defaultValues={
                                                                                peopleDefaultValues
                                                                            }
                                                                            route="people"
                                                                            field="indicator_people_id"
                                                                            tip="Indicador"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* CARGA */}
                                    {getValues('nature') !== 1 &&
                                        getValues('nature') !== 2 && (
                                            <>
                                                <div
                                                    className="tab-pane"
                                                    id="load"
                                                >
                                                    <div className="row mb-1">
                                                        <InputLabelCurrency
                                                            required={
                                                                getValues(
                                                                    'nature'
                                                                ) !== 1
                                                            }
                                                            label="Vlr. mercadoria"
                                                            name="commodity_value"
                                                            data_tip={
                                                                helperTooltips.commodity_value
                                                            }
                                                            size_label="2"
                                                            size_input="2"
                                                            disabled={
                                                                getValues(
                                                                    'form_action'
                                                                ) === 'view'
                                                            }
                                                        />
                                                        <InputLabel
                                                            required
                                                            label="Produto predominante"
                                                            name="predominant_product"
                                                            data_tip={
                                                                helperTooltips.predominant_product
                                                            }
                                                            size_label="2"
                                                            size_input="6"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            label="Outras características"
                                                            name="other_features"
                                                            data_tip={
                                                                helperTooltips.other_features
                                                            }
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>

                                                    <div className="row mb-1">
                                                        <div className="col-sm-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-sm"
                                                                disabled={
                                                                    getValues(
                                                                        'form_action'
                                                                    ) === 'view'
                                                                }
                                                                onClick={() =>
                                                                    setModalLoadVisible(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <i className="fa fa-plus mr-1 c-success" />
                                                                Incluir
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div className="col-sm-6 p-0">
                                                            <CardBody
                                                                size="12"
                                                                smallSize="12"
                                                            >
                                                                <Table
                                                                    id="nfe_table"
                                                                    columns={
                                                                        load_columns
                                                                    }
                                                                >
                                                                    {getValues(
                                                                        'loads'
                                                                    ).map(
                                                                        (
                                                                            load,
                                                                            index
                                                                        ) => (
                                                                            <tr
                                                                                key={Math.random()}
                                                                            >
                                                                                {/* UNIDADE DE MEDIDA */}
                                                                                <td className="pt-2 pl-2 ">
                                                                                    {getMeasureUnit(
                                                                                        load.measurement_unit
                                                                                    )}
                                                                                </td>
                                                                                <td className="pt-2 pl-2 ">
                                                                                    {
                                                                                        load.measurement_type
                                                                                    }
                                                                                </td>
                                                                                {/* QUANTIDADE */}
                                                                                <td className="pt-2">
                                                                                    {new Intl.NumberFormat(
                                                                                        'pt-BR',
                                                                                        {
                                                                                            minimumFractionDigits: 4,
                                                                                            useGrouping: true
                                                                                        }
                                                                                    ).format(
                                                                                        load.amount
                                                                                    )}
                                                                                </td>
                                                                                {/* REMOVER */}
                                                                                <td className="text-center">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-xs"
                                                                                        disabled={
                                                                                            getValues(
                                                                                                'form_action'
                                                                                            ) ===
                                                                                            'view'
                                                                                        }
                                                                                        onClick={() => {
                                                                                            reset(
                                                                                                {
                                                                                                    ...getValues(),
                                                                                                    loads: getValues(
                                                                                                        'loads'
                                                                                                    ).filter(
                                                                                                        (
                                                                                                            item,
                                                                                                            item_index
                                                                                                        ) =>
                                                                                                            index !==
                                                                                                            item_index
                                                                                                    )
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <i className="fas fa-trash" />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                                </Table>
                                                            </CardBody>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    {/* SERVIÇOS / IMPOSTOS */}
                                    <div className="tab-pane" id="services">
                                        <div
                                            className="tab-pane"
                                            id="services_tab"
                                        >
                                            <div className="row mb-3">
                                                <div className="col-sm-6">
                                                    {/* SERVIÇOS */}
                                                    <div className="card card-primary card-outline card-outline-tabs mb-0 h-100">
                                                        <div className="card-header p-0 border-bottom-0">
                                                            <ul
                                                                className="nav nav-tabs"
                                                                id="custom-tabs-for-tab"
                                                                role="tablist"
                                                            >
                                                                <li className="nav-item">
                                                                    <a
                                                                        className="nav-link disabled"
                                                                        data-toggle="pill"
                                                                        href="#services_tab"
                                                                        role="tab"
                                                                        aria-selected="true"
                                                                    >
                                                                        Serviços
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="card-body p-2">
                                                            <div className="tab-content">
                                                                <div
                                                                    className="tab-pane fade show active"
                                                                    id="services_tab"
                                                                    role="tabpanel"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="card-body table-responsive p-0 resp-services">
                                                                                <CardBody
                                                                                    size="12"
                                                                                    smallSize="12"
                                                                                >
                                                                                    <Table
                                                                                        id="services_table"
                                                                                        columns={
                                                                                            services_columns
                                                                                        }
                                                                                    >
                                                                                        {(data.form_action ===
                                                                                        'view'
                                                                                            ? services.filter(
                                                                                                  (
                                                                                                      service
                                                                                                  ) => {
                                                                                                      const services_ids = getValues(
                                                                                                          'services'
                                                                                                      ).map(
                                                                                                          (
                                                                                                              item
                                                                                                          ) =>
                                                                                                              item.service_id
                                                                                                      );
                                                                                                      return services_ids.includes(
                                                                                                          service.id ||
                                                                                                              ''
                                                                                                      );
                                                                                                  }
                                                                                              )
                                                                                            : services
                                                                                        ).map(
                                                                                            (
                                                                                                item,
                                                                                                index
                                                                                            ) => (
                                                                                                <tr
                                                                                                    key={
                                                                                                        item.id
                                                                                                    }
                                                                                                >
                                                                                                    {/* SERVIÇO */}
                                                                                                    <td className="pl-2">
                                                                                                        {
                                                                                                            item.description
                                                                                                        }
                                                                                                    </td>
                                                                                                    {/* VALOR */}
                                                                                                    <td className="p-0">
                                                                                                        <InputLabelCurrency
                                                                                                            remove_label
                                                                                                            label=""
                                                                                                            name={`services[${index}].service_value`}
                                                                                                            size_label=""
                                                                                                            size_input="12"
                                                                                                            style={{
                                                                                                                borderStyle:
                                                                                                                    'none',
                                                                                                                backgroundColor:
                                                                                                                    'inherit'
                                                                                                            }}
                                                                                                            onChange={() =>
                                                                                                                setServiceValues()
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        )}
                                                                                    </Table>
                                                                                </CardBody>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* IMPOSTOS */}
                                                <div className="col-sm-6">
                                                    <div className="card card-primary card-outline card-outline-tabs mb-0 h-100">
                                                        <div className="card-header p-0 border-bottom-0">
                                                            <ul
                                                                className="nav nav-tabs"
                                                                id="custom-tabs-for-tab"
                                                                role="tablist"
                                                            >
                                                                <li className="nav-item">
                                                                    <a
                                                                        className="nav-link disabled"
                                                                        data-toggle="pill"
                                                                        href="#taxes_tab"
                                                                        role="tab"
                                                                        aria-selected="true"
                                                                    >
                                                                        Impostos
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="card-body p-2">
                                                            <div className="tab-content">
                                                                <div
                                                                    className="tab-pane fade show active"
                                                                    id="taxes_tab"
                                                                    role="tabpanel"
                                                                >
                                                                    <div className="row">
                                                                        <SelectInputLabel
                                                                            required
                                                                            label="CST"
                                                                            name="cst"
                                                                            data_tip={
                                                                                helperTooltips.cst
                                                                            }
                                                                            size_label="2"
                                                                            size_input="10"
                                                                            options={
                                                                                csts
                                                                            }
                                                                            isDisabled
                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Redução BC"
                                                                            name="redbc"
                                                                            data_tip={
                                                                                helperTooltips.redbc
                                                                            }
                                                                            size_label="2"
                                                                            size_input="3"
                                                                            suffix="%"
                                                                        />
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Vlr. BC ICMS"
                                                                            name="vlr_bc_icms"
                                                                            data_tip="Valor da Base de Cálculo do ICMS"
                                                                            size_label="2"
                                                                            size_input="3"
                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Alíq. ICMS"
                                                                            name="aliq_icms"
                                                                            data_tip="Alíquota do ICMS"
                                                                            size_label="2"
                                                                            size_input="3"
                                                                            suffix="%"
                                                                        />
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Vlr. ICMS"
                                                                            name="vlr_icms"
                                                                            data_tip="Valor do ICMS"
                                                                            size_label="2"
                                                                            size_input="3"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className="row"
                                                                        hidden
                                                                    >
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Vle. Cred."
                                                                            name="vlr_cred"
                                                                            size_label="2"
                                                                            size_input="3"
                                                                        />
                                                                        <Label
                                                                            respAlt
                                                                            title="Outorgado / Presumido"
                                                                            col="col-sm-auto text-left"
                                                                            to=""
                                                                            remove_colon
                                                                        />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Vlr. Pis"
                                                                            name="vlr_pis"
                                                                            data_tip="Valor do PIS."
                                                                            size_label="2"
                                                                            size_input="3"
                                                                        />
                                                                        <InputLabelMeasurement
                                                                            disabled
                                                                            precision={
                                                                                2
                                                                            }
                                                                            label="Vlr. Cofins"
                                                                            name="vlr_cofins"
                                                                            data_tip="Valor do Cofins."
                                                                            size_label="2"
                                                                            size_input="3"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <SelectInputLabel
                                                    required
                                                    label="Forma de pagamento"
                                                    data_tip="Selecione a forma de Pagamento."
                                                    name="payment_form"
                                                    size_label="2"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Pago'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'À pagar'
                                                        },
                                                        {
                                                            value: 3,
                                                            label: 'Outros'
                                                        }
                                                    ]}
                                                />
                                                <SelectInputLabel
                                                    required
                                                    label="Tipo"
                                                    name="payment_type"
                                                    data_tip="Tipo do pagamento."
                                                    size_label="2"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Recibo'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Boleto'
                                                        }
                                                    ]}
                                                />
                                                <InputLabel
                                                    required
                                                    label="Vencimento"
                                                    name="due_date"
                                                    data_tip="Data de vencimento"
                                                    size_label="2"
                                                    size_input="2"
                                                    type="date"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelCurrency
                                                    label="Valor (prestação)"
                                                    name="value"
                                                    data_tip="Valor."
                                                    size_label="2"
                                                    size_input="2"
                                                    disabled
                                                />
                                                <InputLabelCurrency
                                                    label="Valor a receber"
                                                    name="receivable_value"
                                                    data_tip="Valor a receber."
                                                    size_label="2"
                                                    disabled
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row">
                                                <Label
                                                    respAlt
                                                    col="col-sm-12 text-left"
                                                    title="Observações da tributação (2000 caracteres)"
                                                    to=""
                                                />
                                                <InputLabelTextArea
                                                    remove_label
                                                    label=""
                                                    size_label=""
                                                    size_input="12"
                                                    name="service_obs"
                                                    rows={5}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* VEÍCULOS / MOTORISTAS */}
                                    <div
                                        className="tab-pane"
                                        id="vehicle_driver"
                                    >
                                        <div className="row mb-3">
                                            <SelectInputLabel
                                                label="Veículos"
                                                name="vehicles"
                                                size_label="1"
                                                size_input="10"
                                                options={vehicles.map(
                                                    (item) => ({
                                                        value: item.id,
                                                        label: `${
                                                            item.license_plate
                                                        } - ${
                                                            item.description
                                                        }${
                                                            item.driver?.name
                                                                ? ` - ${item.driver.name}`
                                                                : ''
                                                        }`,
                                                        driver: item.driver
                                                    })
                                                )}
                                                isMulti
                                            />
                                            <div className="col-sm-1">
                                                <div className="row">
                                                    <div className="col-sm-6 pl-1">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-block btn-sm"
                                                            disabled={
                                                                getValues(
                                                                    'form_action'
                                                                ) === 'view'
                                                            }
                                                            onClick={() => {
                                                                setVehicleModalData(
                                                                    vehicleDefaultValues
                                                                );
                                                                setVehicleModalVisible(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-plus c-success" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <SelectInputLabel
                                                label="Motoristas"
                                                name="drivers"
                                                size_label="1"
                                                size_input="10"
                                                options={driver.map((item) => ({
                                                    value: item.id,
                                                    label: item.name_combo || ''
                                                }))}
                                                isMulti
                                            />
                                            <div className="col-sm-1">
                                                <div className="row">
                                                    <div className="col-sm-6 pl-1">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-block btn-sm"
                                                            disabled={
                                                                getValues(
                                                                    'form_action'
                                                                ) === 'view'
                                                            }
                                                            onClick={() => {
                                                                setPeopleModalData(
                                                                    {
                                                                        ...peopleDefaultValues,
                                                                        driver: true
                                                                    }
                                                                );
                                                                setPeopleModalVisible(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-plus c-success" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* COMP. MOTORISTA */}
                                    <div className="tab-pane" id="driver">
                                        <div className="row mb-1">
                                            <InputLabel
                                                label="RNTRC"
                                                name="rntrc"
                                                data_tip={helperTooltips.rntrc}
                                                size_label="1"
                                                size_input="2"
                                                onChange={(event) =>
                                                    setValue(
                                                        'rntrc',
                                                        event.target.value.toUpperCase()
                                                    )
                                                }
                                            />

                                            <SelectInputLabel
                                                label="Veículo"
                                                name="vehicle_id"
                                                data_tip="Selecione o veículo."
                                                size_label="1"
                                                size_input="7"
                                                options={vehicles.map(
                                                    (item) => ({
                                                        value: item.id,
                                                        label: `${
                                                            item.license_plate
                                                        } - ${
                                                            item.description
                                                        }${
                                                            item.driver?.name
                                                                ? ` - ${item.driver.name}`
                                                                : ''
                                                        }`,
                                                        rntrc: item.rntrc,
                                                        people_driver_id:
                                                            item.people_driver_id
                                                    })
                                                )}
                                                onChange={(op: any) => {
                                                    setValue(
                                                        'rntrc',
                                                        op?.rntrc || ''
                                                    );
                                                    setValue(
                                                        'collaborator_people_id',
                                                        op?.people_driver_id ||
                                                            ''
                                                    );
                                                }}
                                                isClearable
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setVehicleModalData
                                                }
                                                setModalVisible={
                                                    setVehicleModalVisible
                                                }
                                                defaultValues={
                                                    vehicleDefaultValues
                                                }
                                                route="vehicles"
                                                field="vehicle_id"
                                                tip="Veículo"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <SelectInputLabel
                                                label="Colaborador"
                                                name="collaborator_people_id"
                                                options={driver.map((item) => ({
                                                    value: item.id,
                                                    label: item.name_combo || ''
                                                }))}
                                                data_tip="Selecione o motorista."
                                                size_label="1"
                                                size_input="10"
                                                isClearable
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="collaborator_people_id"
                                                tip="Colaborador"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <PaginatedAsyncSelectLabel
                                                optionSearch="name_search"
                                                optionLabel="name_combo"
                                                label="Proprietário"
                                                name="owner_people_id"
                                                data_tip="Selecione o propietário."
                                                size_label="1"
                                                size_input="10"
                                                isClearable
                                            />
                                            <EditIncludeButtons
                                                setLoading={setLoading}
                                                setModalData={
                                                    setPeopleModalData
                                                }
                                                setModalVisible={
                                                    setPeopleModalVisible
                                                }
                                                defaultValues={
                                                    peopleDefaultValues
                                                }
                                                route="people"
                                                field="owner_people_id"
                                                tip="Proprietário"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <SelectInputLabel
                                                label="Quebra"
                                                name="breaking"
                                                data_tip="Selecione o tipo de quebra."
                                                size_label="1"
                                                size_input="2"
                                                options={[
                                                    {value: 1, label: 'Total'},
                                                    {value: 2, label: 'Quebra'}
                                                ]}
                                            />

                                            <InputLabelCurrency
                                                label="Tolerância"
                                                name="tolerance"
                                                data_tip="Valor de Tolerância."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Tarifa frete"
                                                name="freight_rate"
                                                data_tip="Valor da tarifa do frete."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Tarifa pedágio"
                                                name="toll_tariff"
                                                data_tip="Valor da tarifa do pedágio."
                                                size_label="1"
                                                size_input="2"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <InputLabelMeasurement
                                                label="Peso"
                                                name="weight"
                                                data_tip="Peso da carga."
                                                precision={2}
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Frete ton."
                                                name="freight_ton"
                                                data_tip="Frete toneladas."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Valor frete"
                                                name="freight_value"
                                                data_tip="Valor do frete."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Valor pedágio"
                                                name="toll_value"
                                                data_tip="Valor do pedágio."
                                                size_label="1"
                                                size_input="2"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <InputLabelCurrency
                                                label="Valor seguro"
                                                name="insurance_value"
                                                data_tip="Valor do seguro."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Desconto"
                                                name="discount"
                                                data_tip="Valor do desconto."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Frete + pedág."
                                                name="freight_toll"
                                                data_tip="Valor do frete somado ao pedágio."
                                                size_label="1"
                                                size_input="2"
                                            />

                                            <InputLabelCurrency
                                                label="Adiantamento"
                                                name="advance"
                                                data_tip="Valor do adiantamento."
                                                size_label="1"
                                                size_input="2"
                                            />
                                        </div>
                                        <div className="row mb-1">
                                            <InputLabelCurrency
                                                label="Saldo"
                                                name="balance"
                                                data_tip="Valor do saldo."
                                                size_label="1"
                                                size_input="2"
                                            />
                                        </div>
                                    </div>

                                    {/* OBSERVAÇÕES */}
                                    <div className="tab-pane" id="obs">
                                        <div className="col-sm-12 mb-3 row">
                                            {/* CONTRIBUINTE */}
                                            <div className="col-sm-6">
                                                <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                    <div className="card-header p-0 border-bottom-0">
                                                        <ul
                                                            className="nav nav-tabs"
                                                            id="custom-tabs-for-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link disabled"
                                                                    data-toggle="pill"
                                                                    href="#obs1"
                                                                    role="tab"
                                                                    aria-selected="true"
                                                                >
                                                                    Observações
                                                                    de interesse
                                                                    do
                                                                    contribuinte
                                                                    (160
                                                                    caracteres)
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="card-body pl-0 pr-1">
                                                        <div className="tab-content">
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="obs1"
                                                                role="tabpanel"
                                                            >
                                                                <div className="pl-3">
                                                                    <InputLabelTextArea
                                                                        label=""
                                                                        remove_label
                                                                        size_label=""
                                                                        size_input="12"
                                                                        name="obs_taxpayer"
                                                                        rows={5}
                                                                        placeholder="..."
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* FISCO */}
                                            <div className="col-sm-6">
                                                <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                    <div className="card-header p-0 border-bottom-0">
                                                        <ul
                                                            className="nav nav-tabs"
                                                            id="custom-tabs-for-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link disabled"
                                                                    data-toggle="pill"
                                                                    href="#obs1"
                                                                    role="tab"
                                                                    aria-selected="true"
                                                                >
                                                                    Observações
                                                                    de interesse
                                                                    do fisco (60
                                                                    caracteres)
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="card-body pl-0 pr-1">
                                                        <div className="tab-content">
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="obs1"
                                                                role="tabpanel"
                                                            >
                                                                <div className="pl-3">
                                                                    <InputLabelTextArea
                                                                        label=""
                                                                        remove_label
                                                                        size_label=""
                                                                        size_input="12"
                                                                        name="obs_fiscal"
                                                                        rows={5}
                                                                        placeholder="..."
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* GERAIS */}
                                        <div className="col-sm-12">
                                            <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                <div className="card-header p-0 border-bottom-0">
                                                    <ul
                                                        className="nav nav-tabs"
                                                        id="custom-tabs-for-tab"
                                                        role="tablist"
                                                    >
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link disabled"
                                                                data-toggle="pill"
                                                                href="#obs2"
                                                                role="tab"
                                                                aria-selected="true"
                                                            >
                                                                Observações
                                                                gerais (2000
                                                                caracteres)
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="card-body pl-0 pr-1">
                                                    <div className="tab-content">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="obs2"
                                                            role="tabpanel"
                                                        >
                                                            <div className="pl-3">
                                                                <InputLabelTextArea
                                                                    label=""
                                                                    remove_label
                                                                    size_label=""
                                                                    size_input="12"
                                                                    name="obs_general"
                                                                    rows={5}
                                                                    placeholder="..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                {getValues('form_action') !== 'view' && (
                                    <>
                                        <label
                                            htmlFor="nfe_xml"
                                            className="col-form-label cursor-pointer"
                                            data-tip="Importar o arquivo XML de uma NF-e"
                                            data-for="info-button"
                                        >
                                            Importar NFe
                                        </label>
                                        <input
                                            id="nfe_xml"
                                            type="file"
                                            accept=".xml"
                                            style={{display: 'none'}}
                                            onChange={(event) => {
                                                readXmlFile(
                                                    event,
                                                    setValue,
                                                    getValues,
                                                    reset,
                                                    setLoading,
                                                    cities,
                                                    () => setPeopleRefresh(),
                                                    'nfe'
                                                );
                                            }}
                                        />

                                        <hr />

                                        <label
                                            htmlFor="cte_xml"
                                            className="col-form-label cursor-pointer"
                                            data-tip="Importar o arquivo XML de um CT-e"
                                            data-for="info-button"
                                        >
                                            Importar CTe
                                        </label>
                                        <input
                                            id="cte_xml"
                                            type="file"
                                            accept=".xml"
                                            style={{display: 'none'}}
                                            onChange={(event) => {
                                                readXmlFile(
                                                    event,
                                                    setValue,
                                                    getValues,
                                                    reset,
                                                    setLoading,
                                                    cities,
                                                    () => setPeopleRefresh(),
                                                    'cte'
                                                );
                                            }}
                                        />

                                        <hr />

                                        <label
                                            htmlFor="dfe_xml"
                                            className="col-form-label cursor-pointer"
                                            data-tip="Importar o arquivo XML de um DF-e"
                                            data-for="info-button"
                                        >
                                            Importar DFe
                                        </label>
                                        <input
                                            id="dfe_xml"
                                            style={{display: 'none'}}
                                            onClick={() =>
                                                setDfesModalVisible(true)
                                            }
                                        />
                                    </>
                                )}

                                <hr />
                                {data.form_action !== 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />

                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                            isLoading={loading}
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                                {data.form_action === 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={hide}
                                        />
                                    </div>
                                )}
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>

            {modalNfeVisible && (
                <ModalNfe
                    hide={() => setModalNfeVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {modalNfManualVisible && (
                <ModalNfManual
                    hide={() => setModalNfManualVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {modalOthersVisible && (
                <ModalOthers
                    hide={() => setModalOthersVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {modalPreviousDocsVisible && (
                <ModalPreviousDocs
                    hide={() => setModalPreviousDocsVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                    resetTable={reset}
                    selectedPreviousDoc={selectedPreviousDoc}
                    refresh={setPeopleRefresh}
                />
            )}

            {modalLoadVisible && (
                <ModalLoad
                    hide={() => setModalLoadVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {peopleModalVisible && (
                <PeopleModal
                    data={peopleModalData}
                    hide={() => setPeopleModalVisible(false)}
                    refresh={setPeopleRefresh}
                />
            )}

            {vehicleModalVisible && (
                <VehicleModal
                    data={vehicleModalData}
                    hide={() => setVehicleModalVisible(false)}
                    refresh={() => setVehicleRefresh(Math.random())}
                />
            )}

            {cfopModalVisible && (
                <CFOPModal
                    data={{
                        id: '',
                        description: '',
                        cfop: '',
                        form_action: 'include'
                    }}
                    hide={() => setCfopModalVisible(false)}
                    refresh={() => setCfopRefresh(Math.random())}
                />
            )}
            {dfesModalVisible && (
                <DFEsModal
                    getValues={getValues}
                    hide={() => setDfesModalVisible(false)}
                    reset={reset}
                    setLoading={setLoading}
                    setValue={setValue}
                    setImportedDfes={setImportedDfes}
                />
            )}

            {modalCtesVisible && (
                <CTEsModal
                    hide={() => setModalCtesVisible(false)}
                    setValue={setValue}
                    field="cte_complement_key"
                />
            )}

            <ReactTooltip
                id="tip"
                place="top"
                backgroundColor="#42494E"
                effect="solid"
                globalEventOff="click"
            />

            {loading && <FullPageLoader />}
        </>
    );
};

export default ModalCTe;
