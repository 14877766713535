import * as Yup from 'yup';
import {Cte_NFe, Cte_NFManual, Cte_Other} from '../../../interfaces';

const validation = Yup.object({
    environment_inssuer_id: Yup.string()
        .required('O emitente deve ser informado.')
        .nullable(),
    type: Yup.number().required('O tipo de emissão deve ser informado.'),
    taker: Yup.number().required('O tomador deve ser informado.'),
    nature: Yup.number().required('A natureza da emissão deve ser informada.'),
    date_emission: Yup.string().required(
        'A data da emissão deve ser informada.'
    ),
    cfop_id: Yup.string().required('O CFOP deve ser informado.').nullable(),
    taker_people_id: Yup.string()
        .nullable()
        .when('taker', {
            is: 4,
            then: Yup.string()
                .required('O tomador deve ser informado.')
                .nullable()
        }),
    sender_people_id: Yup.string()
        .required('O remetente deve ser informado.')
        .nullable(),
    recipient_people_id: Yup.string()
        .required('O destinatário deve ser informado.')
        .nullable(),
    dispatcher_people_id: Yup.string()
        .nullable()
        .when('taker', {
            is: 1,
            then: Yup.string()
                .required('O expedidor deve ser informado caso seja o tomador.')
                .nullable()
        }),
    previous_documents: Yup.array()
        .nullable()
        .when('type', {
            is: (value: number) => value === 1 || value === 2 || value === 3,
            then: Yup.array()
                .min(
                    1,
                    'Em caso de sub-contratação, redespacho ou redespacho intermediario, deve-se informar um documento de transporte anterior.'
                )
                .nullable()
        }),
    cte_complement_key: Yup.string()
        .nullable()
        .when('nature', {
            is: 1,
            then: Yup.string()
                .required(
                    'Informe a chave complementar para emitir um CT-e de complemento de valores'
                )
                .nullable()
        }),
    service_additional_transporter: Yup.string()
        .nullable()
        .test(
            'service_additional_transporter',
            'A caracteristica adicional do transporte deve conter no máximo 15 caracteres.',
            (value) => {
                if (
                    value !== undefined &&
                    value !== null &&
                    value?.length > 0
                ) {
                    return value?.length <= 15;
                }
                return true;
            }
        ),
    service_additional_feature: Yup.string()
        .nullable()
        .test(
            'service_additional_feature',
            'A caracteristica adicional do serviço deve conter no máximo 30 caracteres.',
            (value) => {
                if (
                    value !== undefined &&
                    value !== null &&
                    value?.length > 0
                ) {
                    return value?.length <= 30;
                }
                return true;
            }
        ),
    receiver_people_id: Yup.string()
        .nullable()
        .when('taker', {
            is: 2,
            then: Yup.string()
                .required('O recebedor deve ser informado caso seja o tomador.')
                .nullable()
        }),
    city_origin_id: Yup.string()
        .required('A cidade de origem deve ser informada.')
        .nullable(),
    city_destination_id: Yup.string()
        .required('A cidade de destino deve ser informada.')
        .nullable(),
    commodity_value: Yup.string().required(
        'O valor da mercadoria deve ser informado.'
    ),
    cte_annulment_date: Yup.string()
        .nullable()
        .when('nature', {
            is: (value: number) => value === 2,
            then: Yup.string()
                .required(
                    'A data de emissão do CT-e anulado deve ser informada'
                )
                .nullable()
        }),
    cte_annulment_key: Yup.string()
        .nullable()
        .when('nature', {
            is: (value: number) => value === 2,
            then: Yup.string()
                .required('A chave do CT-e anulado deve ser informada')
                .nullable()
        }),
    cte_replacement_key: Yup.string()
        .nullable()
        .when('nature', {
            is: (value: number) => value === 3,
            then: Yup.string()
                .required('A chave do CT-e anulado deve ser informada')
                .nullable()
        }),
    predominant_product: Yup.string()
        .nullable()
        .when('nature', {
            is: (value: number) => value !== 1 && value !== 2,
            then: Yup.string()
                .required('O produto predominante deve ser informado.')
                .nullable()
        }),
    other_features: Yup.string()
        .max(
            30,
            'Outras características da carga deve conter no máximo 30 caracteres'
        )
        .nullable(),
    cst: Yup.string().required('O CST deve ser informado.').nullable(),
    value: Yup.number().min(0, 'O valor da prestação não pode ser negativo.'),
    receivable_value: Yup.number().min(
        0,
        'O valor a receber não pode ser negativo.'
    ),
    rntrc: Yup.string()
        .nullable()
        .test(
            'rntrc',
            'Caso preenchido, o RNTRC deve conter 8 dígitos, ou ser "ISENTO".',
            (value) => {
                if (
                    value !== undefined &&
                    value !== null &&
                    value !== 'ISENTO' &&
                    value?.length > 0
                ) {
                    return value?.length === 8;
                }
                return true;
            }
        ),
    payment_form: Yup.number().required(
        'A forma de pagamento deve ser informada.'
    ),
    due_date: Yup.string().required('Informe a data de vencimento.'),
    service_obs: Yup.string()
        .max(
            2000,
            'Observação da tributação deve conter no máximo 2000 caracteres'
        )
        .nullable(),
    tables_restrict: Yup.string().when(['nfes', 'manual_nfs', 'others'], {
        is: (
            nfes: Cte_NFe[],
            manual_nfs: Cte_NFManual[],
            others: Cte_Other[]
        ) =>
            (nfes.length !== 0 && manual_nfs.length !== 0) ||
            (nfes.length !== 0 && others.length !== 0) ||
            (manual_nfs.length !== 0 && others.length !== 0),
        then: Yup.string().test(
            'Apenas um tipo de doc',
            'Apenas um tipo de documento, entre NF-e, NF Manual e Outros, deve ser informado.',
            () => false
        )
    }),
    loads: Yup.array().when('nature', {
        is: (value: number) => value !== 1 && value !== 2,
        then: Yup.array().min(
            1,
            'Deve-se incluir ao menos uma Informação de Carga.'
        )
    }),
    obs_general: Yup.string()
        .max(2000, 'A observação geral deve conter no máximo 2000 caracteres')
        .nullable(),
    obs_taxpayer: Yup.string()
        .max(
            160,
            'A observação do contribuinte deve conter no máximo 160 caracteres'
        )
        .nullable(),
    obs_fiscal: Yup.string()
        .max(60, 'A observação do fisco deve conter no máximo 60 caracteres')
        .nullable()
});

export default validation;
