/* eslint-disable no-param-reassign */
import {UseFormGetValues, UseFormReset, UseFormSetValue} from 'react-hook-form';
import Swal from 'sweetalert2';
import {CityProps, CTeProps} from '../interfaces';
import importCTeXML from './importCTeXML';
import importNFeXML from './importNFeXML';

// eslint-disable-next-line
const parseString = require('xml2js').parseString;

function readXmlFile(
    event: React.ChangeEvent<HTMLInputElement>,
    setValue: UseFormSetValue<CTeProps>,
    getValues: UseFormGetValues<CTeProps>,
    reset: UseFormReset<CTeProps>,
    setLoading: (loading: boolean) => void,
    cities: CityProps[],
    refreshPeople: () => void,
    type: 'cte' | 'nfe',
    setImportedDfes?: () => void
) {
    if (event.target.files?.length !== 0) {
        setLoading(true);
        const reader = new FileReader();
        reader.onload = function (e) {
            const xml = e.target?.result;
            parseString(
                xml,
                {
                    explicitArray: false
                },
                async (error: any, result: any) => {
                    try {
                        if (type === 'nfe') {
                            await importNFeXML(
                                result.nfeProc.NFe.infNFe,
                                setValue,
                                getValues,
                                reset,
                                cities,
                                setImportedDfes
                                    ? () => setImportedDfes()
                                    : undefined
                            );
                        } else {
                            await importCTeXML(
                                result.cteProc.CTe.infCte,
                                setValue,
                                getValues,
                                reset,
                                cities
                            );
                        }
                        setLoading(false);
                    } catch (errorxml) {
                        setLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: `Erro ao importar ${
                                type === 'cte' ? 'CTe' : 'NFe'
                            }`,
                            text: `O arquivo selecionado deve ser ${
                                type === 'cte' ? 'um CTe' : 'uma NFe'
                            } no formato .XML`
                        });
                    }
                }
            );
        };
        reader.readAsText(
            event.target.files ? (event.target.files[0] as Blob) : ('' as any)
        );
        event.target.value = '';
    }
}

export default readXmlFile;
