function formatDate(date: string | undefined | null, dfe?: boolean): string {
    return (
        date
            ?.split(dfe ? ' ' : 'T')[0]
            .split('-')
            .reverse()
            .join('/') || ''
    );
}

export default formatDate;
