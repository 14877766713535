import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import FullPageLoader from '../../../components/FullPageLoader';
import api from '../../../services/api';
import columns from './schema';
import CardBody from '../../../components/CardBody';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import {LogsProps, TableOrder, UserFormProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import {events, tables} from '../../../data';
import getDifferenceBetweenDates from '../../../utils/getDifferenceBetweenDates';
import formatDate from '../../../utils/formatDate';
import formatDateToHour from '../../../utils/formatDateToHour';

interface Props {
    hide: () => void;
}

interface SearchProps {
    user_id: string;
    event: string;
    table: string;
    start_date: string;
    end_date: string;
    description: string;
}

const searchDefaultValues: SearchProps = {
    user_id: '',
    event: '',
    table: '',
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    description: ''
};

const LogsModal: React.FC<Props> = ({hide}) => {
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState<LogsProps[]>([]);
    const [users, setUers] = useState<UserFormProps[]>([]);
    const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
    const [order, setOrder] = useState<TableOrder>({
        order: 'created_at',
        type: 'desc'
    });
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const methods = useForm<SearchProps>({
        defaultValues: searchDefaultValues
    });
    const {handleSubmit, reset} = methods;

    useEffect(() => {
        reset(searchDefaultValues);
    }, []);

    useEffect(() => {
        setLoading(true);
        api.get(`usersCombo?order=name&type=asc`)
            .then((response) => {
                setUers(response.data);
            })
            .catch(() => toast.error('Erro ao buscar usuários'))
            .finally(() => setLoading(false));
    }, []); // GET USERS

    useEffect(() => {
        setLoading(true);
        api.get(
            `logs?page=${page}&limit=20&order=${order.order}&type=${order.type}&user_id=${search.user_id}&event=${search.event}&table=${search.table}&start_date=${search.start_date}&end_date=${search.end_date}&description=${search.description}`
        )
            .then((response) => {
                setLogs(response.data.data);
                setCount(response.data.meta.total);
            })
            .catch(() => {
                toast.error('Erro ao gerar logs');
                hide();
            })
            .finally(() => setLoading(false));
    }, [search, order, page]); // GET LOGS

    const onSubmit = useCallback(async (dataSubmit: SearchProps) => {
        const start = new Date(dataSubmit.start_date);
        const end = new Date(dataSubmit.end_date);

        if (
            getDifferenceBetweenDates(start, end) >= 0 &&
            getDifferenceBetweenDates(start, end) <= 30
        ) {
            setSearch(dataSubmit);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'O período da pesquisa deve compreender de 1 a 31 dias.'
            });
        }
    }, []);

    return (
        <Modal show dialogAs="div">
            <Modal.Dialog scrollable size="xl" className="mdf-modal" centered>
                <Modal.Header className="bg-primary">
                    <Modal.Title>Logs do Sistema</Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-1">
                    <FormProvider {...methods}>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className="row"
                                style={{
                                    justifyContent: 'start'
                                }}
                            >
                                <SelectInputLabel
                                    label="Evento"
                                    name="event"
                                    data_tip="Tipo de evento"
                                    size_label="1"
                                    size_input="2"
                                    options={events}
                                />

                                <SelectInputLabel
                                    label="Tabela"
                                    name="table"
                                    data_tip="Local do evento"
                                    size_label="1"
                                    size_input="3"
                                    options={tables}
                                />

                                <SelectInputLabel
                                    label="Usuário"
                                    name="user_id"
                                    data_tip="Usuário que gerou o evento
                                        "
                                    size_label="1"
                                    size_input="4"
                                    options={[
                                        {value: '', label: 'QUALQUER'},
                                        {value: user.id, label: user.name},
                                        ...users.map((item) => ({
                                            value: item.id,
                                            label: item.name
                                        }))
                                    ]}
                                />
                            </div>
                            <div
                                className="row"
                                style={{
                                    justifyContent: 'start'
                                }}
                            >
                                <InputLabel
                                    label="Data"
                                    name="start_date"
                                    data_tip="Filtrar a partir de
                                        determinada data"
                                    size_label="1"
                                    size_input="2"
                                    type="date"
                                />

                                <InputLabel
                                    label="Até"
                                    name="end_date"
                                    data_tip="Filtrar até determinada
                                        dat"
                                    size_label="1"
                                    size_input="2"
                                    type="date"
                                />

                                <InputLabel
                                    label="Descrição"
                                    name="description"
                                    data_tip="Procurar por um texto
                                        especifico"
                                    size_label="1"
                                    size_input="4"
                                />

                                <div className="col-sm-1 pr-3">
                                    <button
                                        type="submit"
                                        className="btn btn-default btn-block btn-sm"
                                    >
                                        <i className="fa fa-search c-success" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                    <CardBody
                        size="12"
                        smallSize="12"
                        page={page}
                        count={count}
                        setPage={setPage}
                    >
                        <Table
                            id="logs"
                            columns={columns}
                            setOrder={setOrder}
                            order={order.order}
                            type={order.type}
                        >
                            {logs.map((log) => (
                                <tr key={Math.random()}>
                                    {/* DATA */}
                                    <td className="pt-2 pl-2 ">
                                        {formatDate(log.created_at)}
                                    </td>
                                    {/* HORA */}
                                    <td className="pt-2">
                                        {formatDateToHour(log.created_at)}
                                    </td>
                                    {/* USUÁRIO */}
                                    <td className="pt-2 short-td">
                                        {
                                            [...users, user].find(
                                                (item) =>
                                                    item.id === log.user_id
                                            )?.name
                                        }
                                    </td>
                                    {/* EVENTO */}
                                    <td className="pt-2">
                                        {events.find(
                                            (item) => item.value === log.event
                                        )?.label || log.event}
                                    </td>
                                    {/* TABELA */}
                                    <td className="pt-2">
                                        {tables.find(
                                            (item) => item.value === log.table
                                        )?.label || log.table}
                                    </td>
                                    {/* DESCRIÇÃO */}
                                    <td className="pt-2 break-td">
                                        {log.description}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </CardBody>
                </Modal.Body>

                <Modal.Footer
                    style={{
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}
                >
                    <div className="float-left responsive-button">
                        <Button
                            type="button"
                            color="danger"
                            size="btn btn-default"
                            icon="fa fa-arrow-circle-left"
                            caption="Retornar"
                            onClick={hide}
                        />
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default LogsModal;
