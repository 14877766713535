import React, {useState} from 'react';
import LogsModal from '../../../Modals/Logs';

const LogsItem: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <a
                className="dropdown-item cursor-pointer desktop"
                onClick={() => setModalVisible(true)}
            >
                Logs do Sistema
            </a>
            <a
                className="dropdown-item cursor-pointer mobile"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                onClick={() => setModalVisible(true)}
            >
                Logs do Sistema
            </a>

            {modalVisible && <LogsModal hide={() => setModalVisible(false)} />}
        </>
    );
};

export default LogsItem;
