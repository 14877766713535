const columns = [
    {
        text: 'Emissão',
        className: 'text-max-sm link-button',
        name: 'emission_date',
        width: '1%'
    },
    {
        text: 'N° NF-e',
        className: 'text-max-sm link-button',
        name: 'nfe_number',
        width: '1%'
    },
    {
        text: 'Emitente',
        className: 'text-max-sm link-button',
        name: 'issuer',
        width: '100%'
    },
    {
        text: 'CNPJ',
        className: 'text-max-sm link-button',
        name: 'issuer_cnpj',
        width: '1%'
    },
    {
        text: 'Vlr. NF-e',
        className: 'text-max-sm link-button',
        name: 'total_value',
        width: '1%'
    },
    {
        text: 'Situação SEFAZ',
        className: 'text-max-sm link-button',
        name: 'sefaz_situation',
        width: '1%'
    },
    {
        text: 'Situação DF-e',
        className: 'text-max-sm link-button',
        name: 'situation',
        width: '1%'
    },
    {
        text: 'Importado',
        className: 'text-max-sm link-button',
        name: 'imported',
        width: '1%'
    },
    {text: 'Ações', width: '1%', className: 'text-max-sm px-1 text-center'}
];

export default columns;
