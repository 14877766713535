/* eslint-disable no-lone-blocks */
/* eslint-disable react/self-closing-comp */
import React, {useState} from 'react';
import {useAuth} from '../../../hooks/Auth';
import convertLocalDateStringToISO from '../../../utils/convertLocalDateStringToISO';
import formatDate from '../../../utils/formatDate';
import getDifferenceBetweenDates from '../../../utils/getDifferenceBetweenDates';
import EnvironmentModal from '../../Modals/Environment';

const Footer: React.FC = () => {
    const {user, enviromentText} = useAuth();
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <footer className="main-footer d-flex pt-footer">
                <div className="pl-1 pr-5 mr-5 tour-footer--01">
                    <strong
                        className="text-primary title-footer"
                        onClick={() => setModalVisible(true)}
                        data-tip="Vizualizar Meus Ambientes"
                        data-for="info-dark"
                    >
                        Meu ambiente:{' '}
                    </strong>
                    {modalVisible && (
                        <EnvironmentModal hide={() => setModalVisible(false)} />
                    )}
                    <span>{enviromentText || 'Não selecionado.'}</span>
                </div>
                <div className="pl-1 pr-5 mr-5 tour-footer--02">
                    {user.environment?.certificate_validity ? (
                        <strong
                            className={`text-${
                                getDifferenceBetweenDates(
                                    new Date(),
                                    new Date(
                                        convertLocalDateStringToISO(
                                            user.environment
                                                ?.certificate_validity
                                        )
                                    )
                                ) > 30
                                    ? 'secondary'
                                    : 'danger'
                            }`}
                        >
                            Validade do certificado:{' '}
                        </strong>
                    ) : (
                        <strong className="text-secondary">
                            Validade do certificado:{' '}
                        </strong>
                    )}
                    <span>
                        {user.environment &&
                        user.environment?.certificate_validity !== null
                            ? formatDate(user.environment?.certificate_validity)
                            : 'Nenhum certificado cadastrado.'}
                    </span>
                </div>
                <div className="disabled__support tour-footer--03"></div>
            </footer>
        </>
    );
};

export default Footer;
