const others_columns = [
    {text: 'Doc. Originário', width: '10% ', className: 'text-max-sm'},
    {text: 'Descrição', width: '60% ', className: 'text-max-sm'},
    {text: 'Número', width: '10% ', className: 'text-max-sm'},
    {text: 'Emissão', width: '10% ', className: 'text-max-sm'},
    {text: 'Valor', width: '10% ', className: 'text-max-sm'},
    {text: 'Remover', className: 'text-max-sm px-1 text-center'}
];

export default others_columns;
