const columns = [
    {
        text: 'Cód.',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'N°',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Emissão',
        className: 'text-max-sm'
    },
    {
        text: 'Remetente',
        width: '25%',
        className: 'text-max-sm'
    },
    {
        text: 'Destinatário',
        width: '25%',
        className: 'text-max-sm'
    },
    {
        text: 'Chave',
        width: '100%',
        className: 'text-max-sm'
    },
    {text: '', className: 'text-max-sm px-1 text-center'}
];

export default columns;
