export default function getDifferenceBetweenDates(start: Date, end: Date) {
    const utc1 = Date.UTC(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
    );
    const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
}
