import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import columns from './schema';
import CardBody from '../../../components/CardBody';
import {PeopleProps} from '../../../interfaces';
import api from '../../../services/api';
import FullPageLoader from '../../../components/FullPageLoader';

interface Props {
    hide: () => void;
    refresh: () => void;
}

const RecoverModal: React.FC<Props> = ({hide, refresh}) => {
    const [people, setPeople] = useState<PeopleProps[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.get(`recoverPeople?order=deleted_at&type=desc`)
            .then((response) => setPeople(response.data))
            .catch(() => {
                toast.error('Não foi possível efetuar a consulta');
                hide();
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <Modal show dialogAs="div">
            <Modal.Dialog scrollable size="xl" className="mdf-modal" centered>
                <Modal.Header className="bg-primary">
                    <Modal.Title>Restaurar Pessoas excluídas</Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-1">
                    <CardBody size="12" smallSize="12">
                        <Table id="eventsTable" columns={columns}>
                            {people.map((item) => (
                                <tr key={Math.random()}>
                                    {/* DATA */}
                                    <td className="pt-2 pl-2 ">
                                        {item.deleted_at
                                            ?.split('T')[0]
                                            .split('-')
                                            .reverse()
                                            .join('/')}
                                    </td>
                                    {/* RAZÃO */}
                                    <td className="pt-2 break-td ">
                                        {item.corporate_name}
                                    </td>
                                    {/* FANTASIA */}
                                    <td className="pt-2 break-td">
                                        {item.name}
                                    </td>
                                    {/* CPF/CNPJ */}
                                    <td className="pt-2 break-td">
                                        {item.cpf_cnpj}
                                    </td>
                                    {/* CIDADE */}
                                    <td className="pt-2 break-td">
                                        {`${item.description} - ${item.initials}`}
                                    </td>
                                    {/* RESTAURAR */}
                                    <td className="p-0 text-center">
                                        <button
                                            type="button"
                                            className="btn btn-default btn-sm btn-block"
                                            onClick={() => {
                                                Swal.fire({
                                                    title:
                                                        'Deseja restaurar este registro?',
                                                    icon: 'question',
                                                    showCancelButton: true,
                                                    showCloseButton: true,
                                                    reverseButtons: true,
                                                    confirmButtonText: 'Sim',
                                                    cancelButtonText: 'Não'
                                                }).then(async (result) => {
                                                    if (result.value) {
                                                        setLoading(true);
                                                        api.put(
                                                            `recoverPeople/${item.id}`
                                                        )
                                                            .then(() => {
                                                                toast.success(
                                                                    'Pessoa restaurada com sucesso!'
                                                                );
                                                                refresh();
                                                                hide();
                                                            })
                                                            .catch(() =>
                                                                toast.error(
                                                                    'Não foi possível restaurar a pessoa'
                                                                )
                                                            )
                                                            .finally(() =>
                                                                setLoading(
                                                                    false
                                                                )
                                                            );
                                                    }
                                                });
                                            }}
                                        >
                                            <i className="fa fa-trash-restore-alt" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </CardBody>
                </Modal.Body>

                <Modal.Footer
                    style={{
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}
                >
                    <div className="float-left responsive-button">
                        <Button
                            type="button"
                            color="danger"
                            size="btn btn-default"
                            icon="fa fa-arrow-circle-left"
                            caption="Retornar"
                            onClick={hide}
                        />
                    </div>
                </Modal.Footer>
            </Modal.Dialog>

            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default RecoverModal;
