function getMeasurementType(unit: string) {
    switch (unit) {
        case '00':
            return 'PESO CUBADO';
        case '01':
            return 'PESO BRUTO';
        case '02':
            return 'PESO BRUTO';
        case '03':
            return 'UNIDADES';
        case '04':
            return 'LITRAGEM';
        case '05':
            return 'PESO CUBADO';
        default:
            return 'PESO BRUTO';
    }
}

export default getMeasurementType;
