/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Tour from 'reactour';
import {useForm, FormProvider} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import {useAuth} from '../../hooks/Auth';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import columns from './schema';
import ModalForm from './Modal';
import {TableOrder, VehicleProps} from '../../interfaces';
import {tourStepsVehicle} from '../../data/tour';
import {vehicleDefaultValues} from '../../data';
import SaveOrderButton from '../../components/SaveOrderButton';
import SelectInputLabel from '../../components/SelectInputLabel';
import importLegacyJSON from '../../services/importLegacyJSON';

const Vehicles: React.FC = () => {
    const {user, updateUser, tablesOrder} = useAuth();
    const tableOrder = tablesOrder.find((item) => item.table === 'vehicles');
    const [isTourOpen, setIsTourOpen] = useState(user.tour_tables || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };
    const [order, setOrder] = useState<TableOrder>({
        order: tableOrder?.field || 'created_at',
        type: tableOrder?.type || 'desc'
    });
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [vehicles, setVehicles] = useState<VehicleProps[]>([]);
    const [modalData, setModalData] = useState<VehicleProps>(
        {} as VehicleProps
    );

    function resetData() {
        setModalData(vehicleDefaultValues);
    }

    async function showVehiclhe(id: string, form_action: 'view' | 'edit') {
        setLoading(true);
        api.get(`vehicles/${id}`)
            .then((response) => {
                setModalData({
                    ...response.data,
                    form_action
                });
                setModalVisible(true);
            })
            .catch(() => toast.error('Não foi possível efetuar a consulta'))
            .finally(() => setLoading(false));
    }

    async function getVehicles() {
        try {
            setLoading(true);
            const response = await api.get(
                `vehicles?page=${page}&limit=20&order=${order.order}&type=${order.type}&search=${search}&active=${active}`
            );
            setVehicles(response.data.data);
            setCount(response.data.meta.total);
            setPerPage(response.data.meta.per_page);
            setCurrentPage(response.data.meta.current_page);
            setLoading(false);
        } catch (err) {
            setVehicles([]);
            toast.error('Não foi possível efetuar a consulta');
            setLoading(false);
        }
    }

    function handleDelete(
        id: string,
        description: string,
        license_plate: string,
        renavam: string
    ) {
        Swal.fire({
            title: 'Confirmar exclusão do registro?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                setLoading(true);
                api.delete(
                    `vehicles/${id}?license_plate=${license_plate}&description=${description}&renavam=${renavam}`
                )
                    .then(() => {
                        setRefresh(Math.random);
                        toast.success('Registro excluído com sucesso');
                    })
                    .catch((error) =>
                        toast.error(
                            error.response?.data ||
                                'Ocorreu um erro ao excluir o registro'
                        )
                    )
                    .finally(() => setLoading(false));
            }
        });
    }

    useEffect(() => {
        getVehicles();
    }, [page, refresh, order, active]);

    const mapTable = useMemo(
        () =>
            vehicles.map((data) => (
                <tr key={data.id}>
                    <td className="pt-2">{data.license_plate}</td>
                    <td className="pt-2">{data.description}</td>
                    <td className="pt-2">{data.driver_name}</td>
                    <td className="pt-2">{data.renavam}</td>
                    <td className="pl-4">
                        <ActionButton
                            view={async () => {
                                await showVehiclhe(data.id || '', 'view');
                            }}
                            edit={async () => {
                                await showVehiclhe(data.id || '', 'edit');
                            }}
                            delete={() => {
                                handleDelete(
                                    data.id || '',
                                    data.description,
                                    data.license_plate,
                                    data.renavam
                                );
                            }}
                        />
                    </td>
                </tr>
            )),
        [vehicles]
    );

    const methods = useForm({defaultValues: {active: true}});

    return (
        <>
            <div className="content-header desktop">
                <div className="title-page">
                    <FormProvider {...methods}>
                        <form autoComplete="off">
                            <div className="container-fluid pl-1 pr-2 d-flex">
                                <TitlePage
                                    title="Veículos"
                                    icon="fa-truck"
                                    tabRight
                                    pagination
                                    count={count}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    customCol="3"
                                />
                                <div className="tab-min-top col-3 text-right mt-1">
                                    <SaveOrderButton
                                        id={tableOrder?.id as string}
                                        table="vehicles"
                                        field={order.order}
                                        type={order.type}
                                        setOrder={setOrder}
                                    />

                                    <Button
                                        onClick={() => {
                                            resetData();
                                            setModalVisible(true);
                                        }}
                                        className="tour-vehicle--01"
                                        type="button"
                                        color="success"
                                        caption="Incluir"
                                        icon="fa-plus"
                                        maxSize
                                    />

                                    <label
                                        htmlFor="json_input"
                                        id="btn-import"
                                        className="cursor-pointer btn btn-default"
                                        data-tip="Importar cadastros via JSON"
                                        data-for="vehicles-main-page-tooltip"
                                        style={{marginLeft: 6}}
                                    >
                                        <i className="fas fa-download" />
                                    </label>

                                    <input
                                        id="json_input"
                                        type="file"
                                        accept=".json"
                                        style={{display: 'none'}}
                                        onChange={(event) => {
                                            const reader = new FileReader();
                                            reader.onload = async function (e) {
                                                const json = e.target
                                                    ?.result as string;
                                                await importLegacyJSON(
                                                    json,
                                                    'legacyVehicles',
                                                    setLoading,
                                                    () =>
                                                        setRefresh(
                                                            Math.random()
                                                        )
                                                );
                                            };
                                            reader.readAsText(
                                                event.target.files
                                                    ? (event.target
                                                          .files[0] as Blob)
                                                    : ('' as any),
                                                'ISO-8859-1'
                                            );
                                            event.target.value = '';
                                        }}
                                    />
                                </div>

                                <div className="col-sm-4 col-xs-12 tab-min-top">
                                    <div className="input-group tab-max-left tour-vehicle--02">
                                        <input
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (page === 1) {
                                                        setRefresh(
                                                            Math.random()
                                                        );
                                                    } else {
                                                        setPage(1);
                                                    }
                                                }
                                            }}
                                            type="text"
                                            placeholder="Buscar por placa, descrição, motorista ou renavam"
                                            className="form-control"
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                        <span className="input-group-append">
                                            <Button
                                                type="button"
                                                color="default"
                                                onClick={() => {
                                                    if (page === 1) {
                                                        setRefresh(
                                                            Math.random()
                                                        );
                                                    } else {
                                                        setPage(1);
                                                    }
                                                }}
                                                icon="fa-search"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-xs-12 tab-min-top row">
                                    <SelectInputLabel
                                        label="Situação"
                                        name="active"
                                        data_tip="Situação cadastral (ativo ou inativo)"
                                        size_label="4"
                                        size_input="8"
                                        options={[
                                            {
                                                value: true,
                                                label: 'Ativo'
                                            },
                                            {
                                                value: false,
                                                label: 'Inativo'
                                            }
                                        ]}
                                        onChange={(op: any) =>
                                            setActive(op.value)
                                        }
                                    />
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>

            <div className="content-header mobile">
                <div className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Veículos"
                            icon="fa-truck"
                            tabRight={true}
                            pagination={true}
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                            mobile={true}
                        />
                        <div className="d-flex">
                            <div className="col-sm-3 my-2 pt-min">
                                <SaveOrderButton
                                    id={tableOrder?.id as string}
                                    table="vehicles"
                                    field={order.order}
                                    type={order.type}
                                    setOrder={setOrder}
                                />
                                <Button
                                    onClick={() => {
                                        resetData();
                                        setModalVisible(true);
                                    }}
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize={true}
                                />
                                <label
                                    htmlFor="json_input"
                                    id="btn-import"
                                    className="cursor-pointer btn btn-default"
                                    data-tip="Importar cadastros via JSON"
                                    data-for="vehicles-main-page-tooltip"
                                    style={{marginLeft: 6}}
                                >
                                    <i className="fas fa-download" />
                                </label>

                                <input
                                    id="json_input"
                                    type="file"
                                    accept=".json"
                                    style={{display: 'none'}}
                                    onChange={(event) => {
                                        const reader = new FileReader();
                                        reader.onload = async function (e) {
                                            const json = e.target
                                                ?.result as string;
                                            await importLegacyJSON(
                                                json,
                                                'legacyVehicles',
                                                setLoading,
                                                () => setRefresh(Math.random())
                                            );
                                        };
                                        reader.readAsText(
                                            event.target.files
                                                ? (event.target
                                                      .files[0] as Blob)
                                                : ('' as any),
                                            'ISO-8859-1'
                                        );
                                        event.target.value = '';
                                    }}
                                />
                            </div>
                            <div className="col-sm-9 col-xs-12 my-2">
                                <div className="input-group tab-max-left">
                                    <input
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }
                                        }}
                                        type="text"
                                        placeholder="Buscar por placa, descrição, motorista ou renavam"
                                        className="form-control"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <span className="input-group-append">
                                        <Button
                                            type="button"
                                            color="default"
                                            onClick={() => {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }}
                                            icon="fa-search"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <CardBody
                            size="12"
                            smallSize="12"
                            page={page}
                            count={count}
                            setPage={setPage}
                        >
                            <Table
                                id="vehicles"
                                columns={columns}
                                setOrder={setOrder}
                                order={order.order}
                                type={order.type}
                            >
                                {mapTable}
                            </Table>
                        </CardBody>
                    </div>
                </div>
            </section>

            {modalVisible && (
                <ModalForm
                    data={modalData}
                    refresh={() => setRefresh(Math.random())}
                    hide={() => setModalVisible(false)}
                />
            )}

            {loading ? (
                <FullPageLoader />
            ) : (
                <Tour
                    steps={tourStepsVehicle}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_tables) {
                            api.post('tourTables');
                            updateUser({
                                ...user,
                                tour_tables: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}

            <ReactTooltip
                id="vehicles-main-page-tooltip"
                place="left"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
                delayShow={150}
            />
        </>
    );
};

export default Vehicles;
