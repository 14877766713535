/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label?: string;
    size_label?: string;
    data_tip?: string;
    data_tipLabel?: string;
}

const TextFieldWrapper: React.FC<Props> = ({
    name,
    required,
    label,
    size_label,
    data_tip,
    data_tipLabel,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            {label && (
                <Label
                    required={required}
                    respAlt={false}
                    title={label}
                    data_tip={data_tipLabel}
                    data-for="help__tip--checkbox"
                    col={`col-sm-${size_label} mr-3`}
                    to={name}
                />
            )}
            <div className="col-sm-auto resp-form checkbox">
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => (
                        <input
                            type="checkbox"
                            className="form-control-sm translate h-10"
                            data-tip={data_tip}
                            data-for="help__tip--checkbox"
                            disabled={getValues('form_action') === 'view'}
                            checked={getValues(name)}
                            {...otherProps}
                            {...field}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
            <ReactTooltip
                id="help__tip--checkbox"
                place="top"
                backgroundColor="#42494E"
                globalEventOff="click"
                multiline
            />
        </>
    );
};

export default TextFieldWrapper;
