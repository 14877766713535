/* eslint-disable @typescript-eslint/no-unused-expressions */
import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CityProps} from '../interfaces';
import api from './api';

interface SintegraProps {
    ie: number;
    state: string;
    enabled: boolean;
}

async function searchCNPJ(
    text: string | undefined,
    setFieldValue: UseFormSetValue<FieldValues>,
    setLoading: (loadind: boolean) => void,
    data: CityProps[],
    cityField: string
) {
    if (text !== undefined && text !== null && text.length === 18) {
        setLoading(true);
        const cnpj = text.replace(/[^0-9]/g, '');
        api.get(`searchCnpj/${cnpj}`)
            .then(async () => {
                await fetch(`https://api.cnpja.com.br/companies/${cnpj}`, {
                    method: 'GET',
                    headers: {
                        Authorization: process.env.REACT_APP_CNPJA_API_KEY || ''
                    }
                })
                    .then((res) => res.json())
                    .then((dataCNPJ) => {
                        setFieldValue('corporate_name', dataCNPJ.name);

                        if (dataCNPJ.alias) {
                            setFieldValue('name', dataCNPJ.alias);
                            setFieldValue('company_name', dataCNPJ.alias);
                        } else {
                            setFieldValue('name', dataCNPJ.name);
                            setFieldValue('company_name', dataCNPJ.name);
                        }

                        const cepRaw: string = dataCNPJ.address.zip
                            .replace('.', '')
                            .replace('-', '');
                        const cep = `${cepRaw.substring(
                            0,
                            5
                        )}-${cepRaw.substring(5)}`;
                        setFieldValue('cep', cep);

                        setFieldValue('address', dataCNPJ.address.street);
                        setFieldValue(
                            'district',
                            dataCNPJ.address.neighborhood
                        );
                        setFieldValue('complement', dataCNPJ.address.details);
                        setFieldValue('number', dataCNPJ.address.number);
                        setFieldValue('phone', dataCNPJ.phone);
                        setFieldValue('company_phone', dataCNPJ.phone);
                        setFieldValue('phone_2', dataCNPJ.phone_alt);
                        setFieldValue('email', dataCNPJ.email);

                        const city = data.find(
                            (item: CityProps) =>
                                item.ibge.toString() ===
                                dataCNPJ.address.city_ibge
                        );
                        setFieldValue(cityField, city?.id);

                        const ieNumber = dataCNPJ.sintegra.registrations.find(
                            (ie: SintegraProps) =>
                                ie.state === dataCNPJ.address.state &&
                                ie.enabled === true
                        );

                        if (ieNumber) {
                            setFieldValue('rg_ie', ieNumber.number);
                        }
                    })
                    .catch(() =>
                        toast.error('Não foi possível consultar o CNPJ')
                    );
            })
            .catch((error) =>
                toast.error(
                    error.response?.data ||
                        'Erro ao consultar CNPJ nos cadastros'
                )
            )
            .finally(() => setLoading(false));
    }
}

export default searchCNPJ;
