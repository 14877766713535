/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    labelFilter?: boolean;
    textRight?: boolean;
    remove_label?: boolean;
    data_tip?: string;
}

const InputLabel: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    labelFilter,
    textRight,
    remove_label,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            {!remove_label && (
                <Label
                    required={required}
                    respAlt
                    title={label}
                    data_tip={data_tip}
                    col={`col-sm-${size_label} ${
                        textRight ? 'text-right' : ''
                    }`}
                    to={name}
                />
            )}
            <div
                className={`col-sm-${size_input} ${
                    labelFilter ? 'resp-form2' : 'resp-form'
                }`}
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <input
                            className="form-control form-control-sm"
                            {...field}
                            {...otherProps}
                            disabled={
                                getValues('form_action') === 'view' ||
                                otherProps.disabled
                            }
                            onChange={(event) => {
                                field.onChange(event);
                                otherProps.onChange &&
                                    otherProps.onChange(event);
                            }}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default InputLabel;
