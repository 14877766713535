import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import InputLabel from '../../../../components/InputLabel';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import InputLabelCurrency from '../../../../components/InputLabelCurrency';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import {CTeProps, Cte_Other} from '../../../../interfaces';
import helperTooltips from '../../../../data/help';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<CTeProps>;
    setTableValue: UseFormSetValue<CTeProps>;
}

const validations = Yup.object().shape({
    original_document: Yup.string().required('Campo obrigatório!'),
    description: Yup.string().when('original_document', {
        is: '99',
        then: Yup.string().required('Campo obrigatório').nullable()
    }),
    number: Yup.string().required('Campo obrigatório!'),
    date_emission: Yup.string().required('Campo obrigatório!'),
    value: Yup.number().required('Campo obrigatório!')
});

const ModalOthers: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue
}) => {
    const methods = useForm<Cte_Other>({
        resolver: yupResolver(validations),
        defaultValues: {
            date_emission: '',
            value: 0,
            description: '',
            number: '',
            original_document: '00'
        }
    });
    const {handleSubmit, reset, getValues, watch} = methods;
    watch('original_document');

    const onSubmit = useCallback((dataSubmit: Cte_Other) => {
        let equivalent = false;
        getTableValues().others.every((item) => {
            equivalent = item.number === dataSubmit.number;
            return !equivalent;
        });
        if (!equivalent) {
            setTableValue('others', [...getTableValues().others, dataSubmit]);
            reset();
            hide();
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Já existe um documento registrado com esse número.'
            });
        }
    }, []);

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Outros</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Doc. Orig."
                                        name="original_document"
                                        data_tip={
                                            helperTooltips.original_document
                                        }
                                        size_label="2"
                                        size_input="3"
                                        noSizeSelect
                                        options={[
                                            {value: '00', label: 'Declaração'},
                                            {value: '10', label: 'Dutoviário'},
                                            {value: '99', label: 'Outro'}
                                        ]}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        required={
                                            getValues('original_document') ===
                                            '99'
                                        }
                                        label="Descrição"
                                        name="description"
                                        data_tip={
                                            helperTooltips.description_document
                                        }
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Número"
                                        name="number"
                                        data_tip={helperTooltips.number_mdfe}
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Emissão"
                                        name="date_emission"
                                        data_tip={
                                            helperTooltips.emission_document
                                        }
                                        size_label="2"
                                        size_input="3"
                                        type="date"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelCurrency
                                        required
                                        label="Valor"
                                        name="value"
                                        data_tip={helperTooltips.value_document}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalOthers;
