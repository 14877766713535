import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {useAuth} from '../../hooks/Auth';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import ModalForm from './Modal';
import columns from './schema';
import {TableOrder, UserFormProps, UserProps} from '../../interfaces';

const People: React.FC = () => {
    const [order, setOrder] = useState<TableOrder>({
        order: 'created_at',
        type: 'desc'
    });
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<UserProps[]>([]);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<UserFormProps>(
        {} as UserFormProps
    );

    async function getUsers() {
        try {
            setLoading(true);
            const response = await api.get(
                `users?page=${page}&limit=20&order=${order.order}&type=${order.type}&search=${search}`
            );
            setUsers(response.data.data);
            setCount(response.data.meta.total);
            setPerPage(response.data.meta.per_page);
            setCurrentPage(response.data.meta.current_page);
            setLoading(false);
        } catch (err) {
            setUsers([]);
            toast.error('Não foi possível efetuar a consulta');
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, [page, refresh, order]); // GET PEOPLE

    function resetData() {
        setModalData({
            tenant_id: user.tenant_id,
            name: '',
            phone: '',
            email: '',
            form_action: 'include'
        });
    }

    function handleDelete(id: string, email: string, name: string) {
        Swal.fire({
            title: 'Confirmar exclusão do registro?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    await api.delete(`user/${id}?email=${email}&name=${name}`);
                    setRefresh(Math.random);
                    toast.success('Registro excluído com sucesso');
                } catch (error: any) {
                    toast.error(
                        error.response?.data ||
                            'Ocorreu um erro ao excluir o registro'
                    );
                    setLoading(false);
                }
            }
        });
    }

    const mapTable = useMemo(
        () =>
            users.map((userItem) => (
                <tr key={userItem.id}>
                    <td className="pt-2">{userItem.email}</td>
                    <td className="pt-2">{userItem.name}</td>
                    <td className="pt-2">{userItem.phone}</td>
                    <td className="pl-4">
                        <ActionButton
                            view={() => {
                                setModalData({
                                    ...userItem,
                                    form_action: 'view'
                                });
                                setModalVisible(true);
                            }}
                            edit={() => {
                                setModalData({
                                    ...userItem,
                                    form_action: 'edit'
                                });
                                setModalVisible(true);
                            }}
                            delete={() => {
                                handleDelete(
                                    userItem.id || '',
                                    userItem.email,
                                    userItem.name
                                );
                            }}
                        />
                    </td>
                </tr>
            )),
        [users]
    );

    return (
        <>
            {/* TITLE PAGE ( DESKTOP ) */}
            <div className="content-header desktop">
                <section className="title-page">
                    <div className="container-fluid pl-1 pr-2 d-flex">
                        <TitlePage
                            title="Usuários"
                            icon="fa-user"
                            pagination={true}
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                        />

                        <div className="tab-min-top col-2 text-right mt-1">
                            <Link to="#">
                                <Button
                                    onClick={() => {
                                        resetData();
                                        setModalVisible(true);
                                    }}
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize={true}
                                />
                            </Link>
                        </div>
                        <div className="col-sm-5 col-xs-12 tab-min-top">
                            <div className="input-group tab-max-left">
                                <input
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }
                                    }}
                                    type="text"
                                    placeholder="Buscar por nome"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span className="input-group-append">
                                    <Button
                                        type="button"
                                        color="default"
                                        onClick={() => {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }}
                                        icon="fa-search"
                                        notTab={true}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="content-header mobile">
                <section className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Usuários"
                            icon="fa-user"
                            pagination={true}
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                            mobile={true}
                        />

                        <div className="d-flex">
                            <div className="col-sm-3 my-2 pt-min">
                                <Button
                                    onClick={() => {
                                        resetData();
                                        setModalVisible(true);
                                    }}
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize={true}
                                />
                            </div>
                            <div className="col-sm-9 col-xs-12 my-2">
                                <div className="input-group tab-max-left">
                                    <input
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }
                                        }}
                                        type="text"
                                        placeholder="Buscar por nome fantasia, razão social ou cpf/cnpj"
                                        className="form-control"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <span className="input-group-append">
                                        <Button
                                            type="button"
                                            color="default"
                                            onClick={() => {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }}
                                            icon="fa-search"
                                            notTab={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <CardBody
                            size="12"
                            smallSize="12"
                            page={page}
                            count={count}
                            setPage={setPage}
                        >
                            <Table
                                id="users"
                                columns={columns}
                                setOrder={setOrder}
                                order={order.order}
                                type={order.type}
                            >
                                {mapTable}
                            </Table>
                        </CardBody>
                    </div>
                </div>
            </section>

            {modalVisible && (
                <ModalForm
                    data={modalData}
                    refresh={() => setRefresh(Math.random())}
                    hide={() => setModalVisible(false)}
                />
            )}

            {loading && <FullPageLoader />}
        </>
    );
};

export default People;
