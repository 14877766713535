import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import columns from './schema';
import CardBody from '../../../components/CardBody';
import {CteMdfeEventsProps} from '../../../interfaces';

interface Props {
    hide: () => void;
    data: CteMdfeEventsProps[];
    type: 'CT-e' | 'MDF-e';
}

const CteMdfeEventsModal: React.FC<Props> = ({hide, data, type}) => {
    return (
        <Modal show dialogAs="div">
            <Modal.Dialog scrollable size="xl" className="mdf-modal" centered>
                <Modal.Header className="bg-primary">
                    <Modal.Title>Eventos do {type}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-1">
                    <CardBody size="12" smallSize="12">
                        <Table id="eventsTable" columns={columns}>
                            {data.map((item) => (
                                <tr key={Math.random()}>
                                    {/* DATA */}
                                    <td className="pt-2 pl-2 ">
                                        {item.created_at
                                            ?.split('T')[0]
                                            .split('-')
                                            .reverse()
                                            .join('/')}
                                    </td>
                                    {/* HORA */}
                                    <td className="pt-2 pl-2 ">
                                        {
                                            item.created_at
                                                ?.split('T')[1]
                                                .split('.')[0]
                                        }
                                    </td>
                                    {/* USUÁRIO */}
                                    <td className="pt-2">{item.user.name}</td>
                                    {/* EVENTO */}
                                    <td className="pt-2 break-td">
                                        {item.message}
                                    </td>
                                </tr>
                            ))}
                        </Table>
                    </CardBody>
                </Modal.Body>

                <Modal.Footer
                    style={{
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}
                >
                    <div className="float-left responsive-button">
                        <Button
                            type="button"
                            color="danger"
                            size="btn btn-default"
                            icon="fa fa-arrow-circle-left"
                            caption="Retornar"
                            onClick={hide}
                        />
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

export default CteMdfeEventsModal;
