import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import InputLabel from '../../../../components/InputLabel';
import InputLabelCurrency from '../../../../components/InputLabelCurrency';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import {CTeProps, Cte_NFManual} from '../../../../interfaces';
import helperTooltips from '../../../../data/help';
import SelectInputLabel from '../../../../components/SelectInputLabel';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<CTeProps>;
    setTableValue: UseFormSetValue<CTeProps>;
}

const validations = Yup.object().shape({
    serie: Yup.number().required('Campo obrigatório!'),
    number: Yup.number().required('Campo obrigatório!'),
    date_emission: Yup.string().required('Campo obrigatório!'),
    cfop: Yup.string()
        .required('Campo obrigatório!')
        .length(4, 'CFOP deve possuir 4 dígitos'),
    bc_icms: Yup.number().required('Campo obrigatório!'),
    value_icms: Yup.number().required('Campo obrigatório!'),
    bc_st: Yup.number().required('Campo obrigatório!'),
    vlr_st: Yup.number().required('Campo obrigatório!'),
    total_value: Yup.number().required('Campo obrigatório!'),
    nf_total_value: Yup.number().required('Campo obrigatório!')
});

const ModalNfManual: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue
}) => {
    const methods = useForm({
        resolver: yupResolver(validations),
        defaultValues: {
            nf_model: '01',
            bc_icms: 0,
            value_icms: 0,
            bc_st: 0,
            vlr_st: 0,
            total_value: 0,
            nf_total_value: 0
        }
    });
    const {handleSubmit, reset} = methods;

    const onSubmit = useCallback((dataSubmit: Cte_NFManual) => {
        let equivalent = false;
        getTableValues().manual_nfs.every((item) => {
            equivalent = item.number === dataSubmit.number;
            return !equivalent;
        });
        if (!equivalent) {
            setTableValue('manual_nfs', [
                ...getTableValues().manual_nfs,
                dataSubmit
            ]);
            reset();
            hide();
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Já existe um documento registrado com esse número.'
            });
        }
    }, []);

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>NF Manual</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Série"
                                        name="serie"
                                        data_tip={helperTooltips.serie_nfe}
                                        size_label="2"
                                        size_input="3"
                                    />
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Número"
                                        name="number"
                                        data_tip={helperTooltips.nfe_number}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Modelo de NF"
                                        data_tip="Modelo da Nota Fiscal"
                                        name="nf_model"
                                        size_label="2"
                                        size_input="8"
                                        noSizeSelect
                                        options={[
                                            {
                                                value: '01',
                                                label:
                                                    'NF Modelo 01/1A e Avulsa'
                                            },
                                            {
                                                value: '04',
                                                label: 'NF de Produtor'
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Emissão"
                                        name="date_emission"
                                        data_tip={helperTooltips.emission_nfe}
                                        size_label="2"
                                        size_input="3"
                                        type="date"
                                    />
                                    <InputLabelOnlyNumbers
                                        required
                                        label="CFOP"
                                        name="cfop"
                                        data_tip={helperTooltips.select_cfop}
                                        size_label="2"
                                        size_input="3"
                                        digits={4}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelCurrency
                                        label="BC. ICMS"
                                        name="bc_icms"
                                        data_tip={helperTooltips.bc_icms}
                                        size_label="2"
                                        size_input="3"
                                    />
                                    <InputLabelCurrency
                                        label="Valor ICMS"
                                        name="value_icms"
                                        data_tip={helperTooltips.value_icms}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelCurrency
                                        label="BC. ICMS ST"
                                        name="bc_st"
                                        data_tip={helperTooltips.bc_st}
                                        size_label="2"
                                        size_input="3"
                                    />
                                    <InputLabelCurrency
                                        label="Valor ICMS ST."
                                        name="vlr_st"
                                        data_tip={helperTooltips.vlr_st}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelCurrency
                                        label="Total Produto"
                                        data_tip="Valor total do produto"
                                        name="total_value"
                                        size_label="2"
                                        size_input="3"
                                    />
                                    <InputLabelCurrency
                                        label="Total da Nota"
                                        name="nf_total_value"
                                        data_tip={helperTooltips.nf_total_value}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalNfManual;
