import React, {useCallback, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import columns from './schema';
import CardBody from '../../../components/CardBody';
import FullPageLoader from '../../../components/FullPageLoader';
import api from '../../../services/api';
import openPdfFromBase64 from '../../../utils/openPdfFromBase64';

interface Letters {
    id: string;
    created_at: string;
    n_prot: string;
    event_number: number;
}

interface Data {
    id: string;
    letters: Letters[];
}

interface Props {
    hide: () => void;
    data: Data;
}

const CorrectionLetterReportsModal: React.FC<Props> = ({hide, data}) => {
    const [loading, setLoading] = useState(false);

    async function printCorrectionLetter(id: string) {
        setLoading(true);
        await api
            .get(`printCteCorrectionNS/${id}`)
            .then((response) => {
                openPdfFromBase64(response.data.pdf);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao imprimir carta de correção',
                    text: error.response?.data || error
                });
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Modal show dialogAs="div">
                <Modal.Dialog
                    scrollable
                    size="xl"
                    className="mdf-modal"
                    centered
                >
                    <Modal.Header className="bg-primary">
                        <Modal.Title>Cartas de correção emitidas</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="p-1">
                        <CardBody size="12" smallSize="12">
                            <Table id="lettersTable" columns={columns}>
                                {data.letters.map((item) => (
                                    <tr key={Math.random()}>
                                        {/* DATA */}
                                        <td className="pt-2 pl-2 ">
                                            {item.created_at
                                                ?.split('T')[0]
                                                .split('-')
                                                .reverse()
                                                .join('/')}
                                        </td>
                                        {/* N° EVENTO */}
                                        <td className="pt-2 pl-2 ">
                                            {item.event_number}
                                        </td>
                                        {/* PROTOCOLO */}
                                        <td className="pt-2 pl-2 ">
                                            {item.n_prot}
                                        </td>
                                        {/* IMPRIMIR */}
                                        <td className="pt-2 pl-2 ">
                                            <button
                                                type="button"
                                                className="btn btn-default btn-sm "
                                                onClick={async () => {
                                                    printCorrectionLetter(
                                                        item.id
                                                    );
                                                }}
                                            >
                                                <i className="fas fa-print" />{' '}
                                                Imprimir
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </Table>
                        </CardBody>
                    </Modal.Body>

                    <Modal.Footer
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <div className="float-left responsive-button">
                            <Button
                                type="button"
                                color="danger"
                                size="btn btn-default"
                                icon="fa fa-arrow-circle-left"
                                caption="Retornar"
                                onClick={hide}
                            />
                        </div>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>
            {loading && <FullPageLoader />}
        </>
    );
};

export default CorrectionLetterReportsModal;
