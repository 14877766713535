const nf_manual_schema = [
    {text: 'Série', className: 'text-max-sm'},
    {text: 'Número', className: 'text-max-sm'},
    {text: 'Emissão', className: 'text-max-sm'},
    {text: 'BC. ICMS', className: 'text-max-sm'},
    {text: 'Vlr. ICMs', className: 'text-max-sm'},
    {text: 'BC. ST', className: 'text-max-sm'},
    {text: 'Vlr. ST', className: 'text-max-sm'},
    {text: 'CFOP', className: 'text-max-sm'},
    {text: 'Total de Produtos', className: 'text-max-sm'},
    {text: 'Total da nota', className: 'text-max-sm'},
    {text: 'Remover', className: 'text-max-sm px-1 text-center'}
];

export default nf_manual_schema;
