import {UseFormGetValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CityProps} from '../interfaces';

async function searchCEP_MDFe(
    text: string | undefined,
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
    setLoading: (loadind: boolean) => void,
    data: CityProps[],
    cityField: string,
    loadsField: 'load' | 'unload'
): Promise<void> {
    setLoading(true);

    if (text) {
        const cep = text.replace(/[^0-9]/g, '');

        if (cep.length === 8) {
            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then((res) => res.json())
                .then((dataCep) => {
                    const city = data.find(
                        // eslint-disable-next-line eqeqeq
                        (item) => dataCep.ibge == item.ibge
                    );
                    if (city) {
                        if (getValues(cityField) !== city?.id) {
                            setValue(cityField, city?.id);
                        }
                        if (!getValues(loadsField).includes(city?.id)) {
                            setValue(loadsField, [
                                ...getValues()[loadsField],
                                city?.id
                            ]);
                            if (loadsField === 'unload') {
                                setValue('citiesCteNfe', [
                                    ...getValues('citiesCteNfe'),
                                    {
                                        value: city.id,
                                        label: `${city.description} - ${city.state.initials}`
                                    }
                                ]);
                            }
                        }
                    }
                })
                .catch(() => toast.error('Cidade não encontrada'));
        }
    }
    setLoading(false);
}

export default searchCEP_MDFe;
