function getDFeSituationText(situation: number) {
    switch (situation) {
        case 0:
            return 'PENDENTE';
        case 210200:
            return 'CONFIRMADO';
        case 210210:
            return 'CIÊNCIA';
        case 210220:
            return 'DESCONHECIDO';
        case 210240:
            return 'NÃO REALIZADA';
        default:
            return situation;
    }
}

export default getDFeSituationText;
