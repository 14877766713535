/* eslint-disable no-unneeded-ternary */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    labelFilter?: boolean;
    textRight?: boolean;
    data_tip?: string;
}

const InputLabelPlate: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    labelFilter,
    textRight,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            <Label
                required={required}
                respAlt={labelFilter ? false : true}
                title={label}
                data_tip={data_tip}
                col={`col-sm-${size_label} ${textRight ? 'text-right' : ''}`}
                to={name}
            />
            <div
                className={`col-sm-${size_input} ${
                    labelFilter ? 'resp-form2' : 'resp-form'
                }`}
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            onChange={(event) => {
                                field.onChange(event.toUpperCase());
                            }}
                            kind="custom"
                            options={{
                                mask: 'AAA-SS99'
                            }}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default InputLabelPlate;
