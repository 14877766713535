/* eslint-disable no-nested-ternary */
import React from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import AltGraphic from '../../../../components/Graphic/AltGraphic';
import Graphic from '../../../../components/Graphic';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import {monthObjectData} from '../../../../data/index';

interface GraphicDashboardProps {
    title: string;
    data: string;
    isYear?: boolean;
    type?: 'cte' | 'mdfe';
    cteYear?: any;
    cteMonth?: any;
    mdfeMonth?: any;
    mdfeYear?: any;
    setDataDaycte: any;
    setDataDaymdfe: any;
    setDataMonthcte: any;
    setDataMonthmdfe: any;
}

const GraphicDashboard: React.FC<GraphicDashboardProps> = ({
    title,
    data,
    isYear,
    type,
    cteYear,
    cteMonth,
    mdfeYear,
    mdfeMonth,
    setDataDaycte,
    setDataDaymdfe,
    setDataMonthcte,
    setDataMonthmdfe
}) => {
    const offcteYear = JSON.stringify(cteYear) === '[]';
    const offcteMonth = JSON.stringify(cteMonth) === '[]';
    const offmdfeYear = JSON.stringify(mdfeYear) === '[]';
    const offmdfeMonth = JSON.stringify(mdfeMonth) === '[]';
    const date = new Date();
    const actualMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const startYear = 2021;
    const difference = currentYear - startYear + 1;
    const years = Array(currentYear - (currentYear - difference))
        .fill('')
        .map((year, i) => currentYear - i);

    const selectYear = years.map((year) => ({
        value: year,
        label: `${year}`
    }));

    const methods = useForm<{start: number}>({
        defaultValues: {
            start: isYear ? selectYear[0].value : actualMonth
        }
    });

    return (
        <div className="col-md-6">
            <div className="card">
                <div className="card-header">
                    <div className="col-sm-12 d-flex justify-content-center align-items-center">
                        <FormProvider {...methods}>
                            <form>
                                <div className="col-sm-12 d-flex justify-content-center align-items-center">
                                    {isYear ? (
                                        <SelectInputLabel
                                            label={title}
                                            name="start"
                                            className={
                                                type === 'cte'
                                                    ? 'tour-dashboard--08'
                                                    : 'tour-dashboard--09'
                                            }
                                            size_label="2 text-nowrap min-w-150"
                                            size_input="7 min-w-92"
                                            options={selectYear}
                                            onChange={
                                                type === 'cte'
                                                    ? (value: any) => {
                                                          setDataDaycte(
                                                              value.value
                                                          );
                                                      }
                                                    : (value: any) => {
                                                          setDataDaymdfe(
                                                              value.value
                                                          );
                                                      }
                                            }
                                        />
                                    ) : (
                                        <SelectInputLabel
                                            label={title}
                                            name="start"
                                            size_label="2 text-nowrap min-w-150"
                                            size_input="7 min-w-92"
                                            options={monthObjectData}
                                            className={
                                                type === 'cte'
                                                    ? 'tour-dashboard--10'
                                                    : 'tour-dashboard--11'
                                            }
                                            onChange={
                                                type === 'cte'
                                                    ? (value: any) => {
                                                          setDataMonthcte(
                                                              value.value
                                                          );
                                                      }
                                                    : (value: any) => {
                                                          setDataMonthmdfe(
                                                              value.value
                                                          );
                                                      }
                                            }
                                        />
                                    )}
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
                <div className="card-body card-graphic">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dc-flex res-graphic desktop">
                                {isYear ? (
                                    !offcteYear ? (
                                        type === 'cte' && (
                                            <Graphic
                                                data={cteYear}
                                                color="#16a085"
                                                dataKey={data}
                                            />
                                        )
                                    ) : (
                                        <AltGraphic>CT-e</AltGraphic>
                                    )
                                ) : !offcteMonth ? (
                                    type === 'cte' && (
                                        <Graphic
                                            data={cteMonth}
                                            color="#16a085"
                                            dataKey={data}
                                        />
                                    )
                                ) : (
                                    <AltGraphic>CT-e</AltGraphic>
                                )}
                                {isYear ? (
                                    !offmdfeYear ? (
                                        type === 'mdfe' && (
                                            <Graphic
                                                data={mdfeYear}
                                                color="#2980b9"
                                                dataKey={data}
                                            />
                                        )
                                    ) : (
                                        <AltGraphic>MDF-e</AltGraphic>
                                    )
                                ) : !offmdfeMonth ? (
                                    type === 'mdfe' && (
                                        <Graphic
                                            data={mdfeMonth}
                                            color="#2980b9"
                                            dataKey={data}
                                        />
                                    )
                                ) : (
                                    <AltGraphic>MDF-e</AltGraphic>
                                )}
                            </div>
                            <div className="dc-flex res-graphic mobile">
                                {isYear ? (
                                    !offcteYear ? (
                                        type === 'cte' && (
                                            <Graphic
                                                isMobile
                                                data={cteYear}
                                                color="#16a085"
                                                dataKey={data}
                                            />
                                        )
                                    ) : (
                                        <AltGraphic>CT-e</AltGraphic>
                                    )
                                ) : !offcteMonth ? (
                                    type === 'cte' && (
                                        <Graphic
                                            isMobile
                                            data={cteMonth}
                                            color="#16a085"
                                            dataKey={data}
                                        />
                                    )
                                ) : (
                                    <AltGraphic>CT-e</AltGraphic>
                                )}
                                {isYear ? (
                                    !offmdfeYear ? (
                                        type === 'mdfe' && (
                                            <Graphic
                                                isMobile
                                                data={mdfeYear}
                                                color="#2980b9"
                                                dataKey={data}
                                            />
                                        )
                                    ) : (
                                        <AltGraphic>MDF-e</AltGraphic>
                                    )
                                ) : !offmdfeMonth ? (
                                    type === 'mdfe' && (
                                        <Graphic
                                            isMobile
                                            data={mdfeMonth}
                                            color="#2980b9"
                                            dataKey={data}
                                        />
                                    )
                                ) : (
                                    <AltGraphic>MDF-e</AltGraphic>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphicDashboard;
