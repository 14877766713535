import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import {useAuth} from '../../hooks/Auth';
import {TableOrder, TablesOrderProps} from '../../interfaces';
import api from '../../services/api';
import Button from '../Button';
import FullPageLoader from '../FullPageLoader';

interface Props extends TablesOrderProps {
    setOrder: (order: TableOrder) => void;
}

const SaveOrderButton: React.FC<Props> = ({
    table,
    field,
    type,
    id,
    setOrder
}) => {
    const [loading, setLoading] = useState(false);
    const {updateTablesOrder, tablesOrder} = useAuth();

    return (
        <>
            <Link
                to="#"
                className="mr-2"
                data-tip="Restaurar ordenação (últimos cadastros no topo)"
                data-for="table-order"
            >
                <Button
                    onClick={() => {
                        setLoading(true);

                        const newOrder = tablesOrder.filter(
                            (order) => order.table !== table
                        );
                        newOrder.push({
                            table,
                            field: 'created_at',
                            type: 'desc',
                            id
                        });
                        api.put(`tablesOrder/${id}`, {
                            table,
                            field: 'created_at',
                            type: 'desc',
                            id
                        })
                            .then(() => {
                                updateTablesOrder(newOrder);
                                setOrder({order: 'created_at', type: 'desc'});
                                toast.success('Ordenação da tabela registrada');
                            })
                            .catch((error) =>
                                toast.error(
                                    'Ocorreu um erro ao salvar a ordenação da tabela'
                                )
                            )
                            .finally(() => setLoading(false));
                    }}
                    type="button"
                    color="default"
                    icon="fa-undo"
                    notTab
                    maxSize={true}
                />
            </Link>
            <Link
                to="#"
                className="mr-2"
                data-tip="Salvar ordenação da tabela"
                data-for="table-order"
            >
                <Button
                    onClick={() => {
                        setLoading(true);

                        const newOrder = tablesOrder.filter(
                            (order) => order.table !== table
                        );
                        newOrder.push({table, field, type, id});
                        api.put(`tablesOrder/${id}`, {table, field, type})
                            .then(() => {
                                toast.success('Ordenação da tabela registrada');
                                updateTablesOrder(newOrder);
                            })
                            .catch((error) =>
                                toast.error(
                                    'Ocorreu um erro ao salvar a ordenação da tabela'
                                )
                            )
                            .finally(() => setLoading(false));
                    }}
                    type="button"
                    color="primary"
                    icon="fa-save"
                    notTab
                    maxSize={true}
                />
            </Link>
            <ReactTooltip
                id="table-order"
                place="left"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
                delayShow={150}
            />

            {loading && <FullPageLoader />}
        </>
    );
};

export default SaveOrderButton;
