/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {useForm, FormProvider} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import ActionButton from '../../../components/ActionButton';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import api from '../../../services/api';
import columns from './schema';
import {useAuth} from '../../../hooks/Auth';
import {TaxationProps} from '../../../interfaces';
import {ufs, csts} from '../../../data';
import isEquivalent from '../../../utils/isEquivalent';
import SelectInputLabel from '../../../components/SelectInputLabel';
import InputLabelMeasurement from '../../../components/InputLabelMeasurement';
import InputLabelTextArea from '../../../components/InputLabelTextArea';
import FullPageLoader from '../../../components/FullPageLoader';

interface Props {
    hide: () => void;
    person_id?: string;
}

interface TaxationFormProps extends TaxationProps {
    taxationArray: TaxationProps[];
    updating: boolean;
    editIndex: number | null;
}

const TaxationModal: React.FC<Props> = ({hide, person_id}) => {
    const {user} = useAuth();
    const [taxations, setTaxations] = useState<TaxationProps[]>([]);
    const [loading, setLoading] = useState(false);

    const defaultValues: TaxationFormProps = {
        environment_id: user.environment?.id || '',
        uf_origin: 100,
        uf_destination: 100,
        redbc: 0,
        aliq: 0,
        cst: '',
        obs: '',
        taxationArray: taxations,
        updating: false,
        editIndex: null
    };

    const methods = useForm<TaxationFormProps>({
        defaultValues
    });
    const {handleSubmit, reset, getValues, setValue, watch} = methods;
    watch('cst');

    useEffect(() => {
        async function getTaxations() {
            setLoading(true);
            try {
                const response = await api.get(
                    `taxations?environment_id=${user.environment?.id}&person_id=${person_id}`
                );
                setTaxations(response.data);
                reset({...defaultValues, taxationArray: response.data});
            } catch (error) {
                toast.error('Erro ao carregar tributações');
            }
            setLoading(false);
        }

        getTaxations();
    }, [user]); // GET TAXATIONS

    const handleAddLine = useCallback(() => {
        if (getValues('cst')) {
            let equivalent = false;
            getValues('taxationArray').every((item) => {
                equivalent = isEquivalent(
                    {
                        uf_origin: item.uf_origin,
                        uf_destination: item.uf_destination
                    },
                    {
                        uf_origin: getValues().uf_origin,
                        uf_destination: getValues().uf_destination
                    }
                );
                if (
                    getValues().updating === true &&
                    getValues().editIndex !== null &&
                    `${
                        getValues().taxationArray[
                            getValues().editIndex as number
                        ].uf_origin
                    }${
                        getValues().taxationArray[
                            getValues().editIndex as number
                        ].uf_destination
                    }` === `${item.uf_origin}${item.uf_destination}`
                ) {
                    equivalent = false;
                }
                return !equivalent;
            });
            if (!equivalent) {
                if (getValues().updating && getValues().editIndex !== null) {
                    //  EDIT
                    const updatedTax = getValues().taxationArray;

                    updatedTax[getValues().editIndex as number] = {
                        id: getValues().id,
                        environment_id: getValues().environment_id,
                        uf_origin: getValues().uf_origin,
                        uf_destination: getValues().uf_destination,
                        redbc: getValues().redbc,
                        aliq: getValues().aliq,
                        cst: getValues().cst,
                        obs: getValues().obs
                    };

                    setValue('taxationArray', updatedTax);
                } else {
                    // INCLUDE
                    reset({
                        ...defaultValues,
                        taxationArray: [
                            ...getValues().taxationArray,
                            {
                                id: getValues().id,
                                environment_id: getValues().environment_id,
                                uf_origin: getValues().uf_origin,
                                uf_destination: getValues().uf_destination,
                                redbc: getValues().redbc,
                                aliq: getValues().aliq,
                                cst: getValues().cst,
                                obs: getValues().obs
                            }
                        ]
                    });
                }
                setValue('editIndex', null);
                reset({
                    ...defaultValues,
                    taxationArray: getValues().taxationArray
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'Já existe uma regra cadastrada para essas localidades.'
                });
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Selecione um CST'
            });
        }
    }, [getValues, reset, setValue, defaultValues]);

    const onSubmit = useCallback(async (dataSubmit) => {
        if (dataSubmit.updating) {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'Você está alterando um registro, termine a alteração antes de salvar.'
            });
        } else {
            setLoading(true);
            try {
                await api.post(`/taxations?person_id=${person_id}`, {
                    log: dataSubmit.taxationArray
                });
                hide();
                toast.success('Cadastro efetuado com sucesso');
            } catch (err) {
                setLoading(false);
                toast.error('Erro ao realizar cadastro');
            }
            setLoading(false);
        }
    }, []);

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog scrollable size="xl" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    {getValues('updating') === false
                                        ? 'Cadastrar '
                                        : 'Alterar '}
                                    Regra de Tributação
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div
                                        className="row"
                                        style={{
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <SelectInputLabel
                                            required
                                            label="Origem"
                                            name="uf_origin"
                                            data_tip="UF de Origem do transporte"
                                            size_label="1"
                                            noSizeSelect
                                            size_input="2"
                                            options={[
                                                {label: '*', value: 100},
                                                ...ufs
                                            ]}
                                        />

                                        <SelectInputLabel
                                            required
                                            label="Destino"
                                            name="uf_destination"
                                            data_tip="UF de Destino do transporte"
                                            size_label="1"
                                            noSizeSelect
                                            size_input="2"
                                            options={[
                                                {label: '*', value: 100},
                                                ...ufs
                                            ]}
                                            isDisabled={!!person_id}
                                        />

                                        <InputLabelMeasurement
                                            required
                                            label="Redução. BC"
                                            name="redbc"
                                            data_tip="Redução da Base de Cálculo do
                                            ICMS"
                                            size_label="2"
                                            size_input="1"
                                            precision={2}
                                            disabled={[
                                                '00',
                                                '40',
                                                '41',
                                                '51',
                                                '60',
                                                '90',
                                                '91'
                                            ].includes(getValues('cst'))}
                                        />

                                        <InputLabelMeasurement
                                            required
                                            label="Alíquota"
                                            name="aliq"
                                            data_tip="Alíquota do ICMS"
                                            size_label="1"
                                            size_input="1"
                                            precision={2}
                                            disabled={[
                                                '40',
                                                '41',
                                                '51',
                                                '91'
                                            ].includes(getValues('cst'))}
                                        />
                                    </div>
                                    <div
                                        className="row mb-1"
                                        style={{
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <InputLabelTextArea
                                            label="Obs."
                                            name="obs"
                                            data_tip="Texto do campo de Observação"
                                            size_label="1"
                                            size_input="10"
                                        />
                                    </div>
                                    <div
                                        className="row"
                                        style={{
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <SelectInputLabel
                                            required
                                            label="CST"
                                            name="cst"
                                            data_tip="Código de situação tributária"
                                            size_label="1"
                                            size_input="10"
                                            noSizeSelect
                                            options={[
                                                {label: '', value: ''},
                                                ...csts
                                            ]}
                                            onChange={(op: any) => {
                                                switch (op.value) {
                                                    case '00':
                                                        setValue('redbc', 0);
                                                        break;
                                                    case '20':
                                                        break;
                                                    case '40':
                                                        setValue('redbc', 0);
                                                        setValue('aliq', 0);
                                                        break;
                                                    case '41':
                                                        setValue('redbc', 0);
                                                        setValue('aliq', 0);
                                                        break;
                                                    case '51':
                                                        setValue('redbc', 0);
                                                        setValue('aliq', 0);
                                                        break;
                                                    case '60':
                                                        setValue('redbc', 0);
                                                        break;
                                                    case '90':
                                                        setValue('redbc', 0);
                                                        break;
                                                    case '91':
                                                        setValue('redbc', 0);
                                                        setValue('aliq', 0);
                                                        break;
                                                    case '92':
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }}
                                        />
                                        <div className="col-sm-1">
                                            <button
                                                type="button"
                                                className="btn btn-default btn-block btn-sm"
                                                onClick={() => handleAddLine()}
                                            >
                                                <i className="fa fa-check" />
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 d-flex">
                                            <Table
                                                id="taxations_table"
                                                columns={columns}
                                            >
                                                {getValues('taxationArray').map(
                                                    (item) => (
                                                        <tr
                                                            key={`${item.uf_origin}${item.uf_destination}`}
                                                            style={
                                                                getValues()
                                                                    .editIndex !==
                                                                    null &&
                                                                `${
                                                                    getValues()
                                                                        .taxationArray[
                                                                        getValues()
                                                                            .editIndex as number
                                                                    ].uf_origin
                                                                }${
                                                                    getValues()
                                                                        .taxationArray[
                                                                        getValues()
                                                                            .editIndex as number
                                                                    ]
                                                                        .uf_destination
                                                                }` ===
                                                                    `${item.uf_origin}${item.uf_destination}`
                                                                    ? {
                                                                          backgroundColor:
                                                                              'rgb(255, 193, 7, 0.2)'
                                                                      }
                                                                    : {}
                                                            }
                                                        >
                                                            <td className="pt-2 pl-2">
                                                                {ufs.find(
                                                                    (uf) =>
                                                                        uf.value ===
                                                                        item.uf_origin
                                                                )?.label || '*'}
                                                            </td>
                                                            <td className="pt-2">
                                                                {ufs.find(
                                                                    (uf) =>
                                                                        uf.value ===
                                                                        item.uf_destination
                                                                )?.label || '*'}
                                                            </td>
                                                            <td className="pt-2">
                                                                {item.redbc}
                                                            </td>
                                                            <td className="pt-2">
                                                                {item.aliq}
                                                            </td>
                                                            <td className="pt-2">
                                                                {csts.find(
                                                                    (cst) =>
                                                                        cst.value ===
                                                                        item.cst
                                                                )?.label || ''}
                                                            </td>
                                                            <td className="pt-2 short-td">
                                                                {item.obs}
                                                            </td>
                                                            <td className="p-2">
                                                                <ActionButton
                                                                    edit={() => {
                                                                        setValue(
                                                                            'editIndex',
                                                                            getValues(
                                                                                'taxationArray'
                                                                            ).findIndex(
                                                                                (
                                                                                    taxationItem
                                                                                ) =>
                                                                                    `${taxationItem.uf_origin}${taxationItem.uf_destination}` ===
                                                                                    `${item.uf_origin}${item.uf_destination}`
                                                                            )
                                                                        );
                                                                        reset({
                                                                            id:
                                                                                '',
                                                                            environment_id: getValues()
                                                                                .environment_id,
                                                                            uf_origin: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .uf_origin,
                                                                            uf_destination: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .uf_destination,
                                                                            redbc: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .redbc,
                                                                            aliq: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .aliq,
                                                                            cst: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .cst,
                                                                            obs: getValues()
                                                                                .taxationArray[
                                                                                getValues()
                                                                                    .editIndex as number
                                                                            ]
                                                                                .obs,
                                                                            taxationArray: getValues()
                                                                                .taxationArray,
                                                                            editIndex: getValues()
                                                                                .editIndex,
                                                                            updating: true
                                                                        });
                                                                    }}
                                                                    delete={() => {
                                                                        reset({
                                                                            ...getValues(),
                                                                            taxationArray: getValues(
                                                                                'taxationArray'
                                                                            ).filter(
                                                                                (
                                                                                    taxationItem
                                                                                ) =>
                                                                                    `${taxationItem.uf_origin}${taxationItem.uf_destination}` !==
                                                                                    `${item.uf_origin}${item.uf_destination}`
                                                                            )
                                                                        });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
                {loading && <FullPageLoader />}
            </Modal>
        </>
    );
};

export default TaxationModal;
