import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import {FormProvider, useForm, UseFormSetValue} from 'react-hook-form';
import {format} from 'cnpj';
import Button from '../../../components/Button';
import {useAuth} from '../../../hooks/Auth';
import Table from '../../../components/Table';
import {CTeProps, InsuranceProps} from '../../../interfaces';
import FullPageLoader from '../../../components/FullPageLoader';
import api from '../../../services/api';
import columns from './schema';
import CardBody from '../../../components/CardBody';
import InputLabel from '../../../components/InputLabel';

interface Props {
    hide: () => void;
    setValue: UseFormSetValue<any>;
    field: string;
    setEndorsements?: React.Dispatch<React.SetStateAction<any>>;
}

interface SearchProps {
    start_emission: string;
    end_emission: string;
    sender: string;
    recipient: string;
    number: string;
    cod: string;
}

const defaultSearch: SearchProps = {
    cod: '',
    end_emission: '',
    start_emission: '',
    number: '',
    recipient: '',
    sender: ''
};

const CTEsModal: React.FC<Props> = ({
    hide,
    setValue,
    field,
    setEndorsements
}) => {
    const {user} = useAuth();
    const [ctes, setCtes] = useState<CTeProps[]>([]);
    const [insurances, setInsurances] = useState<InsuranceProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<SearchProps>(defaultSearch);

    useEffect(() => {
        setLoading(true);
        api.get(
            `ctes?order=cod&type=desc&page=1&limit=20&environment_inssuer_id=${user.environment?.id}&cte_environment=${user.environment?.cte_environment}&filter=true&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_amount=&end_amount=&number=${search.number}&cod=${search.cod}&situation=AUTORIZADO&cfop=&start_delivery=&end_delivery=&sender=${search.sender}&recipient=${search.recipient}&endorsement=true`
        )
            .then((response) => {
                setCtes(response.data.data);
            })
            .catch(() => {
                toast.error('Não foi possível realizar a consulta');
                hide();
            })
            .finally(() => setLoading(false));
    }, [user, search]); // GET CTE's

    useEffect(() => {
        api.get(
            `insurances?page=1&limit=total&order=created_at&type=desc&description=`
        ).then((response) => setInsurances(response.data.data));
    }, []); // GET INSURANCES

    const methods = useForm<SearchProps>({
        defaultValues: defaultSearch
    });
    const {handleSubmit} = methods;
    const onSubmit = useCallback(async (dataSubmit) => {
        setSearch(dataSubmit);
    }, []);

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="ctes_modal"
                    >
                        <Modal.Dialog
                            scrollable
                            size="xl"
                            className="mdf-modal"
                            centered
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Selecionar chave de CT-e
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="p-1">
                                <div
                                    className="row"
                                    style={{
                                        padding: 8
                                    }}
                                >
                                    <InputLabel
                                        label="Emissão"
                                        type="date"
                                        name="start_emission"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <InputLabel
                                        label="Remetente"
                                        name="sender"
                                        size_label="1"
                                        size_input="4"
                                    />
                                    <InputLabel
                                        label="Número"
                                        name="number"
                                        size_label="1"
                                        size_input="1"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        padding: 8,
                                        paddingTop: 0
                                    }}
                                >
                                    <InputLabel
                                        label="Até"
                                        type="date"
                                        name="end_emission"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <InputLabel
                                        label="Destinatário"
                                        name="recipient"
                                        size_label="1"
                                        size_input="4"
                                    />
                                    <InputLabel
                                        label="Código"
                                        name="cod"
                                        size_label="1"
                                        size_input="1"
                                    />
                                    <div className="col-sm-2 text-right">
                                        <button
                                            type="submit"
                                            data-tip="Pesquisar chave de CT-e"
                                            data-for="tip"
                                            className="btn btn-default btn-sm"
                                        >
                                            <i className="fa fa-search pr-1" />{' '}
                                            Pesquisar
                                        </button>
                                    </div>
                                </div>
                                <CardBody size="12" smallSize="12">
                                    <Table id="cte" columns={columns}>
                                        {ctes.map((cte) => (
                                            <tr key={Math.random()}>
                                                {/* CÓDIGO */}
                                                <td className="pt-2 pl-2 ">
                                                    {cte.cod}
                                                </td>
                                                {/* NÚMERO */}
                                                <td className="pt-2">
                                                    {cte.number}
                                                </td>
                                                {/* EMISSÃO */}
                                                <td className="pt-2">
                                                    {cte.date_emission
                                                        ?.split('T')[0]
                                                        .split('-')
                                                        .reverse()
                                                        .join('/')}
                                                </td>
                                                {/* REMETENTE */}
                                                <td className="pt-2 short-td">
                                                    {cte.sender_people?.name}
                                                </td>
                                                {/* DESTINATÁRIO */}
                                                <td className="pt-2 short-td">
                                                    {cte.recipient_people?.name}
                                                </td>
                                                {/* CHAVE */}
                                                <td className="pt-2 short-td">
                                                    {cte.key}
                                                </td>
                                                {/* SELECIONAR */}
                                                <td className="p-0 text-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-default btn-sm btn-block"
                                                        onClick={() => {
                                                            setValue(
                                                                field,
                                                                cte.key || ''
                                                            );
                                                            // JOGAR OS DADOS DA AVERBAÇÃO PARA O COMPONENTE PAI, CASO ESTE PASSE A PROP SETENDORSEMENTS
                                                            if (
                                                                setEndorsements &&
                                                                cte.endorsements &&
                                                                cte.endorsements
                                                                    .length > 0
                                                            ) {
                                                                setEndorsements(
                                                                    cte.endorsements.map(
                                                                        (
                                                                            endorsement
                                                                        ) => ({
                                                                            ...endorsement,
                                                                            insurance_id: insurances.find(
                                                                                (
                                                                                    insurance
                                                                                ) =>
                                                                                    insurance.cpf_cnpj ===
                                                                                    format(
                                                                                        endorsement.cnpj
                                                                                    )
                                                                            )
                                                                                ?.id
                                                                        })
                                                                    )
                                                                );
                                                            }
                                                            hide();
                                                        }}
                                                    >
                                                        <i className="fas fa-check" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </Table>
                                </CardBody>
                            </Modal.Body>

                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={hide}
                                    />
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>

            {loading && <FullPageLoader />}
        </>
    );
};

export default CTEsModal;
