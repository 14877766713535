import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from '../../../../components/Button';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import InputLabelMeasurement from '../../../../components/InputLabelMeasurement';
import {CTeProps, Cte_Load} from '../../../../interfaces';
import helperTooltips from '../../../../data/help';
import getMeasurementType from '../../../../utils/getMeasurementType';
import {useAuth} from '../../../../hooks/Auth';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<CTeProps>;
    setTableValue: UseFormSetValue<CTeProps>;
}

const validations = Yup.object().shape({
    measurement_unit: Yup.string().required('Campo obrigatório!'),
    measurement_type: Yup.string().required('Campo obrigatório!'),
    amount: Yup.number().required('Campo obrigatório!')
});

const ModalLoad: React.FC<Props> = ({hide, getTableValues, setTableValue}) => {
    const {user} = useAuth();
    const methods = useForm<Cte_Load>({
        resolver: yupResolver(validations),
        defaultValues: {
            measurement_unit: user.environment?.config_measurement_unity || '',
            measurement_type: user.environment?.config_measurement_unity
                ? getMeasurementType(user.environment?.config_measurement_unity)
                : '',
            amount: 0
        }
    });
    const {handleSubmit, reset, setValue} = methods;

    const onSubmit = useCallback((dataSubmit: Cte_Load) => {
        setTableValue('loads', [...getTableValues().loads, dataSubmit]);
        reset();
        hide();
    }, []);

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Carga</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Unidade de Medida"
                                        name="measurement_unit"
                                        data_tip={
                                            helperTooltips.measurement_unit
                                        }
                                        size_label="3"
                                        size_input="3"
                                        noSizeSelect
                                        options={[
                                            {value: '00', label: 'M³'},
                                            {value: '01', label: 'KG'},
                                            {value: '02', label: 'TON'},
                                            {value: '03', label: 'UN'},
                                            {value: '04', label: 'LITROS'},
                                            {value: '05', label: 'MMBTU'}
                                        ]}
                                        onChange={(op: any) =>
                                            setValue(
                                                'measurement_type',
                                                getMeasurementType(op.value)
                                            )
                                        }
                                    />
                                </div>
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Tipo de Medida"
                                        name="measurement_type"
                                        data_tip={
                                            helperTooltips.measurement_type
                                        }
                                        size_label="3"
                                        size_input="3"
                                        noSizeSelect
                                        options={[
                                            {
                                                value: 'UNIDADES',
                                                label: 'UNIDADES'
                                            },
                                            {
                                                value: 'PESO BRUTO',
                                                label: 'PESO BRUTO'
                                            },
                                            {
                                                value: 'PESO DECLARADO',
                                                label: 'PESO DECLARADO'
                                            },
                                            {
                                                value: 'PESO CUBADO',
                                                label: 'PESO CUBADO'
                                            },
                                            {
                                                value: 'PESO AFORADO',
                                                label: 'PESO AFORADO'
                                            },
                                            {
                                                value: 'PESO AFERIDO',
                                                label: 'PESO AFERIDO'
                                            },
                                            {
                                                value: 'PESO BASE DE CÁLCULO',
                                                label: 'PESO BASE DE CÁLCULO'
                                            },
                                            {
                                                value: 'LITRAGEM',
                                                label: 'LITRAGEM'
                                            },
                                            {value: 'CAIXAS', label: 'CAIXAS'}
                                        ]}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelMeasurement
                                        required
                                        label="Quantidade"
                                        name="amount"
                                        data_tip={helperTooltips.amount_load}
                                        size_label="3"
                                        size_input="3"
                                        precision={4}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalLoad;
