const columns = [
    {
        text: 'Cód.',
        className: 'text-max-sm pl-2 link-button',
        name: 'cod',
        hasOrder: true
    },
    {
        text: 'N°',
        width: '5% ',
        className: 'text-max-sm pl-2 link-button',
        name: 'number',
        hasOrder: true
    },
    {
        text: 'Emissão',
        width: '5% ',
        className: 'text-max-sm link-button',
        name: 'date_emission',
        hasOrder: true
    },
    {
        text: 'Qtd. CT-e',
        width: '10%',
        className: 'text-max-sm link-button',
        name: 'cte_count',
        hasOrder: true
    },
    {
        text: 'Qtd. NF-e',
        width: '10%',
        className: 'text-max-sm link-button',
        name: 'nfe_count',
        hasOrder: true
    },
    {
        text: 'Vlr. Carga',
        width: '10%',
        className: 'text-max-sm link-button',
        name: 'amount',
        hasOrder: true
    },
    {
        text: 'Chave',
        width: '35%',
        className: 'text-max-sm link-button',
        name: 'key',
        hasOrder: true
    },
    {
        text: 'Situação',
        width: '5%',
        className: 'text-max-sm',
        name: 'situation',
        hasOrder: true
    },
    {
        text: 'Encerrada',
        width: '5%',
        className: 'text-max-sm px-1'
    },
    {
        text: 'Imprimir',
        width: '5%',
        className: 'text-max-sm px-1 text-center'
    },
    {
        text: 'Transmitir',
        width: '5%',
        className: 'text-max-sm px-1 text-center'
    },
    {text: 'Ações', width: '5%', className: 'text-max-sm px-1 text-center'}
];

export default columns;
