/* eslint-disable object-shorthand */
import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import ActionButton from '../../../components/ActionButton';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import api from '../../../services/api';
import columns from './schema';
import EnvironmentModal from './Form';
import {useAuth} from '../../../hooks/Auth';
import FullPageLoader from '../../../components/FullPageLoader';
import {EnvironmentProps} from '../../../interfaces';
import CardBody from '../../../components/CardBody';

interface Props {
    hide: () => void;
}

const ModalSelect: React.FC<Props> = ({hide}) => {
    const [environments, setEnvironments] = useState<EnvironmentProps[]>([]);
    const [modalData, setModalData] = useState<EnvironmentProps>(
        {} as EnvironmentProps
    );
    const [modalVisible, setModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const {setEnvironment, user} = useAuth();

    useEffect(() => {
        async function getEnvironments() {
            setLoading(true);
            try {
                const response = await api.get(
                    `environments?order=created_at&type=asc&page=1&limit=total`
                );
                setEnvironments(response.data.data);
            } catch (error) {
                toast.error(
                    'Não foi possível carregar os ambientes, verifique sua conexão e tente novamente'
                );
            }
            setLoading(false);
        }
        getEnvironments();
    }, [refresh]);

    function resetModalData() {
        setModalData({
            tenant_id: user.tenant_id,
            cnpj: '',
            ie: '',
            im: '',
            icms: 1,
            corporate_name: '',
            company_name: '',
            cep: '',
            address: '',
            number: '',
            district: '',
            complement: '',
            city_id: null,
            phone: '',
            fax: '',
            type: 1,
            cnae: '',
            rntrc: '',
            crt: 0,
            email: '',
            responsible_name: '',
            responsible_cpf: '',
            certificate_name: '',
            certificate_email: '',
            certificate_password: '',
            accountant_email: '',
            cte_environment: 2,
            tax_transparency_rate: 0,
            cte_emission: 1,
            cte_serie: 1,
            cte_number: 0,
            cte_last_cte_svcsp: '',
            cte_obs: '',
            mdfe_environment: 2,
            mdfe_emission: 1,
            mdfe_serie: 1,
            mdfe_number: 0,
            cte_os_environment: 2,
            cte_os_emission: 1,
            cte_os_serie: 1,
            cte_os_number: 0,
            cte_os_obs: '',
            auto_email: 1,
            email_config: false,
            smtp_server: '',
            sender_email: '',
            message_title: '',
            content_message: '',
            cancel_message_title: '',
            cancel_content_message: '',
            smtp_user: '',
            password_smtp: '',
            smtp_port: '',
            mdfe_obs: '',
            insurance_responsible: 1,
            insurance_id: null,
            policy: '',
            endorsement: '',
            insurance: [],
            auto_endorsement: false,
            form_action: 'include'
        });
    }

    async function showEnvironment(id: string, form_action: 'view' | 'edit') {
        setLoading(true);
        try {
            const response = await api.get(`environments/${id}`);
            setModalData({
                ...response.data,
                tax_transparency_rate:
                    response.data.tax_transparency_rate === null
                        ? 0
                        : response.data.tax_transparency_rate,
                certificate: null,
                form_action: form_action
            });
            setModalVisible(true);
        } catch (error) {
            toast.error('Não foi possível efetuar a consulta');
        }
        setLoading(false);
    }

    function handleDelete(id: string, cnpj: string, corporate_name: string) {
        Swal.fire({
            title: 'Confirmar exclusão do registro?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                setLoading(true);
                try {
                    await api.delete(
                        `environments/${id}?cnpj=${cnpj}&corporate_name=${corporate_name}`
                    );
                    setRefresh(Math.random());
                    toast.success('Registro excluído com sucesso');
                } catch (error: any) {
                    toast.error(
                        error.response?.data ||
                            'Ocorreu um erro ao excluir o registro'
                    );
                }
            }
            setLoading(false);
        });
    }

    return (
        <>
            <Modal show dialogAs="div" centered>
                <Modal.Dialog scrollable size="xl" centered>
                    <Modal.Header className="bg-primary">
                        <Modal.Title>Meus Ambientes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-sm-12 d-flex">
                                    <CardBody size="12" smallSize="12">
                                        <Table id="people" columns={columns}>
                                            {environments.map((item) => (
                                                <tr key={item.id}>
                                                    <td className="pt-2 pl-2">
                                                        {item.cnpj}
                                                    </td>
                                                    <td className="pt-2">
                                                        {`${
                                                            item.corporate_name
                                                        } ${
                                                            parseInt(
                                                                item.cnpj[14],
                                                                10
                                                            ) > 1
                                                                ? '(FILIAL)'
                                                                : ''
                                                        }`}
                                                    </td>
                                                    <td className="pt-2">
                                                        {item.cte_environment ===
                                                        1
                                                            ? 'Produção'
                                                            : 'Homologação'}
                                                    </td>
                                                    <td className="pt-2">
                                                        {item.mdfe_environment ===
                                                        1
                                                            ? 'Produção'
                                                            : 'Homologação'}
                                                    </td>
                                                    <td className="pt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-block btn-sm"
                                                            onClick={() => {
                                                                setEnvironment(
                                                                    item
                                                                );
                                                                toast.success(
                                                                    'Ambiente selecionado com sucesso'
                                                                );
                                                                hide();
                                                            }}
                                                        >
                                                            Selecionar
                                                        </button>
                                                    </td>
                                                    <td className="pt-2 pl-4">
                                                        <ActionButton
                                                            view={async () => {
                                                                await showEnvironment(
                                                                    item.id ||
                                                                        '',
                                                                    'view'
                                                                );
                                                            }}
                                                            edit={async () => {
                                                                await showEnvironment(
                                                                    item.id ||
                                                                        '',
                                                                    'edit'
                                                                );
                                                            }}
                                                            // delete={() => { // SERÁ IMPLEMENTADO DEPOIS, DESABILITANDO A LICENÇA NA API
                                                            //     handleDelete(
                                                            //         item.id ||
                                                            //             '',
                                                            //         item.cnpj,
                                                            //         item.corporate_name
                                                            //     );
                                                            // }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </Table>
                                    </CardBody>
                                </div>
                            </div>

                            {loading && <FullPageLoader />}
                        </div>
                    </Modal.Body>
                    <Modal.Footer
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <div className="float-left">
                            <div className="float-left">
                                <Button
                                    type="button"
                                    color="default"
                                    icon="fa fa-arrow-circle-left"
                                    caption="Retornar"
                                    onClick={hide}
                                />
                                <Button
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    onClick={() => {
                                        resetModalData();
                                        setModalVisible(true);
                                    }}
                                />
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>

            {modalVisible && (
                <EnvironmentModal
                    data={modalData}
                    hide={() => setModalVisible(false)}
                    refresh={() => setRefresh(Math.random())}
                />
            )}
        </>
    );
};

export default ModalSelect;
