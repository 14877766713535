import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import Button from '../../../../components/Button';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import {CteNfe, MDFeProps} from '../../../../interfaces';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import CTEsModal from '../../../Modals/CTEsModal';
import getDfeKeyModel from '../../../../utils/getDfeKeyModel';
import api from '../../../../services/api';
import FullPageLoader from '../../../../components/FullPageLoader';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<MDFeProps>;
    setTableValue: UseFormSetValue<MDFeProps>;
}

const validations = Yup.object({
    cte_city_id: Yup.number()
        .required('Selecione uma cidade.')
        .min(1, 'Selecione uma cidade.')
        .nullable(),
    access_key: Yup.string()
        .required('Insira uma chave de acesso.')
        .length(44, 'A chave deve ter 44 caracteres')
});

const CteNfeModal: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue
}) => {
    const [modalCtesVisible, setModalCtesVisible] = useState(false);
    const [endorsements, setEndorsements] = useState<any[]>();
    const [loading, setLoading] = useState(false);

    const methods = useForm<CteNfe>({
        resolver: yupResolver(validations),
        defaultValues: {
            cte_city_id: getTableValues('citiesCteNfe')[0]?.value || 0,
            access_key: ''
        }
    });

    const {handleSubmit, getValues, setValue} = methods;

    const onSubmit = useCallback(
        async (dataSubmit: CteNfe) => {
            let equivalent = false;
            getTableValues('cte_nfe').every((item) => {
                equivalent = item.access_key === getValues('access_key');
                return !equivalent;
            });
            if (!equivalent) {
                if (
                    (getTableValues('type') === 1 &&
                        getDfeKeyModel(dataSubmit.access_key) === '57') ||
                    (getTableValues('type') === 2 &&
                        getDfeKeyModel(dataSubmit.access_key) === '55')
                ) {
                    if (
                        getTableValues('unload').includes(
                            getValues('cte_city_id')
                        )
                    ) {
                        setLoading(true);
                        await api
                            .get(`verifyCteNfeKey/${dataSubmit.access_key}`)
                            .then((response) => {
                                if (response.data.length > 0) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Chave já importada',
                                        showCancelButton: true,
                                        confirmButtonText:
                                            'Continuar mesmo assim',
                                        cancelButtonText: 'Cancelar inclusão',
                                        html: `${
                                            getTableValues('type') === 1
                                                ? 'Este CT-e'
                                                : 'Esta NF-e'
                                        } está presente nos seguintes MDF-e's:<br/><br/> ${response.data
                                            .map(
                                                (item: any) =>
                                                    `Código: ${
                                                        item.cod
                                                    }, Número: ${
                                                        item.number ||
                                                        'EM DIGITAÇÃO'
                                                    }`
                                            )
                                            .join('<br/>')}`
                                    }).then(() => {
                                        setTableValue('cte_nfe', [
                                            ...getTableValues().cte_nfe,
                                            dataSubmit
                                        ]);
                                        setValue('access_key', '');
                                        toast.info('Chave adicionada');
                                    });
                                } else {
                                    setTableValue('cte_nfe', [
                                        ...getTableValues().cte_nfe,
                                        dataSubmit
                                    ]);
                                    setValue('access_key', '');
                                    toast.info('Chave adicionada');
                                }

                                if (endorsements) {
                                    setTableValue('insurance', [
                                        ...getTableValues('insurance'),
                                        ...endorsements.map((item) => ({
                                            insurance_responsible: 1,
                                            insurance_id: item.insurance_id,
                                            endorsement: item.endorsement,
                                            policy: item.policy,
                                            endorsement_id: item.id
                                        }))
                                    ]);
                                    toast.info('Averbação de CT-e importada');
                                    setEndorsements(undefined);
                                }
                            })
                            .catch((error) =>
                                toast.warning(
                                    error.response?.data ||
                                        error ||
                                        'Não foi possível verificar a chave informada em outros documentos'
                                )
                            )
                            .finally(() => setLoading(false));
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text:
                                'Esta cidade não está na lista de cidades de descarregamento.'
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: `O modelo de chave inserido deve ser ${
                            getTableValues('type') === 1
                                ? '57 (CT-e) para transportadora.'
                                : '55 (NF-e) para carga própria.'
                        }`
                    });
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Esta chave já está cadastrada.'
                });
            }
        },
        [endorsements]
    );

    return (
        <>
            <Modal show dialogAs="div" centered>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    {getTableValues('type') === 1
                                        ? 'Cadastrar chave de CT-e'
                                        : 'Cadastrar chave de NF-e'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <SelectInputLabel
                                            required
                                            label="Cidade"
                                            name="cte_city_id"
                                            data_tip="Cidade onde o documento será descarregado.<br />As opções são as cidades de descarregamento selecionadas."
                                            size_label="1"
                                            size_input="5"
                                            noSizeSelect
                                            options={
                                                getTableValues().citiesCteNfe
                                            }
                                        />
                                    </div>
                                    <div className="row">
                                        <InputLabelOnlyNumbers
                                            required
                                            label="Chave"
                                            name="access_key"
                                            size_label="1"
                                            size_input="10"
                                            data_tip="Chave de acesso do documento."
                                            digits={44}
                                            onChange={() =>
                                                setEndorsements(undefined)
                                            }
                                        />
                                        <div className="col-sm-1">
                                            <div className="row">
                                                {getTableValues('type') ===
                                                    1 && (
                                                    <button
                                                        type="button"
                                                        data-tip="Pesquisar chave de CT-e"
                                                        data-for="tip"
                                                        className="btn btn-default btn-block btn-sm"
                                                        onClick={() =>
                                                            setModalCtesVisible(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-search" />
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={() => hide()}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>
            {modalCtesVisible && (
                <CTEsModal
                    hide={() => setModalCtesVisible(false)}
                    setValue={setValue}
                    field="access_key"
                    setEndorsements={setEndorsements}
                />
            )}

            {loading && <FullPageLoader />}
        </>
    );
};

export default CteNfeModal;
