const columns = [
    {
        text: 'Descrição',
        width: '100%',
        className: 'text-max-sm link-button',
        name: 'UPPER(description)',
        hasOrder: true
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
