/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import Select from 'react-select';
import Label from '../Label';
import {styles, stylesMulti} from './styles';

interface Option {
    value: any;
    label: string;
}

interface SelectProps {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    options: Option[];
    isMulti?: boolean;
    onChange?: Function;
    hideErrors?: boolean;
    labelFilter?: boolean;
    textRight?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    className?: string;
    data_tip?: string;
    modal_id?: string;
    noSizeSelect?: boolean;
    remove_label?: boolean;
    menuPlace?: 'top' | 'bottom';
    menuHeight?: number;
}

const SelectInputLabel: React.FC<SelectProps> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    options,
    isMulti,
    onChange,
    hideErrors,
    labelFilter,
    textRight,
    isDisabled,
    isClearable,
    className,
    data_tip,
    modal_id,
    noSizeSelect,
    menuPlace,
    menuHeight,
    remove_label,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        setValue,
        getValues
    } = useFormContext();

    const handleChange = (value: any) => {
        setValue(name, value ? value.value : null);
    };

    function handleChangeMulti(option: any) {
        setValue(
            name,
            option ? (option as Option[]).map((item: Option) => item.value) : []
        );
    }

    return (
        <>
            {!remove_label && (
                <Label
                    required={required}
                    respAlt={!!labelFilter}
                    title={label}
                    col={`col-sm-${size_label} ${
                        textRight ? 'text-right' : ''
                    }`}
                    to={name}
                    data_tip={data_tip}
                />
            )}
            <div
                className={`col-sm-${size_input} ${
                    labelFilter ? 'resp-form2' : 'resp-form'
                } `}
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <Select
                            options={options}
                            {...otherProps}
                            {...field}
                            noOptionsMessage={() =>
                                'Nenhum resultado foi encontrado'
                            }
                            menuPortalTarget={
                                noSizeSelect ? document.body : undefined
                            }
                            menuPlacement={
                                noSizeSelect ? 'auto' : menuPlace || 'auto'
                            }
                            maxMenuHeight={
                                noSizeSelect ? undefined : menuHeight || 200
                            }
                            styles={isMulti ? stylesMulti : styles}
                            className={className}
                            placeholder=""
                            isMulti={isMulti}
                            isClearable={isClearable}
                            value={
                                !isMulti
                                    ? options.find(
                                          (option: any) =>
                                              option.value === field.value
                                      )
                                    : field.value?.map(
                                          (value: string | number) =>
                                              options.find(
                                                  (option) =>
                                                      option.value === value
                                              )
                                      ) || null
                            }
                            onChange={(value) => {
                                isMulti
                                    ? handleChangeMulti(value)
                                    : handleChange(value);
                                onChange && onChange(value);
                            }}
                            isDisabled={
                                isDisabled !== undefined
                                    ? isDisabled
                                    : getValues('form_action') === 'view'
                            }
                        />
                    )}
                />

                {errors && errors[`${name}`] && !hideErrors && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default SelectInputLabel;
