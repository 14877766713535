const columns = [
    {
        text: 'Cód.',
        className: 'text-max-sm pl-2 link-button',
        name: 'cod',
        hasOrder: true
    },
    {
        text: 'N°',
        className: 'text-max-sm pl-2 link-button',
        name: 'number',
        hasOrder: true
    },
    {
        text: 'Emissão',
        className: 'text-max-sm link-button',
        name: 'date_emission',
        hasOrder: true
    },
    {
        text: 'Entrega',
        className: 'text-max-sm link-button',
        name: 'date_delivery',
        hasOrder: true
    },
    {
        text: 'Remetente',
        width: '50%',
        className: 'text-max-sm link-button',
        name: 'sender_people',
        hasOrder: true
    },
    {
        text: 'Destinatário',
        width: '50%',
        className: 'text-max-sm link-button',
        name: 'recipient_people',
        hasOrder: true
    },
    {
        text: 'Prestação',
        className: 'text-max-sm link-button',
        name: 'value',
        hasOrder: true
    },
    {
        text: 'Produtos',
        className: 'text-max-sm link-button',
        name: 'commodity_value',
        hasOrder: true
    },
    {
        text: 'Situação',
        className: 'text-max-sm link-button',
        name: 'situation',
        hasOrder: true
    },
    {
        text: 'Imprimir',
        className: 'text-max-sm px-1 text-center'
    },
    {
        text: 'Enviar',
        className: 'text-max-sm px-1 text-center'
    },
    {text: 'Ações', className: 'text-max-sm px-1 text-center'}
];

export default columns;
