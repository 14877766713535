const tourStepsInfobox = [
    {
        selector: '.tour-dashboard--01',
        content: 'Apresenta a quantidade de emissões de CT-e no mês atual.'
    },
    {
        selector: '.tour-dashboard--02',
        content: 'Apresenta a quantidade de emissões de MDF-e no mês atual.'
    },
    {
        selector: '.tour-dashboard--03',
        content: 'Apresenta a quantidade de MDF-e não encerrados no mês atual.'
    },
    {
        selector: '.tour-dashboard--04',
        content:
            'Apresenta o seu plano de contrato atual e a porcentagem de limite de uso.'
    }
];

const tourStepsCardgraphicMonth = [
    {
        selector: '.tour-dashboard--08',
        content:
            'Selecione o ano em que deseja ver as emissões de CT-e mensais.'
    },
    {
        selector: '.tour-dashboard--09',
        content:
            'Selecione o ano em que deseja ver as emissões de MDF-e mensais.'
    }
];

const tourStepsCardgraphicDay = [
    {
        selector: '.tour-dashboard--10',
        content:
            'Selecione o mês em que deseja ver as emissões de CT-e diários.'
    },
    {
        selector: '.tour-dashboard--11',
        content:
            'Selecione o mês em que deseja ver as emissões de MDF-e diários.'
    }
];

const tourStepsNavbar = [
    {
        selector: '.tour-navbar--01',
        content:
            'Apresenta informações de emissões de documentos e informações do plano de contrato.'
    },
    {
        selector: '.tour-navbar--02',
        content:
            'Inclusão, vizualização, edição e exclusão de Pessoa, Grupo, Composição de Serviço, Veículo, Seguradora e CFOP .'
    },
    {
        selector: '.tour-navbar--03',
        content:
            'Inclusão, vizualização, edição, clonagem, exclusão e outras ações de CT-e'
    },
    {
        selector: '.tour-navbar--04',
        content:
            'Inclusão, vizualização, edição, clonagem, exclusão e outras ações de MDF-e'
    },
    {
        selector: '.tour-navbar--05',
        content:
            'Gerar Relatório de Conhecimentos de Transporte, Gerar Manifestos, Exportar XML'
    }
];

const tourStepsNavbarAdmin = [
    {
        selector: '.tour-navbar--06',
        content:
            'Inclusão, vizualização, edição e exclusão de Usuário, Regra de Tributação e Logs do Sistema'
    }
];

const tourStepNavbarPlan = [
    {
        selector: '.tour-navbar--07',
        content: 'Planos, preços e benefícios de contratos mensais ou anuais'
    }
];

const tourStepsNavbarIcons = [
    {
        selector: '.tour-navbar--08',
        content:
            'Inclusão, seleção, vizualização, edição e exclusão dos ambientes.'
    },
    {
        selector: '.tour-navbar--09',
        content: 'Notificações de atualizações.'
    },
    {
        selector: '.tour-navbar--10',
        content: 'Perfil e configurações do usuário.'
    }
];

const tourStepsFooter = [
    {
        selector: '.tour-footer--01',
        content: 'Exibe o ambiente selecionado.'
    },
    {
        selector: '.tour-footer--02',
        content: 'Exibe a validade do certificado cadastrado.'
    },
    {
        selector: '.tour-footer--03',
        content: 'Caso precise de ajuda do suporte, clique aqui.'
    }
];

const tourStepsPeople = [
    {
        selector: '.tour-people--01',
        content: 'Clique aqui para incluir uma pessoa.'
    },
    {
        selector: '.tour-people--02',
        content:
            'Pesquise uma pessoa pela razão social, nome fantasia, CPF ou CNPJ.'
    }
];

const tourStepsGroup = [
    {
        selector: '.tour-group--01',
        content: 'Clique aqui para incluir um grupo.'
    },
    {
        selector: '.tour-group--02',
        content: 'Pesquise um grupo pela descrição.'
    }
];

const tourStepsServices = [
    {
        selector: '.tour-services--01',
        content: 'Clique aqui para incluir uma composição de serviço.'
    },
    {
        selector: '.tour-services--02',
        content: 'Pesquise uma composição de serviço pela descrição.'
    }
];

const tourStepsVehicle = [
    {
        selector: '.tour-vehicle--01',
        content: 'Clique aqui para incluir um veículo.'
    },
    {
        selector: '.tour-vehicle--02',
        content: 'Pesquise um veículo pela  descrição, renavam ou placa.'
    }
];

const tourStepsInsurance = [
    {
        selector: '.tour-insurance--01',
        content: 'Clique aqui para incluir uma seguradora.'
    },
    {
        selector: '.tour-insurance--02',
        content: 'Pesquise uma seguradora pelo nome ou CNPJ'
    }
];

const tourStepsCfop = [
    {
        selector: '.tour-cfop--01',
        content: 'Clique aqui para incluir uma CFOP.'
    },
    {
        selector: '.tour-cfop--02',
        content: 'Pesquise um CFOP pela descrição ou pelo própio CFOP.'
    }
];

const tourStepsCte = [
    {
        selector: '.tour-cte--05',
        content:
            'Título da página atual, caso possua um ambiente exibe tipo do ambiente (Produção ou Homologação) e total de registros exibidos e não exibidos.'
    },
    {
        selector: '.tour-cte--01',
        content: 'Clique aqui para incluir um CT-e.'
    },
    {
        selector: '.tour-cte--02',
        content: 'Adicione os dados nos campos para filtrar sua pesquisa.'
    },
    {
        selector: '.tour-cte--03',
        content:
            'Após preencher a filtragem de pesquisa, clique aqui para filtrar.'
    }
];

const tourStepsMdfe = [
    {
        selector: '.tour-mdfe--05',
        content:
            'Título da página atual, caso possua um ambiente exibe tipo do ambiente (Produção ou Homologação) e total de registros exibidos e não exibidos.'
    },
    {
        selector: '.tour-mdfe--01',
        content: 'Clique aqui para incluir um MDF-e.'
    },
    {
        selector: '.tour-mdfe--02',
        content: 'Adicione os dados nos campos para filtrar sua pesquisa.'
    },
    {
        selector: '.tour-mdfe--03',
        content:
            'Após preencher a filtragem de pesquisa, clique aqui para filtrar.'
    }
];

export {
    tourStepsInfobox,
    tourStepsCardgraphicMonth,
    tourStepsCardgraphicDay,
    tourStepsNavbar,
    tourStepsNavbarAdmin,
    tourStepNavbarPlan,
    tourStepsNavbarIcons,
    tourStepsFooter,
    tourStepsPeople,
    tourStepsGroup,
    tourStepsServices,
    tourStepsVehicle,
    tourStepsInsurance,
    tourStepsCfop,
    tourStepsCte,
    tourStepsMdfe
};
