import React from 'react';
import Pagination from '../Pagination';

interface cardBodyProps {
    size: string;
    smallSize: string;
    page?: number;
    count?: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    setPage?: Function;
}

const CardBody: React.FC<cardBodyProps> = ({
    size,
    smallSize,
    page,
    count,
    setPage,
    children
}) => {
    return (
        <div className={`col-${size}`}>
            <div className="card">
                <div className="card-body p-0 desktop">
                    <div className="row">
                        <div className={`col-sm-${smallSize}`}>{children}</div>
                    </div>
                </div>
                <div className="card-body table-responsive p-0 mobile">
                    <div className="row">
                        <div className={`col-sm-${smallSize}`}>{children}</div>
                    </div>
                </div>
                {page && setPage && count !== 0 && (
                    <div className="card-footer clearfix pr-1">
                        <Pagination
                            page={page}
                            count={count || 1}
                            setPage={setPage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardBody;
