/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import {useAuth} from '../../../hooks/Auth';
import Table from '../../../components/Table';
import {CTeProps} from '../../../interfaces';
import FullPageLoader from '../../../components/FullPageLoader';
import api from '../../../services/api';
import CardBody from '../../../components/CardBody';
import schema from './schema';
import formatDate from '../../../utils/formatDate';

interface Props {
    hide: () => void;
    refresh: () => void;
}

const SendBatchModal: React.FC<Props> = ({hide, refresh}) => {
    const {user} = useAuth();
    const [ctes, setCtes] = useState<CTeProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [ids, setIds] = useState<string[]>([]);

    useEffect(() => {
        setLoading(true);
        api.get(
            `ctes?order=cod&type=desc&environment_inssuer_id=${user.environment?.id}&cte_environment=${user.environment?.cte_environment}&situation=EM DIGITAÇÃO`
        )
            .then((response) => {
                if (response.data.length > 0) {
                    setCtes(response.data);
                    setIds(response.data.map((cte: CTeProps) => cte.id));
                } else {
                    toast.warning('Nenhum documento cadastrado em digitação.');
                    hide();
                }
            })
            .catch(() => {
                toast.error('Não foi possível realizar a consulta.');
                hide();
            })
            .finally(() => setLoading(false));
    }, [user]);

    const onSubmit = useCallback(() => {
        if (ids.length > 0) {
            Swal.fire({
                icon: 'info',
                title: 'Enviar lote de CT-e',
                text:
                    "Esta ação pode demorar alguns minutos, dependendo da quantidade de CT-e's selecionados. Por favor, aguarde. O sistema irá avisar quando o processo for concluído.",
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    const reversed_ids = [...ids];
                    reversed_ids.reverse();
                    setLoading(true);
                    await api
                        .post('apiCtesBatchNS', {ids: reversed_ids})
                        .then((response) => {
                            hide();
                            Swal.fire({
                                icon: 'info',
                                title: 'Envio de lote de CT-e finalizado',
                                text: response.data
                            });
                            refresh();
                        })
                        .catch((error) => {
                            setLoading(false);
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro ao enviar lote de CT-e',
                                text:
                                    error.response?.data ||
                                    'Erro ao enviar lote'
                            });
                        })
                        .finally(() => setLoading(false));
                }
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Selecione ao menos um documento para enviar'
            });
        }
    }, [ids]);

    const mappedCtes = useMemo(
        () =>
            ctes.map((cte) => (
                <tr key={Math.random()}>
                    {/* SELECIONAR */}
                    <td className="pb-2 p-0">
                        <input
                            name={cte.id}
                            type="checkbox"
                            className="form-control-sm translate h-10"
                            defaultChecked={true}
                            onChange={(event) => {
                                setIds((current: any) => {
                                    if (event.target.checked) {
                                        return [...current, cte.id];
                                    }
                                    const newArray: string[] = current;
                                    return newArray.filter(
                                        (item) => item !== cte.id
                                    );
                                });
                            }}
                        />
                    </td>
                    {/* CÓDIGO */}
                    <td className="pt-2 pl-2">{cte.cod}</td>
                    {/* EMISSÃO */}
                    <td className="pt-2">{formatDate(cte.date_emission)}</td>
                    {/* EMITENTE */}
                    <td className="pt-2 pl-2 short-td">
                        {cte.sender_people?.name}
                    </td>
                    {/* DESTINATÁRIO */}
                    <td className="pt-2 pl-2 short-td">
                        {cte.recipient_people?.name}
                    </td>
                    {/* VALOR PRESTAÇÃO */}
                    <td className="pt-2">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(cte.value)}
                    </td>
                    {/* VALOR PRODUTO */}
                    <td className="pt-2">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(cte.commodity_value)}
                    </td>
                </tr>
            )),
        [ctes]
    );

    return (
        <>
            <Modal show={ctes.length > 0} dialogAs="div">
                <Modal.Dialog
                    scrollable
                    size="xl"
                    className="mdf-modal"
                    centered
                >
                    <Modal.Header className="bg-primary">
                        <Modal.Title>Enviar lote de CT-e</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="p-1">
                        <CardBody size="12" smallSize="12">
                            <Table id="cte" columns={schema}>
                                {mappedCtes}
                            </Table>
                        </CardBody>
                    </Modal.Body>

                    <Modal.Footer
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}
                    >
                        <div className="float-left responsive-button">
                            <Button
                                type="button"
                                color="default"
                                size="btn btn-default"
                                icon="fa fa-arrow-circle-left"
                                caption="Cancelar"
                                onClick={hide}
                            />
                            <Button
                                type="button"
                                color="success"
                                size="btn btn-success"
                                icon="fa fa-paper-plane"
                                caption="Enviar"
                                onClick={onSubmit}
                            />
                        </div>
                    </Modal.Footer>
                </Modal.Dialog>
            </Modal>

            {loading && <FullPageLoader />}
        </>
    );
};

export default SendBatchModal;
