/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary   */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    precision?: number;
    suffix?: string;
    data_tip?: string;
}

const InputLabelMeasurement: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    precision,
    suffix,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        setValue,
        getValues
    } = useFormContext();

    return (
        <>
            <Label
                required={required}
                respAlt
                title={label}
                data_tip={data_tip}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div className={`col-sm-${size_input} resp-form`}>
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            kind="money"
                            onChange={(text) => {
                                setValue(
                                    name,
                                    parseFloat(
                                        text
                                            .replaceAll('.', '')
                                            .replaceAll(',', '.')
                                            .replaceAll(suffix || '', '')
                                            .trim()
                                    )
                                );
                            }}
                            options={{
                                precision: precision
                                    ? precision
                                    : precision === 0
                                    ? 0
                                    : 3,
                                separator: ',',
                                delimiter: '.',
                                unit: '',
                                suffixUnit: suffix
                            }}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default InputLabelMeasurement;
