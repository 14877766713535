import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import {InsuranceMDFe, InsuranceProps} from '../../../../interfaces';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import {insuranceDefaultValues} from '../../../../data';
import isEquivalent from '../../../../utils/isEquivalent';
import EditIncludeButtons from '../../../../components/EditIncludeButtons';
import InsuranceModal from '../../../Insurance/ModalForm';
import FullPageLoader from '../../../../components/FullPageLoader';
import InputLabel from '../../../../components/InputLabel';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<any>;
    setTableValue: UseFormSetValue<any>;
    insurances: InsuranceProps[];
    setInsuranceRefresh: () => void;
}

const validations = Yup.object({
    insurance_responsible: Yup.number().required(
        'Informe o responsável pelo seguro'
    ),
    policy: Yup.string()
        .required('Informe a apólice')
        .max(20, 'Máximo de 20 caracteres')
        .nullable(),
    insurance_id: Yup.string().required('Selecione uma seguradora').nullable()
});

const InsurancesModal: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue,
    insurances,
    setInsuranceRefresh
}) => {
    const [loading, setLoading] = useState(false);
    const [insuranceModalVisible, setInsuranceModalVisible] = useState(false);
    const [
        insuranceModalData,
        setInsuranceModalData
    ] = useState<InsuranceProps>({} as InsuranceProps);

    const methods = useForm<InsuranceMDFe>({
        resolver: yupResolver(validations),
        defaultValues: {
            insurance_responsible: 1,
            insurance_id: '',
            policy: '',
            endorsement: ''
        }
    });

    const {handleSubmit, setValue} = methods;

    const onSubmit = useCallback((dataSubmit: InsuranceMDFe) => {
        if (
            !getTableValues('insurance')?.some((item: any) =>
                isEquivalent(item, dataSubmit)
            )
        ) {
            setTableValue('insurance', [
                ...getTableValues('insurance'),
                dataSubmit
            ]);
            hide();
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Já existe um seguro registrado com esses mesmos dados.'
            });
        }
    }, []);

    return (
        <>
            <Modal show dialogAs="div" centered>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Cadastrar informação de seguro
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <SelectInputLabel
                                            required
                                            label="Responsável"
                                            data_tip="Responsável pelo seguro."
                                            name="insurance_responsible"
                                            size_label="2"
                                            size_input="7"
                                            noSizeSelect
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'Emitente do MDF-e'
                                                },
                                                {
                                                    value: 2,
                                                    label: 'Contratante'
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            required
                                            label="Seguradora"
                                            data_tip="Nome da seguradora."
                                            name="insurance_id"
                                            noSizeSelect
                                            size_label="2"
                                            size_input="7"
                                            options={insurances.map(
                                                (
                                                    insurance: InsuranceProps
                                                ) => ({
                                                    value: insurance.id,
                                                    label:
                                                        insurance.corporate_name
                                                })
                                            )}
                                            onChange={(option: any) => {
                                                setValue(
                                                    'policy',
                                                    insurances.find(
                                                        (insurance) =>
                                                            insurance.id ===
                                                            option.value
                                                    )?.policy || ''
                                                );
                                                setValue(
                                                    'endorsement',
                                                    insurances.find(
                                                        (insurance) =>
                                                            insurance.id ===
                                                            option.value
                                                    )?.endorsement || ''
                                                );
                                            }}
                                        />
                                        <EditIncludeButtons
                                            size="2"
                                            setLoading={setLoading}
                                            setModalData={setInsuranceModalData}
                                            setModalVisible={
                                                setInsuranceModalVisible
                                            }
                                            defaultValues={
                                                insuranceDefaultValues
                                            }
                                            route="insurances"
                                            field="insurance_id"
                                            tip="Seguradora"
                                        />
                                    </div>
                                    <div className="row">
                                        <InputLabel
                                            label="Apólice"
                                            required
                                            data_tip="Número da apólice."
                                            name="policy"
                                            size_label="2"
                                            size_input="3"
                                        />

                                        <InputLabel
                                            label="Averbação"
                                            data_tip="Número da averbação."
                                            name="endorsement"
                                            size_label="2"
                                            size_input="3"
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={() => hide()}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>

            {insuranceModalVisible && (
                <InsuranceModal
                    data={insuranceModalData}
                    hide={() => setInsuranceModalVisible(false)}
                    refresh={() => setInsuranceRefresh()}
                />
            )}

            {loading && <FullPageLoader />}
        </>
    );
};

export default InsurancesModal;
