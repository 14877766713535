/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {ChangeEvent} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    data_tip?: string;
}

const InputLabelCEPnoSearch: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            <Label
                required={required}
                respAlt
                title={label}
                data_tip={data_tip}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div className={`col-sm-${size_input} resp-form`}>
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            onChange={(event) => {
                                field.onChange(event);
                                otherProps.onChange &&
                                    otherProps.onChange(
                                        (event as unknown) as ChangeEvent<HTMLInputElement>
                                    );
                            }}
                            kind="zip-code"
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default InputLabelCEPnoSearch;
