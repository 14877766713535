import React, {useCallback, useState, useEffect} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue,
    UseFormReset
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import Table from '../../../../components/Table';
import {CTeProps, Cte_Previous, PeopleProps} from '../../../../interfaces';
import PeopleModal from '../../../People/ModalForm';
import {peopleDefaultValues} from '../../../../data';
import EditIncludeButtons from '../../../../components/EditIncludeButtons';
import FullPageLoader from '../../../../components/FullPageLoader';
import helperTooltips from '../../../../data/help';
import PaginatedAsyncSelectLabel from '../../../../components/PaginatedAsyncSelectLabel';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<CTeProps>;
    setTableValue: UseFormSetValue<CTeProps>;
    resetTable: UseFormReset<CTeProps>;
    refresh: () => void;
    selectedPreviousDoc: string;
}

interface FormProps extends Cte_Previous {
    table: Cte_Previous[];
}

const validations = Yup.object().shape({
    people_id: Yup.string().required('Campo obrigatório!').nullable(),
    key: Yup.string()
        .required('Campo obrigatório!')
        .length(44, 'A chave deve conter 44 caracteres')
});

const ModalPreviousDocs: React.FC<Props> = ({
    hide,
    getTableValues,
    resetTable,
    refresh,
    selectedPreviousDoc
}) => {
    const [loading, setLoading] = useState(false);
    const [modalPeopleVisible, setModalPeopleVisible] = useState(false);
    const [peopleModalData, setPeopleModalData] = useState<PeopleProps>(
        {} as PeopleProps
    );

    const methods = useForm<FormProps>({
        resolver: yupResolver(validations),
        defaultValues: {
            people_id: '',
            key: '',
            table: getTableValues().previous_documents
        }
    });
    const {handleSubmit, getValues, setValue, reset, watch} = methods;
    watch(['people_id']);
    useEffect(() => {
        reset({
            people_id: selectedPreviousDoc,
            key: '',
            table: getTableValues().previous_documents
        });
    }, []);

    const onSubmit = useCallback(() => {
        resetTable({
            ...getTableValues(),
            previous_documents: getValues('table')
        });
        hide();
        setValue('key', '');
    }, []);

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    className="responsive-modal"
                    id="maxsize__select--previous"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Documentos anteriores</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <PaginatedAsyncSelectLabel
                                        optionSearch="name_search"
                                        optionLabel="name_combo"
                                        required
                                        label="Emissor"
                                        name="people_id"
                                        filter="type=1"
                                        size_label="2"
                                        size_input="8"
                                        data_tip={
                                            helperTooltips.people_emission
                                        }
                                        onChange={(op: any) => {
                                            setValue(
                                                'people_name',
                                                op.name_combo
                                            );
                                        }}
                                    />
                                    <EditIncludeButtons
                                        setLoading={setLoading}
                                        setModalData={setPeopleModalData}
                                        setModalVisible={setModalPeopleVisible}
                                        defaultValues={peopleDefaultValues}
                                        route="people"
                                        field="people_id"
                                        size="2"
                                        tip="Emissor"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Chave"
                                        name="key"
                                        data_tip={helperTooltips.key}
                                        size_label="2"
                                        size_input="8"
                                        digits={44}
                                    />
                                    <div className="col-sm-2">
                                        <button
                                            type="button"
                                            className="btn btn-default btn-block btn-sm"
                                            onClick={handleSubmit(async () => {
                                                let equivalent = false;
                                                getValues('table').every(
                                                    (item) => {
                                                        equivalent =
                                                            item.key ===
                                                            getValues('key');
                                                        return !equivalent;
                                                    }
                                                );
                                                if (!equivalent) {
                                                    setValue('table', [
                                                        ...getValues('table'),
                                                        {
                                                            people_id: getValues(
                                                                'people_id'
                                                            ),
                                                            people_name: getValues(
                                                                'people_name'
                                                            ),
                                                            key: getValues(
                                                                'key'
                                                            )
                                                        }
                                                    ]);
                                                    setValue('key', '');
                                                } else {
                                                    Swal.fire({
                                                        icon: 'warning',
                                                        title: 'Oops...',
                                                        text:
                                                            'Esta chave já está cadastrada.'
                                                    });
                                                }
                                            })}
                                        >
                                            <i className="fa fa-check" />
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <Table
                                    id="cte_nfe"
                                    columns={[
                                        {
                                            text: 'Chaves de acesso',
                                            width: '100%',
                                            className: 'text-max-sm'
                                        },
                                        {
                                            text: 'Remover',
                                            className: 'text-center'
                                        }
                                    ]}
                                >
                                    {getValues()
                                        .table.filter(
                                            (tableItem) =>
                                                tableItem.people_id ===
                                                getValues('people_id')
                                        )
                                        .map((item) => (
                                            <tr key={`${item.key}`}>
                                                <td className="pt-2">
                                                    {item.key}
                                                </td>
                                                <td className="text-center">
                                                    <button
                                                        type="button"
                                                        className="btn btn-xs"
                                                        onClick={() => {
                                                            reset({
                                                                ...getValues(),
                                                                table: getValues(
                                                                    'table'
                                                                ).filter(
                                                                    (
                                                                        filter_item
                                                                    ) =>
                                                                        item.key !==
                                                                        filter_item.key
                                                                )
                                                            });
                                                        }}
                                                    >
                                                        <i className="fas fa-trash" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </Table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => hide()}
                                    />
                                    <Button
                                        type="button"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        onClick={() => onSubmit()}
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {modalPeopleVisible && (
                <PeopleModal
                    data={peopleModalData}
                    hide={() => setModalPeopleVisible(false)}
                    refresh={() => refresh()}
                />
            )}

            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default ModalPreviousDocs;
