import * as Yup from 'yup';
import cpf from 'cpf';
import {validate} from 'cnpj';

const validation = Yup.object().shape({
    name: Yup.string()
        .required('Campo obrigatório!')
        .nullable()
        .max(60, 'Deve conter no máximo 60 caracteres'),
    corporate_name: Yup.string()
        .max(60, 'Deve conter no máximo 60 caracteres!')
        .required('Campo obrigatório!')
        .nullable(),
    cpf_cnpj: Yup.string()
        .required('Campo obrigatório!')
        .when('type', {
            is: 0,
            then: Yup.string().test('cpf', 'CPF inválido.', (value) => {
                if (value) {
                    return cpf.isValid(value);
                }
                return true;
            })
        })
        .when('type', {
            is: 1,
            then: Yup.string().test('cnpj', 'CNPJ inválido.', (value) => {
                if (value && value !== '00.000.000/0000-00') {
                    return validate(value);
                }
                return true;
            })
        }),
    rg_ie: Yup.string()
        .nullable()
        .max(30, 'Deve conter no máximo 30 caracteres')
        .when('type', {
            is: 1,
            then: Yup.string()
                .nullable()
                .when('icms', {
                    is: 1,
                    then: Yup.string()
                        .required('Campo obrigatório')
                        .max(30, 'Deve conter no máximo 30 caracteres')
                })
        }),
    cep: Yup.string()
        .required('Campo obrigatório')
        .length(9, 'CEP inválido')
        .nullable(),
    address: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres!')
        .required('Campo obrigatório')
        .nullable(),
    number: Yup.string()
        .max(30, 'Deve conter no máximo 30 caracteres!')
        .required('Campo obrigatório')
        .nullable(),
    district: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres!')
        .required('Campo obrigatório')
        .nullable(),
    complement: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres!')
        .nullable(),
    city_id: Yup.number().required('Campo obrigatório').nullable(),
    email: Yup.string()
        .email('E-mail inválido')
        .nullable()
        .max(120, 'Deve conter no máximo 120 caracteres'),
    site: Yup.string()
        .nullable()
        .max(120, 'Deve conter no máximo 120 caracteres'),
    suframa: Yup.string()
        .nullable()
        .max(120, 'Deve conter no máximo 120 caracteres'),
    nit_pis: Yup.string()
        .nullable()
        .max(30, 'Deve conter no máximo 30 caracteres'),
    inss: Yup.string().nullable().max(30, 'Deve conter no máximo 30 caracteres')
});

export default validation;
