/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */
import React, {useCallback, useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import removeAccents from 'remove-accents';
import {Controller, useFormContext} from 'react-hook-form';
import Label from '../Label';
import {styles, stylesMulti} from './styles';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';

interface Option {
    value: string;
    label: string;
}

interface SelectProps {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    optionLabel: string;
    optionSearch: string;
    isMulti?: boolean;
    onChange?: Function;
    isClearable?: boolean;
    data_tip?: string;
    modal_id?: string;
    menuPlace?: 'top' | 'bottom';
    menuHeight?: number;
    isDisabled?: boolean;
    filter?: string;
}

const SelectAsyncLabel: React.FC<SelectProps> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    optionLabel,
    optionSearch,
    isMulti,
    onChange,
    isClearable,
    data_tip,
    modal_id,
    menuPlace,
    menuHeight,
    filter,
    isDisabled,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues,
        setValue,
        watch
    } = useFormContext();

    const watched = watch(name);

    const [state, setState] = useState<any[]>([]);
    const {peopleRefresh} = useAuth();

    useEffect(() => {
        if (
            getValues(name) &&
            !state.find((item) => item.id === getValues(name))
        ) {
            api.get(
                `peopleCombo/${getValues(name)}?form_action=${getValues(
                    'form_action'
                )}`
            ).then((response) => setState([response.data]));
        }
    }, [peopleRefresh, watched, state]);

    const getOptions = useCallback(async (input: string) => {
        return api
            .get(
                `peopleCombo?order=name&type=asc&search=${input}${
                    filter ? `&filter=${filter}` : ''
                }&form_action=${getValues('form_action')}`
            )
            .then((response) => {
                if (input.length === 3) {
                    setState(response.data);
                }
                return response.data.map((item: any) => ({
                    label: item[optionLabel],
                    value: item.id,
                    ...item
                }));
            });
    }, []);

    const loadOptions = async (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        if (inputValue.length >= 3) {
            callback(await getOptions(inputValue));
        }
        return [];
    };

    const handleChange = (value: Option | null) => {
        setValue(name, value ? value.value : null);
    };
    function handleChangeMulti(option: any) {
        setValue(name, option ? option.map((item: Option) => item.value) : []);
    }

    return (
        <>
            <Label
                required={required}
                respAlt
                title={label}
                data_tip={data_tip}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div className={`col-sm-${size_input} resp-form`}>
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <AsyncSelect
                            loadOptions={loadOptions}
                            menuPortalTarget={document.body}
                            isDisabled={
                                getValues('form_action') === 'view' ||
                                isDisabled
                            }
                            {...otherProps}
                            {...field}
                            name={name}
                            value={
                                !isMulti
                                    ? field.value
                                        ? [
                                              {
                                                  label: state.find(
                                                      (item) =>
                                                          item.id ===
                                                          field.value
                                                  )
                                                      ? state.find(
                                                            (item) =>
                                                                item.id ===
                                                                field.value
                                                        )[optionLabel]
                                                      : '',
                                                  value: field.value
                                              }
                                          ]
                                        : []
                                    : field.value.map(
                                          (itemValue: string | number) => ({
                                              label: state.find(
                                                  (item) =>
                                                      item.id === field.value
                                              )
                                                  ? state.find(
                                                        (item) =>
                                                            item.id ===
                                                            field.value
                                                    )[optionLabel]
                                                  : '',
                                              value: itemValue
                                          })
                                      )
                            }
                            placeholder="Digite 3 ou mais caracteres"
                            noOptionsMessage={() => 'Sem resultados'}
                            loadingMessage={() => 'Digite 3 ou mais caracteres'}
                            menuPlacement={menuPlace || 'auto'}
                            maxMenuHeight={menuHeight || 200}
                            styles={isMulti ? stylesMulti : styles}
                            onChange={(value) => {
                                isMulti
                                    ? handleChangeMulti(value)
                                    : handleChange(value);
                                onChange && onChange(value);
                            }}
                            isMulti={isMulti}
                            isClearable={isClearable || !required}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default SelectAsyncLabel;
