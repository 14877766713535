import React, {useState} from 'react';
import Tour from 'reactour';
import Header from './Header';
import Body from './Body';
import {CardGraphicProps} from '../../../interfaces';
import {
    tourStepsCardgraphicDay,
    tourStepsCardgraphicMonth
} from '../../../data/tour';

interface DashboardProps {
    title: string;
    data: CardGraphicProps;
    isYear?: boolean;
    setDataDaycte: any;
    setDataDaymdfe: any;
    setDataMonthcte: any;
    setDataMonthmdfe: any;
}

const InfoBox: React.FC<DashboardProps> = ({
    title,
    isYear,
    data,
    setDataDaycte,
    setDataDaymdfe,
    setDataMonthcte,
    setDataMonthmdfe
}) => {
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card tour-dashboard--05">
                    <Header title={title} hasTour={setIsTourOpen} />
                    <div className="card-body">
                        <div className="row">
                            <Body
                                title="Emissão de CT-e em"
                                data="CT-e"
                                isYear={isYear}
                                type="cte"
                                cteYear={data.cteYear}
                                cteMonth={data.cteMonth}
                                setDataDaycte={setDataDaycte}
                                setDataDaymdfe={setDataDaymdfe}
                                setDataMonthcte={setDataMonthcte}
                                setDataMonthmdfe={setDataMonthmdfe}
                            />

                            <Body
                                title="Emissão de MDF-e em"
                                data="MDF-e"
                                isYear={isYear}
                                type="mdfe"
                                mdfeYear={data.mdfeYear}
                                mdfeMonth={data.mdfeMonth}
                                setDataDaycte={setDataDaycte}
                                setDataDaymdfe={setDataDaymdfe}
                                setDataMonthcte={setDataMonthcte}
                                setDataMonthmdfe={setDataMonthmdfe}
                            />
                        </div>
                        <Tour
                            steps={
                                isYear
                                    ? tourStepsCardgraphicMonth
                                    : tourStepsCardgraphicDay
                            }
                            isOpen={isTourOpen}
                            onRequestClose={() => setIsTourOpen(false)}
                            accentColor="#007BFF"
                            maskSpace={8}
                            rounded={20}
                            startAt={0}
                            onAfterOpen={() => {
                                setOverflow(false);
                                disabledOverflow();
                            }}
                            onBeforeClose={() => {
                                setOverflow(true);
                                disabledOverflow();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoBox;
