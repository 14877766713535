const columns = [
  {text: 'Origem', className: 'text-max-sm'},
  {text: 'Destino', className: 'text-max-sm'},
  {text: 'Red. BC', width: "5%", className: 'text-max-sm'},
  {text: 'Alíq.', width: "5%", className: 'text-max-sm'},
  {text: 'CST', width: "40%", className: 'text-max-sm'},
  {text: 'Obs', width: "50%", className: 'text-max-sm'},
  {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
