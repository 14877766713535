/* eslint-disable prefer-destructuring */
import {UseFormGetValues, UseFormReset, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {format as formatCPF} from 'cpf';
import {format as formatCNPJ} from 'cnpj';
import {CityProps, CTeProps, NFeFromXML} from '../interfaces';
import api from './api';

function getMeasureUnit(unit: string) {
    switch (unit) {
        case 'M³':
            return '00';
        case 'M3':
            return '00';
        case 'KG':
            return '01';
        case 'TON':
            return '02';
        case 'UN':
            return '03';
        case 'LITROS':
            return '04';
        case 'LT':
            return '04';
        case 'LTS':
            return '04';
        case 'MMBTU':
            return '05';
        default:
            return '03';
    }
}

function getMeasureType(unit: string) {
    switch (unit) {
        case 'M³':
            return 'PESO CUBADO';
        case 'M3':
            return 'PESO CUBADO';
        case 'KG':
            return 'PESO BRUTO';
        case 'TON':
            return 'PESO BRUTO';
        case 'UN':
            return 'UNIDADES';
        case 'LITROS':
            return 'LITRAGEM';
        case 'LT':
            return 'LITRAGEM';
        case 'LTS':
            return 'LITRAGEM';
        case 'MMBTU':
            return 'PESO CUBADO';
        default:
            return 'UNIDADES';
    }
}

async function ImportNFeXML(
    data: NFeFromXML,
    setValue: UseFormSetValue<CTeProps>,
    getValues: UseFormGetValues<CTeProps>,
    reset: UseFormReset<CTeProps>,
    cities: CityProps[],
    setImportedDfes?: () => void
): Promise<void> {
    // VERIFICA SE HÁ CPF OU CNPJ
    let sender;
    try {
        const sender_response = await api.get(
            `peopleCombo?order=name&type=asc&filter=${`cpf_cnpj=${
                data.emit.CNPJ
                    ? formatCNPJ(data.emit.CNPJ)
                    : formatCPF(data.emit.CPF as string)
            }`}`
        );
        sender = sender_response.data[0];
    } catch (error: any) {
        toast.error(error || 'Erro ao obter remetente');
    }

    let recipient;
    try {
        const recipient_response = await api.get(
            `peopleCombo?order=name&type=asc&filter=${`cpf_cnpj=${
                data.dest.CNPJ
                    ? formatCNPJ(data.dest.CNPJ)
                    : formatCPF(data.dest.CPF as string)
            }`}`
        );
        recipient = recipient_response.data[0];
    } catch (error: any) {
        toast.error(error || 'Erro ao obter destinatário');
    }

    // VERIFICA SE REMETENTE E DESTINATÁRIO SÃO OS MESMOS QUE ESTÃO NO FORMULÁRIO
    if (
        (!getValues('sender_people_id') ||
            getValues('sender_people_id') === sender?.id) &&
        (!getValues('recipient_people_id') ||
            getValues('recipient_people_id') === recipient?.id)
    ) {
        // VERIFICA SE A NOTA JÁ FOI IMPORTADA
        if (
            getValues('nfes').some(
                (item) => item.access_key === data.$.Id.substring(3)
            )
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'Não foi possível importar o XML',
                text: 'Esta NF-e já foi importada'
            });
        } else {
            setValue('nfes', [
                ...getValues('nfes'),
                {
                    access_key: data.$.Id.substring(3),
                    nfe_number: parseInt(
                        data.$.Id.substring(3).substring(25, 34),
                        10
                    ).toString() // TRANSFORMADO EM INT E EM STRING NOVAMENTE PARA REMOVER OS 0's ANTES DO NÚMERO DA NOTA
                }
            ]); // SET NF-e
            setValue(
                'commodity_value',
                getValues('commodity_value') +
                    parseFloat(data.total.ICMSTot.vNF.replace(',', '.'))
            ); // SET COMMODITY_VALUE
            const {det}: any = data; // A PROPRIEDADE DET DA NOTA PODE SER UM ARRAY OU NÃO, ESSA ATRIBUIÇÃO FOI FEITA PARA CONTORNAR ISSO
            reset({
                ...getValues(),
                loads: data.det[0]
                    ? [
                          ...getValues('loads'),
                          ...data.det.map((item) => ({
                              amount: parseFloat(item.prod.qCom),
                              measurement_unit: getMeasureUnit(item.prod.uCom),
                              measurement_type: getMeasureType(item.prod.uCom)
                          }))
                      ]
                    : [
                          ...getValues('loads'),
                          {
                              amount: parseFloat(det.prod.qCom),
                              measurement_unit: getMeasureUnit(det.prod.uCom),
                              measurement_type: getMeasureType(det.prod.uCom)
                          }
                      ]
            }); // SET LOADS - FOI USADO O RESET POIS SE A CARGA FOR MUDADA PARA UM VALOR INFERIOR AO ATUAL, O FORMULÁRIO NÃO SE COMPORTA ADEQUADAMENTE

            // SET SENDER AND CITY_ORIGIN (CADASTRA UM NOVO CASO NECESSÁRIO)
            if (sender) {
                setValue('sender_people_id', sender.id || '');
                setValue('city_origin_id', sender.city_id);
            } else {
                const cepRaw: string = data.emit.enderEmit.CEP.replace(
                    '.',
                    ''
                ).replace('-', '');
                const cep = `${cepRaw.substring(0, 5)}-${cepRaw.substring(5)}`;

                await api
                    .post('people', {
                        corporate_name: data.emit.xNome,
                        name: data.emit.xFant,
                        cpf_cnpj: data.emit.CNPJ
                            ? formatCNPJ(data.emit.CNPJ)
                            : formatCPF(data.emit.CPF as string),
                        rg_ie: data.emit.IE || 'ISENTO',
                        type: data.emit.CNPJ ? 1 : 0,
                        driver: false,
                        phone: data.emit.enderEmit.fone,
                        address: data.emit.enderEmit.xLgr,
                        district: data.emit.enderEmit.xBairro,
                        cep,
                        number: data.emit.enderEmit.nro,
                        city_id: cities.find(
                            (item) =>
                                data.emit.enderEmit.cMun ===
                                item.ibge.toString()
                        )?.id,
                        log: {
                            name: data.emit.xFant || data.emit.xNome,
                            cpf_cnpj: data.emit.CNPJ
                                ? formatCNPJ(data.emit.CNPJ)
                                : formatCPF(data.emit.CPF as string)
                        }
                    })
                    .then((response) => {
                        setValue('sender_people_id', response.data.id);
                        setValue('city_origin_id', response.data.city_id);
                    })
                    .catch((error) =>
                        toast.error(
                            error.response?.data.errors[0]?.message ||
                                error.response.data ||
                                'Erro ao cadastrar remetente da NFe'
                        )
                    );
            }

            // SET RECIPIENT (CADASTRA UM NOVO CASO NECESSÁRIO)
            if (recipient) {
                // VERIFICA SE JÁ EXISTE UM CADASTRO, SE NÃO EXISTIR REALIZA UM NOVO
                setValue('recipient_people_id', recipient.id || '');
                setValue('city_destination_id', recipient.city_id);
            } else {
                const cepRaw: string = data.dest.enderDest.CEP.replace(
                    '.',
                    ''
                ).replace('-', '');
                const cep = `${cepRaw.substring(0, 5)}-${cepRaw.substring(5)}`;
                await api
                    .post('people', {
                        corporate_name: data.dest.xNome,
                        name: data.dest.xFant || data.dest.xNome,
                        cpf_cnpj: data.dest.CNPJ
                            ? formatCNPJ(data.dest.CNPJ)
                            : formatCPF(data.dest.CPF as string),
                        rg_ie: data.dest.IE || 'ISENTO',
                        type: data.dest.CNPJ ? 1 : 0,
                        driver: false,
                        phone: data.dest.enderDest.fone,
                        address: data.dest.enderDest.xLgr,
                        district: data.dest.enderDest.xBairro,
                        cep,
                        number: data.dest.enderDest.nro,
                        city_id: cities.find(
                            (item) =>
                                data.dest.enderDest.cMun ===
                                item.ibge.toString()
                        )?.id,
                        log: {
                            name: data.dest.xFant || data.dest.xNome,
                            cpf_cnpj: data.dest.CNPJ
                                ? formatCNPJ(data.dest.CNPJ)
                                : formatCPF(data.dest.CPF as string)
                        }
                    })
                    .then((response) => {
                        setValue('recipient_people_id', response.data.id);
                        setValue('city_destination_id', response.data.city_id);
                    })
                    .catch((error) =>
                        toast.error(
                            error.response?.data.errors[0]?.message ||
                                error.response.data ||
                                'Erro ao cadastrar destinatário da NFe'
                        )
                    );
            }
            if (setImportedDfes) setImportedDfes();
            toast.info('NF-e importada');
        }
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Não foi possível importar o XML',
            text:
                'O remetente e destinatário desse documento não correspondem aos que já estão selecionados no CTe'
        });
    }
}

export default ImportNFeXML;
