const columns = [
    {
        text: 'Placa',
        className: 'text-max-sm link-button',
        width: '10%',
        name: 'UPPER(license_plate)',
        hasOrder: true
    },
    {
        text: 'Descrição',
        className: 'text-max-sm link-button',
        width: '40%',
        name: 'UPPER(description)',
        hasOrder: true
    },
    {
        text: 'Motorista',
        className: 'text-max-sm link-button',
        width: '40%',
        name: 'driver_name',
        hasOrder: true
    },
    {
        text: 'Renavam',
        className: 'text-max-sm link-button',
        width: '10%',
        name: 'renavam',
        hasOrder: true
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
