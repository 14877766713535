import React, {useState} from 'react';
import Swal from 'sweetalert2';
import {useAuth} from '../../../../hooks/Auth';
import CteReportModal from '../../../Modals/CteReport';

const CteReportItem: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const {user} = useAuth();

    function openModal() {
        if (user.environment_id) {
            setModalVisible(true);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'É necessário cadastrar um ambiente antes de realizar esta ação.'
            });
        }
    }

    return (
        <>
            <a
                className="dropdown-item cursor-pointer desktop"
                onClick={() => openModal()}
            >
                Conhecimentos de Transporte
            </a>
            <a
                className="dropdown-item cursor-pointer mobile"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                onClick={() => openModal()}
            >
                Conhecimentos de Transporte
            </a>

            {modalVisible && (
                <CteReportModal hide={() => setModalVisible(false)} />
            )}
        </>
    );
};

export default CteReportItem;
