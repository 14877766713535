import React from 'react';
import {Bar, BarChart, Tooltip, XAxis} from 'recharts';

interface GraphicProps {
    data: any;
    dataKey: any;
    isMobile?: boolean;
    color: string;
}

const Graphic: React.FC<GraphicProps> = ({data, dataKey, isMobile, color}) => {
    return (
        <BarChart
            width={isMobile ? 350 : 600}
            height={isMobile ? 200 : 300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 30
            }}
        >
            <XAxis dataKey="name" />
            <Tooltip />
            <Bar dataKey={dataKey} fill={color} />
        </BarChart>
    );
};

export default Graphic;
