import React, {
    createContext,
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import api from '../services/api';
import {
    CityProps,
    EnvironmentProps,
    TablesOrderProps,
    UserProps
} from '../interfaces';
import getDifferenceBetweenDates from '../utils/getDifferenceBetweenDates';
import convertLocalDateStringToISO from '../utils/convertLocalDateStringToISO';

interface AuthState {
    token: string;
    user: UserProps;
}

interface SignInCredentials {
    email: string;
    password: string;
}

interface RegisterCredentials {
    company_name: string;
    name: string;
    email: string;
    phone: string;
    password: string;
}

interface AuthContextData {
    user: UserProps;
    signIn(credentials: SignInCredentials): Promise<void>;
    register(credentials: RegisterCredentials): Promise<void>;
    signOut(): void;
    updateUser(user: UserProps): void;
    setEnvironment(environment: EnvironmentProps): void;
    cities: CityProps[];
    peopleRefresh: number | null;
    setPeopleRefresh: (nullValue?: null) => void;
    loadingDashboard: boolean;
    setLoadingDashboard: React.Dispatch<React.SetStateAction<boolean>>;
    tablesOrder: TablesOrderProps[];
    updateTablesOrder: (order: TablesOrderProps[]) => void;
    enviromentText: string;
}

export const AuthContext = createContext<AuthContextData>(
    {} as AuthContextData
);

export const AuthProvider: React.FC = ({children}) => {
    const history = useHistory();
    const [cities, setCities] = useState<CityProps[]>([]);
    const [peopleRefresh, setPeopleRefresh] = useState<number | null>(null);
    const [loadingDashboard, setLoadingDashboard] = useState(true);
    const [enviromentText, setEnvironmentText] = useState('');

    // OBTER AS CIDADES
    const getCitiesCallback = useCallback(() => {
        api.get(
            `cities?page=1&limit=total&order=description&type=asc&description=`
        )
            .then((responseCities) => setCities(responseCities.data.data))
            .catch(() => toast.error('Erro ao consultar cidades'));
    }, [cities]);

    const [data, setData] = useState<AuthState>(() => {
        // VERIFICAR SE JÁ EXISTE REGISTRO NO STORAGE
        const token = localStorage.getItem('@ms1cte:token');
        const user = localStorage.getItem('@ms1cte:user');

        if (token && user) {
            api.defaults.headers.authorization = `Bearer ${token}`;

            return {
                token,
                user: JSON.parse(user)
            };
        }

        return {} as AuthState;
    });

    // ORDENAÇÃO DAS TABELAS
    const [tablesOrder, setTablesOrder] = useState<TablesOrderProps[]>(() => {
        const order = localStorage.getItem('@ms1cte:tablesOrder');
        if (order) {
            return JSON.parse(order);
        }
        return [];
    });
    function updateTablesOrder(order: TablesOrderProps[]) {
        setTablesOrder(order);
        localStorage.setItem('@ms1cte:tablesOrder', JSON.stringify(order));
    }

    // ALERTA DA VALIDADE DO CERTIFICADO
    function certificateValidityAlert(certificate_validity: string) {
        const days = getDifferenceBetweenDates(
            new Date(),
            new Date(convertLocalDateStringToISO(certificate_validity))
        );

        if (days < 30) {
            Swal.fire({
                icon: days > 0 ? 'warning' : 'error',
                title:
                    days > 0
                        ? 'Vencimento do certificado digital'
                        : 'Certificado digital vencido',
                html:
                    days > 0
                        ? `Seu certificado digital vence em ${days} dias.<br/><strong>Você não conseguirá mais emitir documentos fiscais após o vencimento.</strong><br/>Renove seu certificado.`
                        : 'Renove seu certificado para poder emitir documentos fiscais após o vencimento.'
            });
        }
    }

    // OBTER DADOS DO USUÁRIO LOGADO
    useEffect(() => {
        if (data.token) {
            api.get('me').then((response) => {
                const {user, tablesOrders} = response.data;
                const {environment} = user;

                localStorage.setItem(
                    '@ms1cte:user',
                    JSON.stringify({
                        email: user.email,
                        tenant: {company_name: user.tenant.company_name},
                        environment: user.environment
                    })
                );

                if (environment) {
                    // CASO TENHA AMBIENTE, DEFINE O TEXTO QUE APARECE NO TOPO DOS MODAIS DE CTE E MDFE E NO RODAPÉ DA PÁGINA
                    setEnvironmentText(
                        ` ${`${environment.cnpj} - ${
                            environment.corporate_name
                        } ${
                            parseInt(environment.cnpj[14], 10) > 1
                                ? '(FILIAL)'
                                : ''
                        }`}`
                    );
                    // ALERTA DA VALIDADE DO CERTIFICADO
                    if (environment.certificate_validity) {
                        certificateValidityAlert(
                            environment.certificate_validity
                        );
                    }
                }
                setData({
                    user,
                    token: data.token
                });

                updateTablesOrder(tablesOrders);

                getCitiesCallback();
            });
        }
    }, [data.token]);

    // LOGIN
    const signIn = useCallback(async ({email, password}) => {
        const response = await api.post('login', {
            email,
            password
        });

        const {token} = response.data.token;
        localStorage.setItem('@ms1cte:token', token);
        api.defaults.headers.authorization = `Bearer ${token}`;

        setData((current) => ({token, user: current.user}));
    }, []);

    // REGISTRAR NOVO USUÁRIO
    const register = useCallback(
        async ({company_name, name, email, phone, password}) => {
            await api.post('register', {
                company_name,
                name,
                email,
                phone,
                password
            });
        },
        []
    );

    // LOGOUT
    const signOut = useCallback(() => {
        try {
            api.post('logout');
        } catch (error) {
            // console.log(error);
        }
        localStorage.removeItem('@ms1cte:token');
        localStorage.removeItem('@ms1cte:user');
        localStorage.removeItem('@ms1cte:environment');
        localStorage.removeItem('@ms1cte:tablesOrder');
        setData({} as AuthState);
        window.location.reload();
    }, [history]);

    // ATUALIZAR DADOS DO USUÁRIO
    const updateUser = useCallback(
        (user: UserProps) => {
            setData({
                token: data.token,
                user
            });
        },
        [setData, data.token]
    );

    // DEFINIR OU TROCAR AMBIENTE
    const setEnvironment = useCallback(
        async (environment: EnvironmentProps) => {
            await api
                .get(`environments/${environment.id}`)
                .then((response) => {
                    setData({
                        token: data.token,
                        user: {
                            ...data.user,
                            environment_id: environment.id as string,
                            environment: response.data
                        }
                    });
                    // CASO TENHA AMBIENTE, DEFINE O TEXTO QUE APARECE NO TOPO DOS MODAIS DE CTE E MDFE E NO RODAPÉ DA PÁGINA
                    setEnvironmentText(
                        ` ${`${response.data.cnpj} - ${
                            response.data.corporate_name
                        } ${
                            parseInt(response.data.cnpj[14], 10) > 1
                                ? '(FILIAL)'
                                : ''
                        }`}`
                    );
                    if (response.data?.certificate_validity) {
                        // ALERTA DA VALIDADE DO CERTIFICADO
                        certificateValidityAlert(
                            response.data?.certificate_validity
                        );
                    }
                    api.put(`changeEnvironment/${environment.id}`);
                })
                .catch((error) =>
                    toast.error(
                        error.response?.data?.errors[0]?.message ||
                            error.response?.data ||
                            error ||
                            'Erro ao selecionar ambiente'
                    )
                );
        },
        [setData, data.token, data.user]
    );

    return (
        <AuthContext.Provider
            value={{
                user: data.user,
                signIn,
                register,
                signOut,
                updateUser,
                setEnvironment,
                cities,
                peopleRefresh,
                setPeopleRefresh: (nullValue?: null) => {
                    setPeopleRefresh(nullValue === null ? null : Math.random());
                },
                loadingDashboard,
                setLoadingDashboard,
                tablesOrder,
                updateTablesOrder,
                enviromentText
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}
