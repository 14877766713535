import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import {useForm, FormProvider} from 'react-hook-form';
import Button from '../../../components/Button';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';
import FullPageLoader from '../../../components/FullPageLoader';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import {CFOPProps, PeopleProps, VehicleProps} from '../../../interfaces';
import {cte_situations} from '../../../data';
import PaginatedAsyncSelectLabel from '../../../components/PaginatedAsyncSelectLabel';

interface Props {
    hide: () => void;
}

const CteReportModal: React.FC<Props> = ({hide}) => {
    const {cities, user} = useAuth();
    const [loading, setLoading] = useState(false);

    const [cfop, setCFOP] = useState<CFOPProps[]>([]);
    const [driver, setDriver] = useState<PeopleProps[]>([]);
    const [vehicle, setVehicle] = useState<VehicleProps[]>([]);

    useEffect(() => {
        setLoading(true);
        try {
            api.get(
                `vehiclesCombo?order=description&type=asc`
            ).then((response) => setVehicle(response.data));
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de veívulos');
        }
        setLoading(false);
    }, []); // GET VEHICLES

    useEffect(() => {
        setLoading(true);
        try {
            api.get(`peopleCombo?order=name&type=asc&driver=true`).then(
                (response) => {
                    setDriver(response.data);
                }
            );
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de pessoas');
        }
        setLoading(false);
    }, []); // GET PEOPLE

    useEffect(() => {
        setLoading(true);
        try {
            api.get(
                `cfops?page=1&limit=total&order=cfop&type=asc&search=`
            ).then((response) => {
                setCFOP(response.data.data);
            });
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de CFOP');
        }
        setLoading(false);
    }, []); // GET CFOP's

    const methods = useForm({
        defaultValues: {
            environment_inssuer_id: user.environment?.id,
            environment_type: user.environment?.cte_environment,
            sender: '',
            recipient: '',
            driver: '',
            vehicle: '',
            cfop: '',
            indicator: '',
            origin: '',
            destination: '',
            start_emission: '',
            end_emission: '',
            situation: ''
        }
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset({
            environment_inssuer_id: user.environment?.id,
            environment_type: user.environment?.cte_environment,
            sender: '',
            recipient: '',
            driver: '',
            vehicle: '',
            cfop: '',
            indicator: '',
            origin: '',
            destination: '',
            start_emission: '',
            end_emission: '',
            situation: ''
        });
    }, [user]);

    const onSubmit = useCallback(async (dataSubmit) => {
        setLoading(true);
        await api
            .post(`/cteReport`, dataSubmit, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf'
                }
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            })
            .catch((error) => toast.error('Nenhum resultado encontrado'))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function handleCancel() {
        hide();
        reset();
    }

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Relatório de Conhecimentos de Transporte
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <PaginatedAsyncSelectLabel
                                            optionSearch="name_search"
                                            optionLabel="name_combo"
                                            label="Remetente"
                                            name="sender"
                                            data_tip="Traz apenas os documentos de um remetente específico"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <PaginatedAsyncSelectLabel
                                            optionSearch="name_search"
                                            optionLabel="name_combo"
                                            label="Destinatário"
                                            name="recipient"
                                            data_tip="Traz apenas os documentos de um destinatário específico"
                                            size_label="2"
                                            size_input="10"
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            options={driver.map((item) => ({
                                                value: item.id,
                                                label: item.name_combo || ''
                                            }))}
                                            label="Motorista"
                                            name="driver"
                                            data_tip="Traz apenas os documentos de um motorista específico"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuHeight={150}
                                            options={vehicle.map((item) => ({
                                                value: item.id,
                                                label: `${
                                                    item.license_plate
                                                } - ${item.description}${
                                                    item.driver?.name
                                                        ? ` - ${item.driver.name}`
                                                        : ''
                                                }`
                                            }))}
                                            label="Veículo"
                                            name="vehicle"
                                            data_tip="Traz apenas os documentos de um veículo específico"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuHeight={150}
                                            options={cfop.map((item) => ({
                                                value: item.cfop,
                                                label: `${item.cfop} - ${item.description}`
                                            }))}
                                            label="CFOP"
                                            name="cfop"
                                            data_tip="Traz apenas os documentos de um determinado CFOP"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <PaginatedAsyncSelectLabel
                                            menuHeight={100}
                                            optionSearch="name_search"
                                            optionLabel="name_combo"
                                            label="Indicador"
                                            name="indicator"
                                            data_tip="Traz apenas os documentos de um Indicador específico"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <AsyncSelectLabelCities
                                            menuPlace="top"
                                            data={cities}
                                            label="Origem"
                                            name="origin"
                                            data_tip="Cidade de origem"
                                            size_label="2"
                                            size_input="4"
                                            isClearable
                                        />
                                        <AsyncSelectLabelCities
                                            menuPlace="top"
                                            data={cities}
                                            label="Destino"
                                            name="destination"
                                            data_tip="Cidade de destino"
                                            size_label="2"
                                            size_input="4"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <InputLabel
                                            label="Período"
                                            name="start_emission"
                                            data_tip="Filtrar A PARTIR de certa data de emissão"
                                            size_label="2"
                                            type="date"
                                            size_input="4"
                                        />
                                        <InputLabel
                                            label="Até"
                                            name="end_emission"
                                            data_tip="Filtrar ATÉ certa data de emissão"
                                            size_label="2"
                                            size_input="4"
                                            type="date"
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuPlace="top"
                                            label="Situação"
                                            name="situation"
                                            data_tip="Situação do documento"
                                            size_label="2"
                                            size_input="10"
                                            options={cte_situations}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-print"
                                            caption="Gerar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
                {loading && <FullPageLoader />}
            </Modal>
        </>
    );
};

export default CteReportModal;
