/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../components/Button';
import api from '../../../services/api';
import FullPageLoader from '../../../components/FullPageLoader';
import {CFOPProps} from '../../../interfaces';
import InputLabel from '../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';

interface Props {
    data: CFOPProps;
    hide: () => void;
    refresh: () => void;
}

const validations = Yup.object().shape({
    description: Yup.string()
        .max(60, 'A descrição conter no máximo 60 caracteres!')
        .required('Campo obrigatório!'),
    cfop: Yup.string()
        .required('Campo obrigatório!')
        .length(4, 'O CFOP deve ter 4 dígitos')
});

const ModalForm: React.FC<Props> = ({data, hide, refresh}) => {
    const [loading, setLoading] = useState(false);

    const methods = useForm<CFOPProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(data);
    }, [data]);

    const onSubmit = useCallback(
        async (dataSubmit: CFOPProps) => {
            setLoading(true);
            try {
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/cfops', {
                            ...dataSubmit,
                            log: {
                                description: dataSubmit.description,
                                cfop: dataSubmit.cfop
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/cfops/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                setLoading(false);
                refresh();
                hide();
            } catch (err: any) {
                setLoading(false);
                toast.error(err.response?.data?.errors[0]?.message);
            }
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            showCloseButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="xl" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar CFOP'
                                    : data.form_action === 'edit'
                                    ? 'Alterar CFOP'
                                    : 'Visualizar CFOP'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Descrição"
                                        name="description"
                                        data_tip={
                                            helperTooltips.description_cfop
                                        }
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="CFOP"
                                        name="cfop"
                                        data_tip={helperTooltips.cfop}
                                        size_label="2"
                                        size_input="1"
                                        digits={4}
                                    />
                                </div>
                            </div>
                            {loading && <FullPageLoader />}
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            {data.form_action !== 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                    />

                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            )}
                            {data.form_action === 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={hide}
                                    />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalForm;
