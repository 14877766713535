/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ServiceCompositionProps} from '../../../interfaces';
import api from '../../../services/api';
import Button from '../../../components/Button';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import InputLabelMeasurement from '../../../components/InputLabelMeasurement';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';

interface Props {
    data: ServiceCompositionProps;
    hide: () => void;
    refresh: Function;
}

const validations = Yup.object().shape({
    description: Yup.string()
        .required('Campo obrigatório')
        .max(15, 'O serviço deve conter no máximo 15 caracteres')
});

const ModalForm: React.FC<Props> = ({data, hide, refresh}) => {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<ServiceCompositionProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(data);
    }, [data]);

    const onSubmit = useCallback(
        async (dataSubmit: ServiceCompositionProps) => {
            try {
                if (dataSubmit.action === 0 && dataSubmit.multiply_charge) {
                    await Swal.fire({
                        icon: 'warning',
                        title: 'Serviço com multiplicação sem ação definida',
                        text:
                            'Caso deseje que os valores multiplicados por este serviço tenham ação na emissão de CT-e, selecione uma ação.'
                    });
                }
                setIsLoading(true);
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/services', {
                            ...dataSubmit,
                            log: {
                                description: dataSubmit.description
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/services/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                setIsLoading(false);
                refresh(Math.random());
                hide();
            } catch (err: any) {
                setIsLoading(false);
                toast.error(err.response?.data?.errors[0]?.message);
            }
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }
    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar Composição de Serviços'
                                    : data.form_action === 'edit'
                                    ? 'Alterar Composição de Serviços'
                                    : 'Visualizar Composição de Serviços'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <SelectInputLabel
                                        required
                                        options={[
                                            {
                                                value: false,
                                                label: 'Não'
                                            },
                                            {
                                                value: true,
                                                label: 'Sim'
                                            }
                                        ]}
                                        label="ICMS"
                                        name="icms"
                                        data_tip={helperTooltips.icms}
                                        size_label="2"
                                        size_input="2"
                                    />
                                    <SelectInputLabel
                                        required
                                        options={[
                                            {
                                                value: 0,
                                                label: 'Nenhum'
                                            },
                                            {
                                                value: 1,
                                                label: 'Soma ao valor recebido'
                                            },
                                            {
                                                value: 2,
                                                label:
                                                    'Subtrair do valor recebido'
                                            },
                                            {
                                                value: 3,
                                                label:
                                                    'Somar ao valor da prestação'
                                            },
                                            {
                                                value: 4,
                                                label:
                                                    'Subtrair do valor da prestação'
                                            },
                                            {
                                                value: 5,
                                                label:
                                                    'Somar em recebido e prestação'
                                            },
                                            {
                                                value: 6,
                                                label:
                                                    'Subtrair em recebido e prestação'
                                            }
                                        ]}
                                        label="Ação"
                                        name="action"
                                        data_tip={
                                            helperTooltips.action_services
                                        }
                                        noSizeSelect
                                        size_label="1"
                                        size_input="7"
                                    />
                                </div>

                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabel
                                        required
                                        label="Descrição"
                                        name="description"
                                        data_tip={
                                            helperTooltips.description_services
                                        }
                                        size_label="2"
                                        size_input="6"
                                    />

                                    <SelectInputLabel
                                        required
                                        label="Mult. pela carga"
                                        name="multiply_charge"
                                        data_tip={
                                            helperTooltips.multiply_charge
                                        }
                                        size_label="2"
                                        size_input="2"
                                        options={[
                                            {
                                                value: false,
                                                label: 'Não'
                                            },
                                            {
                                                value: true,
                                                label: 'Sim'
                                            }
                                        ]}
                                    />
                                </div>
                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabelMeasurement
                                        precision={2}
                                        required
                                        label="Alíq. PIS"
                                        name="pis"
                                        data_tip={helperTooltips.pis}
                                        size_label="2"
                                        size_input="2"
                                        suffix="%"
                                    />

                                    <InputLabelMeasurement
                                        precision={2}
                                        required
                                        label="Alíq. Cofins"
                                        name="cofins"
                                        data_tip={helperTooltips.cofins}
                                        size_label="2"
                                        size_input="2"
                                        suffix="%"
                                    />

                                    <InputLabelMeasurement
                                        precision={2}
                                        required
                                        label="Percentual acrésc."
                                        name="percentage_inc"
                                        data_tip={helperTooltips.percentage_inc}
                                        size_label="2"
                                        size_input="2"
                                        suffix="%"
                                    />
                                </div>
                                <div className="row mb-1">
                                    <InputLabelMeasurement
                                        precision={2}
                                        required
                                        label="Cálculo"
                                        name="calc"
                                        data_tip={helperTooltips.calc_services}
                                        size_label="2"
                                        size_input="2"
                                        suffix="%"
                                    />
                                    <span className="col-form-label col-sm-8 text-nowrap">
                                        % para cálculo automático sobre o valor
                                        da mercadoria.
                                    </span>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                {data.form_action !== 'view' && (
                                    <div className="float-left">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                        />
                                    </div>
                                )}
                                {data.form_action === 'view' && (
                                    <div className="float-left">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={hide}
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalForm;
