/* eslint-disable no-plusplus */

/**
 * VERIFICA A IGUALDADE DE DOIS OBJETOS, RETORNANDO `TRUE` CASO SEJAM IGUAIS OU `FALSE` CASO POSSUAM DIFERENÇAS
 * @param a PRIMEIRO OBJETO
 * @param b SEGUNDO OBJETO
 */
function isEquivalent(a: any, b: any) {
    const props = Object.getOwnPropertyNames(a);

    for (let i = 0; i < props.length; i++) {
        const propName = props[i];

        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    return true;
}

export default isEquivalent;
