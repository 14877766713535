import React, {useCallback, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Button from '../../components/Button';
import {useAuth} from '../../hooks/Auth';
import logo from '../../assets/logo_cte.png';
import logo_natal from '../../assets/logo_cte_natal.png';
import InputGroup from '../../components/InputGroup';

const validations = Yup.object({
    email: Yup.string()
        .email('Endereço de e-mail inválido')
        .required('O endereço de e-mail é obrigatório'),
    password: Yup.string().required('A senha é obrigatória')
});

const defaultValues = {
    email: '',
    password: ''
};

const SignIn: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const {signIn} = useAuth();
    const history = useHistory();

    const methods = useForm<{email: string; password: string}>({
        resolver: yupResolver(validations),
        defaultValues,
        mode: 'onSubmit'
    });
    const {handleSubmit} = methods;

    const login = useCallback(async (dataSubmit) => {
        setLoading(true);
        signIn(dataSubmit)
            .then(() => {
                history.push('/dashboard');
            })
            .catch((error) => {
                setLoading(false);
                toast.error(
                    error.response?.data ||
                        error ||
                        'Ocorreu um erro ao realizar login, verifique sua conexão e tente novamente'
                );
            });
    }, []);

    return (
        <div className="login-page">
            <div style={{height: '100%', width: '100%', position: 'fixed'}}>
                <svg
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    style={{height: '100%', width: '100%'}}
                >
                    <path
                        d="M-5.92,121.88 C152.09,134.70 405.47,59.70 523.98,108.05 L508.74,167.27 L0.00,150.00 Z"
                        style={{
                            stroke: 'none',
                            fill:
                                new Date().getMonth() === 11
                                    ? '#c0392b'
                                    : '#08f'
                        }}
                    />
                </svg>
            </div>
            <div className="login-box">
                <div className="card">
                    <div className="card-body login-card-body">
                        <div className="login-logo">
                            <img
                                src={
                                    new Date().getMonth() === 11
                                        ? logo_natal
                                        : logo
                                }
                                style={{width: 225}}
                                alt="MS1 Sistemas"
                            />
                        </div>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(login)}>
                                <div className="mt-5 mb-3 tour-login--02">
                                    <InputGroup
                                        name="email"
                                        placeholder="E-mail"
                                        icon="fas fa-envelope"
                                    />
                                </div>
                                <div className="mb-1 tour-login--03">
                                    <InputGroup
                                        name="password"
                                        placeholder="Senha"
                                        icon="fas fa-lock"
                                        type="password"
                                    />
                                </div>
                                <div className="float-right text-min-sm mb-4">
                                    <Link to="/forgotPassword">
                                        <p className="sign-link tour-login--05">
                                            Esqueci minha senha
                                        </p>
                                    </Link>
                                </div>
                                <div className="col-13">
                                    <Button
                                        caption="Entrar"
                                        type="submit"
                                        color="primary"
                                        size="btn-block tour-login--04"
                                        border={true}
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                                {/* <div className="mt-5">
                                    Ainda não tem uma conta?
                                </div>
                                <Link to="/register">
                                    <strong
                                        className="link-register tour-login--01"
                                        style={{
                                            color:
                                                new Date().getMonth() === 11
                                                    ? '#c0392b'
                                                    : ''
                                        }}
                                    >
                                        Cadastre-se gratuitamente
                                    </strong>
                                </Link> */}
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
