const columns = [
    {
        text: 'CFOP',
        className: 'text-max-sm link-button',
        hasOrder: true,
        name: 'cfop'
    },
    {
        text: 'Descrição',
        width: '100%',
        className: 'text-max-sm link-button',
        hasOrder: true,
        name: 'UPPER(description)'
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
