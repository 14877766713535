/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {validate} from 'cnpj';
import cpf from 'cpf';
import Button from '../../../components/Button';
import api from '../../../services/api';
import PeopleModal from '../../People/ModalForm';
import {PeopleProps, VehicleProps} from '../../../interfaces';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import InputLabelPlate from '../../../components/InputLabelPlate';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import {useAuth} from '../../../hooks/Auth';
import {peopleDefaultValues} from '../../../data';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';
import InputLabelCPF from '../../../components/InputLabelCPF';
import InputLabelCNPJnoSearch from '../../../components/InputLabelCNPJnoSearch';

interface Props {
    data: VehicleProps;
    hide: () => void;
    refresh: () => void;
}

const validations = Yup.object().shape({
    description: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres'),
    renavam: Yup.string().test(
        'renavam',
        'Possui entre 9 e 11 dígitos',
        (value) => {
            if (value !== undefined && value !== null && value?.length > 0) {
                return value.length > 8 && value.length < 12;
            }
            return true;
        }
    ),
    license_plate: Yup.string()
        .required('Campo obrigatório')
        .length(8, 'Placa inválida'),
    vehicle_type: Yup.number().required('Campo obrigatório').nullable(),
    wheelset_type: Yup.string().required('Campo obrigatório'),
    bodywork: Yup.string().required('Campo obrigatório'),
    tare_kg: Yup.string().required('Campo obrigatório').nullable(),
    capacity_kg: Yup.string()
        .nullable()
        .when('vehicle_type', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório para reboque')
                .nullable()
        }),
    name: Yup.string()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório')
                .nullable()
                .max(120, 'Deve conter no máximo 120 caracteres')
        }),
    address: Yup.string()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório')
                .nullable()
                .max(120, 'Deve conter no máximo 120 caracteres')
        }),
    number: Yup.string()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório')
                .nullable()
                .max(30, 'Deve conter no máximo 30 caracteres')
        }),
    cpf_cnpj: Yup.string()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório')
                .nullable()
                .when('owner_people_type', {
                    is: 0,
                    then: Yup.string().test('cpf', 'CPF inválido.', (value) => {
                        if (value) return cpf.isValid(value);
                        return true;
                    })
                })
                .when('owner_people_type', {
                    is: 1,
                    then: Yup.string().test(
                        'cnpj',
                        'CNPJ inválido.',
                        (value) => {
                            if (value && value !== '00.000.000/0000-00')
                                return validate(value);
                            return true;
                        }
                    )
                })
        }),
    owner_city_id: Yup.number()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.number()
                .required('Campo obrigatório')
                .min(1, 'Campo obrigatório')
                .nullable()
        }),
    owner_people_type: Yup.number()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.number().required('Campo obrigatório').nullable()
        }),
    rntrc: Yup.string()
        .nullable()
        .test(
            'rntrc',
            'Caso preenchido, o RNTRC deve conter 8 dígitos, ou ser "ISENTO".',
            (value) => {
                if (
                    value !== undefined &&
                    value !== null &&
                    value !== 'ISENTO' &&
                    value?.length > 0
                ) {
                    return !!value?.match('^[0-9]{8}$');
                }
                return true;
            }
        )
        .when('owner', {
            is: 1,
            then: Yup.string()
                .required('O RNTRC deve ser informado.')
                .test(
                    'rntrc',
                    'Caso preenchido, o RNTRC deve conter 8 dígitos, ou ser "ISENTO".',
                    (value) => {
                        if (
                            value !== undefined &&
                            value !== null &&
                            value !== 'ISENTO' &&
                            value?.length > 0
                        ) {
                            return value?.length === 8;
                        }
                        return true;
                    }
                )
                .nullable()
        })
        .when('vehicle_type', {
            is: 1,
            then: Yup.string()
                .required('O RNTRC deve ser informado.')
                .test(
                    'rntrc',
                    'Caso preenchido, o RNTRC deve conter 8 dígitos, ou ser "ISENTO".',
                    (value) => {
                        if (
                            value !== undefined &&
                            value !== null &&
                            value !== 'ISENTO' &&
                            value?.length > 0
                        ) {
                            return value?.length === 8;
                        }
                        return true;
                    }
                )
                .nullable()
        }),
    owner_type: Yup.number()
        .nullable()
        .when('owner', {
            is: 1,
            then: Yup.number().required('Campo obrigatório').nullable()
        }),
    chassi: Yup.string()
        .nullable()
        .max(120, 'Deve conter no máximo 120 caracteres'),
    ie: Yup.string().nullable().max(30, 'Deve conter no máximo 30 caracteres'),
    cei_inss: Yup.string()
        .nullable()
        .max(30, 'Deve conter no máximo 30 caracteres')
});

const ModalForm: React.FC<Props> = ({data, hide, refresh}) => {
    const [loading, setLoading] = useState(false);
    const [modalDriverVisible, setModalDriverVisible] = useState(false);
    const [driverData, setDriverData] = useState<PeopleProps[]>([]);
    const [driverModalData, setDriverModalData] = useState<PeopleProps>(
        {} as PeopleProps
    );
    const {cities, peopleRefresh, setPeopleRefresh} = useAuth();
    const methods = useForm<VehicleProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset, getValues, watch, setValue} = methods;
    watch(['vehicle_type', 'owner', 'owner_people_type']);
    useEffect(() => {
        reset(data);
    }, [data]);

    useEffect(() => {
        api.get(
            `peopleCombo?order=name&type=asc&driver=true`
        ).then((response) => setDriverData(response.data));
    }, [peopleRefresh]); // REFRESH DRIVER

    const onSubmit = useCallback(
        async (dataSubmit: VehicleProps) => {
            try {
                setLoading(true);
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/vehicles', {
                            ...dataSubmit,
                            log: {
                                description: dataSubmit.description,
                                license_plate: dataSubmit.license_plate
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/vehicles/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                setLoading(false);
                refresh();
                hide();
            } catch (err: any) {
                setLoading(false);
                toast.error(
                    err.response?.data?.errors[0]?.message ||
                        'Erro ao efetuar cadastro'
                );
            }
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <Modal show dialogAs="div">
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="xl" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar Veículo'
                                    : data.form_action === 'edit'
                                    ? 'Alterar Veículo'
                                    : 'Visualizar Veículo'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabel
                                        required
                                        label="Descrição"
                                        name="description"
                                        data_tip={
                                            helperTooltips.description_vehicle
                                        }
                                        size_label="2"
                                        size_input="4"
                                    />

                                    <InputLabelOnlyNumbers
                                        label="Renavam"
                                        name="renavam"
                                        data_tip={helperTooltips.renavam}
                                        size_label="1 pl-0"
                                        size_input="2"
                                        digits={11}
                                    />

                                    <InputLabelPlate
                                        required
                                        label="Placa"
                                        name="license_plate"
                                        data_tip={helperTooltips.license_plate}
                                        size_label="1"
                                        size_input="2"
                                    />
                                </div>

                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <SelectInputLabel
                                        required
                                        noSizeSelect
                                        label="Carroceria"
                                        name="bodywork"
                                        data_tip={helperTooltips.bodywork}
                                        size_label="2"
                                        size_input="3"
                                        options={[
                                            {
                                                value: '00',
                                                label: 'Não aplicável'
                                            },
                                            {
                                                value: '01',
                                                label: 'Aberta'
                                            },
                                            {
                                                value: '02',
                                                label: 'Fecha/Baú'
                                            },
                                            {
                                                value: '03',
                                                label: 'Graneleira'
                                            },
                                            {
                                                value: '04',
                                                label: 'Porta-Contêiner'
                                            },
                                            {
                                                value: '05',
                                                label: 'Sider'
                                            }
                                        ]}
                                    />

                                    <SelectInputLabel
                                        required
                                        noSizeSelect
                                        label="Rodado"
                                        name="wheelset_type"
                                        data_tip={helperTooltips.wheelset_type}
                                        size_label="1"
                                        size_input="3"
                                        options={[
                                            {
                                                value: '01',
                                                label: 'Truck'
                                            },
                                            {
                                                value: '02',
                                                label: 'Toco'
                                            },
                                            {
                                                value: '03',
                                                label: 'Cavalo Mecânico'
                                            },
                                            {
                                                value: '04',
                                                label: 'Van'
                                            },
                                            {
                                                value: '05',
                                                label: 'Utilitário'
                                            },
                                            {
                                                value: '06',
                                                label: 'Outros'
                                            }
                                        ]}
                                    />

                                    <SelectInputLabel
                                        required
                                        label="Tipo"
                                        noSizeSelect
                                        name="vehicle_type"
                                        data_tip={helperTooltips.type_vehicle}
                                        size_label="1"
                                        size_input="2"
                                        options={[
                                            {
                                                value: 0,
                                                label: 'Tração'
                                            },
                                            {
                                                value: 1,
                                                label: 'Reboque'
                                            }
                                        ]}
                                    />
                                </div>

                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <SelectInputLabel
                                        required
                                        noSizeSelect
                                        label="Proprietário"
                                        name="owner"
                                        data_tip={helperTooltips.owner}
                                        size_label="2"
                                        size_input="2"
                                        options={[
                                            {
                                                value: 0,
                                                label: 'Próprio'
                                            },
                                            {
                                                value: 1,
                                                label: 'Terceiro'
                                            }
                                        ]}
                                    />

                                    <InputLabel
                                        label="RNTRC"
                                        required={
                                            getValues('owner') === 1 ||
                                            getValues('vehicle_type') === 1
                                        }
                                        name="rntrc"
                                        data_tip={helperTooltips.rntrc}
                                        size_label="1"
                                        size_input="3"
                                        onChange={(event) =>
                                            setValue(
                                                'rntrc',
                                                event.target.value.toUpperCase()
                                            )
                                        }
                                    />

                                    <InputLabel
                                        label="Chassi"
                                        name="chassi"
                                        data_tip={helperTooltips.chassi}
                                        size_label="1"
                                        size_input="3"
                                    />
                                </div>
                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Tara (kg)"
                                        name="tare_kg"
                                        data_tip={helperTooltips.tare_kg}
                                        size_label="2"
                                        size_input="2"
                                        digits={6}
                                    />

                                    <InputLabelOnlyNumbers
                                        required={
                                            getValues('vehicle_type') === 1
                                        }
                                        label="Capacidade (kg)"
                                        name="capacity_kg"
                                        data_tip={helperTooltips.capacity_kg}
                                        size_label="2"
                                        size_input="2"
                                        digits={6}
                                    />

                                    <InputLabelOnlyNumbers
                                        label="Capacidade (m³)"
                                        name="capacity"
                                        data_tip={helperTooltips.capacity_m}
                                        size_label="2"
                                        size_input="2"
                                        digits={3}
                                    />
                                </div>
                                <div className="row mb-1">
                                    <SelectInputLabel
                                        required
                                        label="Situação"
                                        name="active"
                                        data_tip="Situação cadastral (ativo ou inativo)"
                                        size_label="2"
                                        size_input="2"
                                        noSizeSelect
                                        options={[
                                            {
                                                value: true,
                                                label: 'Ativo'
                                            },
                                            {
                                                value: false,
                                                label: 'Inativo'
                                            }
                                        ]}
                                    />
                                    <SelectInputLabel
                                        noSizeSelect
                                        label="Motorista"
                                        name="people_driver_id"
                                        data_tip={helperTooltips.driver_vehicle}
                                        size_label="1 pl-1"
                                        size_input="5"
                                        options={driverData.map((item) => ({
                                            value: item.id,
                                            label: item.name_combo || ''
                                        }))}
                                        isClearable
                                    />
                                    <div className="col-sm-2 responsive-label desktop">
                                        <EditIncludeButtons
                                            size="12"
                                            setLoading={setLoading}
                                            setModalData={setDriverModalData}
                                            setModalVisible={
                                                setModalDriverVisible
                                            }
                                            defaultValues={{
                                                ...peopleDefaultValues,
                                                driver: true,
                                                type: 0
                                            }}
                                            route="people"
                                            field="people_driver_id"
                                            tip="Motorista"
                                        />
                                    </div>
                                    <div className="col-sm-2 responsive-label mb-3 mobile">
                                        <EditIncludeButtons
                                            size="12"
                                            setLoading={setLoading}
                                            setModalData={setDriverModalData}
                                            setModalVisible={
                                                setModalDriverVisible
                                            }
                                            defaultValues={{
                                                ...peopleDefaultValues,
                                                driver: true
                                            }}
                                            route="people"
                                            field="people_driver_id"
                                            tip="Motorista"
                                        />
                                    </div>
                                </div>
                                {getValues('owner') === 1 && (
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                <div className="card-header p-0 border-bottom-0">
                                                    <ul
                                                        className="nav nav-tabs"
                                                        id="custom-tabs-for-tab"
                                                        role="tablist"
                                                    >
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link active"
                                                                id="custom-tabs-for-owner-tab"
                                                                data-toggle="pill"
                                                                href="#custom-tabs-for-owner"
                                                                role="tab"
                                                                aria-controls="custom-tabs-for-owner"
                                                                aria-selected="true"
                                                            >
                                                                Informações do
                                                                Proprietário
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="card-body pl-0 pr-1">
                                                    <div className="tab-content">
                                                        <div
                                                            className="tab-pane fade show active"
                                                            id="custom-tabs-for-owner"
                                                            role="tabpanel"
                                                            aria-labelledby="custom-tabs-for-owner-tab"
                                                        >
                                                            <div
                                                                className="row mb-1"
                                                                style={{
                                                                    justifyContent:
                                                                        'space-between'
                                                                }}
                                                            >
                                                                <SelectInputLabel
                                                                    required
                                                                    label="CPF/CNPJ"
                                                                    data_tip={
                                                                        helperTooltips.cpf_cnpj
                                                                    }
                                                                    name="owner_people_type"
                                                                    size_label="2"
                                                                    size_input="2 pr-0"
                                                                    options={[
                                                                        {
                                                                            value: 1,
                                                                            label:
                                                                                'CNPJ'
                                                                        },
                                                                        {
                                                                            value: 0,
                                                                            label:
                                                                                'CPF'
                                                                        }
                                                                    ]}
                                                                    onChange={(
                                                                        option: any
                                                                    ) => {
                                                                        setValue(
                                                                            'cpf_cnpj',
                                                                            ''
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="col-sm-3 resp-form">
                                                                    {getValues()
                                                                        .owner_people_type ===
                                                                    0 ? (
                                                                        <InputLabelCPF
                                                                            label=""
                                                                            name="cpf_cnpj"
                                                                            size_label=""
                                                                            size_input="12 p-0"
                                                                            remove_label
                                                                        />
                                                                    ) : (
                                                                        <div className="input-group input-group-sm">
                                                                            <InputLabelCNPJnoSearch
                                                                                label=""
                                                                                name="cpf_cnpj"
                                                                                data_tip={
                                                                                    helperTooltips.cnpj
                                                                                }
                                                                                size_label="2"
                                                                                size_input="12 p-0"
                                                                                remove_label
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <SelectInputLabel
                                                                    required
                                                                    label="Tipo Propietário"
                                                                    name="owner_type"
                                                                    data_tip="Indica se o proprietário é um Transportador Autônomo de Cargas"
                                                                    size_label="2"
                                                                    size_input="3"
                                                                    options={[
                                                                        {
                                                                            value: 0,
                                                                            label:
                                                                                'TAC - Agregado'
                                                                        },
                                                                        {
                                                                            value: 1,
                                                                            label:
                                                                                'TAC - Independente'
                                                                        },
                                                                        {
                                                                            value: 2,
                                                                            label:
                                                                                'Outros'
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>
                                                            <div
                                                                className="row mb-1"
                                                                style={{
                                                                    justifyContent:
                                                                        'space-between'
                                                                }}
                                                            >
                                                                <InputLabel
                                                                    required
                                                                    label="Nome"
                                                                    name="name"
                                                                    size_label="2"
                                                                    size_input="7"
                                                                />
                                                                <InputLabel
                                                                    label="IE"
                                                                    name="ie"
                                                                    size_label="1"
                                                                    size_input="2"
                                                                />
                                                            </div>
                                                            <div
                                                                className="row mb-1"
                                                                style={{
                                                                    justifyContent:
                                                                        'space-between'
                                                                }}
                                                            >
                                                                <InputLabel
                                                                    required
                                                                    label="Endereço"
                                                                    name="address"
                                                                    size_label="2"
                                                                    size_input="7"
                                                                />

                                                                <InputLabel
                                                                    required
                                                                    label="N°"
                                                                    name="number"
                                                                    size_label="1"
                                                                    size_input="2"
                                                                />
                                                            </div>
                                                            <div
                                                                className="row mb-1"
                                                                style={{
                                                                    justifyContent:
                                                                        'space-between'
                                                                }}
                                                            >
                                                                <AsyncSelectLabelCities
                                                                    required
                                                                    label="Cidade"
                                                                    name="owner_city_id"
                                                                    size_label="2"
                                                                    size_input="7"
                                                                    data={
                                                                        cities
                                                                    }
                                                                />
                                                                <InputLabel
                                                                    label="CEI"
                                                                    name="cei_inss"
                                                                    size_label="1"
                                                                    size_input="2"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            {data.form_action !== 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            )}
                            {data.form_action === 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={hide}
                                    />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {modalDriverVisible && (
                <PeopleModal
                    data={driverModalData}
                    hide={() => setModalDriverVisible(false)}
                    refresh={setPeopleRefresh}
                />
            )}
        </Modal>
    );
};

export default ModalForm;
