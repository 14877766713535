/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import {
    FormProvider,
    useForm,
    UseFormGetValues,
    UseFormReset,
    UseFormSetValue
} from 'react-hook-form';
import {format as formatCNPJ} from 'cnpj';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import {useAuth} from '../../../../hooks/Auth';
import Table from '../../../../components/Table';
import {CTeProps, DfeProps} from '../../../../interfaces';
import FullPageLoader from '../../../../components/FullPageLoader';
import api from '../../../../services/api';
import CardBody from '../../../../components/CardBody';
import InputLabel from '../../../../components/InputLabel';
import setSituationColor from '../../../../utils/setSituationColor';
import getDFeSituationText from '../../../../utils/getDFeSituationText';
import readXmlFile from '../../../../services/readXmlFile';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import dfe_schema from '../TablesSchemas/dfe_schema';
import formatDate from '../../../../utils/formatDate';

interface Props {
    hide: () => void;
    setValue: UseFormSetValue<CTeProps>;
    getValues: UseFormGetValues<CTeProps>;
    reset: UseFormReset<CTeProps>;
    setLoading: (loading: boolean) => void;
    setImportedDfes: (value: React.SetStateAction<string[]>) => void;
}

interface SearchProps {
    start_emission: string;
    end_emission: string;
    start_value: string | number;
    end_value: string | number;
    dfe_situation: string;
    sefaz_situation: string;
    issuer: string;
}

const searchDefaultValues = {
    start_emission: '',
    end_emission: '',
    start_value: '',
    end_value: '',
    dfe_situation: '',
    sefaz_situation: '',
    issuer: ''
};

const DFEsModal: React.FC<Props> = ({
    hide,
    getValues,
    reset,
    setValue,
    setImportedDfes
}) => {
    const {setPeopleRefresh, cities} = useAuth();
    const [dfes, setDfes] = useState<DfeProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<SearchProps>(searchDefaultValues);

    useEffect(() => {
        setLoading(true);
        api.get(
            `dfes?order=created_at&type=desc&page=1&limit=20&know=true&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_value=${search.start_value}&end_value=${search.end_value}&dfe_situation=${search.dfe_situation}&sefaz_situation=${search.sefaz_situation}&issuer=${search.issuer}`
        )
            .then((response) => {
                setDfes(response.data.data);
            })
            .catch(() => {
                toast.error('Não foi possível realizar a consulta');
                hide();
            })
            .finally(() => setLoading(false));
    }, [search]);

    const methods = useForm<SearchProps>({
        defaultValues: searchDefaultValues
    });
    const {handleSubmit} = methods;
    const onSubmit = useCallback(async (dataSubmit) => {
        if (dataSubmit.start_value === 0) {
            dataSubmit.start_value = '';
        }
        if (dataSubmit.end_value === 0) {
            dataSubmit.end_value = '';
        }
        setSearch(dataSubmit);
    }, []);

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="ctes_modal"
                    >
                        <Modal.Dialog
                            scrollable
                            size="xl"
                            className="mdf-modal"
                            centered
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Selecionar DF-e com ciência
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="p-1">
                                <div
                                    className="row"
                                    style={{
                                        padding: 8
                                    }}
                                >
                                    <InputLabel
                                        label="Emissão"
                                        type="date"
                                        name="start_emission"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <InputLabel
                                        label="Até"
                                        type="date"
                                        name="end_emission"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <SelectInputLabel
                                        options={[
                                            {
                                                value: '',
                                                label: 'QUALQUER'
                                            },
                                            {
                                                value: 0,
                                                label: 'PENDENTE'
                                            },
                                            {
                                                value: 1,
                                                label: 'CONFIRMADO'
                                            },
                                            {
                                                value: 2,
                                                label: 'CIÊNCIA'
                                            },
                                            {
                                                value: 3,
                                                label: 'DESCONHECIDO'
                                            },
                                            {
                                                value: 4,
                                                label: 'NÃO REALIZADA'
                                            }
                                        ]}
                                        label="Sit. DF-e"
                                        noSizeSelect
                                        name="dfe_situation"
                                        size_label="1"
                                        size_input="2"
                                    />

                                    <SelectInputLabel
                                        options={[
                                            {
                                                value: '',
                                                label: 'QUALQUER'
                                            },
                                            {
                                                value: 'AUTORIZADO',
                                                label: 'AUTORIZADO'
                                            },
                                            {
                                                value: 'CANCELADO',
                                                label: 'CANCELADO'
                                            },
                                            {
                                                value: 'REJEITADO',
                                                label: 'REJEITADO'
                                            }
                                        ]}
                                        label="Sit. Sefaz"
                                        noSizeSelect
                                        name="sefaz_situation"
                                        size_label="1"
                                        size_input="2"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        padding: 8,
                                        paddingTop: 0
                                    }}
                                >
                                    <InputLabel
                                        label="Valor"
                                        name="start_value"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <InputLabel
                                        label="Até"
                                        name="end_value"
                                        size_label="1"
                                        size_input="2"
                                    />
                                    <InputLabel
                                        label="Remetente"
                                        name="issuer"
                                        size_label="1"
                                        size_input="3"
                                    />
                                    <div className="col-sm-2 text-right">
                                        <button
                                            type="submit"
                                            data-tip="Pesquisar chave de CT-e"
                                            data-for="tip"
                                            className="btn btn-default btn-sm"
                                        >
                                            <i className="fa fa-search pr-1" />{' '}
                                            Pesquisar
                                        </button>
                                    </div>
                                </div>
                                <CardBody size="12" smallSize="12">
                                    <Table id="cte" columns={dfe_schema}>
                                        {dfes.map((dfe) => (
                                            <tr key={Math.random()}>
                                                {/* DATA DE EMISSÃO */}
                                                <td className="pt-2">
                                                    {formatDate(
                                                        dfe.emission_date,
                                                        true
                                                    )}
                                                </td>
                                                {/* NÚMERO */}
                                                <td className="pt-2 pl-2">
                                                    {dfe.nfe_number}
                                                </td>
                                                {/* EMITENTE */}
                                                <td className="pt-2 pl-2 short-td">
                                                    {dfe.issuer}
                                                </td>
                                                {/* CNPJ */}
                                                <td className="pt-2 pl-2 short-td">
                                                    {formatCNPJ(
                                                        dfe.issuer_cnpj
                                                    )}
                                                </td>
                                                {/* VALOR */}
                                                <td className="pt-2">
                                                    {new Intl.NumberFormat(
                                                        'pt-BR',
                                                        {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        }
                                                    ).format(dfe.total_value)}
                                                </td>
                                                {/* SITUAÇÃO SEFAZ */}
                                                <td
                                                    className={`pt-2 text-center bg-${setSituationColor(
                                                        dfe.sefaz_situation
                                                    )}`}
                                                >
                                                    {dfe.sefaz_situation}
                                                </td>
                                                {/* SITUAÇÃO DFE */}
                                                <td className="pt-2 text-center">
                                                    {getDFeSituationText(
                                                        dfe.situation
                                                    )}
                                                </td>
                                                {/* IMPORTADO */}
                                                <td className="pt-2 text-center">
                                                    {dfe.imported
                                                        ? 'SIM'
                                                        : 'NÃO'}
                                                </td>
                                                {/* SELECIONAR */}
                                                <td className="p-0 text-center">
                                                    <button
                                                        disabled={dfe.imported}
                                                        type="button"
                                                        className="btn btn-default btn-sm btn-block"
                                                        onClick={() => {
                                                            api.get(
                                                                `dfesXml/${dfe.id}`
                                                            )
                                                                .then(
                                                                    async (
                                                                        response
                                                                    ) => {
                                                                        const blob = new Blob(
                                                                            [
                                                                                response.data
                                                                            ],
                                                                            {
                                                                                type:
                                                                                    'text/XML'
                                                                            }
                                                                        );
                                                                        const data = {
                                                                            target: {
                                                                                files: [
                                                                                    blob
                                                                                ],
                                                                                value:
                                                                                    ''
                                                                            }
                                                                        };
                                                                        setLoading(
                                                                            true
                                                                        );
                                                                        await readXmlFile(
                                                                            data as any,
                                                                            setValue,
                                                                            getValues,
                                                                            reset,
                                                                            setLoading,
                                                                            cities,
                                                                            () =>
                                                                                setPeopleRefresh(),
                                                                            'nfe',
                                                                            () =>
                                                                                setImportedDfes(
                                                                                    (
                                                                                        imported
                                                                                    ) => [
                                                                                        ...imported,
                                                                                        dfe.id
                                                                                    ]
                                                                                )
                                                                        );

                                                                        setLoading(
                                                                            false
                                                                        );
                                                                        hide();
                                                                    }
                                                                )
                                                                .catch(
                                                                    (error) => {
                                                                        setLoading(
                                                                            false
                                                                        );
                                                                        Swal.fire(
                                                                            {
                                                                                icon:
                                                                                    'info',
                                                                                title:
                                                                                    error
                                                                                        .response
                                                                                        ?.data ||
                                                                                    'Erro ao importar nota'
                                                                            }
                                                                        );
                                                                    }
                                                                )
                                                                .finally(() =>
                                                                    setLoading(
                                                                        false
                                                                    )
                                                                );
                                                        }}
                                                    >
                                                        <i className="fas fa-check" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </Table>
                                </CardBody>
                            </Modal.Body>

                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={hide}
                                    />
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>

            {loading && <FullPageLoader />}
        </>
    );
};

export default DFEsModal;
