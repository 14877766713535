import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`

html, body, button, input {
  font-family: 'Roboto', sans-serif;
  color: #353b48;
}

.mobile{
  display: none !important;
}

.desktop{
  display: block;
}

h6.pt-2{
  color: #FFF !important;
}

.fp-fullPageLoader {
  display: flex;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  background: rgba(0,0,0,0.7);
}

form.responsive-modal{
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}

div.modal-content{
  min-width: 60vw;
}

div.fade.modal{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.float-right button{
  margin-left: .5rem;
}

.float-left button{
  margin-left: .5rem;
}

.swal2-styled.swal2-confirm{
  background-color: var(--success) !important;
}
.swal2-styled.swal2-cancel{
  background-color: var(--light) !important;
  color: var(--dark);
  border: 1px solid #DEE2E6;
}

.swal2-title{
  font-size: 1.2em !important;
}

.swal2-html-container{
  font-size: 1em !important;
}

.breadcrumb.float-sm-right{
  margin-top: 1rem;
}

.titlePage{
  display:flex;
}

.tab-top{
  margin-top: .7rem;
}

.tab-min-top{
  margin-top: .2rem;
}

.pt-min{
  padding-top: .1rem;
}

.pt-footer{
  padding: 1.2rem !important;
}

.pl-max{
  padding-left: 2.5rem !important;
}

.tab-left{
  margin-left: .5rem;
}

.tab-right{
  margin-right: .3rem;
}

.cursor-pointer{
  cursor: pointer;
}

.btn-primary{
  transition: background .4s !important;
}

.btn-primary:hover{
  background: #0062cc !important;
}

.btn-danger{
  transition: background .4s !important;
}

.btn-danger:hover{
  background: #c82333 !important;
}

.btn-default{
  background: #FFF !important;
}

.btn-default:hover{
  background: #EEE !important;
  border-color: #343a405b !important;
}

.btn-default:focus{
  color: #2B2B2B !important;
  background: #FFF !important;
  border-color: #DEE2E6 !important;
}

.btn-success:hover{
  background: #218838 !important;
  border-color: #218838 !important;
}

.sign-link{
  color: #777777 !important;
  text-decoration: none;
}

.sign-link:hover{
  color: #0062cc !important;
  text-decoration: underline;
  cursor: pointer;
}

.sign-nolink{
  color: #777777 !important;
  text-decoration: none;
}

.sign-nolink:hover{
  color: #0062cc !important;
  cursor: text;
}

.sign-link-button{
  color: #777777 !important;
  text-decoration: none;
}

.sign-link-button:hover{
  color: #0062cc !important;
}

.underline:hover{
  text-decoration: underline;
}

.btn-login{
  margin-bottom: .5rem;
  padding: .5rem 0 .5rem;
  border-radius: 1rem;
}

.not-margin{
  margin: 0 !important;
}

.bg-color-gray{
  background: #ECECEC !important;
}

.bg-color-dark-gray{
  background: #DDDDDD !important;
}

.text-min-sm{
  font-size: .8rem;
}

.text-max-sm{
  font-size: 1rem;
}

.title-main-header::after{
  content: '';
  width: 0%;
  height: .5px;
  background: #ECECEC;
  opacity: .7;
  position: absolute;
  bottom: 0%; left: 50%;
  transition: all 600ms ease;
}

.title-main-header:hover::after{
  left: 10%;
  width: 80%;
}

i.fas.fa-arrow-left, i.fas.fa-arrow-right{
  transition: transform .4s;
}

i.fas.fa-arrow-left:hover{
  transform: translateX(-.3rem);
}

i.fas.fa-arrow-right:hover{
  transform: translateX(.3rem);
}

strong.tab-left{
  text-decoration: none;
}

strong.tab-left:hover{
  text-decoration: underline;
}

.mr-big{
  margin-right: 15rem;
}

.br-5{
  border-radius: 5px !important;
}

.navbar-toggler:hover{
  border-color: #3498db;
}

.navbar-toggler:focus {
  text-decoration: none !important;
  outline: 0 !important;
}

.adm-badge{
  transform: translateY(-13px);
}

.responsive-label{
  text-align: right;
}

.col-sm-45{
  -ms-flex: 0 0 38.5%;
    flex: 0 0 38.5%;
    max-width: 38.5%;
}

button.btn.btn-default.btn-sm.btn-flat{
  padding: .26rem 1rem;
  border-radius: 10%;
}

.contdash{
  margin: 0 5%;
}

div.mdf-modal{
  width: 1200px !important;
}

div.env-modal{
  width: 1150px !important;
}

.responsive-form-modal{
  min-width: 1200px;
  min-height: 560px;
}

div.modal-dialog.mdf-modal > div.modal-content {
  height: 570px;
}

div.modal-dialog.env-modal > div.modal-content {
  height: 540px;
}

div.progress{
  border-radius: 10px;
}

.ml-ct{
  margin-left: 1.9rem !important;
}

.pr-ct{
  padding-right: 2.45rem !important;
}

.table th {
  padding: .5rem !important;
}

.card-footer {
  padding: .5rem 1.25rem;
}

.reduce-input{
  max-width: 174px !important;
}

d-wrap{
  flex-wrap: wrap !important;
}
.col-sm-ct{
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col-sm-ct {
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  max-width: 32%;
}

.sup-mobile{
  display: none !important;
}

.responsive-link{
  padding-top: 0.5rem !important;
  padding-right: 3rem !important;
  padding-left: .25rem !important;
}

.w-40{
  width: 40%;
}

.w-70{
  width: 72%;
}

.w-100{
  width: 100%;
}

.w-350{
  min-width: 390px !important;
}

.w-date{
  min-width: 165px !important;
}

.navbar-toggler{
 font-size: .85rem !important;
}

.char-date{
  font-size: 12px;
}

.recharts-wrapper{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-height: 300px;
}

.dc-flex{
  display: -ms-flexbox !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.contract-link{
  font-size: .8rem;
}

div.progress{
  width: 88%;
  height: 4px !important;
}



.min-w-425{
  min-width: 430px !important;
}
.min-w-300{
  min-width: 300px !important;
}
.min-w-225{
  min-width: 225px !important;
}
.min-w-180{
  min-width: 180px !important;
}
.min-w-160{
  min-width: 160px !important;
}
.min-w-150{
  min-width: 150px !important;
}
.min-w-145{
  min-width: 145px !important;
}
.min-w-135{
  min-width: 135px !important;
}
.min-w-130{
  min-width: 130px !important;
}
.min-w-120{
  min-width: 120px !important;
}
.min-w-110{
  min-width: 110px !important;
}
.min-w-100{
  min-width: 100px !important;
}
.min-w-92{
  min-width: 92px !important;
}
.min-w-80{
  min-width: 79.8px !important;
}
.max-w-80{
  max-width: 79.8px !important;
}
.min-w-75{
  min-width: 75px !important;
}
.min-w-70{
  min-width: 70px !important;
}

.short-td{
  max-width: 200px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.break-td{
  max-width: 200px !important;
  white-space: normal;
}

.btn-contract{
  border: 1px solid var(--success);
  color: var(--success);
  border-radius: 6px;
  padding: 0 12px 0 !important;
  font-size: 13.5px;
  transition: background .4s, color .4s;
}

.btn-contract:hover{
  background: var(--success);
  color: #F4F6F9;
}

.card-header.card-graphic{
  border-top: 2px solid var(--primary);
}

.responsive-box-mobile{
  display: none !important;
}

.responsive-screen-mobile{
 display: none;
}

footer{
  color: #212529 !important;
}

.progress-group.d-flex.align-items-center.justify-content-between{
  height: 6px;
  margin-top: .5rem;
}

.title-footer:hover{
  cursor: pointer;
  text-decoration: underline;
}

.stay-up{
  z-index: 1 !important;
}

.stay-down{
  z-index: 0 !important;
}

.min-h-425{
  min-height: 420px;
}

.min-h-380{
  min-height: 382.5px;
}

.min-h-150{
  min-height: 150px;
}

.resp-h-100{
  height: 100px !important;
}

.translate{
  transform: translateY(10px);
}

.responsive-forms{
  width: 100% !important;
}

.modal-body{
  overflow-x: hidden;
}

span.span.text-danger{
  white-space: nowrap !important;
}

.RFS-StepperContainer{
  padding: 18px;
}

.tutorial-pc{
  display: flex !important;
}

.tutorial-mb{
  display: none !important;
}

.stepper-head{
  display: flex !important;
  align-items: center !important;
}

.up-username{
  text-transform: capitalize;
}

.ini-username{
  text-transform: lowercase;
}

.h-10{
  height: 15px;
}

.min-h-415{
  min-height: 425px !important;
}

.info-circle{
  font-size: 14px !important;
  color: var(--primary) !important;
}

.z-2{
  z-index: 2 !important;
}

.filter-mobile{
  display: none;
}

.resp-services{
  height: 175px;
}

.t-gray{
  color: #999a9b;
}

.switch-plans{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 35px;
  padding: 10px;
  border-radius: 20px;
  background: #E9ECEF;
}

.switch-label.right{
  padding-right: 10px;
}
.switch-label.left{
  padding-left: 8px;
  width: 55px;
}

.text-through{
  text-decoration: line-through;
}

.btn-sign{
  background: #e67e22;
  color: white;
  &:hover {
    color: white;
    background: #d35400;
  }
}

.content-benefits.right{
  margin-right: 25px;
}

.content-benefits.left{
  margin-left: 25px;
}

.text-max-sm{
  font-size: .875rem !important;
}

.form-control{
  font-size: .875rem !important;
}

.card-body.p-0 .table tbody>tr>td, .card-body.p-0 .table tbody>tr>th,
.card-body.p-0 .table tfoot>tr>td, .card-body.p-0 .table tfoot>tr>th,
.card-body.p-0 .table thead>tr>td, .card-body.p-0 .table thead>tr>th{
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.padding-none{
  padding: none !important;
}

.modal-header{
  display: flex;
  align-items: center;
  height: 50px;
}

.modal-title{
  font-size: 1.25rem;
}

.main-plans > div:last-child{
  display: none !important;
}

.link-register{
  &:hover {
    text-decoration: underline;
  }
}

.link-button{
  color: inherit;
  &:hover {
    color: inherit;
    cursor: pointer;
  }
}

.table-ordered {
  color: inherit;
}

.table-ordered.disabled {
  color: #bdc3c7;
}

.title-version{
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: .8;
  margin: 0 0 3px 130px;
  font-weight: 300;
  font-size: 10px;
}

.profile__user--img{
  border-radius: 100%;
  border: 1px solid #adb5bd;
  width: 100px;
  height: 100px;
  max-width: 100%;
}

.input__file[type=file]::-webkit-file-upload-button {
  border: 1px solid #343a405b;
  border-radius: 4px;
  color: #2b2b2b;
  background: #FFF;
  padding: 1px 16px;
  transition: filter 0.3s;
  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
}

.reactour__close {
  color: #646464;
  width: 11px;
  top: 10px;
  right: 18px;
  &:hover {
    color: #b33939;
  }
}

.card-body.login-card-body > div{
  position: relative;
}

.card-body.login-card-body > button{
  position: absolute;
  top: 20px;
  right: 15px;
}

.disabled__support {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 4px;
}

.question__infobox--icon{
  position: absolute;
  top: 5px;
  right: 15px;
}

.col-sm-auto.text-right.h-10.resp-form.checkbox {
  position: static !important;
}

input[type="checkbox"] {
  width: 25px;
}

input:disabled {
  background: #F2F2F2 !important;
  color: #999999 !important;
  border-color: #E6E6E6 !important;
}

textarea:disabled {
  background: #F2F2F2 !important;
  color: #999999 !important;
  border-color: #E6E6E6 !important;
}

.dropdown-menu{
  overflow-y: hidden !important;
  max-height: 450px !important;
}

.input-group-append {
  z-index: 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 2px 2px 2px #d1d8e0;
  background: #fff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #bdc3c7cf;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a6aaad;
}

#btn-import {
  height: 35.2px;
  margin-bottom: 0;
}


/* MEDIA QUERIES */

@media (max-width: 1115px){
  .resp-benefits{
    display: inline;
    margin: auto;
  }
  .content-benefits.right{
    margin-right: 0;
  }

  .content-benefits.left{
    margin-left: 0;
  }
}

@media (max-width: 1320px){
  .filter-mobile{
    display: initial;
  }
  .filter-desktop{
    display: none;
  }
}

@media (min-width: 1300px) and (max-width: 1500px){
  .recharts-wrapper{
    width: 600px !important;
  }
}

@media (max-width: 1200px){
  div.modal-content{
    min-width: 95vw;
    max-width: 95vw;
  }
  .col-12.col-sm-6.col-md-2.d-flex.align-items-stretch.flex-column.mt-2{
    flex: auto !important;
    max-width: 50% !important;
  }
  .d-none-mobile {
    display: none !important;
  }
}

@media (min-width: 1026px) and (max-width: 1200px){
  div.dc-flex.res-graphic.desktop{
    display: none !important;
  }
  div.dc-flex.res-graphic.mobile{
    display: block !important;
  }
}

@media (max-width: 1180px){
 .responsive-filter{
   display: flex !important;
 }
}

@media (max-width: 1122px){
  nav.main-header, h6.bg-primary{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  a.nav-link.title-main-header{
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .tutorial-pc{
    padding: 0 !important;
  }
  .title-version{
    margin: 0 0 3px 115px;
  }
}

@media (max-width: 1300px){
  .desktop{
    display: none !important;
  }
  .mobile{
    display: block !important;
  }
  a.page-link{
    font-size: 1.3rem !important;
    padding: 1.5px 8px !important;
  }
  .responsive-label{
    text-align: left;
  }
  .responsive-link{
    padding-top: 0.5rem !important;
    padding-left: 1.5rem !important;
  }
  .responsive-button{
   margin: auto !important;
  }
  .responsive-form-modal{
    min-width: 0;
    min-height: 0 ;
  }
  .responsive-info-footer{
    display: none !important;
  }
  footer.main-footer{
    display: inline !important;
  }
  .pt-footer{
    padding: 8px !important;
  }
  .responsive-title-navbar{
    display: none !important;
  }
  .title-main-header::after{
    content: '';
    width: 0%;
    height: 0px;
  }
  .responsive-forms{
    min-width: 1000px !important;
  }
  .responsive-min-forms{
    min-width: 100px !important;
  }
  .responsive-inputs{
    min-width: 65vw;
  }
  .max-w{
    max-width: 65vw !important;
    flex: initial !important;
  }
}

@media (max-width: 1000px){
  .responsive-box-pc{
    display: none !important;
  }
  .responsive-box-mobile{
    display: flex !important;
  }
}

@media (max-width: 950px){
  .resp-form {
    -ms-flex: auto !important;
    flex: auto !important;
    max-width: none !important;
}
  .resp-form2 {
  -ms-flex: auto !important;
  flex: auto !important;
}
.resp-h-100{
  height: 105px !important;
}
.resp-services{
  height: 560px;
}
}

@media (max-width: 1220px){
  .responsive-screen-pc{
    display: none !important;
  }
  .responsive-screen-mobile{
    display: inline-block !important;
  }
}

@media (min-width: 766px){
  ul.pagination.pagination-sm.m-0.justify-content-center.cursor-pointer{
    float: right !important;
  }
}

@media (max-width: 766px){
  .responsive-title-navbar{
    display: inline-block !important;
  }
  h6.bg-primary.responsive-link{
    padding-left: 1rem !important;
  }
  nav.main-header, h6.bg-primary{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  a.nav-link.title-main-header{
    padding-left: 16px !important;
    padding-right: 15px !important;
  }
  .responsive-screen-pc{
    display: inline-block !important;
  }
  .responsive-screen-mobile{
    display: none  !important;
  }
  .tutorial-pc{
    display: none !important;
  }
  .tutorial-mb{
    display: flex !important;
    padding: 1.7rem 1rem;
    position: absolute;
    right: 0;
  }
  .stepper-head{
    display: flex !important;
    align-items: start !important;
    position: relative;
  }
}

@media (max-width: 550px){
  .col-12.col-sm-6.col-md-2.d-flex.align-items-stretch.flex-column.mt-2{
    flex: auto !important;
    max-width: 100% !important;
  }
}

@media (max-width: 400px){
  h6.bg-primary.responsive-link{
    padding-right: 0 !important;
    padding-left: .5rem !important;
  }
  li.nav-link{
    padding-right: 10px !important;
    padding-left: 0px !important;
  }
  a.nav-link{
    padding-right: 12px !important;
    padding-left: 12px !important;
  }
}

@media (min-width: 700px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

`;
