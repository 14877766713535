import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {UserProps} from '../interfaces';
import api from './api';

function support(
    user: UserProps,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    updateUser: (userData: UserProps) => void
) {
    Swal.fire({
        title: `${user.support ? 'Desativar' : 'Ativar'} acesso do suporte`,
        text: `${
            user.support
                ? 'No momento, a equipe de suporte possui acesso ao seu sistema. Caso tenha ativado por engano ou não precise mais do atendimento, prossiga. Se ainda estiver sendo atendido, aguarde até que o processo seja concluído e a permissão será desativada automaticamente.'
                : 'Ao realizar esta ação, a equipe de suporte terá acesso temporário ao sistema. Não se preocupe, o acesso será desativado automaticamente após o atendimento.'
        }`,
        icon: 'info',
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
    }).then(async (result) => {
        if (result.value) {
            setLoading(true);
            await api
                .get(`support/${!user.support}`)
                .then(() => {
                    updateUser({
                        ...user,
                        support: !user.support
                    });
                    Swal.fire({
                        icon: 'info',
                        title: `Permissão ao suporte ${
                            user.support ? 'desativada' : 'ativada'
                        }`
                    });
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro inesperado, tente novamente');
                })
                .finally(() => setLoading(false));
        }
    });
}

export default support;
