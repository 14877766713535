const columns = [
    {
        text: '',
        name: 'check'
    },
    {
        text: 'Cód.',
        name: 'cod'
    },
    {
        text: 'Emissão',
        name: 'emission_date'
    },
    {
        text: 'Remetente',
        name: 'issuer',
        width: '50%'
    },
    {
        text: 'Destinatário',
        name: 'issuer_cnpj',
        width: '50%'
    },
    {
        text: 'Vlr. Prestação',
        name: 'total_value'
    },
    {
        text: 'Vlr. Produto',
        name: 'total_value'
    }
];

export default columns;
