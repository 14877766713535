/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useCallback, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import {validate} from 'cnpj';
import {toast} from 'react-toastify';
import cpf from 'cpf';
import Button from '../../../../components/Button';
import FullPageLoader from '../../../../components/FullPageLoader';
import api from '../../../../services/api';
import helperTooltips from '../../../../data/help';
import {useAuth} from '../../../../hooks/Auth';
import {EnvironmentProps, InsuranceProps} from '../../../../interfaces';
import InsuranceModal from '../../../MonitorMDFE/ModalForm/Modals/insurance';
import InputLabelCNPJ from '../../../../components/InputLabelCNPJ';
import InputLabel from '../../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import InputLabelCEP from '../../../../components/InputLabelCEP';
import AsyncSelectLabelCities from '../../../../components/AsyncSelectLabelCities';
import InputPhoneLabel from '../../../../components/InputLabelPhone';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import InputLabelCPF from '../../../../components/InputLabelCPF';
import InputLabelFile from '../../../../components/InputLabelFile';
import InputLabelMeasurement from '../../../../components/InputLabelMeasurement';
import InputLabelTextArea from '../../../../components/InputLabelTextArea';
import Label from '../../../../components/Label';
import noImage from '../../../../assets/no-image.png';
import getChanges from '../../../../utils/getChanges';
import formatDate from '../../../../utils/formatDate';
import Table from '../../../../components/Table';

interface Props {
    hide: () => void;
    refresh: () => void;
    data: EnvironmentProps;
}

const validations = Yup.object().shape({
    cnpj: Yup.string()
        .required('Campo obrigatório')
        .test('cnpj', 'CNPJ inválido!', (value) => {
            if (value && value !== '00.000.000/0000-00') {
                return validate(value);
            }
            return false;
        }),
    ie: Yup.string()
        .required('Campo obrigatório')
        .max(30, 'Máximo de 30 caracteres'),
    corporate_name: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres'),
    company_name: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres'),
    cep: Yup.string().required('Campo obrigatório').length(9, 'CEP inválido'),
    address: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres'),
    number: Yup.string()
        .required('Campo obrigatório')
        .max(30, 'Deve conter no máximo 30 caracteres'),
    district: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres'),
    complement: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres')
        .nullable(),
    city_id: Yup.number().nullable().required('Campo obrigatório'),
    type: Yup.number().nullable().required('Campo obrigatório'),
    rntrc: Yup.string()
        .nullable()
        .max(30, 'Deve conter no máximo 30 caracteres')
        .when('type', {
            is: 1,
            then: Yup.string()
                .required('Campo obrigatório')
                .test(
                    'rntrc',
                    "O RNTRC deve conter 8 dígitos ou ser 'ISENTO'",
                    (value) =>
                        !!value?.match('^[0-9]{8}$') || value === 'ISENTO'
                )
                .nullable()
        }),
    crt: Yup.number().nullable().required('Campo obrigatório'),
    email: Yup.string()
        .nullable()
        .email('E-mail inválido')
        .required('Campo obrigatório'),
    policy: Yup.string()
        .nullable()
        .when('insurance_id', {
            is: (insurance_id: string) => insurance_id !== null,
            then: Yup.string()
                .required(
                    'Caso informe uma seguradora, deve-se informar a apólice'
                )
                .nullable()
        }),
    phone: Yup.string()
        .required('Campo obrigatório')
        .min(14, 'Telefone inválido')
        .nullable(),
    fax: Yup.string()
        .test('fax', 'Fax inválido!', (value) => {
            if (
                value !== undefined &&
                value !== '' &&
                value !== null &&
                value?.length < 14
            ) {
                return false;
            }
            return true;
        })
        .max(30, 'Deve conter no máximo 30 caracteres')
        .nullable(),
    certificate_password: Yup.string().required('Campo obrigatório').nullable(),
    certificate_validation: Yup.string()
        .required('Campo obrigatório')
        .nullable(),
    responsible_name: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres')
        .nullable(),
    responsible_cpf: Yup.string()
        .test('responsible_cpf', 'CPF inválido!', (value) => {
            if (value && value !== '') {
                return cpf.isValid(value);
            }
            return true;
        })
        .nullable(),
    smtp_server: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    sender_email: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    message_title: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    content_message: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    cancel_message_title: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    cancel_content_message: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    smtp_user: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    password_smtp: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        }),
    smtp_port: Yup.string()
        .nullable()
        .when('email_config', {
            is: true,
            then: Yup.string().required('Campo obrigatório').nullable()
        })
});

const EnvironmentModal: React.FC<Props> = ({hide, refresh, data}) => {
    const {user, setEnvironment, cities} = useAuth();
    const [loading, setLoading] = useState(false);
    const [insurances, setInsurances] = useState<InsuranceProps[]>([]);
    const [insuranceModalVisible, setInsuranceModalVisible] = useState(false);
    const [insuranceRefresh, setInsuranceRefresh] = useState(0);
    const [editCertificate, setEditCertificate] = useState<boolean>();
    const [certificateFile, setCertificateFile] = useState<string>();
    const [logoFile, setLogoFile] = useState<string>();

    const methods = useForm<EnvironmentProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset, setValue, getValues, watch} = methods;
    useEffect(() => {
        reset({
            ...data,
            certificate_validation:
                data.certificate_validity === undefined ||
                data.certificate_validity === null
                    ? null
                    : 'file'
        });
        setEditCertificate(
            data.certificate_validity === undefined ||
                data.certificate_validity === null
        );
        setLogoFile(undefined);
        setCertificateFile(undefined);
    }, [data]);
    watch(['email_config', 'type', 'insurance']);

    useEffect(() => {
        setLoading(true);
        api.get(
            `insurances?page=1&limit=total&order=created_at&type=desc&description=`
        )
            .then((response) => setInsurances(response.data.data))
            .finally(() => setLoading(false));
    }, [insuranceRefresh]); // GET INSURANCES

    const onSubmit = useCallback(
        async (dataSubmit) => {
            setLoading(true);
            dataSubmit.certificate = certificateFile || null;
            dataSubmit.logo = logoFile || null;
            const formData = new FormData();
            Object.keys(dataSubmit).forEach((key) =>
                formData.append(
                    key,
                    dataSubmit[key] === null ? '' : dataSubmit[key]
                )
            );
            formData.set('insurance', JSON.stringify(dataSubmit.insurance));

            switch (dataSubmit.form_action) {
                case 'include':
                    try {
                        formData.append(
                            'log',
                            JSON.stringify({
                                corporate_name: dataSubmit.corporate_name,
                                cnpj: dataSubmit.cnpj
                            })
                        );
                        await api.post('/environments', formData);
                        toast.success('Ambiente cadastrado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        setLoading(false);
                        toast.error(
                            error.response?.data ||
                                'Ocorreu um erro ao cadastrar o ambiente'
                        );
                    }
                    break;
                case 'edit':
                    try {
                        const log = getChanges(data, {
                            ...dataSubmit,
                            insurance_id: dataSubmit.insurance_id || null
                        });
                        formData.append('log', JSON.stringify(log));
                        formData.delete('deleted_at');
                        await api.put(
                            `/environments/${dataSubmit.id}`,
                            formData
                        );
                        if (user.environment?.id === dataSubmit.id) {
                            setEnvironment(dataSubmit);
                        }
                        toast.success('Ambiente alterado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        setLoading(false);
                        toast.error(
                            error.response?.data ||
                                'Ocorreu um erro ao alterar o ambiente'
                        );
                    }
                    break;
                default:
                    toast.error('Desculpe, ocorreu um erro');
            }
            setLoading(false);
        },
        [data, certificateFile, logoFile, user]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog
                            scrollable
                            size="xl"
                            className="env-modal"
                            centered
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    {data.form_action === 'include'
                                        ? 'Cadastrar Ambiente'
                                        : data.form_action === 'edit'
                                        ? 'Alterar Ambiente'
                                        : 'Visualizar Ambiente'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-0 m-0">
                                <div className="card-header p-1">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                href="#general-info"
                                                data-toggle="tab"
                                            >
                                                1 - Informações Gerais
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#certificate"
                                                data-toggle="tab"
                                            >
                                                2 - Certificado Digital e Logo
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#cte-tab"
                                                data-toggle="tab"
                                            >
                                                3 - CT-e
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#mdfe-tab"
                                                data-toggle="tab"
                                            >
                                                4 - MDF-e
                                            </a>
                                        </li>

                                        {/* <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#cteos"
                                                data-toggle="tab"
                                            >
                                                5 - CT-e OS
                                            </a>
                                        </li> */}

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#environment-insurance"
                                                data-toggle="tab"
                                            >
                                                5 - Seguro
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#email"
                                                data-toggle="tab"
                                            >
                                                6 - Configurações de e-mail
                                            </a>
                                        </li>

                                        {user?.tenant?.module_endorsement && (
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    href="#endorsement"
                                                    data-toggle="tab"
                                                >
                                                    7 - Averbação
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="card-body p-2 pr-3">
                                    <div className="tab-content">
                                        <div // Informações Gerais
                                            className="tab-pane active"
                                            id="general-info"
                                        >
                                            <div className="row">
                                                <InputLabelCNPJ
                                                    required
                                                    label="CNPJ"
                                                    data_tip={
                                                        helperTooltips.cnpj
                                                    }
                                                    name="cnpj"
                                                    size_label="2"
                                                    size_input="3"
                                                    cities={cities}
                                                    city_field="city_id"
                                                    setLoading={setLoading}
                                                    disabled={
                                                        getValues(
                                                            'form_action'
                                                        ) !== 'include'
                                                    }
                                                />

                                                <InputLabel
                                                    label="IE"
                                                    data_tip={helperTooltips.ie}
                                                    required
                                                    name="ie"
                                                    onChange={(event) =>
                                                        setValue(
                                                            'ie',
                                                            event.target.value.toUpperCase()
                                                        )
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="IM"
                                                    data_tip={helperTooltips.im}
                                                    name="im"
                                                    size_label="1"
                                                    size_input="2"
                                                    digits={30}
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabel
                                                    required
                                                    label="Razão social"
                                                    data_tip={
                                                        helperTooltips.corporate_name
                                                    }
                                                    name="corporate_name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabel
                                                    required
                                                    label="Nome Fantasia"
                                                    data_tip={
                                                        helperTooltips.name
                                                    }
                                                    name="company_name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabelCEP
                                                    required
                                                    label="CEP"
                                                    name="cep"
                                                    data_tip={
                                                        helperTooltips.cep
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                    cities={cities}
                                                    city_field="city_id"
                                                    setLoading={setLoading}
                                                />

                                                <InputLabel
                                                    required
                                                    label="Logradouro"
                                                    name="address"
                                                    data_tip={
                                                        helperTooltips.address
                                                    }
                                                    size_label="1"
                                                    size_input="4"
                                                />

                                                <InputLabel
                                                    required
                                                    label="Número"
                                                    name="number"
                                                    data_tip={
                                                        helperTooltips.number
                                                    }
                                                    size_label="1"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabel
                                                    required
                                                    label="Bairro"
                                                    name="district"
                                                    data_tip={
                                                        helperTooltips.district
                                                    }
                                                    size_label="2"
                                                    size_input="5"
                                                />

                                                <InputLabel
                                                    label="Complemento"
                                                    name="complement"
                                                    data_tip={
                                                        helperTooltips.complement
                                                    }
                                                    size_label="2"
                                                    size_input="3"
                                                />
                                            </div>
                                            <div className="row">
                                                <AsyncSelectLabelCities
                                                    menuHeight={150}
                                                    required
                                                    label="Cidade"
                                                    name="city_id"
                                                    data_tip={
                                                        helperTooltips.city
                                                    }
                                                    size_label="2"
                                                    size_input="6"
                                                    data={cities}
                                                />

                                                <SelectInputLabel
                                                    label="ICMS"
                                                    data_tip={
                                                        helperTooltips.icms
                                                    }
                                                    required
                                                    name="icms"
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label:
                                                                'Contribuinte ICMS'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Isento'
                                                        },
                                                        {
                                                            value: 9,
                                                            label:
                                                                'Não Contribuinte'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row">
                                                <InputPhoneLabel
                                                    required
                                                    label="Telefone"
                                                    name="phone"
                                                    data_tip={
                                                        helperTooltips.phone
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <InputPhoneLabel
                                                    label="Fax"
                                                    name="fax"
                                                    data_tip={
                                                        helperTooltips.fax
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <SelectInputLabel
                                                    required
                                                    label="Tipo"
                                                    name="type"
                                                    data_tip={
                                                        helperTooltips.type_mdfe
                                                    }
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label:
                                                                'Transportadora'
                                                        },
                                                        {
                                                            value: 2,
                                                            label:
                                                                'Carga própria'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabelOnlyNumbers
                                                    label="CNAE"
                                                    name="cnae"
                                                    data_tip={
                                                        helperTooltips.cnae
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                    digits={30}
                                                />

                                                <InputLabel
                                                    label="RNTRC"
                                                    required={
                                                        getValues('type') === 1
                                                    }
                                                    name="rntrc"
                                                    data_tip={
                                                        helperTooltips.rntrc
                                                    }
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <SelectInputLabel
                                                    menuPlace="top"
                                                    required
                                                    label="CRT"
                                                    name="crt"
                                                    data_tip={
                                                        helperTooltips.crt
                                                    }
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 0,
                                                            label:
                                                                'Simples nacional'
                                                        },
                                                        {
                                                            value: 1,
                                                            label:
                                                                'Simples excesso nacional'
                                                        },
                                                        {
                                                            value: 2,
                                                            label:
                                                                'Regime normal'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabel
                                                    required
                                                    label="E-mail"
                                                    name="email"
                                                    data_tip={
                                                        helperTooltips.email
                                                    }
                                                    size_label="2"
                                                    size_input="4"
                                                />
                                                <InputLabel
                                                    label="E-mail p/ contabilidade"
                                                    name="accountant_email"
                                                    data_tip="E-mail do escritório de contabilidade da
                                                    empresa"
                                                    size_label="2"
                                                    size_input="4"
                                                />
                                            </div>
                                            <div className="row">
                                                <InputLabel
                                                    label="Responsável"
                                                    name="responsible_name"
                                                    data_tip="Nome do responsável"
                                                    size_label="2"
                                                    size_input="7"
                                                />

                                                <InputLabelCPF
                                                    label="CPF"
                                                    name="responsible_cpf"
                                                    data_tip={
                                                        helperTooltips.cpf
                                                    }
                                                    size_label="1"
                                                    size_input="2"
                                                />
                                            </div>
                                        </div>

                                        <div // Certificado Digital
                                            className="tab-pane"
                                            id="certificate"
                                        >
                                            <div className="row mb-1">
                                                <InputLabel
                                                    label="Apelido do Certificado"
                                                    name="certificate_name"
                                                    data_tip="Nome para se referir ao certificado"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabel
                                                    label="E-mail p/ Comunicados"
                                                    data_tip={
                                                        helperTooltips.email
                                                    }
                                                    name="certificate_email"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            {editCertificate ? (
                                                <>
                                                    <div className="row mb-1">
                                                        <InputLabelFile
                                                            label="Certificado"
                                                            data_tip="Certificado digital."
                                                            name="file_certificate"
                                                            name_validation="certificate_validation"
                                                            size_label="2"
                                                            size_input="10"
                                                            accept=".cer, .pfx"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    event.target
                                                                        .files &&
                                                                    event.target
                                                                        .files[0]
                                                                ) {
                                                                    const reader = new FileReader();
                                                                    reader.readAsDataURL(
                                                                        event
                                                                            .target
                                                                            .files[0]
                                                                    );
                                                                    reader.onload = () => {
                                                                        setCertificateFile(
                                                                            reader.result as string
                                                                        );
                                                                        setValue(
                                                                            'certificate_validation',
                                                                            'file'
                                                                        );
                                                                    };
                                                                } else {
                                                                    setValue(
                                                                        'certificate_validation',
                                                                        null
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            label="Senha do Certificado"
                                                            data_tip="Senha do certificado digital."
                                                            name="certificate_password"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="row mb-1">
                                                    <Label
                                                        respAlt
                                                        title="Validade do certificado cadastrado"
                                                        col="col-sm-auto"
                                                        to=""
                                                    />
                                                    <span className="col-form-label">
                                                        {formatDate(
                                                            data.certificate_validity
                                                        )}
                                                    </span>
                                                    <div className="col-sm-auto ml-5">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default btn-block btn-sm"
                                                            disabled={
                                                                getValues(
                                                                    'form_action'
                                                                ) === 'view'
                                                            }
                                                            onClick={() => {
                                                                setEditCertificate(
                                                                    true
                                                                );
                                                                setValue(
                                                                    'certificate_password',
                                                                    ''
                                                                );
                                                            }}
                                                        >
                                                            <i className="fa fa-edit mr-1 c-success" />
                                                            Alterar Certificado
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {/* LOGO */}
                                            <div className="tab-pane" id="logo">
                                                <div className="col-sm-12">
                                                    <div className="card card-primary card-outline card-outline-tabs mb-0 mt-3">
                                                        <div className="card-header p-0 border-bottom-0">
                                                            <ul
                                                                className="nav nav-tabs"
                                                                id="custom-tabs-for-tab"
                                                                role="tablist"
                                                            >
                                                                <li className="nav-item">
                                                                    <a
                                                                        className="nav-link text-dark disabled"
                                                                        data-toggle="pill"
                                                                        href="#logo_input"
                                                                        role="tab"
                                                                        aria-selected="true"
                                                                    >
                                                                        <strong>
                                                                            Logotipo
                                                                            para
                                                                            impressos
                                                                        </strong>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="card-body">
                                                            <div className="tab-content">
                                                                <div
                                                                    className="tab-pane fade show active"
                                                                    id="logo_input"
                                                                    role="tabpanel"
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-sm-auto">
                                                                            <img
                                                                                className="profile__user--img"
                                                                                src={
                                                                                    (getValues(
                                                                                        'logo'
                                                                                    ) as string) ||
                                                                                    logoFile ||
                                                                                    noImage
                                                                                }
                                                                                alt="Logo para impressos"
                                                                            />
                                                                        </div>
                                                                        {getValues(
                                                                            'form_action'
                                                                        ) !==
                                                                            'view' && (
                                                                            <InputLabelFile
                                                                                label="Alterar"
                                                                                data_tip="Alterar logotipo."
                                                                                name="certificate"
                                                                                size_label="1"
                                                                                size_input="1"
                                                                                accept=".jpg, .jpeg, .png"
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    if (
                                                                                        event
                                                                                            .target
                                                                                            .files &&
                                                                                        event
                                                                                            .target
                                                                                            .files[0]
                                                                                    ) {
                                                                                        setValue(
                                                                                            'logo',
                                                                                            undefined
                                                                                        );
                                                                                        const reader = new FileReader();
                                                                                        reader.readAsDataURL(
                                                                                            event
                                                                                                .target
                                                                                                .files[0]
                                                                                        );
                                                                                        reader.onload = () => {
                                                                                            setLogoFile(
                                                                                                reader.result as string
                                                                                            );
                                                                                        };
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div // CTE-e
                                            className="tab-pane"
                                            id="cte-tab"
                                        >
                                            <div className="row mb-1">
                                                <SelectInputLabel
                                                    required
                                                    label="Ambiente"
                                                    data_tip="Selecione o ambiente."
                                                    name="cte_environment"
                                                    size_label="2"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Produção'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Homologação'
                                                        }
                                                    ]}
                                                />

                                                <SelectInputLabel
                                                    required
                                                    label="Emissão"
                                                    data_tip="Selecione o tipo da emissão."
                                                    name="cte_emission"
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Normal'
                                                        },
                                                        {
                                                            value: 5,
                                                            label:
                                                                'Contingência FSDA'
                                                        }
                                                    ]}
                                                />

                                                <InputLabelMeasurement
                                                    label="Alíq. Transparência Fiscal"
                                                    name="tax_transparency_rate"
                                                    data_tip="Alíquota de transparência fisca"
                                                    size_label="2"
                                                    size_input="2"
                                                    precision={2}
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Último CT-e"
                                                    data_tip="Último número fiscal utilizado"
                                                    name="cte_number"
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="Próximo CT-e SVC-SP"
                                                    name="cte_last_cte_svcsp"
                                                    data_tip="Próximo CT-e da SEFAZ Virtual
                                                    de Contingência – São Paulo"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Série"
                                                    name="cte_serie"
                                                    data_tip="Série fiscal dos documentos
                                                    "
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelTextArea
                                                    label="Observação"
                                                    name="cte_obs"
                                                    size_label="2"
                                                    size_input="10"
                                                    rows={5}
                                                />
                                            </div>
                                        </div>

                                        <div // MDF-e
                                            className="tab-pane"
                                            id="mdfe-tab"
                                        >
                                            <div className="row mb-1">
                                                <SelectInputLabel
                                                    required
                                                    label="Ambiente"
                                                    name="mdfe_environment"
                                                    data_tip="Ambiente de emissão dos
                                                    documentos"
                                                    size_label="2"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Produção'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Homologação'
                                                        }
                                                    ]}
                                                />
                                                <SelectInputLabel
                                                    required
                                                    label="Emissão"
                                                    name="mdfe_emission"
                                                    data_tip="Tipo de emissão"
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Normal'
                                                        },
                                                        {
                                                            value: 2,
                                                            label:
                                                                'Contingência FSDA'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Último MDF-e"
                                                    name="mdfe_number"
                                                    data_tip="Último número fiscal utilizado"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Série"
                                                    name="mdfe_serie"
                                                    data_tip="Série fiscal dos documentos"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelTextArea
                                                    label="Observação"
                                                    name="mdfe_obs"
                                                    data_tip="Informações adicionais"
                                                    size_label="2"
                                                    size_input="10"
                                                    rows={5}
                                                />
                                            </div>
                                        </div>

                                        {/* <div // CT-e OS // SERÁ ATIVADO QUANDO IMPLEMENTAR O CTE OS
                                            className="tab-pane"
                                            id="cteos"
                                        >
                                            <div className="row mb-1">
                                                <SelectInputLabel
                                                    required
                                                    label="Ambiente"
                                                    name="cte_os_environment"
                                                    size_label="2"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Produção'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Homologação'
                                                        }
                                                    ]}
                                                />

                                                <SelectInputLabel
                                                    required
                                                    label="Emissão"
                                                    name="cte_os_emission"
                                                    size_label="1"
                                                    size_input="3"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Normal'
                                                        },
                                                        {
                                                            value: 2,
                                                            label:
                                                                'Contingência FSDA'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Último CT-e OS"
                                                    name="cte_os_number"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Série"
                                                    name="cte_os_serie"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelTextArea
                                                    label="Observação"
                                                    name="cte_os_obs"
                                                    size_label="2"
                                                    size_input="10"
                                                    rows={5}
                                                />
                                            </div>
                                        </div> */}

                                        <div // Seguro
                                            className="tab-pane"
                                            id="environment-insurance"
                                        >
                                            <div className="row mb-1">
                                                <div className="col-sm-12 text-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-default btn-sm"
                                                        disabled={
                                                            getValues(
                                                                'form_action'
                                                            ) === 'view'
                                                        }
                                                        onClick={() =>
                                                            setInsuranceModalVisible(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-plus mr-1 c-success" />
                                                        Incluir
                                                    </button>
                                                </div>
                                            </div>
                                            <Table
                                                id="mdfe"
                                                columns={[
                                                    {
                                                        text: 'Responsável',
                                                        width: '15%',
                                                        className: 'text-max-sm'
                                                    },
                                                    {
                                                        text: 'Seguradora',
                                                        width: '55% ',
                                                        className: 'text-max-sm'
                                                    },
                                                    {
                                                        text: 'Apólice',
                                                        width: '15% ',
                                                        className: 'text-max-sm'
                                                    },
                                                    {
                                                        text: 'Averbação',
                                                        width: '15% ',
                                                        className: 'text-max-sm'
                                                    },
                                                    {
                                                        text: 'Remover',
                                                        className: 'text-center'
                                                    }
                                                ]}
                                            >
                                                {getValues('insurance')?.map(
                                                    (item, index) => (
                                                        <tr
                                                            key={`${item.insurance_id}-${item.policy}-${item.endorsement}`}
                                                        >
                                                            <td className="pt-2">
                                                                {item.insurance_responsible ===
                                                                1
                                                                    ? 'Emitente do CT-e'
                                                                    : 'Contratante'}
                                                            </td>
                                                            <td className="pt-2">
                                                                {
                                                                    insurances.find(
                                                                        (
                                                                            insurance
                                                                        ) =>
                                                                            insurance.id ===
                                                                            item.insurance_id
                                                                    )
                                                                        ?.corporate_name
                                                                }
                                                            </td>
                                                            <td className="pt-2">
                                                                {item.policy}
                                                            </td>
                                                            <td className="pt-2">
                                                                {
                                                                    item.endorsement
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs"
                                                                    disabled={
                                                                        getValues(
                                                                            'form_action'
                                                                        ) ===
                                                                        'view'
                                                                    }
                                                                    onClick={() => {
                                                                        reset({
                                                                            ...getValues(),
                                                                            insurance: getValues(
                                                                                'insurance'
                                                                            )?.filter(
                                                                                (
                                                                                    insuranceItem,
                                                                                    insuranceIndex
                                                                                ) =>
                                                                                    index !==
                                                                                    insuranceIndex
                                                                            )
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </Table>
                                            {/* <div className="row mb-1">
                                                <SelectInputLabel
                                                    required
                                                    label="Responsável"
                                                    name="insurance_responsible"
                                                    data_tip="Responsável pelo seguro"
                                                    size_label="2"
                                                    size_input="6"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label:
                                                                'Emitente do MDF-e'
                                                        },
                                                        {
                                                            value: 2,
                                                            label: 'Contratante'
                                                        }
                                                    ]}
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <SelectInputLabel
                                                    label="Seguradora"
                                                    name="insurance_id"
                                                    data_tip="Nome da Seguradora"
                                                    size_label="2"
                                                    size_input="6"
                                                    options={insurances.map(
                                                        (
                                                            insurance: InsuranceProps
                                                        ) => ({
                                                            value: insurance.id,
                                                            label:
                                                                insurance.corporate_name
                                                        })
                                                    )}
                                                    onChange={(option: any) => {
                                                        if (option) {
                                                            setValue(
                                                                'policy',
                                                                insurances.find(
                                                                    (
                                                                        insurance
                                                                    ) =>
                                                                        insurance.id ===
                                                                        option.value
                                                                )?.policy || ''
                                                            );
                                                            setValue(
                                                                'endorsement',
                                                                insurances.find(
                                                                    (
                                                                        insurance
                                                                    ) =>
                                                                        insurance.id ===
                                                                        option.value
                                                                )
                                                                    ?.endorsement ||
                                                                    ''
                                                            );
                                                        }
                                                    }}
                                                    isClearable
                                                />
                                                <EditIncludeButtons
                                                    setLoading={setLoading}
                                                    setModalData={
                                                        setInsuranceModalData
                                                    }
                                                    setModalVisible={
                                                        setInsuranceModalVisible
                                                    }
                                                    defaultValues={
                                                        insuranceDefaultValues
                                                    }
                                                    route="insurances"
                                                    field="insurance_id"
                                                    tip="Seguradora"
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Apólice"
                                                    name="policy"
                                                    data_tip="Número/Código da Apólice"
                                                    size_label="2"
                                                    size_input="6"
                                                    digits={20}
                                                />
                                            </div>
                                            <div className="row mb-1">
                                                <InputLabelOnlyNumbers
                                                    label="Averbação"
                                                    name="endorsement"
                                                    data_tip="Número/Código da Averbação"
                                                    size_label="2"
                                                    size_input="6"
                                                    digits={20}
                                                />
                                            </div> */}
                                        </div>

                                        <div // Email
                                            className="tab-pane"
                                            id="email"
                                        >
                                            {/* <div className="row mb-3">
                                                <SelectInputLabel
                                                    required
                                                    label="Envio de e-mail automático ao final da autorização"
                                                    name="auto_email"
                                                    data_tip="Envia automaticamente um email."
                                                    size_label="5"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: 1,
                                                            label: 'Habilitado'
                                                        },
                                                        {
                                                            value: 0,
                                                            label:
                                                                'Desabilitado'
                                                        }
                                                    ]}
                                                />
                                            </div> */}
                                            <div className="row mb-3">
                                                <SelectInputLabel
                                                    required
                                                    label="Configuração de e-mail personalizada"
                                                    name="email_config"
                                                    data_tip="Habilita uma configuração de e-mail
                                                    personalizada."
                                                    size_label="5"
                                                    size_input="2"
                                                    options={[
                                                        {
                                                            value: true,
                                                            label: 'Habilitado'
                                                        },
                                                        {
                                                            value: false,
                                                            label:
                                                                'Desabilitado'
                                                        }
                                                    ]}
                                                    onChange={(op: any) => {
                                                        if (
                                                            op.value === false
                                                        ) {
                                                            setValue(
                                                                'smtp_server',
                                                                ''
                                                            );
                                                            setValue(
                                                                'sender_email',
                                                                ''
                                                            );
                                                            setValue(
                                                                'message_title',
                                                                ''
                                                            );
                                                            setValue(
                                                                'content_message',
                                                                ''
                                                            );
                                                            setValue(
                                                                'cancel_message_title',
                                                                ''
                                                            );
                                                            setValue(
                                                                'cancel_content_message',
                                                                ''
                                                            );
                                                            setValue(
                                                                'smtp_user',
                                                                ''
                                                            );
                                                            setValue(
                                                                'password_smtp',
                                                                ''
                                                            );
                                                            setValue(
                                                                'smtp_port',
                                                                ''
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {getValues('email_config') ===
                                                true && (
                                                <>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Servidor SMTP"
                                                            name="smtp_server"
                                                            data_tip="Endereço do servidor SMTP"
                                                            size_label="2"
                                                            size_input="4"
                                                        />

                                                        <InputLabelOnlyNumbers
                                                            required
                                                            label="Porta SMTP"
                                                            name="smtp_port"
                                                            data_tip="Porta do servidor SMTP"
                                                            size_label="2"
                                                            size_input="4"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Usuário SMTP"
                                                            name="smtp_user"
                                                            data_tip="Usuário do servidor SMTP"
                                                            size_label="2"
                                                            size_input="4"
                                                        />

                                                        <InputLabel
                                                            required
                                                            label="Senha SMTP"
                                                            name="password_smtp"
                                                            data_tip="Senha do servidor SMTP"
                                                            size_label="2"
                                                            size_input="4"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="E-mail remetente"
                                                            name="sender_email"
                                                            data_tip="Endereço de e-mail do remetente"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Título da mensagem"
                                                            name="message_title"
                                                            data_tip="Título/Assunto do e-mail"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Corpo da mensagem"
                                                            name="content_message"
                                                            data_tip="Texto do corpo do e-mail"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Título msg. cancel."
                                                            name="cancel_message_title"
                                                            data_tip="Título do e-mail em mensagens de cancelamento"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                    <div className="row mb-1">
                                                        <InputLabel
                                                            required
                                                            label="Corpo msg. cancel."
                                                            name="cancel_content_message"
                                                            data_tip="Corpo do e-mail em mensagens de cancelamento"
                                                            size_label="2"
                                                            size_input="10"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {user?.tenant?.module_endorsement && (
                                            <div // Averbação
                                                className="tab-pane"
                                                id="endorsement"
                                            >
                                                <div className="row mb-1">
                                                    <SelectInputLabel
                                                        label="API de averbação"
                                                        name="endorsement_api"
                                                        data_tip="Selecione qual serviço de averbação será utilizado"
                                                        size_label="2"
                                                        size_input="3"
                                                        options={[
                                                            {
                                                                label: 'AT&M',
                                                                value: 'atm'
                                                            },
                                                            {
                                                                label:
                                                                    'Porto Seguros',
                                                                value:
                                                                    'porto_seguros'
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="row mb-1">
                                                    <InputLabel
                                                        label="Usuário"
                                                        name="endorsement_api_user"
                                                        data_tip="Usuário do portal da averbação"
                                                        size_label="2"
                                                        size_input="3"
                                                    />
                                                </div>
                                                <div className="row mb-1">
                                                    <InputLabel
                                                        type="password"
                                                        label="Senha"
                                                        name="endorsement_api_password"
                                                        data_tip="Senha do portal da averbação"
                                                        size_label="2"
                                                        size_input="3"
                                                    />
                                                </div>
                                                <div className="row mb-1">
                                                    <InputLabel
                                                        label="Código"
                                                        name="endorsement_api_cod"
                                                        data_tip="Código do portal da averbação"
                                                        size_label="2"
                                                        size_input="3"
                                                    />
                                                </div>
                                                <div className="row mb-1">
                                                    <SelectInputLabel
                                                        label="Averbação"
                                                        name="auto_endorsement"
                                                        data_tip="Define se a averbação dos documentos será automática ou manual"
                                                        size_label="2"
                                                        size_input="3"
                                                        options={[
                                                            {
                                                                label:
                                                                    'Averbação manual',
                                                                value: false
                                                            },
                                                            {
                                                                label:
                                                                    'Averbação automática',
                                                                value: true
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {loading && <FullPageLoader />}
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                {data.form_action !== 'view' ? (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />

                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                ) : (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={hide}
                                        />
                                    </div>
                                )}
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>
            {insuranceModalVisible && (
                <InsuranceModal
                    hide={() => setInsuranceModalVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                    insurances={insurances}
                    setInsuranceRefresh={() =>
                        setInsuranceRefresh(Math.random())
                    }
                />
            )}
        </>
    );
};

export default EnvironmentModal;
