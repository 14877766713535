import React from 'react';

interface LastEmissionProps {
    title: string;
    click: () => void;
}

const LastEmission: React.FC<LastEmissionProps> = ({title, click}) => {
    return (
        <div className="text-center">
            <h6 className="text-center text-dark">{title}</h6>
            <a className="btn btn-success mr-2" onClick={click}>
                Sim
                <i className="fas fa-level-down-alt pl-2" />
            </a>
        </div>
    );
};

export default LastEmission;
