import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import {TextInputMask} from 'tp-react-web-masked-text';
import {CityProps} from '../../interfaces';
import searchCEP from '../../services/searchCEP';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    cities: CityProps[];
    city_field: string;
    data_tip?: string;
    data_tipLabel?: string;
    type?: 'company' | 'responsible';
    setLoading: (loadind: boolean) => void;
}

const InputLabelCEP: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    cities,
    city_field,
    type,
    setLoading,
    data_tip,
    data_tipLabel,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues,
        setValue
    } = useFormContext();

    return (
        <>
            <Label
                required={required}
                respAlt
                title={label}
                data_tip={data_tipLabel}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div className={`col-sm-${size_input} resp-form`}>
                <div className="input-group input-group-sm">
                    <Controller
                        name={`${name}`}
                        control={control}
                        render={({field}) => (
                            <TextInputMask
                                className="form-control form-control-sm"
                                disabled={getValues('form_action') === 'view'}
                                {...otherProps}
                                {...field}
                                kind="zip-code"
                            />
                        )}
                    />
                    <span className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-default btn-flat"
                            data-tip={data_tip}
                            data-for="help__tip--cep"
                            disabled={getValues('form_action') === 'view'}
                            onClick={() =>
                                searchCEP(
                                    getValues(name),
                                    setValue,
                                    setLoading,
                                    cities,
                                    city_field,
                                    type
                                )
                            }
                        >
                            <i className="fas fa-search" />
                        </button>
                    </span>
                </div>
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
            <ReactTooltip
                id="help__tip--cep"
                place="top"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
            />
        </>
    );
};

export default InputLabelCEP;
