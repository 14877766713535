/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Button from '../../../../components/Button';
import {useAuth} from '../../../../hooks/Auth';
import noUser from '../../../../assets/empty-user.jpg';
import FullPageLoader from '../../../../components/FullPageLoader';
import support from '../../../../services/support';

interface UserMenuProps {
    className?: string;
}

const UserMenu: React.FC<UserMenuProps> = ({className}) => {
    const {signOut, user, updateUser} = useAuth();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <li className="nav-item dropdown user-menu">
                <a
                    href="#"
                    className={`nav-link dropdown-toggle ${className}`}
                    data-toggle="dropdown"
                    aria-expanded="true"
                >
                    <i
                        className="fas fa-user"
                        data-tip="Perfil"
                        data-for="info-dark"
                    />
                </a>
                <ul
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                    style={{left: 'inherit', right: 0}}
                >
                    <li className="user-header">
                        <img
                            src={
                                user.tenant.avatar_url
                                    ? `${process.env.REACT_APP_API_URL}/images/${user.tenant.avatar_url}`
                                    : noUser
                            }
                            className="img-circle elevation-2"
                            alt="User"
                        />
                        <p className="username" style={{fontSize: 15}}>
                            {user.admin && (
                                <span className="badge badge-success navbar-badge">
                                    Admin
                                </span>
                            )}
                            {user.name}
                            <small className="ini-username">{user.email}</small>
                        </p>
                    </li>
                    <li className="user-footer bg-color-gray">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Link to="/profile">
                                <Button
                                    type="button"
                                    color="default"
                                    caption="Perfil"
                                    icon="fa-user-circle"
                                />
                            </Link>

                            <Button
                                type="button"
                                color={`${
                                    user.support ? 'primary' : 'default'
                                }`}
                                caption="Suporte"
                                icon="fa-life-ring"
                                onClick={() =>
                                    support(user, setLoading, updateUser)
                                }
                            />

                            <Button
                                type="button"
                                color="danger"
                                caption="Sair"
                                icon="fa-sign-out-alt"
                                onClick={() => {
                                    setLoading(true);
                                    signOut();
                                    setLoading(false);
                                }}
                            />
                        </div>
                    </li>
                </ul>
            </li>

            {loading && <FullPageLoader />}
        </>
    );
};

export default UserMenu;
