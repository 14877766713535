import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import AppProvider from './hooks';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from './globalStyles';

const App: React.FC = () => {
    return (
        <Router>
            <AppProvider>
                <Routes />
            </AppProvider>
            <GlobalStyles />
            <ToastContainer
                autoClose={7000}
                draggable={false}
                position="top-right"
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick={true}
                rtl={false}
                pauseOnHover={true}
                pauseOnFocusLoss={false}
            />
        </Router>
    );
};
export default App;
