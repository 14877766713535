import React from 'react';
import DropdownItem from './DropdownItem';

import './styles.scss';

interface ActionButtonProps {
    view?: (e: React.MouseEvent<HTMLElement>) => void;
    clone?: (e: React.MouseEvent<HTMLElement>) => void;
    consult?: (e: React.MouseEvent<HTMLElement>) => void;
    cancel?: (e: React.MouseEvent<HTMLElement>) => void;
    disable?: (e: React.MouseEvent<HTMLElement>) => void;
    shutdown?: (e: React.MouseEvent<HTMLElement>) => void;
    edit?: (e: React.MouseEvent<HTMLElement>) => void;
    delete?: (e: React.MouseEvent<HTMLElement>) => void;
    email?: (e: React.MouseEvent<HTMLElement>) => void;
    events?: (e: React.MouseEvent<HTMLElement>) => void;
    correction?: (e: React.MouseEvent<HTMLElement>) => void;
    correctionsReport?: (e: React.MouseEvent<HTMLElement>) => void;
    xml?: (e: React.MouseEvent<HTMLElement>) => void;
    json?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_confirm?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_know?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_unknow?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_not_done?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_disagreement?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_print?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_show_events?: (e: React.MouseEvent<HTMLElement>) => void;
    dfe_xml?: (e: React.MouseEvent<HTMLElement>) => void;
    taxation?: (e: React.MouseEvent<HTMLElement>) => void;
    endorsement?: (e: React.MouseEvent<HTMLElement>) => void;
}

const AppButton: React.FC<ActionButtonProps> = (props) => {
    return (
        <div className="btn-group">
            <button
                type="button"
                className="btn btn-default btn-sm btn-flat"
                data-toggle="dropdown"
                aria-expanded="true"
            >
                <i className="fa fa-list-ul" />
            </button>

            <div className="dropdown-menu dropdown-menu-right">
                {props.view && (
                    <DropdownItem
                        action={props.view}
                        icon="fa fa-eye"
                        text="Visualizar"
                    />
                )}
                {props.clone && (
                    <DropdownItem
                        action={props.clone}
                        icon="fas fa-clone"
                        text="Clonar"
                    />
                )}
                {props.edit && (
                    <DropdownItem
                        action={props.edit}
                        icon="fa fa-edit"
                        text="Editar"
                    />
                )}
                {props.delete && (
                    <DropdownItem
                        action={props.delete}
                        icon="fa fa-trash"
                        text="Excluir"
                    />
                )}
                {props.taxation && (
                    <DropdownItem
                        action={props.taxation}
                        icon="fa fa-hand-holding-usd"
                        text="Tributação"
                    />
                )}
                {props.consult && (
                    <>
                        <hr className="my-2" />
                        <DropdownItem
                            action={props.consult}
                            icon="fa fa-search"
                            text="Consultar"
                        />
                    </>
                )}
                {props.email && (
                    <DropdownItem
                        action={props.email}
                        icon="fa fa-envelope"
                        text="Enviar p/ e-mail"
                    />
                )}
                {props.cancel && (
                    <DropdownItem
                        action={props.cancel}
                        icon="fas fa-ban"
                        text="Cancelar"
                    />
                )}
                {props.disable && (
                    <DropdownItem
                        action={props.disable}
                        icon="fas fa-times"
                        text="Inutilizar"
                    />
                )}
                {props.shutdown && (
                    <DropdownItem
                        action={props.shutdown}
                        icon="fas fa-check"
                        text="Encerrar"
                    />
                )}
                {props.events && (
                    <DropdownItem
                        action={props.events}
                        icon="fas fa-question-circle"
                        text="Eventos"
                    />
                )}
                {props.correction && (
                    <DropdownItem
                        action={props.correction}
                        icon="fas fa-envelope-open-text"
                        text="Carta de correção"
                    />
                )}
                {props.correctionsReport && (
                    <DropdownItem
                        action={props.correctionsReport}
                        icon="fas fa-envelope-open-text"
                        text="Cartas emitidas"
                    />
                )}
                {props.endorsement && (
                    <DropdownItem
                        action={props.endorsement}
                        icon="fas fa-file-contract"
                        text="Averbação"
                    />
                )}
                {props.xml && (
                    <>
                        <hr className="my-2" />
                        <DropdownItem
                            action={props.xml}
                            icon="fas fa-copy"
                            text="Download XML"
                        />
                    </>
                )}
                {props.json && (
                    <DropdownItem
                        action={props.json}
                        icon="fas fa-copy"
                        text="Download JSON"
                    />
                )}
                {props.dfe_know && (
                    <DropdownItem
                        action={props.dfe_know}
                        icon="far fa-eye"
                        text="Ciência"
                    />
                )}
                {props.dfe_confirm && (
                    <DropdownItem
                        action={props.dfe_confirm}
                        icon="far fa-check-circle"
                        text="Confirmar"
                    />
                )}
                {props.dfe_unknow && (
                    <DropdownItem
                        action={props.dfe_unknow}
                        icon="fas fa-question"
                        text="Desconhecer"
                    />
                )}
                {props.dfe_not_done && (
                    <DropdownItem
                        action={props.dfe_not_done}
                        icon="fas fa-ban"
                        text="Não realizada"
                    />
                )}
                {props.dfe_disagreement && (
                    <DropdownItem
                        action={props.dfe_disagreement}
                        icon="fas fa-thumbs-down"
                        text="Desacordo"
                    />
                )}
                {props.dfe_print && (
                    <DropdownItem
                        action={props.dfe_print}
                        icon="fas fa-print"
                        text="Imprimir"
                    />
                )}
                {props.dfe_show_events && (
                    <DropdownItem
                        action={props.dfe_show_events}
                        icon="fas fa-search"
                        text="Visualizar eventos"
                    />
                )}
                {props.dfe_xml && (
                    <DropdownItem
                        action={props.dfe_xml}
                        icon="fas fa-download"
                        text="Baixar XML"
                    />
                )}
            </div>
        </div>
    );
};
export default AppButton;
