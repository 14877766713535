/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Tour from 'reactour';
import ReactTooltip from 'react-tooltip';
import GroupModal from './Modal';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import columns from './schema';
import {GroupProps, TableOrder} from '../../interfaces';
import {tourStepsGroup} from '../../data/tour';
import {useAuth} from '../../hooks/Auth';
import SaveOrderButton from '../../components/SaveOrderButton';
import importLegacyJSON from '../../services/importLegacyJSON';

const PeopleGroups: React.FC = () => {
    const {user, updateUser, tablesOrder} = useAuth();
    const tableOrder = tablesOrder.find(
        (item) => item.table === 'peopleGroups'
    );
    const [isTourOpen, setIsTourOpen] = useState(user.tour_tables || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };
    const [order, setOrder] = useState<TableOrder>({
        order: tableOrder?.field || 'created_at',
        type: tableOrder?.type || 'desc'
    });
    const [loading, setLoading] = useState(false);
    const [peopleGroups, setPeopleGroups] = useState<GroupProps[]>([]);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<GroupProps>({} as GroupProps);

    function resetData() {
        setModalData({
            description: '',
            form_action: 'include'
        });
    }

    async function getPeopleGroups() {
        try {
            setLoading(true);
            const response = await api.get(
                `peopleGroups?page=${page}&limit=20&order=${order.order}&type=${order.type}&search=${search}`
            );
            setPeopleGroups(response.data.data);
            setCount(response.data.meta.total);
            setPerPage(response.data.meta.per_page);
            setCurrentPage(response.data.meta.current_page);
            setLoading(false);
        } catch (err) {
            setPeopleGroups([]);
            toast.error('Não foi possível efetuar a consulta');
            setLoading(false);
        }
    }

    function handleDelete(id: string, description: string) {
        Swal.fire({
            title: 'Confirmar exclusão do registro?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    await api.delete(
                        `peopleGroups/${id}?description=${description}`
                    );
                    setRefresh(Math.random());
                    toast.success('Registro excluído com sucesso');
                } catch (error: any) {
                    toast.error(
                        error.response?.data ||
                            'Ocorreu um erro ao excluir o registro'
                    );
                    setLoading(false);
                }
            }
        });
    }

    useEffect(() => {
        getPeopleGroups();
    }, [page, refresh, order]);

    const mapTable = useMemo(
        () =>
            peopleGroups.map((data) => (
                <tr key={data.id} className="even">
                    <td className="pt-2 dtr-control sorting_1" tabIndex={0}>
                        {data.description}
                    </td>
                    <td className="pl-4">
                        <ActionButton
                            view={() => {
                                setModalData({
                                    ...data,
                                    form_action: 'view'
                                });
                                setModalVisible(true);
                            }}
                            edit={() => {
                                setModalData({
                                    ...data,
                                    form_action: 'edit'
                                });
                                setModalVisible(true);
                            }}
                            delete={() => {
                                handleDelete(data.id || '', data.description);
                            }}
                        />
                    </td>
                </tr>
            )),
        [peopleGroups]
    );

    return (
        <>
            {/* TITLE PAGE ( DESKTOP ) */}
            <div className="content-header desktop">
                <div className="container-fluid pl-1 pr-2 d-flex">
                    <TitlePage
                        title="Grupos"
                        icon="fa-user-friends"
                        tabRight={true}
                        pagination={true}
                        count={count}
                        currentPage={currentPage}
                        perPage={perPage}
                        customCol="4"
                    />
                    <div className="tab-min-top col-3 text-right mt-1">
                        <SaveOrderButton
                            id={tableOrder?.id as string}
                            table="peopleGroups"
                            field={order.order}
                            type={order.type}
                            setOrder={setOrder}
                        />

                        <Button
                            onClick={() => {
                                resetData();
                                setModalVisible(true);
                            }}
                            className="tour-group--01"
                            type="button"
                            color="success"
                            caption="Incluir"
                            icon="fa-plus"
                            maxSize={true}
                        />

                        <label
                            htmlFor="json_input"
                            id="btn-import"
                            className="cursor-pointer btn btn-default"
                            data-tip="Importar cadastros via JSON"
                            data-for="groups-main-page-tooltip"
                            style={{marginLeft: 6}}
                        >
                            <i className="fas fa-download" />
                        </label>

                        <input
                            id="json_input"
                            type="file"
                            accept=".json"
                            style={{display: 'none'}}
                            onChange={(event) => {
                                const reader = new FileReader();
                                reader.onload = async function (e) {
                                    const json = e.target?.result as string;
                                    await importLegacyJSON(
                                        json,
                                        'legacyGroups',
                                        setLoading,
                                        () => setRefresh(Math.random())
                                    );
                                };
                                reader.readAsText(
                                    event.target.files
                                        ? (event.target.files[0] as Blob)
                                        : ('' as any),
                                    'ISO-8859-1'
                                );
                                event.target.value = '';
                            }}
                        />
                    </div>

                    <div className="col-sm-5 col-xs-12 tab-min-top">
                        <div className="input-group tab-max-left tour-group--02">
                            <input
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        if (page === 1) {
                                            setRefresh(Math.random());
                                        } else {
                                            setPage(1);
                                        }
                                    }
                                }}
                                type="text"
                                placeholder="Buscar por descrição"
                                className="form-control"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="input-group-append">
                                <Button
                                    type="button"
                                    color="default"
                                    onClick={() => {
                                        if (page === 1) {
                                            setRefresh(Math.random());
                                        } else {
                                            setPage(1);
                                        }
                                    }}
                                    icon="fa-search"
                                    notTab
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* TITLE PAGE ( MOBILE ) */}
            <div className="content-header mobile">
                <div className="container-fluid pl-1 pr-2">
                    <TitlePage
                        title="Grupos"
                        icon="fa-user-friends"
                        tabRight={true}
                        pagination={true}
                        count={count}
                        currentPage={currentPage}
                        perPage={perPage}
                        mobile={true}
                    />
                    <div className="d-flex">
                        <div className="col-sm-3 my-2 pt-min">
                            <SaveOrderButton
                                id={tableOrder?.id as string}
                                table="peopleGroups"
                                field={order.order}
                                type={order.type}
                                setOrder={setOrder}
                            />
                            <Button
                                onClick={() => {
                                    resetData();
                                    setModalVisible(true);
                                }}
                                type="button"
                                color="success"
                                caption="Incluir"
                                icon="fa-plus"
                                maxSize={true}
                            />
                            <label
                                htmlFor="json_input"
                                id="btn-import"
                                className="cursor-pointer btn btn-default"
                                data-tip="Importar cadastros via JSON"
                                data-for="groups-main-page-tooltip"
                                style={{marginLeft: 6}}
                            >
                                <i className="fas fa-download" />
                            </label>

                            <input
                                id="json_input"
                                type="file"
                                accept=".json"
                                style={{display: 'none'}}
                                onChange={(event) => {
                                    const reader = new FileReader();
                                    reader.onload = async function (e) {
                                        const json = e.target?.result as string;
                                        await importLegacyJSON(
                                            json,
                                            'legacyGroups',
                                            setLoading,
                                            () => setRefresh(Math.random())
                                        );
                                    };
                                    reader.readAsText(
                                        event.target.files
                                            ? (event.target.files[0] as Blob)
                                            : ('' as any),
                                        'ISO-8859-1'
                                    );
                                    event.target.value = '';
                                }}
                            />
                        </div>
                        <div className="col-sm-9 col-xs-12 my-2">
                            <div className="input-group tab-max-left">
                                <input
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }
                                    }}
                                    type="text"
                                    placeholder="Buscar por descrição"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span className="input-group-append">
                                    <Button
                                        type="button"
                                        color="default"
                                        onClick={() => {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }}
                                        icon="fa-search"
                                        notTab
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <CardBody
                            size="12"
                            smallSize="12"
                            page={page}
                            count={count}
                            setPage={setPage}
                        >
                            <div className="dataTables_wrapper dt-bootstrap4">
                                <Table
                                    id="groups"
                                    columns={columns}
                                    setOrder={setOrder}
                                    order={order.order}
                                    type={order.type}
                                >
                                    {mapTable}
                                </Table>
                            </div>
                        </CardBody>
                    </div>
                </div>
            </div>

            {modalVisible && (
                <GroupModal
                    data={modalData}
                    refresh={() => setRefresh(Math.random())}
                    hide={() => setModalVisible(false)}
                />
            )}

            {loading ? (
                <FullPageLoader />
            ) : (
                <Tour
                    steps={tourStepsGroup}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_tables) {
                            api.post('tourTables');
                            updateUser({
                                ...user,
                                tour_tables: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}

            <ReactTooltip
                id="groups-main-page-tooltip"
                place="left"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
                delayShow={150}
            />
        </>
    );
};

export default PeopleGroups;
