const columns = [
    {
        text: 'Data',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'N° evento',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Protocolo',
        className: 'text-max-sm pl-2'
    },
    {
        text: '',
        className: 'text-max-sm pl-2',
        width: '1%'
    }
];

export default columns;
