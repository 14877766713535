const columns = [
    {
        text: 'Exclusão',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Razão Social',
        width: '30%',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Nome Fantasia',
        width: '30%',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'CPF/CNPJ',
        width: '15%',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Cidade',
        width: '20%',
        className: 'text-max-sm pl-2'
    },
    {
        text: '',
        className: 'text-max-sm pl-2'
    }
];

export default columns;
