function setSituationColor(situation: string) {
    // SET THE COLOR OF THE SITUATION COLUMNS AT CT-e, MDF-e AND DF-e TABLES
    let color = '';
    switch (situation) {
        case 'EM DIGITAÇÃO':
            color = 'light';
            break;
        case 'AUTORIZADO':
        case 'AUTORIZADA':
            color = 'success';
            break;
        case 'CANCELADO':
        case 'CANCELADA':
            color = 'gray';
            break;
        case 'INUTILIZADO':
        case 'INUTILIZADA':
            color = 'purple';
            break;
        case 'AUTORIZADO EM CONTINGÊNCIA':
        case 'AUTORIZADA EM CONTINGÊNCIA':
            color = 'info';
            break;
        case 'REJEITADO':
        case 'REJEITADA':
            color = 'danger';
            break;
        case 'DENEGADO':
        case 'DENEGADA':
            color = 'danger';
            break;
        default:
            color = 'warning';
            break;
    }
    return color;
}

export default setSituationColor;
