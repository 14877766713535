/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import {useCallback, useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import Tour from 'reactour';
import {useForm, FormProvider} from 'react-hook-form';
import {useAuth} from '../../hooks/Auth';
import {
    ColumnsProps,
    CteMdfeEventsProps,
    CTeProps,
    TableOrder
} from '../../interfaces';
import columns from './schema';
import Button from '../../components/Button';
import TitlePage from '../../components/TitlePage';
import CardBody from '../../components/CardBody';
import Table from '../../components/Table';
import ActionButton from '../../components/ActionButton';
import ModalCTe from './ModalForm';
import api from '../../services/api';
import FullPageLoader from '../../components/FullPageLoader';
import setSituationColor from '../../utils/setSituationColor';
import InputLabel from '../../components/InputLabel';
import InputLabelCurrency from '../../components/InputLabelCurrency';
import InputLabelOnlyNumbers from '../../components/InputLabelOnlyNumbers';
import SelectInputLabel from '../../components/SelectInputLabel';
import CorrectionLetterModal from './CorrectionLetterModal';
import {tourStepsCte} from '../../data/tour';
import {cte_situations} from '../../data';
import CteMdfeEventsModal from '../Modals/CteMdfeEventsModal';
import CorrectionLetterReportsModal from './CorrectionLetterModal/reports';
import openPdfFromBase64 from '../../utils/openPdfFromBase64';
import SendBatchModal from './ModalSendBatch';
import setEndorsementColor from '../../utils/setEndorsementColor';

interface SearchProps {
    start_emission: string;
    end_emission: string;
    start_amount: string | number;
    end_amount: string | number;
    number: string;
    cfop: string;
    situation: string;
    start_delivery: string;
    end_delivery: string;
    sender: string;
    recipient: string;
}

const searchDefaultValues = {
    start_emission: '',
    end_emission: '',
    start_amount: '',
    end_amount: '',
    number: '',
    cfop: '',
    situation: '',
    start_delivery: '',
    end_delivery: '',
    sender: '',
    recipient: ''
};

const MonitorCTE: React.FC = () => {
    const {user, updateUser} = useAuth();
    const [isTourOpen, setIsTourOpen] = useState(user.tour_monitors || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };
    const [order, setOrder] = useState<TableOrder>({
        order: 'cod',
        type: 'desc'
    });
    const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [correctionModalVisible, setCorrectionModalVisible] = useState(false);
    const [letterReportsVisible, setLetterReportsVisible] = useState(false);
    const [letterReportsData, setLetterReportsData] = useState<any>();
    const [correctionId, setCorrectionId] = useState('');
    const [eventsModalVisible, setEventsModalVisible] = useState(false);
    const [eventsModalData, setEventsModalData] = useState<
        CteMdfeEventsProps[]
    >([]);
    const [sendBatchModalVisible, setSendBatchModalVisible] = useState(false);
    const [ctes, setCtes] = useState<CTeProps[]>([] as CTeProps[]);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [tableColumns, setTableColumns] = useState<ColumnsProps[]>([]);

    const dueDate = new Date();
    const dateDeliver = new Date();
    if (user.environment?.config_days_due_date) {
        dueDate.setDate(
            dueDate.getDate() + user.environment?.config_days_due_date
        );
    }
    if (user.environment?.config_days_deliver) {
        dateDeliver.setDate(
            dateDeliver.getDate() + user.environment?.config_days_deliver
        );
    }

    // VALORES PADRÃO PARA ABRIR O FORMULÁRIO
    const cteDefaultValues: CTeProps = {
        serie: user.environment?.cte_serie,
        environment_inssuer_id: user.environment?.id || '',
        type: 0,
        taker: user.environment?.config_taker || 0,
        nature: 0,
        date_emission: new Date()
            .toLocaleString()
            .substring(0, 10)
            .split('/')
            .reverse()
            .join('-'),
        cfop_id: user.environment?.config_cfop || '',
        taker_people_id: null,
        sender_people_id: user.environment?.config_sender || '',
        recipient_people_id: '',
        commodity_value: 0,
        predominant_product: user.environment?.config_predominant_product || '',
        city_origin_id: null,
        city_destination_id: null,
        payment_form: user.environment?.config_payment_form || 1,
        payment_type: 1,
        due_date: user.environment?.config_days_due_date
            ? dueDate
                  .toLocaleString()
                  .substring(0, 10)
                  .split('/')
                  .reverse()
                  .join('-')
            : '',
        date_delivery: user.environment?.config_days_deliver
            ? dateDeliver
                  .toLocaleString()
                  .substring(0, 10)
                  .split('/')
                  .reverse()
                  .join('-')
            : '',
        value: 0,
        receivable_value: 0,
        percentage_service: 0,
        cst: '',
        redbc: 0,
        aliq_icms: 0,
        vlr_cred: 0,
        vlr_pis: 0,
        vlr_bc_icms: 0,
        vlr_icms: 0,
        vlr_cofins: 0,
        vehicle_id: null,
        rntrc: '',
        breaking: 1,
        tolerance: 0,
        toll_tariff: 0,
        weight: 0,
        freight_ton: 0,
        freight_rate: 0,
        freight_value: 0,
        toll_value: 0,
        insurance_value: 0,
        discount: 0,
        freight_toll: 0,
        advance: 0,
        balance: 0,
        nfes: [],
        manual_nfs: [],
        others: [],
        previous_documents: [],
        loads: [],
        services: [],
        vehicles: [],
        drivers: [],
        cte_replacement_change_taker: 0,
        cte_replacement_icms_taker: 0,
        obs_general: user.environment?.cte_obs || '',
        auto_endorsement: user.environment?.auto_endorsement || false,
        // READ ONLY
        situation: 'EM DIGITAÇÃO',
        form_action: 'include'
    };

    const [modalData, setModalData] = useState<CTeProps>(cteDefaultValues);

    // GET CT-e's
    useEffect(() => {
        if (user.environment) {
            setLoading(true);

            api.get(
                `ctes?order=${order.order}&type=${order.type}&page=${page}&limit=20&environment_inssuer_id=${user.environment?.id}&cte_environment=${user.environment?.cte_environment}&filter=true&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_amount=${search.start_amount}&end_amount=${search.end_amount}&number=${search.number}&cod=&situation=${search.situation}&cfop=${search.cfop}&start_delivery=${search.start_delivery}&end_delivery=${search.end_delivery}&sender=${search.sender}&recipient=${search.recipient}`
            )
                .then((response) => {
                    setCtes(response.data.data);
                    setCount(response.data.meta.total);
                    setPerPage(response.data.meta.per_page);
                    setCurrentPage(response.data.meta.current_page);
                })
                .catch(() =>
                    toast.error('Não foi possível realizar a consulta')
                )
                .finally(() => setLoading(false));
        }
    }, [page, refresh, user, order]);

    // HABILITAR OU DESABILITAR COLUNA DE AVERBAÇÃO
    useEffect(() => {
        if (user.tenant.module_endorsement) {
            const newColumns = [...columns];
            newColumns.splice(9, 0, {
                text: 'Averb.',
                className: 'text-max-sm px-1 text-center'
            });
            setTableColumns(newColumns);
        } else {
            setTableColumns(columns);
        }
    }, [user]);

    const methods = useForm<SearchProps>({
        defaultValues: searchDefaultValues
    });
    const {handleSubmit, reset} = methods;

    const onSearch = useCallback(
        async (values: SearchProps) => {
            if (values.start_amount === 0) {
                values.start_amount = '';
            }
            if (values.end_amount === 0) {
                values.end_amount = '';
            }

            setSearch(values);

            if (page === 1) {
                setRefresh(Math.random());
            } else {
                setPage(1);
            }

            reset(values);
        },
        [page]
    );

    const resetData = useCallback(() => {
        setModalData(cteDefaultValues);
    }, [user]);

    // ABRIR MODAL DO FORMULÁRIO OU DO ENVIO DE LOTE
    const openModal = useCallback(
        (type: 'form' | 'batch') => {
            if (user.environment) {
                if (type === 'form') {
                    resetData();
                    setModalVisible(true);
                } else {
                    setSendBatchModalVisible(true);
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'É necessário cadastrar um ambiente antes de realizar esta ação.'
                });
            }
        },
        [user.environment]
    );

    // VISUALIZAR, EDITAR OU CLONAR CTE
    const showCTE = useCallback(
        (id: string, form_action: 'include' | 'view' | 'edit') => {
            setLoading(true);
            api.get(`ctes/${id}`)
                .then((response) => {
                    setModalData({
                        ...response.data.cte,
                        key:
                            form_action === 'include'
                                ? ''
                                : response.data.cte.key,
                        number:
                            form_action === 'include'
                                ? ''
                                : response.data.cte.number,
                        serie:
                            form_action === 'include'
                                ? ''
                                : response.data.cte.serie,
                        date_emission:
                            form_action === 'include'
                                ? new Date()
                                      .toLocaleString()
                                      .substring(0, 10)
                                      .split('/')
                                      .reverse()
                                      .join('-')
                                : response.data.cte.date_emission
                                      ?.toLocaleString()
                                      .substring(0, 10)
                                      .split('/')
                                      .reverse()
                                      .join('-'),
                        date_delivery: user.environment?.config_days_deliver
                            ? dateDeliver
                                  .toLocaleString()
                                  .substring(0, 10)
                                  .split('/')
                                  .reverse()
                                  .join('-')
                            : response.data.cte.date_delivery
                                  ?.toLocaleString()
                                  .substring(0, 10)
                                  .split('/')
                                  .reverse()
                                  .join('-') || '',
                        due_date:
                            form_action === 'include'
                                ? user.environment?.config_days_due_date
                                    ? dueDate
                                          .toLocaleString()
                                          .split(' ')[0]
                                          .split('/')
                                          .reverse()
                                          .join('-')
                                    : ''
                                : response.data.cte.due_date
                                      ?.toLocaleString()
                                      .substring(0, 10)
                                      .split('/')
                                      .reverse()
                                      .join('-') || '',
                        cte_annulment_date: response.data.cte.cte_annulment_date
                            ?.toLocaleString()
                            .substring(0, 10)
                            .split('/')
                            .reverse()
                            .join('-'),
                        drivers: response.data.drivers.map(
                            (driver: any) => driver.driver_people_id
                        ),
                        vehicles: response.data.vehicles.map(
                            (vehicle: any) => vehicle.vehicle_id
                        ),
                        loads: response.data.loads,
                        manual_nfs: response.data.manual_nfs,
                        nfes: response.data.nfes,
                        others: response.data.others,
                        previous_documents: response.data.previous_documents,
                        services: response.data.services,
                        form_action
                    });
                    setModalVisible(true);
                })
                .catch(() => toast.error('Não foi possível efetuar a consulta'))
                .finally(() => setLoading(false));
        },
        [user]
    );

    // EXCLUIR
    const handleDelete = useCallback(
        (id: string, situation: string, cod: string, number?: number) => {
            if (situation === 'EM DIGITAÇÃO' && !number) {
                Swal.fire({
                    title: 'Confirmar exclusão do registro?',
                    icon: 'question',
                    showCancelButton: true,
                    showCloseButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (result) => {
                    if (result.value) {
                        setLoading(true);
                        try {
                            await api.delete(
                                `ctes/${id}?environment_inssuer_id=${user.environment?.id}&cod=${cod}`
                            );
                            setRefresh(Math.random());
                            toast.success('Registro excluído com sucesso');
                        } catch (error: any) {
                            toast.error(
                                error.response?.data ||
                                    'Ocorreu um erro ao excluir o registro'
                            );
                        }
                        setLoading(false);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'Só é possível excluir um documento em digitação e sem numeração.'
                });
            }
        },
        [user]
    );

    // IMPRIMIR
    const printCte = useCallback(async (id: string, situation: string) => {
        if (
            situation === 'CANCELADO' ||
            situation === 'AUTORIZADO' ||
            situation === 'AUTORIZADO EM CONTINGÊNCIA'
        ) {
            setLoading(true);
            await api
                .get(`/printCteNS/${id}`)
                .then(async (response) => {
                    openPdfFromBase64(response.data.pdf);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: `Erro ao imprimir: ${error.response?.data}`
                    });
                })
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Só é possivel imprimir um documento autorizado.'
            });
        }
    }, []);

    // ENVIAR
    const sendCte = useCallback(
        async (id: string, situation: string, auto_endorsement: boolean) => {
            if (situation === 'EM DIGITAÇÃO' || situation === 'ENVIADO') {
                setLoading(true);
                await api
                    .post('apiCtesNS', {
                        id,
                        print: user.environment?.config_print_documents
                    })
                    .then(async (response) => {
                        if (auto_endorsement) {
                            setLoading(false);
                            const {messages} = response.data;
                            Swal.fire({
                                icon: 'success',
                                title: messages[0],
                                text: messages[1]
                            });
                        } else {
                            toast.success(response.data.messages[0]);
                        }
                        if (
                            user.environment?.config_print_documents &&
                            response.data.pdf
                        ) {
                            openPdfFromBase64(response.data.pdf);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        Swal.fire({
                            icon: 'error',
                            title: 'CT-e rejeitado',
                            text:
                                error.response?.data ||
                                'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status'
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                        setRefresh(Math.random());
                    });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Só é possível enviar um documento em digitação.'
                });
            }
        },
        [user]
    );

    // ENVIAR POR EMAIL
    const emailCte = useCallback((id: string, situation: string) => {
        if (
            situation === 'CANCELADO' ||
            situation === 'AUTORIZADO' ||
            situation === 'AUTORIZADO EM CONTINGÊNCIA'
        ) {
            Swal.fire({
                title: 'Enviar por e-mail',
                input: 'email',
                inputPlaceholder: 'Insira o e-mail do destinatário',
                validationMessage: 'Endereço de email inválido',
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                showLoaderOnConfirm: true,
                preConfirm: async (result) => {
                    setLoading(true);
                    await api
                        .post(`sendCtesNS`, {
                            email: result,
                            id
                        })
                        .then(() => toast.success('E-mail enviado com sucesso'))
                        .catch((error) => {
                            setLoading(false);
                            Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text:
                                    error.response?.data ||
                                    'Ocorreu um erro, tente novamente'
                            });
                        });
                    setLoading(false);
                }
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Só é possível enviar por e-mail um documento autorizado.'
            });
        }
    }, []);

    // EMITIR CARTA DE CORREÇÃO
    const correctionCte = useCallback((id: string, situation: string) => {
        if (
            situation === 'AUTORIZADO' ||
            situation === 'AUTORIZADO EM CONTINGÊNCIA'
        ) {
            setCorrectionId(id);
            setCorrectionModalVisible(true);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'Só é possível enviar uma Carta de Correção para um documento autorizado'
            });
        }
    }, []);

    // VISUALIZAR CARTAS DE CORREÇÃO
    const showCorrections = useCallback((id: string) => {
        setLoading(true);
        api.get(`cteCorrections/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setLetterReportsData({letters: response.data, id});
                    setLetterReportsVisible(true);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Este documento não possui cartas de correção'
                    });
                }
            })
            .catch(() => toast.error('Erro ao obter cartas de correção'))
            .finally(() => setLoading(false));
    }, []);

    // VISUALIZAR EVENTOS
    const showEvents = useCallback((id: string) => {
        setLoading(true);
        api.get(`cteEvents/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setEventsModalData(response.data);
                    setEventsModalVisible(true);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Este documento não possui eventos'
                    });
                }
            })
            .catch(() => toast.error('Erro ao obter eventos'))
            .finally(() => setLoading(false));
    }, []);

    // CANCELAR
    const cancelCte = useCallback(
        (
            id: string,
            situation: string,
            cod: string,
            auto_endorsement: boolean
        ) => {
            if (
                situation === 'AUTORIZADO' ||
                situation === 'AUTORIZADO EM CONTINGÊNCIA'
            ) {
                Swal.fire({
                    title: 'Motivo do cancelamento',
                    input: 'text',
                    inputValidator: (value) => {
                        if (value.length < 15) {
                            return 'Escreva ao menos 15 caracteres';
                        }

                        return null;
                    },
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Enviar',
                    showLoaderOnConfirm: true,
                    preConfirm: (result) => {
                        setLoading(true);
                        api.delete(
                            `apiCtesCancelNS/${id}?justify_cancellation=${result}&cod=${cod}&environment_inssuer_id=${user.environment?.id}`
                        )
                            .then((response) => {
                                setRefresh(Math.random());
                                if (auto_endorsement) {
                                    setLoading(false);
                                    const {messages} = response.data;
                                    Swal.fire({
                                        icon: 'success',
                                        title: messages[0],
                                        text: messages[1]
                                    });
                                } else {
                                    toast.success(response.data);
                                }
                            })
                            .catch((error) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Oops...',
                                    text:
                                        error.response?.data ||
                                        'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status ou tente cancelar novamente'
                                });
                            })
                            .finally(() => setLoading(false));
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Só é possível cancelar um documento autorizado'
                });
            }
        },
        [user]
    );

    // INUTILIZAR
    const disableCte = useCallback(
        (id: string, situation: string, number?: number) => {
            if (situation === 'EM DIGITAÇÃO' && number) {
                Swal.fire({
                    title: 'Motivo da inutilização',
                    input: 'text',
                    inputValidator: (value) => {
                        if (value.length < 15) {
                            return 'Escreva ao menos 15 caracteres';
                        }

                        return null;
                    },
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Enviar',
                    showLoaderOnConfirm: true,
                    preConfirm: (result) => {
                        setLoading(true);
                        api.delete(
                            `apiCtesDisableNS/${id}?justify_disable=${result}&number=${number}&environment_inssuer_id=${user.environment?.id}`
                        )
                            .then(() => {
                                setRefresh(Math.random());
                                toast.success('Inutilizado com sucesso');
                            })
                            .catch((error) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Oops...',
                                    text:
                                        error.response?.data ||
                                        'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status ou tente cancelar novamente'
                                });
                            })
                            .finally(() => setLoading(false));
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'Só é possível inutilizar um documento em digitação com numeração'
                });
            }
        },
        [user]
    );

    // CONSULTAR
    const searchCte = useCallback((id: string) => {
        setLoading(true);
        api.get(`searchCteNS/${id}`)
            .then((response) => {
                Swal.fire({
                    icon: 'info',
                    title: 'Consulta',
                    text: response.data
                });
                setRefresh(Math.random());
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        error.response?.data ||
                        'Não foi possível realizar a consulta'
                });
            })
            .finally(() => setLoading(false));
    }, []);

    // BAIXAR XML
    const cteFile = useCallback(
        (id: string, key: string, type: 'tx2' | 'xml' | 'json') => {
            setLoading(true);
            api.get(`cteFile?id=${id}&type=${type}`)
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    const fileName = `CTe_${key}.${type}`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    navigator.clipboard.writeText(`${response.data}`);
                    toast.info('Exportado com sucesso.');
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text:
                            error.response?.data ||
                            'Ocorreu um erro ao copiar os dados'
                    });
                })
                .finally(() => setLoading(false));
        },
        []
    );

    // AVERBAÇÃO
    const cteEndorsement = useCallback(
        (
            id: string,
            situation: string,
            protocol?: string,
            protocol_cancel?: string
        ) => {
            if (user?.tenant?.module_endorsement) {
                if (
                    (situation === 'AUTORIZADO' && !protocol) ||
                    (situation === 'CANCELADO' && !protocol_cancel)
                ) {
                    setLoading(true);
                    api.post(
                        `cteEndorsement${
                            situation === 'CANCELADO' ? 'Cancel' : ''
                        }/${id}`
                    )
                        .then((response) => {
                            Swal.fire({
                                icon: 'success',
                                title: response.data
                            });
                            setRefresh(Math.random());
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Erro ao efetuar averbação',
                                text: error.response?.data || error
                            });
                        })
                        .finally(() => setLoading(false));
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Documento já averbado',
                        text: `Protocolo da averbação${
                            protocol_cancel ? ' de cancelamento' : ''
                        }: ${protocol_cancel || protocol}`
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Módulo não habilitado',
                    text:
                        'Esta função não está habilitada para a sua empresa. Caso tenha interesse, entre em contato com o suporte'
                });
            }
        },
        [user]
    );

    // CORPO DA TABELA
    const mapTable = useMemo(
        () =>
            ctes.map((cte) => (
                <tr key={Math.random()}>
                    {/* CÓDIGO */}
                    <td className="pt-2 pl-2 ">{cte.cod}</td>
                    {/* NÚMERO */}
                    <td className="pt-2">{cte.number}</td>
                    {/* EMISSÃO */}
                    <td className="pt-2">
                        {cte.date_emission
                            ?.split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                    </td>
                    {/* ENTREGA */}
                    <td className="pt-2">
                        {cte.date_delivery
                            ?.split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                    </td>
                    {/* REMETENTE */}
                    <td className="pt-2 short-td">
                        {cte.sender_people?.name?.toUpperCase()}
                    </td>
                    {/* DESTINATÁRIO */}
                    <td className="pt-2 short-td">
                        {cte.recipient_people?.name?.toUpperCase()}
                    </td>
                    {/* VALOR PRESTAÇÃO */}
                    <td className="pt-2">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(cte.value || 0)}
                    </td>
                    {/* VALOR PRODUTO */}
                    <td className="pt-2">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(cte.commodity_value)}
                    </td>
                    {/* SITUAÇÃO */}
                    <td
                        className={`pt-2 text-center bg-${setSituationColor(
                            cte.situation
                        )}`}
                    >
                        {cte.situation}
                    </td>
                    {/* AVERBAÇÃO */}
                    {user.tenant.module_endorsement && (
                        <td
                            className={`pt-2 text-center bg-${setEndorsementColor(
                                cte.endorsement_protocol,
                                cte.endorsement_cancel_protocol
                            )}`}
                        >
                            <i
                                className="fas fa-file-contract pt-1"
                                style={{fontSize: 16}}
                            />
                        </td>
                    )}
                    {/* IMPRIMIR */}
                    <td className="text-center">
                        <button
                            type="button"
                            className="btn btn-default btn-sm btn-block"
                            onClick={async () => {
                                await printCte(cte.id || '', cte.situation);
                            }}
                        >
                            <i className="fas fa-print" />
                        </button>
                    </td>
                    {/* ENVIAR */}
                    <td>
                        <button
                            type="button"
                            className="btn btn-default btn-sm btn-block"
                            onClick={async () => {
                                await sendCte(
                                    cte.id || '',
                                    cte.situation,
                                    cte.auto_endorsement
                                );
                            }}
                        >
                            <i className="fas fa-paper-plane" />
                        </button>
                    </td>
                    {/* AÇÕES */}
                    <td className="px-0 text-center">
                        <ActionButton
                            // QUALQUER SITUAÇÃO
                            view={() => {
                                showCTE(cte.id || '', 'view');
                            }}
                            clone={() => {
                                showCTE(cte.id || '', 'include');
                            }}
                            events={() => showEvents(cte.id || '')}
                            // QUALQUER SITUAÇÃO, EXCETO INUTILIZADO
                            consult={
                                cte.situation !== 'INUTILIZADO'
                                    ? () => {
                                          searchCte(cte.id || '');
                                      }
                                    : undefined
                            }
                            //
                            // EM DIGITAÇÃO
                            edit={
                                cte.situation === 'EM DIGITAÇÃO'
                                    ? () => {
                                          if (
                                              cte.situation === 'EM DIGITAÇÃO'
                                          ) {
                                              showCTE(cte.id || '', 'edit');
                                          } else {
                                              Swal.fire({
                                                  icon: 'warning',
                                                  title: 'Oops...',
                                                  text:
                                                      'Só é possível editar um documento em digitação.'
                                              });
                                          }
                                      }
                                    : undefined
                            }
                            //
                            // DIGITAÇÃO SEM NUMERAÇÃO
                            delete={
                                cte.situation === 'EM DIGITAÇÃO' && !cte.number
                                    ? () =>
                                          handleDelete(
                                              cte.id || '',
                                              cte.situation,
                                              cte.cod || '',
                                              cte.number
                                          )
                                    : undefined
                            }
                            //
                            // EM DIGITAÇÃO COM NUMERAÇÃO
                            disable={
                                cte.situation === 'EM DIGITAÇÃO' && cte.number
                                    ? () => {
                                          disableCte(
                                              cte.id || '',
                                              cte.situation,
                                              cte.number
                                          );
                                      }
                                    : undefined
                            }
                            //
                            // AUTORIZADO
                            correction={
                                cte.situation === 'AUTORIZADO'
                                    ? () => {
                                          correctionCte(
                                              cte.id || '',
                                              cte.situation
                                          );
                                      }
                                    : undefined
                            }
                            cancel={
                                cte.situation === 'AUTORIZADO'
                                    ? () => {
                                          cancelCte(
                                              cte.id || '',
                                              cte.situation,
                                              cte.cod || '',
                                              cte.auto_endorsement
                                          );
                                      }
                                    : undefined
                            }
                            //
                            // AUTORIZADO OU CANCELADO
                            email={
                                cte.situation === 'AUTORIZADO' ||
                                cte.situation === 'CANCELADO'
                                    ? () => {
                                          emailCte(cte.id || '', cte.situation);
                                      }
                                    : undefined
                            }
                            correctionsReport={
                                cte.situation === 'AUTORIZADO' ||
                                cte.situation === 'CANCELADO'
                                    ? () => showCorrections(cte.id || '')
                                    : undefined
                            }
                            xml={
                                cte.situation === 'AUTORIZADO' ||
                                cte.situation === 'CANCELADO'
                                    ? () =>
                                          cteFile(
                                              cte.id || '',
                                              cte.key || '',
                                              'xml'
                                          )
                                    : undefined
                            }
                            endorsement={
                                user.tenant.module_endorsement && // APENAS SE O MÓDULO ESTIVER ATIVO
                                (cte.situation === 'AUTORIZADO' ||
                                    cte.situation === 'CANCELADO')
                                    ? () =>
                                          cteEndorsement(
                                              cte.id || '',
                                              cte.situation,
                                              cte.endorsement_protocol,
                                              cte.endorsement_cancel_protocol
                                          )
                                    : undefined
                            }
                            //
                            // json={ () =>
                            //     cteFile(cte.id || '', cte.key || '', 'json')
                            // }
                        />
                    </td>
                </tr>
            )),
        [ctes, user]
    );

    return (
        <div>
            <div className="content-header">
                <div className="title-page">
                    <div className="container-fluid pl-1 dc-flex desktop">
                        <TitlePage
                            title={`Monitor CT-e ${
                                user.environment
                                    ? ` | ${
                                          user.environment?.cte_environment ===
                                          1
                                              ? 'Produção'
                                              : 'Homologação'
                                      }`
                                    : ''
                            }`}
                            icon="fa-desktop"
                            tabRight
                            pagination
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                        />
                        <div className="tab-min-top col-1 text-left mt-1 pr-1">
                            <Button
                                className="tour-cte--01"
                                type="button"
                                color="success"
                                caption="Incluir"
                                icon="fa-plus"
                                maxSize
                                onClick={() => openModal('form')}
                            />
                        </div>
                        <div className="tab-min-top col-6 text-left mt-1 pr-1">
                            <Button
                                className="tour-cte--01"
                                type="button"
                                color="primary"
                                caption="Enviar lote"
                                icon="fa-paper-plane"
                                maxSize
                                onClick={() => openModal('batch')}
                            />
                        </div>
                        {/* <div className="col-5 text-right col-form-label">
                            <strong className="text-primary tour-cte--04">
                                Servidor da receita:{' '}
                                <span className="text-dark">
                                    {
                                        setStatusData(status.ModoOperacaoSaaS)
                                            .status
                                    }
                                    <i
                                        className={`fas fa-circle text-${
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).colorClass
                                        } pl-1`}
                                        data-tip={
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).tip
                                        }
                                        data-for="status"
                                    />
                                    <ReactTooltip
                                        id="status"
                                        place="bottom"
                                        backgroundColor={
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).color
                                        }
                                    />
                                </span>
                            </strong>
                        </div> */}
                    </div>
                    <div className="content-header mobile">
                        <div className="container-fluid pl-1 pr-2">
                            <TitlePage
                                title={`Monitor CT-e ${
                                    user.environment
                                        ? ` | ${
                                              user.environment
                                                  ?.cte_environment === 1
                                                  ? 'Produção'
                                                  : 'Homologação'
                                          }`
                                        : ''
                                }`}
                                icon="fa-desktop"
                                tabRight
                                pagination
                                count={count}
                                currentPage={currentPage}
                                perPage={perPage}
                                mobile
                            />
                            <div className="dc-flex">
                                <div className="col-sm-3 my-2 pt-min">
                                    <Button
                                        type="button"
                                        color="success"
                                        caption="Incluir"
                                        icon="fa-plus"
                                        maxSize={true}
                                        onClick={() => openModal('form')}
                                    />
                                </div>
                                {/* <div className="col-sm-9 col-xs-12 my-2">
                                    <strong className="text-primary">
                                        Servidor da receita:{' '}
                                    </strong>
                                    <span>
                                        {
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).status
                                        }
                                        <i
                                            className={`fas fa-circle text-${
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).colorClass
                                            } pl-1`}
                                            data-tip={
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).tip
                                            }
                                            data-for="status"
                                        />
                                        <ReactTooltip
                                            id="status"
                                            place="bottom"
                                            backgroundColor={
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).color
                                            }
                                        />
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="main-content">
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSearch)}
                            >
                                <div className="w-100 dc-flex filter-desktop tour-cte--02">
                                    <div className="w-70">
                                        <div className="d-flex">
                                            <section className="d-flex w-100 pt-1 pb-1 px-1">
                                                <InputLabel
                                                    label="Emissão"
                                                    name="start_emission"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                    type="date"
                                                />

                                                <InputLabel
                                                    label="Até"
                                                    name="end_emission"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                    type="date"
                                                />
                                                <InputLabel
                                                    label="Remetente"
                                                    name="sender"
                                                    size_label="1"
                                                    size_input="3"
                                                />
                                                <InputLabel
                                                    label="Destinatário"
                                                    name="recipient"
                                                    size_label="1"
                                                    size_input="3"
                                                />
                                            </section>
                                        </div>
                                        <div className="d-flex">
                                            <section className="d-flex w-100 px-1 pb-1">
                                                <InputLabelCurrency
                                                    label="Vlr. Prod."
                                                    name="start_amount"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                />

                                                <InputLabelCurrency
                                                    label="Até"
                                                    name="end_amount"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="CFOP"
                                                    name="cfop"
                                                    size_label="1"
                                                    size_input="1"
                                                />
                                                <InputLabelOnlyNumbers
                                                    label="N° CT-e"
                                                    name="number"
                                                    size_label="1"
                                                    size_input="1"
                                                />
                                            </section>
                                        </div>
                                        <div className="d-flex">
                                            <section className="d-flex w-100 px-1 pb-2">
                                                <InputLabel
                                                    label="Entrega"
                                                    name="start_delivery"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                    type="date"
                                                />

                                                <InputLabel
                                                    label="Até"
                                                    name="end_delivery"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                    type="date"
                                                />
                                                <SelectInputLabel
                                                    label="Situação"
                                                    name="situation"
                                                    size_label="1"
                                                    size_input="3 z-2"
                                                    options={cte_situations}
                                                />
                                            </section>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right mb-2 pr-4 ml-2">
                                        <Button
                                            className="tour-cte--03"
                                            type="submit"
                                            color="default"
                                            caption="Pesquisar"
                                            icon="fa-search"
                                        />
                                    </div>
                                </div>
                                {/* <div className="filter-mobile">
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Emissão"
                                                name="start_emission"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                type="date"
                                                labelFilter
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Até"
                                                name="end_emission"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                type="date"
                                                labelFilter
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Remetente"
                                                name="sender"
                                                size_label="auto max-w-80"
                                                size_input="11"
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Destinatário"
                                                name="recipient"
                                                size_label="auto max-w-80"
                                                size_input="11"
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabelCurrency
                                                label="Vlr. Prod."
                                                name="start_amount"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                labelFilter
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabelCurrency
                                                label="Até"
                                                name="end_amount"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                labelFilter
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabelOnlyNumbers
                                                label="CFOP"
                                                name="cfop"
                                                size_label="auto min-w-80"
                                                size_input="11"
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabelOnlyNumbers
                                                label="N° CT-e"
                                                name="number"
                                                size_label="auto min-w-80"
                                                size_input="11"
                                                labelFilter
                                                textRight
                                            />
                                        </section>
                                    </div>

                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Entrega"
                                                name="start_delivery"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                type="date"
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 pt-1 pb-1 px-1">
                                            <InputLabel
                                                label="Até"
                                                name="end_delivery"
                                                size_label="auto min-w-80"
                                                size_input="11 w-date"
                                                type="date"
                                                textRight
                                            />
                                        </section>
                                    </div>
                                    <div className="d-flex">
                                        <section className="d-flex w-100 px-1 pb-2">
                                            <SelectInputLabel
                                                label="Situação"
                                                name="situation"
                                                size_label="auto min-w-80"
                                                size_input="11 z-2"
                                                labelFilter
                                                textRight
                                                options={cte_situations}
                                            />
                                        </section>
                                    </div>
                                    <div className="col-11 text-right">
                                        <Button
                                            type="submit"
                                            color="default"
                                            caption="Pesquisar"
                                            icon="fa-search"
                                            fullSize
                                        />
                                    </div>
                                </div> */}
                            </form>
                        </FormProvider>
                        <div className="container-fluid">
                            <div className="row">
                                <CardBody
                                    size="12"
                                    smallSize="12"
                                    page={page}
                                    count={count}
                                    setPage={setPage}
                                >
                                    <Table
                                        id="cte"
                                        columns={tableColumns}
                                        setOrder={setOrder}
                                        order={order.order}
                                        type={order.type}
                                    >
                                        {mapTable}
                                    </Table>
                                </CardBody>
                            </div>
                        </div>

                        {modalVisible && (
                            <ModalCTe
                                data={modalData}
                                hide={() => setModalVisible(false)}
                                refresh={() => setRefresh(Math.random())}
                            />
                        )}

                        {correctionModalVisible && (
                            <CorrectionLetterModal
                                id={correctionId}
                                hide={() => setCorrectionModalVisible(false)}
                                setLoading={setLoading}
                            />
                        )}

                        {letterReportsVisible && (
                            <CorrectionLetterReportsModal
                                hide={() => setLetterReportsVisible(false)}
                                data={letterReportsData}
                            />
                        )}

                        {eventsModalVisible && (
                            <CteMdfeEventsModal
                                hide={() => setEventsModalVisible(false)}
                                data={eventsModalData}
                                type="CT-e"
                            />
                        )}

                        {sendBatchModalVisible && (
                            <SendBatchModal
                                hide={() => setSendBatchModalVisible(false)}
                                refresh={() => setRefresh(Math.random())}
                            />
                        )}

                        {loading ? (
                            <FullPageLoader />
                        ) : (
                            <Tour
                                steps={tourStepsCte}
                                isOpen={isTourOpen}
                                onRequestClose={() => setIsTourOpen(false)}
                                accentColor="#007BFF"
                                maskSpace={8}
                                rounded={20}
                                startAt={0}
                                onAfterOpen={() => {
                                    setOverflow(false);
                                    disabledOverflow();
                                }}
                                onBeforeClose={() => {
                                    if (user.tour_monitors) {
                                        api.post('tourMonitors');
                                        updateUser({
                                            ...user,
                                            tour_monitors: false
                                        });
                                    }
                                    setOverflow(true);
                                    disabledOverflow();
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonitorCTE;
