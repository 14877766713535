import React from 'react';

import './styles.scss';

const Graphic: React.FC = ({children}) => {
    return (
        <div className="graphic__undefined">
            <h6>
                <i className="fas fa-info-circle mr-2" />
                Nenhum {children} emitido foi encontrado nesta data.
            </h6>
        </div>
    );
};

export default Graphic;
