const columns = [
    {
        text: 'Nome',
        width: '80%',
        className: 'text-max-sm link-button',
        name: 'UPPER(corporate_name)',
        hasOrder: true
    },
    {
        text: 'CNPJ',
        width: '20% ',
        className: 'text-max-sm link-button',
        name: 'cpf_cnpj',
        hasOrder: true
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
