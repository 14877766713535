import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import cpf from 'cpf';
import {validate} from 'cnpj';
import Button from '../../../../components/Button';
import {MDFeProps} from '../../../../interfaces';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import InputLabelCNPJnoSearch from '../../../../components/InputLabelCNPJnoSearch';
import InputLabelCPF from '../../../../components/InputLabelCPF';
import isEquivalent from '../../../../utils/isEquivalent';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<MDFeProps>;
    setTableValue: UseFormSetValue<MDFeProps>;
}

interface Ciot {
    cpf_cnpj_service: string;
    ciot: string;
    ciot_type: number;
}

const validations = Yup.object({
    ciot: Yup.string()
        .required('Insira o CIOT.')
        .length(12, 'O CIOT deve conter 12 caracteres'),
    cpf_cnpj_service: Yup.string()
        .required('CPF/CNPJ Obrigatório.')
        .when('ciot_type', {
            is: 0,
            then: Yup.string().test('cpf_cnpj', 'CPF inválido.', (value) => {
                if (value !== undefined) {
                    return cpf.isValid(value);
                }
                return true;
            })
        })
        .when('ciot_type', {
            is: 1,
            then: Yup.string().test('cpf_cnpj', 'CNPJ inválido.', (value) => {
                if (value !== undefined) {
                    return validate(value);
                }
                return true;
            })
        })
});

const CiotModal: React.FC<Props> = ({hide, getTableValues, setTableValue}) => {
    const methods = useForm<Ciot>({
        resolver: yupResolver(validations),
        defaultValues: {
            cpf_cnpj_service: '',
            ciot: '',
            ciot_type: 1
        }
    });

    const {handleSubmit, getValues, setValue, watch} = methods;
    watch('ciot_type');

    const onSubmit = useCallback((dataSubmit: Ciot) => {
        let equivalent = false;
        getTableValues('ciot').every((item) => {
            equivalent = isEquivalent(item, {
                ciot: getValues('ciot'),
                cpf_cnpj_service: dataSubmit.cpf_cnpj_service
            });
            if (equivalent === true) {
                return false;
            }
            return true;
        });
        if (!equivalent) {
            setTableValue('ciot', [
                ...getTableValues().ciot,
                {
                    ciot: dataSubmit.ciot,
                    cpf_cnpj_service: dataSubmit.cpf_cnpj_service
                }
            ]);
            hide();
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Já existe um CIOT registrado com esses mesmos dados.'
            });
        }
    }, []);

    return (
        <>
            <Modal show dialogAs="div" centered>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Cadastrar informação de CIOT
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <SelectInputLabel
                                            label="CPF/CNPJ (contratante do serviço)"
                                            data_tip="CPF/CNPJ do contratante do serviço"
                                            name="ciot_type"
                                            size_label="4"
                                            noSizeSelect
                                            size_input="2"
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'CNPJ'
                                                },
                                                {
                                                    value: 0,
                                                    label: 'CPF'
                                                }
                                            ]}
                                            onChange={(option: any) => {
                                                setValue(
                                                    'cpf_cnpj_service',
                                                    ''
                                                );
                                            }}
                                        />

                                        {getValues('ciot_type') === 1 ? (
                                            <InputLabelCNPJnoSearch
                                                label=""
                                                name="cpf_cnpj_service"
                                                size_label=""
                                                size_input="4"
                                                remove_label
                                            />
                                        ) : (
                                            <InputLabelCPF
                                                label=""
                                                name="cpf_cnpj_service"
                                                size_label=""
                                                size_input="4"
                                                remove_label
                                            />
                                        )}
                                    </div>

                                    <div className="row">
                                        <InputLabelOnlyNumbers
                                            label="CIOT"
                                            data_tip="Código Identificador da Operação de Transporte."
                                            name="ciot"
                                            digits={12}
                                            size_label="4"
                                            size_input="2"
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={() => hide()}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default CiotModal;
