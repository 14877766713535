/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {ChangeEvent} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    labelFilter?: boolean;
    textRight?: boolean;
    remove_label?: boolean;
    data_tip?: string;
}

const InputLabelCurrency: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    labelFilter,
    textRight,
    remove_label,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        setValue,
        getValues
    } = useFormContext();

    return (
        <>
            {!remove_label && (
                <Label
                    required={required}
                    respAlt={labelFilter ? false : true}
                    title={label}
                    data_tip={data_tip}
                    col={`col-sm-${size_label} ${
                        textRight ? 'text-right' : ''
                    }`}
                    to={name}
                />
            )}
            <div
                className={`col-sm-${size_input} ${
                    labelFilter ? 'resp-form2' : 'resp-form'
                } `}
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            onChange={(text) => {
                                setValue(
                                    name,
                                    parseFloat(
                                        text
                                            .replaceAll('.', '')
                                            .replaceAll(',', '.')
                                            .replace('R$ ', '')
                                    )
                                );
                                otherProps.onChange &&
                                    otherProps.onChange(
                                        (text as unknown) as ChangeEvent<HTMLInputElement>
                                    );
                            }}
                            kind="money"
                            options={{
                                precision: 2,
                                separator: ',',
                                delimiter: '.',
                                unit: field.value >= 0 ? 'R$ ' : '-R$ ',
                                suffixUnit: ''
                            }}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default InputLabelCurrency;
