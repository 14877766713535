import React, {useState} from 'react';
import EnvironmentModal from '../../../Modals/Environment';

interface EnvironmentIconProps {
    className?: string;
}

const EnvironmentIcon: React.FC<EnvironmentIconProps> = ({className}) => {
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            <li
                className={`nav-link cursor-pointer ${className}`}
                onClick={() => setModalVisible(true)}
                data-tip="Meus ambientes"
                data-for="info-dark"
            >
                <i className="fas fa-building" />
            </li>
            {modalVisible && (
                <EnvironmentModal hide={() => setModalVisible(false)} />
            )}
        </>
    );
};

export default EnvironmentIcon;
