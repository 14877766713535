import React from 'react';

import './styles.scss';

const FullPageLoader: React.FC = () => {
    return (
        <div className="fullpage__loader">
            <div className="loader-wrapper">
                <div className="truck-wrapper">
                    <div className="truck">
                        <div className="truck-container" />
                        <div className="glases" />
                        <div className="bonet" />
                        <div className="base" />
                        <div className="base-aux" />
                        <div className="wheel-back" />
                        <div className="wheel-front" />
                        <div className="smoke" />
                    </div>
                </div>
                <div className="load-3">
                    <div className="line" />
                    <div className="line" />
                    <div className="line" />
                </div>
            </div>
        </div>
    );
};
export default FullPageLoader;
