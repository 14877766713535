/* eslint-disable react/self-closing-comp */
import React, {useCallback, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Button from '../../components/Button';
import logo from '../../assets/logo_cte.png';
import logo_natal from '../../assets/logo_cte_natal.png';
import api from '../../services/api';
import InputGroup from '../../components/InputGroup';
import InputGroupPhone from '../../components/InputGroupPhone';
import FullPageLoader from '../../components/FullPageLoader';

interface Register {
    company_name: string;
    name: string;
    email: string;
    phone: string;
    password: string;
}

const validations = Yup.object({
    company_name: Yup.string().required('Nome da empresa obrigatório!'),
    name: Yup.string().required('Nome de usuário obrigatório!'),
    email: Yup.string()
        .email('Endereço de e-mail inválido!')
        .required('O endereço de e-mail é obrigatório!'),
    phone: Yup.string()
        .required('Número de telefone obrigatório!')
        .min(14, 'Número de telefone inválido!'),
    password: Yup.string()
        .required('A senha é obrigatória!')
        .min(6, 'A senha deve conter no mínimo 6 dígitos!'),
    support_password: Yup.string().required(
        'O código do suporte é obrigatória!'
    )
});

const defaultValues = {
    company_name: '',
    name: '',
    email: '',
    phone: '',
    password: ''
};

const Register: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const methods = useForm<{email: string; password: string}>({
        resolver: yupResolver(validations),
        defaultValues,
        mode: 'onSubmit'
    });
    const {handleSubmit} = methods;

    const registerAccount = useCallback(async (dataSubmit) => {
        setLoading(true);
        api.post('register', dataSubmit)
            .then(() => {
                history.push('/');
                toast.info(
                    <div>
                        Dados registrados!
                        <br />
                        <strong>Confirme seu e-mail para continuar</strong>
                    </div>
                );
            })
            .catch((error) => {
                toast.error(
                    error.response?.data?.errors
                        ? error.response?.data?.errors[0].message
                        : error.response?.data ||
                              'Ocorreu um erro ao cadastrar o usuário'
                );
            });
        setLoading(false);
    }, []);

    return (
        <body className="login-page">
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'fixed'
                }}
            >
                <svg
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    style={{height: '100%', width: '100%'}}
                >
                    <path
                        d="M-5.92,121.88 C152.09,134.70 405.47,59.70 523.98,108.05 L508.74,167.27 L0.00,150.00 Z"
                        style={{
                            stroke: 'none',
                            fill:
                                new Date().getMonth() === 11
                                    ? '#c0392b'
                                    : '#08f'
                        }}
                    />
                </svg>
            </div>
            <div className="login-box">
                <div className="card ">
                    <div className="card-body login-card-body">
                        <div className="text-min-sm mb-2">
                            <Link to="/">
                                <i
                                    className="fas fa-arrow-left"
                                    style={{
                                        color:
                                            new Date().getMonth() === 11
                                                ? '#c0392b'
                                                : ''
                                    }}
                                />
                                <strong
                                    className="tab-left"
                                    style={{
                                        color:
                                            new Date().getMonth() === 11
                                                ? '#c0392b'
                                                : ''
                                    }}
                                >
                                    Retornar
                                </strong>
                            </Link>
                        </div>
                        <div className="login-logo">
                            <img
                                src={
                                    new Date().getMonth() === 11
                                        ? logo_natal
                                        : logo
                                }
                                style={{width: 225}}
                                alt="MS1 Sistemas"
                                className="yuri"
                            />
                        </div>
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(registerAccount)}
                            >
                                <div className="mt-4 mb-3">
                                    <InputGroup
                                        name="name"
                                        placeholder="Nome"
                                        icon="fas fa-user"
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputGroup
                                        name="company_name"
                                        placeholder="Nome da Empresa"
                                        icon="fas fa-building"
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputGroupPhone
                                        name="phone"
                                        placeholder="Telefone ou Celular"
                                        icon="fas fa-phone"
                                    />
                                </div>
                                <div className="mt-5 mb-3">
                                    <InputGroup
                                        name="email"
                                        placeholder="E-mail"
                                        icon="fas fa-envelope"
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputGroup
                                        name="password"
                                        placeholder="Senha"
                                        icon="fas fa-lock"
                                        type="password"
                                    />
                                </div>
                                <div className="mb-3">
                                    <InputGroup
                                        name="support_password"
                                        placeholder="Código do suporte"
                                        icon="fas fa-life-ring"
                                        type="password"
                                    />
                                </div>
                                <div className="mt-4 col-13">
                                    <Button
                                        caption="Cadastrar"
                                        type="submit"
                                        color="primary"
                                        size="btn-block"
                                        border={true}
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>

            {loading && <FullPageLoader />}
        </body>
    );
};

export default Register;
