/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Tour from 'reactour';
import ReactTooltip from 'react-tooltip';
import {useAuth} from '../../hooks/Auth';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import ModalForm from './ModalForm';
import columns from './schema';
import {CFOPProps, TableOrder} from '../../interfaces';
import {tourStepsCfop} from '../../data/tour';
import SaveOrderButton from '../../components/SaveOrderButton';
import importLegacyJSON from '../../services/importLegacyJSON';

const CFOP: React.FC = () => {
    const {user, updateUser, tablesOrder} = useAuth();
    const tableOrder = tablesOrder.find((item) => item.table === 'cfops');
    const [isTourOpen, setIsTourOpen] = useState(user.tour_tables || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };
    const [order, setOrder] = useState<TableOrder>({
        order: tableOrder?.field || 'created_at',
        type: tableOrder?.type || 'desc'
    });
    const [loading, setLoading] = useState(false);
    const [cfops, setCFOPs] = useState<CFOPProps[]>([]);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<CFOPProps>({} as CFOPProps);

    function resetData() {
        setModalData({
            description: '',
            cfop: '',
            form_action: 'include'
        });
    }

    const openModal = useCallback(() => {
        resetData();
        setModalVisible(true);
    }, []);

    async function getCFOP() {
        try {
            setLoading(true);
            const response = await api.get(
                `cfops?page=${page}&limit=20&order=${order.order}&type=${order.type}&search=${search}`
            );
            setCFOPs(response.data.data);
            setCount(response.data.meta.total);
            setPerPage(response.data.meta.per_page);
            setCurrentPage(response.data.meta.current_page);
            setLoading(false);
        } catch (error) {
            setCFOPs([]);
            toast.error('Não foi possível efetuar a consulta');
            setLoading(false);
        }
    }

    function handleDelete(id: string, description: string, cfop: string) {
        Swal.fire({
            title: 'Confirmar exclusão do registro?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.value) {
                try {
                    setLoading(true);
                    await api.delete(
                        `cfops/${id}?description=${description}&cfop=${cfop}`
                    );
                    setRefresh(Math.random());
                    toast.success('Registro excluído com sucesso');
                } catch (error: any) {
                    toast.error(
                        error.response?.data ||
                            'Ocorreu um erro ao excluir o registro'
                    );
                    setLoading(false);
                }
            }
        });
    }

    useEffect(() => {
        getCFOP();
    }, [page, refresh, order]);

    const mapTable = useMemo(
        () =>
            cfops.map((data) => (
                <tr key={data.id}>
                    <td className="pt-2">{data.cfop}</td>
                    <td className="pt-2 pl-2">{data.description}</td>
                    <td className="pl-1 pr-1 text-center">
                        <ActionButton
                            view={() => {
                                setModalData({
                                    ...data,
                                    form_action: 'view'
                                });
                                setModalVisible(true);
                            }}
                            edit={() => {
                                setModalData({
                                    ...data,
                                    form_action: 'edit'
                                });
                                setModalVisible(true);
                            }}
                            delete={() => {
                                handleDelete(
                                    data.id || '',
                                    data.description,
                                    data.cfop
                                );
                            }}
                        />
                    </td>
                </tr>
            )),
        [cfops]
    );

    return (
        <>
            {/* TITLE PAGE ( DESKTOP ) */}
            <div className="content-header desktop">
                <section className="title-page">
                    <div className="container-fluid pl-1 pr-2 d-flex">
                        <TitlePage
                            title="CFOP"
                            icon="far fa-file-alt"
                            pagination
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                            customCol="4"
                        />

                        <div className="tab-min-top col-3 text-right mt-1">
                            <SaveOrderButton
                                id={tableOrder?.id as string}
                                table="cfops"
                                field={order.order}
                                type={order.type}
                                setOrder={setOrder}
                            />

                            <Link to="#">
                                <Button
                                    onClick={() => {
                                        openModal();
                                    }}
                                    className="tour-cfop--01"
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize
                                />
                            </Link>

                            <label
                                htmlFor="json_input"
                                id="btn-import"
                                className="cursor-pointer btn btn-default"
                                data-for="cfop-main-page-tooltip"
                                data-tip="Importar cadastros via JSON"
                                style={{marginLeft: 6}}
                            >
                                <i className="fas fa-download" />
                            </label>

                            <input
                                id="json_input"
                                type="file"
                                accept=".json"
                                style={{display: 'none'}}
                                onChange={(event) => {
                                    const reader = new FileReader();
                                    reader.onload = async function (e) {
                                        const json = e.target?.result as string;
                                        await importLegacyJSON(
                                            json,
                                            'legacyCfops',
                                            setLoading,
                                            () => setRefresh(Math.random())
                                        );
                                    };
                                    reader.readAsText(
                                        event.target.files
                                            ? (event.target.files[0] as Blob)
                                            : ('' as any),
                                        'ISO-8859-1'
                                    );
                                    event.target.value = '';
                                }}
                            />
                        </div>

                        <div className="col-sm-5 col-xs-12 tab-min-top">
                            <div className="input-group tab-max-left tour-cfop--02">
                                <input
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }
                                    }}
                                    type="text"
                                    placeholder="Buscar por descrição ou CFOP"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span className="input-group-append">
                                    <Button
                                        type="button"
                                        color="default"
                                        onClick={() => {
                                            if (page === 1) {
                                                setRefresh(Math.random());
                                            } else {
                                                setPage(1);
                                            }
                                        }}
                                        icon="fa-search"
                                        notTab
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="content-header mobile">
                <section className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            pagination
                            mobile
                            title="CFOP"
                            icon="fa-user"
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                        />

                        <div className="d-flex">
                            <div className="col-sm-3 my-2 pt-min">
                                <SaveOrderButton
                                    id={tableOrder?.id as string}
                                    table="cfops"
                                    field={order.order}
                                    type={order.type}
                                    setOrder={setOrder}
                                />
                                <Button
                                    onClick={() => {
                                        openModal();
                                    }}
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize
                                />
                                <label
                                    htmlFor="json_input"
                                    id="btn-import"
                                    className="cursor-pointer btn btn-default"
                                    data-tip="Importar cadastros via JSON (necessário possuir ambiente selecionado)"
                                    data-for="cfop-main-page-tooltip"
                                    style={{marginLeft: 6}}
                                >
                                    <i className="fas fa-download" />
                                </label>

                                <input
                                    id="json_input"
                                    type="file"
                                    accept=".json"
                                    style={{display: 'none'}}
                                    onChange={(event) => {
                                        const reader = new FileReader();
                                        reader.onload = async function (e) {
                                            const json = e.target
                                                ?.result as string;
                                            await importLegacyJSON(
                                                json,
                                                'legacyCfops',
                                                setLoading,
                                                () => setRefresh(Math.random())
                                            );
                                        };
                                        reader.readAsText(
                                            event.target.files
                                                ? (event.target
                                                      .files[0] as Blob)
                                                : ('' as any),
                                            'ISO-8859-1'
                                        );
                                        event.target.value = '';
                                    }}
                                />
                            </div>
                            <div className="col-sm-9 col-xs-12 my-2">
                                <div className="input-group tab-max-left">
                                    <input
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }
                                        }}
                                        type="text"
                                        placeholder="Buscar por descrição ou CFOP"
                                        className="form-control"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <span className="input-group-append">
                                        <Button
                                            type="button"
                                            color="default"
                                            onClick={() => {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }}
                                            icon="fa-search"
                                            notTab
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <CardBody
                            size="12"
                            smallSize="12"
                            page={page}
                            count={count}
                            setPage={setPage}
                        >
                            <Table
                                id="cfop"
                                columns={columns}
                                setOrder={setOrder}
                                order={order.order}
                                type={order.type}
                            >
                                {mapTable}
                            </Table>
                        </CardBody>
                    </div>
                </div>
            </section>

            {modalVisible && (
                <ModalForm
                    data={modalData}
                    refresh={() => setRefresh(Math.random())}
                    hide={() => setModalVisible(false)}
                />
            )}

            {loading ? (
                <FullPageLoader />
            ) : (
                <Tour
                    steps={tourStepsCfop}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_tables) {
                            api.post('tourTables');
                            updateUser({
                                ...user,
                                tour_tables: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}

            <ReactTooltip
                id="cfop-main-page-tooltip"
                place="left"
                effect="solid"
                backgroundColor="#42494E"
                delayShow={150}
            />
        </>
    );
};

export default CFOP;
