import {InsuranceProps, PeopleProps, VehicleProps} from '../interfaces';

const monthObjectData = [
    {
        value: 1,
        label: 'Janeiro'
    },
    {
        value: 2,
        label: 'Fevereiro'
    },
    {
        value: 3,
        label: 'Março'
    },
    {
        value: 4,
        label: 'Abril'
    },
    {
        value: 5,
        label: 'Maio'
    },
    {
        value: 6,
        label: 'Junho'
    },
    {
        value: 7,
        label: 'Julho'
    },
    {
        value: 8,
        label: 'Agosto'
    },
    {
        value: 9,
        label: 'Setembro'
    },
    {
        value: 10,
        label: 'Outubro'
    },
    {
        value: 11,
        label: 'Novembro'
    },
    {
        value: 12,
        label: 'Dezembro'
    }
];

const ufs = [
    {
        label: 'AC',
        value: 1
    },
    {
        label: 'AL',
        value: 2
    },
    {
        label: 'AM',
        value: 3
    },
    {
        label: 'AP',
        value: 4
    },
    {
        label: 'BA',
        value: 5
    },
    {
        label: 'CE',
        value: 6
    },
    {
        label: 'DF',
        value: 7
    },
    {
        label: 'ES',
        value: 8
    },
    {
        label: 'GO',
        value: 9
    },
    {
        label: 'MA',
        value: 10
    },
    {
        label: 'MG',
        value: 11
    },
    {
        label: 'MS',
        value: 12
    },
    {
        label: 'MT',
        value: 13
    },
    {
        label: 'PA',
        value: 14
    },
    {
        label: 'PB',
        value: 15
    },
    {
        label: 'PE',
        value: 16
    },
    {
        label: 'PI',
        value: 17
    },
    {
        label: 'PR',
        value: 18
    },
    {
        label: 'RJ',
        value: 19
    },
    {
        label: 'RN',
        value: 20
    },
    {
        label: 'RO',
        value: 21
    },
    {
        label: 'RR',
        value: 22
    },
    {
        label: 'RS',
        value: 23
    },
    {
        label: 'SC',
        value: 24
    },
    {
        label: 'SE',
        value: 25
    },
    {
        label: 'SP',
        value: 26
    },
    {
        label: 'TO',
        value: 27
    },
    {
        label: 'EX',
        value: 27
    }
];

const csts = [
    {
        label: '00 - TRIBUTAÇÃO NORMAL ICMS',
        value: '00'
    },
    {
        label: '20 - TRIBUTAÇÃO COM BC REDUZIDA DO ICMS',
        value: '20'
    },
    {
        label: '40 - ISENTO',
        value: '40'
    },
    {
        label: '41 - ICMS NÃO TRIBUTADO',
        value: '41'
    },
    {
        label: '51 - ICMS DIFERIDO',
        value: '51'
    },
    {
        label: '60 - ICMS COBRADO POR SUBSTITUIÇÃO TRIBUTÁRIA',
        value: '60'
    },
    {
        label: '90 - OUTROS',
        value: '90'
    },
    {
        label: '91 - SIMPLES NACIONAL',
        value: '91'
    },
    {
        label: '92 - OUTROS ESTADOS',
        value: '92'
    }
];

const correctionLetterGroups = [
    {
        label: 'Identificação do CT-e',
        value: 'ide'
    },
    {
        label: 'Tomador do Serviço (apenas em caso de "Outro")',
        value: 'toma4'
    },
    {
        label: 'Dados do Endereço do Tomador (apenas em caso de "Outro")',
        value: 'enderToma'
    },
    {
        label: 'Observações gerais',
        value: 'compl'
    },
    {
        label: 'Observações do Contribuinte',
        value: 'ObsCont'
    },
    {
        label: 'Observações do Fisco',
        value: 'ObsFisco'
    },
    {
        label: 'Identificação do Emitente do CT-e',
        value: 'emit'
    },
    {
        label: 'Endereço do Emitente do CT-e',
        value: 'enderEmit'
    },
    {
        label: 'Identificação do Remetente do CT-e',
        value: 'rem'
    },
    {
        label: 'Endereço do Remetente do CT-e',
        value: 'enderRem'
    },
    {
        label: 'Identificação do Destinatário do CT-e',
        value: 'dest'
    },
    {
        label: 'Endereço do Destinatário do CT-e',
        value: 'enderDest'
    },
    {
        label: 'Identificação do Expedidor do CT-e',
        value: 'exped'
    },
    {
        label: 'Endereço do Expedidor do CT-e',
        value: 'enderExped'
    },
    {
        label: 'Identificação do Recebedor do CT-e',
        value: 'receb'
    },
    {
        label: 'Endereço do Recebedor do CT-e',
        value: 'enderReceb'
    },
    {
        label: 'Valores da Prestação de Serviço',
        value: 'vPrest'
    },
    {
        label: 'Componentes do Valor da Prestação',
        value: 'Comp'
    },
    {
        label: 'Informações da Carga do CT-e',
        value: 'infCarga'
    },
    {
        label: 'Informações de Quantidades da Carga do CT-e',
        value: 'infQ'
    },
    {
        label: 'Informações das NF Manuais',
        value: 'infNF'
    },
    {
        label: 'Informações das NF-e',
        value: 'infNFe'
    },
    {
        label: 'Informações de Outros Documentos',
        value: 'infOutros'
    },
    {
        label: 'Documentos de Transporte Anterior',
        value: 'docAnt'
    },
    {
        label: 'Identificação dos Documentos de Transporte Anterior',
        value: 'idDocAntPap'
    },
    {
        label: 'Chave de Documentos de Transporte Anterior',
        value: 'idDocAntEle'
    },
    {
        label: 'Informações do Modal Rodoviário',
        value: 'rodo'
    }
];

const correctionLetterIde = [
    {
        label: 'CFOP',
        value: 'CFOP'
    },
    {
        label: 'Natureza da Operação (descrição do CFOP)',
        value: 'natOp'
    },
    {
        label: 'Código do Município de envio do CT-e (IBGE)',
        value: 'cMunEnv'
    },
    {
        label: 'Nome do Município de envio do CT-e ',
        value: 'xMunEnv'
    },
    {
        label: 'Sigla da UF de envio do CT-e',
        value: 'UFEnv'
    },
    {
        label: 'Tipo do Serviço (natureza)',
        value: 'tpServ'
    },
    {
        label: 'Código do Município de início da prestação (IBGE)',
        value: 'cMunIni'
    },
    {
        label: 'Nome do Município de início da prestação',
        value: 'xMunIni'
    },
    {
        label: 'Sigla da UF do início da prestação',
        value: 'UFIni'
    },
    {
        label: 'Código do Município de término da prestação (IBGE)',
        value: 'cMunFim'
    },
    {
        label: 'Nome do Município de término da prestação',
        value: 'xMunFim'
    },
    {
        label: 'Sigla da UF do término da prestação',
        value: 'UFFim'
    }
];

const correctionLetterToma4 = [
    {
        label: 'Razão Social ou Nome',
        value: 'xNome'
    },
    {
        label: 'Nome Fantasia',
        value: 'xFant'
    },
    {
        label: 'Telefone',
        value: 'fone'
    },
    {
        label: 'Endereço de email',
        value: 'email'
    }
];

const correctionLetterEnderToma = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'Sigla da UF',
        value: 'UF'
    }
];

const correctionLetterCompl = [
    {
        label: 'Observações gerais',
        value: 'xObs'
    }
];

const correctionLetterObsCont = [
    {
        label: 'Observações do contribuinte',
        value: 'xTexto'
    }
];

const correctionLetterObsFisco = [
    {
        label: 'Observações do fisco',
        value: 'xTexto'
    }
];

const correctionLetterEmit = [
    {
        label: 'Razão social ou nome',
        value: 'xNome'
    },
    {
        label: 'Nome fantasia',
        value: 'xFant'
    }
];

const correctionLetterEnderEmit = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número predial',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'UF',
        value: 'Sigla da UF'
    },
    {
        label: 'Telefone',
        value: 'fone'
    }
];

const correctionLetterRem = [
    {
        label: 'Razão social ou nome',
        value: 'xNome'
    },
    {
        label: 'Nome fantasia',
        value: 'xFant'
    },
    {
        label: 'Telefone',
        value: 'fone'
    }
];

const correctionLetterEnderRem = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número predial',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'UF',
        value: 'Sigla da UF'
    },
    {
        label: 'Endereço de email',
        value: 'email'
    }
];

const correctionLetterDest = [
    {
        label: 'Razão social ou nome',
        value: 'xNome'
    },
    {
        label: 'Nome fantasia',
        value: 'xFant'
    },
    {
        label: 'Inscrição na SUFRAMA',
        value: 'ISUF'
    }
];

const correctionLetterEnderDest = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número predial',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'UF',
        value: 'Sigla da UF'
    },
    {
        label: 'Endereço de email',
        value: 'email'
    }
];

const correctionLetterExped = [
    {
        label: 'CNPJ',
        value: 'CNPJ'
    },
    {
        label: 'CPF',
        value: 'CPF'
    },
    {
        label: 'Inscrição Estadual',
        value: 'IE'
    },
    {
        label: 'Razão social ou nome',
        value: 'xNome'
    },
    {
        label: 'Nome fantasia',
        value: 'xFant'
    },
    {
        label: 'Nome fantasia',
        value: 'fone'
    }
];

const correctionLetterEnderExped = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número predial',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'UF',
        value: 'Sigla da UF'
    },
    {
        label: 'Endereço de email',
        value: 'email'
    }
];

const correctionLetterReceb = [
    {
        label: 'CNPJ',
        value: 'CNPJ'
    },
    {
        label: 'CPF',
        value: 'CPF'
    },
    {
        label: 'Inscrição Estadual',
        value: 'IE'
    },
    {
        label: 'Razão social ou nome',
        value: 'xNome'
    },
    {
        label: 'Nome fantasia',
        value: 'xFant'
    },
    {
        label: 'Nome fantasia',
        value: 'fone'
    }
];

const correctionLetterEnderReceb = [
    {
        label: 'Logradouro',
        value: 'xLgr'
    },
    {
        label: 'Número predial',
        value: 'nro'
    },
    {
        label: 'Complemento',
        value: 'xCpl'
    },
    {
        label: 'Bairro',
        value: 'xBairro'
    },
    {
        label: 'Código do município (IBGE)',
        value: 'cMun'
    },
    {
        label: 'Nome do município',
        value: 'xMun'
    },
    {
        label: 'CEP',
        value: 'CEP'
    },
    {
        label: 'UF',
        value: 'Sigla da UF'
    },
    {
        label: 'Endereço de email',
        value: 'email'
    }
];

const correctionLetterVPrest = [
    {
        label: 'Valor a Receber',
        value: 'vRec'
    }
];

const correctionLetterComp = [
    {
        label: 'Nome do componente',
        value: 'xNome'
    }
];

const correctionLetterInfCarga = [
    {
        label: 'Valor total da carga',
        value: 'vCarga'
    },
    {
        label: 'Produto predominante',
        value: 'proPred'
    },
    {
        label: 'Outras características da carga',
        value: 'xOutCat'
    }
];

const correctionLetterInfQ = [
    {
        label: 'Código da Unidade de Medida',
        value: 'cUnid'
    },
    {
        label: 'Tipo da Medida',
        value: 'tpMed'
    },
    {
        label: 'Quantidade',
        value: 'qCarga'
    }
];

const correctionLetterInfNF = [
    {
        label: 'Série',
        value: 'serie'
    },
    {
        label: 'Número',
        value: 'nDoc'
    },
    {
        label: 'Data de Emissão',
        value: 'dEmi'
    },
    {
        label: 'Valor da Base de Cálculo do ICMS',
        value: 'vBC'
    },
    {
        label: 'Valor Total do ICMS',
        value: 'vICMS'
    },
    {
        label: 'Valor da Base de Cálculo do ICMS ST',
        value: 'vBCST'
    },
    {
        label: 'Valor Total do ICMS ST',
        value: 'vST'
    },
    {
        label: 'Valor Total dos Produtos',
        value: 'vProd'
    },
    {
        label: 'Valor Total da NF',
        value: 'vNF'
    },
    {
        label: 'CFOP Predominante',
        value: 'nCFOP'
    },
    {
        label: 'Modelo da Nota Fiscal',
        value: 'mod'
    }
];

const correctionLetterInfNFe = [
    {
        label: 'PIN',
        value: 'PIN'
    }
];

const correctionLetterInfOutros = [
    {
        label: 'Tipo do documento',
        value: 'tpDoc'
    },
    {
        label: 'Descrição (apenas para tipo 99-Outros)',
        value: 'descOutros'
    },
    {
        label: 'Número',
        value: 'nDoc'
    },
    {
        label: 'Data de Emissão',
        value: 'dEmi'
    },
    {
        label: 'Valor do documento',
        value: 'vDocFisc'
    }
];

const correctionLetterDocAnt = [
    {
        label: 'CNPJ',
        value: 'CNPJ'
    },
    {
        label: 'CPF',
        value: 'CPF'
    },
    {
        label: 'Inscrição Estadual ',
        value: 'IE'
    },
    {
        label: 'Sigla da UF',
        value: 'UF'
    },
    {
        label: 'Razão Social ou Nome do expedidor',
        value: 'xNome'
    }
];

const correctionLetterIdDocAntPap = [
    {
        label: 'Tipo do Documento',
        value: 'tpDoc'
    },
    {
        label: 'Série',
        value: 'serie'
    },
    {
        label: 'Número',
        value: 'nDoc'
    },
    {
        label: 'Data de emissão (AAAA-MM-DD)',
        value: 'dEmi'
    }
];

const correctionLetterIdDocAntEle = [
    {
        label: 'Chave de acesso do CT-e',
        value: 'chave'
    }
];

const correctionLetterRodo = [
    {
        label: 'RNTRC (8 dígitos)',
        value: 'RNTRC'
    }
];

const peopleDefaultValues: PeopleProps = {
    type: 1,
    cpf_cnpj: '',
    driver: false,
    rg_ie: '',
    corporate_name: '',
    name: '',
    phone: '',
    phone_2: '',
    email: '',
    site: '',
    cep: '',
    address: '',
    number: '',
    district: '',
    complement: '',
    city_id: null,
    contact: '',
    people_group_id: null,
    icms: 1,
    obs: '',
    active: true,
    form_action: 'include'
};

const vehicleDefaultValues: VehicleProps = {
    description: '',
    renavam: '',
    license_plate: '',
    bodywork: '',
    vehicle_type: null,
    wheelset_type: '',
    owner: 0,
    owner_type: 0,
    owner_people_type: 1,
    name: '',
    owner_rntrc: '',
    address: '',
    number: '',
    cpf_cnpj: '',
    owner_city_id: null,
    active: true,
    form_action: 'include'
};

const insuranceDefaultValues: InsuranceProps = {
    corporate_name: '',
    cpf_cnpj: '',
    policy: '',
    form_action: 'include'
};

const events = [
    {label: 'QUALQUER', value: ''},
    {label: 'INCLUSÃO', value: 'POST'},
    {label: 'ALTERAÇÃO', value: 'PUT'},
    {label: 'EXCLUSÃO', value: 'DELETE'},
    {label: 'TRANSMISSÃO', value: 'SEND'},
    {label: 'CANCELAMENTO', value: 'CANCEL'},
    {label: 'ENCERRAMENTO DE MANIFESTO', value: 'CLOSE'}
];

const tables = [
    {label: 'QUALQUER', value: ''},
    {label: 'PESSOA', value: 'PESSOA'},
    {label: 'GRUPO', value: 'GRUPO'},
    {label: 'COMPOSIÇÃO DE SERVIÇO', value: 'COMPOSIÇÃO DE SERVIÇO'},
    {label: 'VEÍCULO', value: 'VEÍCULO'},
    {label: 'SEGURADORA', value: 'SEGURADORA'},
    {label: 'CFOP', value: 'CFOP'},
    {label: 'MONITOR CTE', value: 'CT-E'},
    {label: 'MONITOR MDFE', value: 'MDF-E'},
    {label: 'USUÁRIO', value: 'USUÁRIO'},
    {label: 'TRIBUTAÇÃO', value: 'TRIBUTAÇÕES'},
    {label: 'AMBIENTE', value: 'AMBIENTE'},
    {label: 'USUÁRIO ADICIONAL', value: 'USUÁRIO ADICIONAL'},
    {label: 'PERFIL', value: 'PERFIL'}
];

const mdfe_situations = [
    {
        value: '',
        label: 'QUALQUER'
    },
    {
        value: 'EM DIGITAÇÃO',
        label: 'EM DIGITAÇÃO'
    },
    {
        value: 'AUTORIZADO',
        label: 'AUTORIZADO'
    },
    {
        value: 'CANCELADO',
        label: 'CANCELADO'
    }
];

const cte_situations = [
    {
        value: '',
        label: 'QUALQUER'
    },
    {
        value: 'EM DIGITAÇÃO',
        label: 'EM DIGITAÇÃO'
    },
    {
        value: 'AUTORIZADO',
        label: 'AUTORIZADO'
    },
    {
        value: 'CANCELADO',
        label: 'CANCELADO'
    },
    {
        value: 'INUTILIZADO',
        label: 'INUTILIZADO'
    }
];

const mdf_load_types = [
    {
        value: 1,
        label: 'Container'
    },
    {
        value: 2,
        label: 'ULD'
    },
    {
        value: 3,
        label: 'Pallet'
    },
    {
        value: 4,
        label: 'Outros'
    }
];

export {
    monthObjectData,
    ufs,
    csts,
    peopleDefaultValues,
    vehicleDefaultValues,
    insuranceDefaultValues,
    correctionLetterGroups,
    correctionLetterIde,
    correctionLetterToma4,
    correctionLetterEnderToma,
    correctionLetterCompl,
    correctionLetterObsCont,
    correctionLetterObsFisco,
    correctionLetterEmit,
    correctionLetterEnderEmit,
    correctionLetterRem,
    correctionLetterEnderRem,
    correctionLetterDest,
    correctionLetterEnderDest,
    correctionLetterExped,
    correctionLetterEnderExped,
    correctionLetterReceb,
    correctionLetterEnderReceb,
    correctionLetterVPrest,
    correctionLetterComp,
    correctionLetterInfCarga,
    correctionLetterInfQ,
    correctionLetterInfNF,
    correctionLetterInfNFe,
    correctionLetterInfOutros,
    correctionLetterDocAnt,
    correctionLetterIdDocAntPap,
    correctionLetterIdDocAntEle,
    correctionLetterRodo,
    events,
    tables,
    cte_situations,
    mdfe_situations,
    mdf_load_types
};
