/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import {Link} from 'react-router-dom';
import Tour from 'reactour';
import UserMenu from './UserMenu';
import ListDropdown from './ListDropdown';
import EnvironmentIcon from './EnvironmentIcon';
import TaxationItem from './TaxationItem';
import {useAuth} from '../../../hooks/Auth';
import CteReportItem from './CteReportItem';
import MdfReportItem from './MdfReportItem';
import LogsItem from './LogsItem';
import {
    tourStepNavbarPlan,
    tourStepsFooter,
    tourStepsNavbar,
    tourStepsNavbarAdmin,
    tourStepsNavbarIcons
} from '../../../data/tour';
import api from '../../../services/api';
import CloseThirPartyMdfeItem from './CloseMdfeItem';
import support from '../../../services/support';
import FullPageLoader from '../../../components/FullPageLoader';
import ExportFilesItem from './ExportFilesItem';

const Header: React.FC = () => {
    const {user, updateUser, loadingDashboard} = useAuth();
    const version = '4.11.1';
    const [isTourOpen, setIsTourOpen] = useState(user.tour_dashboard || false);
    const [overflow, setOverflow] = useState(true);
    const [loading, setLoading] = useState(false);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };

    return (
        <nav className="main-header border-bottom-0 navbar navbar-expand-md navbar-dark bg-primary">
            <div className="container-fluid">
                <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <h6 className="bg-primary responsive-link responsive-title-navbar">
                    <Link
                        to="/dashboard"
                        className="title-main-header text-nowrap"
                    >
                        MS1 - Transportes
                    </Link>
                    <small className="title-version">{version}</small>
                </h6>
                <div
                    className="collapse navbar-collapse order-3"
                    id="navbarNavAltMarkup"
                >
                    <div className="navbar-nav">
                        <Link
                            to="/dashboard"
                            className="nav-link title-main-header tour-navbar--01"
                        >
                            Dashboard
                        </Link>
                        <ListDropdown
                            title="Cadastros"
                            className="tour-navbar--02"
                        >
                            <Link
                                to="/people"
                                className="dropdown-item desktop"
                            >
                                Pessoa
                            </Link>
                            <Link
                                to="/people"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Pessoa
                            </Link>
                            <Link
                                to="/peopleGroups"
                                className="dropdown-item desktop"
                            >
                                Grupo
                            </Link>
                            <Link
                                to="/peopleGroups"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Grupo
                            </Link>
                            <li className="dropdown-divider" />
                            <Link
                                to="/serviceComposition"
                                className="dropdown-item desktop"
                            >
                                Composição de Serviço
                            </Link>
                            <Link
                                to="/serviceComposition"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Composição de Serviço
                            </Link>
                            <Link
                                to="/vehicles"
                                className="dropdown-item desktop"
                            >
                                Veículo
                            </Link>
                            <Link
                                to="/vehicles"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Veículo
                            </Link>
                            <Link
                                to="/insurances"
                                className="dropdown-item desktop"
                            >
                                Seguradora
                            </Link>
                            <Link
                                to="/insurances"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Seguradora
                            </Link>
                            <Link to="/cfops" className="dropdown-item desktop">
                                CFOP
                            </Link>
                            <Link
                                to="/cfops"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                CFOP
                            </Link>
                        </ListDropdown>
                        <li className="nav-item responsive-screen-pc">
                            <Link
                                to="/monitorCTE"
                                className="nav-link title-main-header desktop tour-navbar--03"
                            >
                                CT-e
                            </Link>
                            <Link
                                to="/monitorCTE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="nav-link title-main-header mobile"
                            >
                                CT-e
                            </Link>
                        </li>
                        <li className="nav-item responsive-screen-pc">
                            <Link
                                to="/monitorMDFE"
                                className="nav-link title-main-header desktop tour-navbar--04"
                            >
                                MDF-e
                            </Link>
                            <Link
                                to="/monitorMDFE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="nav-link title-main-header mobile"
                            >
                                MDF-e
                            </Link>
                        </li>
                        <li className="nav-item responsive-screen-pc">
                            <Link
                                to="/monitorDFE"
                                className="nav-link title-main-header desktop tour-navbar--04"
                            >
                                DF-e
                            </Link>
                            <Link
                                to="/monitorDFE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="nav-link title-main-header mobile"
                            >
                                DF-e
                            </Link>
                        </li>
                        {/* <li className="nav-item responsive-screen-pc">
                            <Link
                                to="/monitorCTEOS"
                                className="nav-link title-main-header desktop tour-navbar--04"
                            >
                                CT-e OS
                            </Link>
                            <Link
                                to="/monitorCTEOS"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="nav-link title-main-header mobile"
                            >
                                CT-e OS
                            </Link>
                        </li> */}
                        <ListDropdown
                            title="Relatórios"
                            className="tour-navbar--05"
                        >
                            <CteReportItem />
                            <MdfReportItem />
                            <ExportFilesItem />
                        </ListDropdown>
                        <ListDropdown title="Monitores" responsive>
                            <Link
                                to="/monitorCTE"
                                className="dropdown-item desktop"
                            >
                                CT-e
                            </Link>
                            <Link
                                to="/monitorCTE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                CT-e
                            </Link>
                            <Link
                                to="/monitorMDFE"
                                className="dropdown-item desktop"
                            >
                                MDF-e
                            </Link>
                            <Link
                                to="/monitorMDFE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                MDF-e
                            </Link>
                            <Link
                                to="/monitorDFE"
                                className="dropdown-item desktop"
                            >
                                DF-e
                            </Link>
                            <Link
                                to="/monitorDFE"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                DF-e
                            </Link>
                            {/* <Link
                                to="/monitorCTEOS"
                                className="dropdown-item desktop"
                            >
                                CT-e OS
                            </Link>
                            <Link
                                to="/monitorCTEOS"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                CT-e OS
                            </Link> */}
                        </ListDropdown>
                        {user.admin && (
                            <>
                                <ListDropdown
                                    title="Administrador"
                                    className="tour-navbar--06"
                                >
                                    <Link
                                        to="users"
                                        className="dropdown-item desktop"
                                    >
                                        Usuários
                                    </Link>
                                    <Link
                                        to="users"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarNavAltMarkup"
                                        aria-controls="navbarNavAltMarkup"
                                        aria-expanded="false"
                                        className="dropdown-item mobile"
                                    >
                                        Usuários
                                    </Link>
                                    <TaxationItem />
                                    <CloseThirPartyMdfeItem />
                                    <LogsItem />
                                </ListDropdown>

                                {/* {user.admin && // RETIRADO POIS AINDA NÃO FOI INTEGRADA A API DE PAGAMENTOS
                                    user.tenant.plan_id !==
                                        process.env.REACT_APP_LEGACY_PLAN && (
                                        <li className="nav-item">
                                            <Link
                                                to="/contract"
                                                className="nav-link title-main-header desktop tour-navbar--07"
                                            >
                                                Planos
                                            </Link>
                                            <Link
                                                to="/contract"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#navbarNavAltMarkup"
                                                aria-controls="navbarNavAltMarkup"
                                                aria-expanded="false"
                                                className="nav-link title-main-header mobile"
                                            >
                                                Planos
                                            </Link>
                                        </li>
                                    )} */}
                            </>
                        )}
                        <ListDropdown title="Ajuda">
                            <a
                                href="https://www.anydesk.com/pt"
                                rel="noreferrer"
                                target="_blank"
                                className="dropdown-item desktop"
                            >
                                Baixar AnyDesk
                            </a>
                            <a
                                href="https://www.anydesk.com/pt"
                                rel="noreferrer"
                                target="_blank"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Baixar AnyDesk
                            </a>
                            <a
                                href="https://www.teamviewer.com/pt-br/download-automatico-do-teamviewer/"
                                rel="noreferrer"
                                target="_blank"
                                className="dropdown-item desktop"
                            >
                                Baixar TeamViewer
                            </a>
                            <a
                                href="https://www.teamviewer.com/pt-br/download-automatico-do-teamviewer/"
                                rel="noreferrer"
                                target="_blank"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                className="dropdown-item mobile"
                            >
                                Baixar TeamViewer
                            </a>
                            <a
                                onClick={() =>
                                    support(user, setLoading, updateUser)
                                }
                                className="dropdown-item desktop"
                                style={{
                                    backgroundColor: user.support
                                        ? '#0070BB'
                                        : ''
                                }}
                            >
                                <span
                                    style={{
                                        color: user.support ? 'white' : ''
                                    }}
                                >
                                    Suporte
                                </span>
                            </a>
                            <a
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                onClick={() =>
                                    support(user, setLoading, updateUser)
                                }
                                className="dropdown-item mobile"
                                style={{
                                    backgroundColor: user.support
                                        ? '#0070BB'
                                        : ''
                                }}
                            >
                                <span
                                    style={{
                                        color: user.support ? 'white' : ''
                                    }}
                                >
                                    Suporte
                                </span>
                            </a>
                        </ListDropdown>
                    </div>
                </div>
                <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                    <EnvironmentIcon className="tour-navbar--08" />
                    <>
                        <li
                            className="nav-item dropdown"
                            data-tip="Notificações"
                            data-for="info-dark"
                        >
                            <a
                                className="nav-link tour-navbar--09"
                                data-toggle="dropdown"
                                href="#"
                            >
                                <i className="far fa-bell" />
                                {/* <span className="badge badge-danger navbar-badge">
                                    0
                                </span> */}
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">
                                    Não há notificações
                                </span>
                                {/* <div className="dropdown-divider" />
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-envelope mr-2" /> 1
                                    nova mensagem
                                    <span className="float-right text-muted text-sm">
                                        3 min
                                    </span>
                                </a>
                                <div className="dropdown-divider" />
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-file mr-2" /> 1 nova
                                    atualização
                                    <span className="float-right text-muted text-sm">
                                        2 dias
                                    </span>
                                </a>
                                <div className="dropdown-divider" /> */}
                                {/* <a
                                    href="#"
                                    className="dropdown-item dropdown-footer"
                                >
                                    Visualizar todas notificações
                                </a> */}
                            </div>
                        </li>
                    </>

                    <UserMenu className="tour-navbar--10" />
                </ul>
            </div>
            <ReactTooltip
                id="info-dark"
                place="bottom"
                backgroundColor="#1e272eee"
                effect="solid"
            />
            {!loadingDashboard && (
                <Tour
                    steps={
                        user.admin
                            ? user.tenant.plan_id !==
                              process.env.REACT_APP_LEGACY_PLAN
                                ? [
                                      ...tourStepsNavbar,
                                      ...tourStepsNavbarAdmin,
                                      ...tourStepsNavbarIcons,
                                      ...tourStepsFooter
                                  ]
                                : [
                                      ...tourStepsNavbar,
                                      ...tourStepsNavbarAdmin,
                                      ...tourStepNavbarPlan,
                                      ...tourStepsNavbarIcons,
                                      ...tourStepsFooter
                                  ]
                            : [
                                  ...tourStepsNavbar,
                                  ...tourStepsNavbarIcons,
                                  ...tourStepsFooter
                              ]
                    }
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_dashboard) {
                            api.post('tourDashboard');
                            updateUser({
                                ...user,
                                tour_dashboard: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}

            {loading && <FullPageLoader />}
        </nav>
    );
};

export default Header;
