import React from 'react';

interface Props {
    action: (e: React.MouseEvent<HTMLElement>) => void;
    icon: string;
    text: string;
}

const DropdownItem: React.FC<Props> = ({action, icon, text}) => {
    return (
        <a
            className="dropdown-item"
            onClick={action}
            style={{
                display: 'flex',
                justifyContent: 'left',
                padding: 4
            }}
        >
            <div
                style={{
                    width: '20%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <i className={icon} />
            </div>
            <div style={{width: '80%'}}>{text}</div>
        </a>
    );
};

export default DropdownItem;
