import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    remove_label?: boolean;
    hideErrors?: boolean;
    data_tip?: string;
}

const InputLabelCNPJnoSearch: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    remove_label,
    hideErrors,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            {!remove_label && (
                <Label
                    required={required}
                    respAlt
                    title={label}
                    data_tip={data_tip}
                    col={`col-sm-${size_label}`}
                    to={name}
                />
            )}
            <div className={`col-sm-${size_input} resp-form`}>
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            kind="cnpj"
                        />
                    )}
                />
            </div>
            {errors && errors[`${name}`] && !hideErrors && (
                <span className="span text-danger text-min-sm">
                    {errors[`${name}`].message}
                </span>
            )}
        </>
    );
};

export default InputLabelCNPJnoSearch;
