import React, {useState} from 'react';
import ThirdPartyMdfeClosure from '../../../Modals/ThirdPartyMdfeClosure';

const CloseThirPartyMdfeItem: React.FC = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <a
                className="dropdown-item cursor-pointer desktop"
                onClick={() => setModalVisible(true)}
            >
                Encerrar MDF-e de sistema externo
            </a>
            <a
                className="dropdown-item cursor-pointer mobile"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                onClick={() => setModalVisible(true)}
            >
                Encerrar MDF-e de sistema externo
            </a>

            {modalVisible && (
                <ThirdPartyMdfeClosure hide={() => setModalVisible(false)} />
            )}
        </>
    );
};

export default CloseThirPartyMdfeItem;
