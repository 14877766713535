/* eslint-disable no-useless-escape */
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from '../../../components/Button';
import api from '../../../services/api';
import InputLabel from '../../../components/InputLabel';

interface Props {
    hide: () => void;
}

interface ChangePassword {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}

const validations = Yup.object().shape({
    current_password: Yup.string().required('Preencha sua senha atual'),
    new_password: Yup.string()
        .required('Preencha sua nova senha')
        .min(6, 'Sua nova senha deve conter no mínimo 6 dígitos')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/,
            'A senha deve conter: uma letra minúscula, uma letra maiúscula e um número'
        ),
    new_password_confirmation: Yup.string()
        .required('Confirme sua nova senha')
        .test(
            'new_password_confirmation',
            'As senhas não coincidem',
            function checkPassword(value) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.parent.new_password === value;
            }
        )
});

const defaultData = {
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
};

const ModalPassword: React.FC<Props> = ({hide}) => {
    const methods = useForm<ChangePassword>({
        resolver: yupResolver(validations),
        defaultValues: defaultData
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(defaultData);
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(async (dataSubmit: ChangePassword) => {
        setIsLoading(true);
        api.post('/user/changePassword', dataSubmit)
            .then(() => {
                toast.success('Sua senha foi alterada com sucesso');
                hide();
            })
            .catch(() => {
                toast.error(
                    'Erro ao alterar senha, confira as informações inseridas'
                );
            })
            .finally(() => setIsLoading(false));
    }, []);

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }
    return (
        <Modal show dialogAs="div">
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Alterar Senha</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row mb-0">
                                <div className="form-group col-sm-3 mb-0">
                                    <p>
                                        Por motivos de segurança, sua nova senha
                                        deve conter:
                                    </p>
                                    <ul>
                                        <li>Ao menos 6 caracteres</li>
                                        <li>Uma letra maíucula</li>
                                        <li>Uma letra minúscula</li>
                                        <li>Um número</li>
                                    </ul>
                                </div>
                                <div className="form-group col-sm-9">
                                    <div className="form-group row">
                                        <InputLabel
                                            required
                                            label="Senha atual"
                                            name="current_password"
                                            size_label="3"
                                            size_input="9"
                                            type="password"
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputLabel
                                            required
                                            label="Nova senha"
                                            name="new_password"
                                            size_label="3"
                                            size_input="9"
                                            type="password"
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <InputLabel
                                            required
                                            label="Confirmar senha"
                                            name="new_password_confirmation"
                                            size_label="3"
                                            size_input="9"
                                            type="password"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                        disabled={isLoading}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalPassword;
