import {useFormContext} from 'react-hook-form';
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import api from '../../services/api';

interface Props {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setModalData: React.Dispatch<React.SetStateAction<any>>;
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    defaultValues: any;
    route: string;
    field: string;
    tip: string;
    size?: string;
    isDisabled?: boolean;
    isMulti?: boolean;
}

const EditIncludeButtons: React.FC<Props> = ({
    setLoading,
    setModalData,
    setModalVisible,
    defaultValues,
    route,
    field,
    tip,
    size,
    isDisabled,
    isMulti
}) => {
    const methods = useFormContext();
    const {getValues} = methods;

    async function show(id: string | undefined) {
        if (id && id !== '') {
            setLoading(true);
            await api
                .get(`${route}/${id}`)
                .then((response) => {
                    setModalData({
                        ...response.data,
                        form_action: 'edit'
                    });
                    setModalVisible(true);
                })
                .catch(() => toast.error('Não foi possível efetuar a consulta'))
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Selecione uma opção para editar.'
            });
        }
    }

    return (
        <div className={`col-sm-${size || '1'}`}>
            <div className="row">
                <div className="col-sm-6 pr-1">
                    <button
                        type="button"
                        disabled={
                            isDisabled || getValues('form_action') === 'view'
                        }
                        data-tip={`Editar ${
                            isMulti ? 'último' : ''
                        } ${tip} selecionado`}
                        data-for="tip"
                        className="btn btn-default btn-block btn-sm"
                        onClick={async () => {
                            await show(
                                getValues(
                                    isMulti
                                        ? `${field}[${
                                              getValues(field).length - 1
                                          }]`
                                        : field
                                )
                            );
                        }}
                    >
                        <i className="fa fa-edit c-success" />
                    </button>
                </div>
                <div className="col-sm-6 pl-1">
                    <button
                        type="button"
                        disabled={
                            isDisabled || getValues('form_action') === 'view'
                        }
                        data-tip={`Adicionar ${tip}`}
                        data-for="tip"
                        className="btn btn-default btn-block btn-sm"
                        onClick={() => {
                            setModalData(defaultValues);
                            setModalVisible(true);
                        }}
                    >
                        <i className="fa fa-plus c-success" />
                    </button>
                </div>
            </div>

            <ReactTooltip
                id="tip"
                place="top"
                backgroundColor="#42494E"
                effect="solid"
                globalEventOff="click"
            />
        </div>
    );
};

export default EditIncludeButtons;
