import React from 'react';

import {Link} from 'react-router-dom';
import TitlePage from '../../components/TitlePage';

const NotFound: React.FC = () => {
    return (
        <>
            <div className="content-header desktop">
                <div className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Erro 404"
                            icon="fa-exclamation-triangle"
                            tabRight={true}
                            pagination={false}
                            description="Página não encontrada"
                        />
                    </div>
                </div>
            </div>
            <div className="content-header mobile">
                <div className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Erro 404"
                            icon="fa-exclamation-triangle"
                            tabRight={true}
                            pagination={false}
                            description="Página não encontrada"
                            mobile={true}
                        />
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="error-page">
                        <h2 className="headline text-danger tab-top">404</h2>
                        <div className="error-content">
                            <h3>
                                <i className="fas fa-exclamation-triangle text-danger tab-right" />
                                Oooops! Página não encontrada.
                            </h3>
                            <p>
                                Nós não conseguimos encontrar a página que você
                                está procurando.
                                <p className="not-margin">
                                    Você pode retornar para o <i>dashboard</i>{' '}
                                    <Link to="/dashboard" className="underline">
                                        clicando aqui.
                                    </Link>
                                </p>
                                Caso o erro persista, você pode entrar em
                                contato com nosso suporte{' '}
                                <Link to="/dashboard" className="underline">
                                    clicando aqui.
                                </Link>{' '}
                                <p className="not-margin">
                                    Obrigado, desculpe pelo incoveniente.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NotFound;
