import * as Yup from 'yup';

const validations = Yup.object({
    date_emission: Yup.string().required(
        'A data de emissão deve ser informada.'
    ),
    date_travel: Yup.string().required('Informe a data da viagem.'),
    environment_inssuer_id: Yup.string()
        .required('O emitente deve ser informado.')
        .nullable(),
    city_origin_id: Yup.number()
        .nullable()
        .required('A cidade de origem deve ser informada.'),
    city_destination_id: Yup.number()
        .nullable()
        .required('A cidade de destino deve ser informada.'),
    cep_load: Yup.string()
        .required('O CEP de carregamento deve ser informado.')
        .length(9, 'CEP de carregamento inválido.')
        .nullable(),
    cep_unload: Yup.string()
        .required('O CEP de descarregamento deve ser informado.')
        .length(9, 'CEP de descarregamento inválido.')
        .nullable(),
    vehicle_id: Yup.string()
        .nullable()
        .required('O veículo deve ser informado.'),
    driver: Yup.array().min(1, 'Deve-se cadastrar ao menos um motorista.'),
    rntrc: Yup.string()
        .nullable()
        .when('type', {
            is: 1,
            then: Yup.string()
                .required('O RNTRC deve ser informado.')
                .length(8, 'O RNTRC deve conter 8 caracteres')
                .not(
                    [
                        '00000000',
                        '11111111',
                        '22222222',
                        '33333333',
                        '44444444',
                        '55555555',
                        '66666666',
                        '77777777',
                        '88888888',
                        '99999999'
                    ],
                    'RNTRC inválido para transportadora'
                )
                .nullable()
        }),
    load: Yup.array()
        .required('Informe ao menos uma cidade de carregamento.')
        .min(1, 'Informe ao menos uma cidade de carregamento.')
        .nullable(),
    unload: Yup.array()
        .of(Yup.number())
        .required('Informe ao menos uma cidade de descarregamento.')
        .min(1, 'Informe ao menos uma cidade de descarregamento.')
        .nullable(),
    insurance: Yup.array().min(
        1,
        'Deve-se cadastrar ao menos uma Informação de Seguro.'
    ),
    ean: Yup.string()
        .test(
            'ean',
            'Caso preenchido, o EAN deve conter 13 dígitos.',
            (value) => {
                if (value && value?.length > 0) {
                    return value?.length === 13;
                }
                return true;
            }
        )
        .nullable(),
    ncm: Yup.string()
        .test(
            'ncm',
            'Caso preenchido, o NCM deve conter 8 dígitos.',
            (value) => {
                if (value && value?.length > 0) {
                    return value?.length === 8;
                }
                return true;
            }
        )
        .nullable(),
    product_key: Yup.string()
        .nullable()
        .required('O produto chave deve ser informado.'),
    load_type: Yup.string()
        .nullable()
        .required('O tipo da carga deve ser informado.')
});

export default validations;
