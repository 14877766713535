// SET THE COLOR OF THE ENDORSEMENT COLUMN AT CT-e TABLE
function setEndorsementColor(protocol?: string, cancel_protocol?: string) {
    let color = 'default';

    if (cancel_protocol) {
        color = 'secondary';
    } else if (protocol) {
        color = 'success';
    }

    return color;
}

export default setEndorsementColor;
