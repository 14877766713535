/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {
    RouteProps as ReactDOMRouteProps,
    Route as ReactDOMRoute,
    Redirect
} from 'react-router-dom';

import {useAuth} from '../hooks/Auth';
import Main from '../pages/Main';
import NotAuthorized from '../pages/NotAuthorized';
import Tutorial from '../pages/Tutorial';

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    isAdmin?: boolean;
    title: string;
    component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
    isPrivate = false,
    isAdmin = false,
    title,
    component: Component,
    ...rest
}) => {
    const {user} = useAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={({location}) => {
                title
                    ? (document.title = `${title} | MS1 Transportes`)
                    : (document.title = 'MS1 Transportes');
                return isPrivate === !!user ? (
                    user?.tutorial === true ? (
                        <Tutorial />
                    ) : isAdmin === true ? (
                        user.admin === true ? (
                            <Main>
                                <Component />
                            </Main>
                        ) : (
                            <Main>
                                <NotAuthorized />
                            </Main>
                        )
                    ) : (
                        <Main>
                            <Component />
                        </Main>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate ? '/' : '/dashboard',
                            state: {from: location}
                        }}
                    />
                );
            }}
        />
    );
};

export default Route;
