/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    data_tip?: string;
    size_label: string;
    size_input: string;
    name_validation?: string;
}

const InputLabelFile: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    data_tip,
    name_validation,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            <Label
                required={required}
                data-tip={data_tip}
                data-for="help__tip--file"
                respAlt
                title={label}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div
                className={`custom-file form-control-sm col-sm-${size_input} mb-2`}
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <input
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            type="file"
                            id={name}
                            className="input__file"
                            onChange={(event) => {
                                otherProps.onChange &&
                                    otherProps.onChange(event);
                            }}
                        />
                    )}
                />
                {errors && errors[`${name_validation || name}`] && (
                    <p className="text-danger text-min-sm">
                        {errors[`${name_validation || name}`].message}
                    </p>
                )}
            </div>

            <ReactTooltip
                id="help__tip--file"
                place="top"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
            />
        </>
    );
};

export default InputLabelFile;
