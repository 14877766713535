/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import ReactTooltip from 'react-tooltip';
import validation from './validation';
import GroupModal from '../../Group/Modal';
import Button from '../../../components/Button';
import api from '../../../services/api';
import FullPageLoader from '../../../components/FullPageLoader';
import {PeopleProps, GroupProps} from '../../../interfaces';
import SelectInputLabel from '../../../components/SelectInputLabel';
import InputLabelCPF from '../../../components/InputLabelCPF';
import InputLabelCNPJ from '../../../components/InputLabelCNPJ';
import InputLabel from '../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import InputPhoneLabel from '../../../components/InputLabelPhone';
import InputLabelCEP from '../../../components/InputLabelCEP';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import InputLabelTextArea from '../../../components/InputLabelTextArea';
import InputLabelCheckBox from '../../../components/InputLabelCheckBox';
import {useAuth} from '../../../hooks/Auth';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';

interface Props {
    data: PeopleProps;
    hide: () => void;
    refresh: () => void;
}

const ModalForm: React.FC<Props> = ({data, hide, refresh}) => {
    const [loading, setLoading] = useState(false);
    const [modalGroupVisible, setModalGroupVisible] = useState(false);
    const [refreshGroup, setRefreshGroup] = useState(0);
    const [groups, setGroups] = useState<GroupProps[]>([]);
    const [groupModalData, setGroupModalData] = useState<GroupProps>(
        {} as GroupProps
    );
    const {cities} = useAuth();

    const methods = useForm<PeopleProps>({
        resolver: yupResolver(validation)
    });
    const {handleSubmit, reset, setValue, getValues, watch} = methods;
    watch(['driver']);
    useEffect(() => {
        reset(data);
    }, [data]);

    useEffect(() => {
        api.get(
            `peopleGroups?page=1&limit=total&order=description&type=asc&description=`
        ).then((response) => {
            setGroups(response.data.data);
        });
    }, [refreshGroup]); // REFRESH GROUPS

    const onSubmit = useCallback(
        async (dataSubmit) => {
            setLoading(true);
            try {
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/people', {
                            ...dataSubmit,
                            log: {
                                name: dataSubmit.name,
                                cpf_cnpj: dataSubmit.cpf_cnpj
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/people/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                refresh();
                hide();
            } catch (err: any) {
                toast.error(err.response?.data?.errors[0]?.message);
            }
            setLoading(false);
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            showCloseButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar Pessoa'
                                    : data.form_action === 'edit'
                                    ? 'Alterar Pessoa'
                                    : 'Visualizar Pessoa'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'start'
                                    }}
                                >
                                    <SelectInputLabel
                                        required
                                        label="CPF/CNPJ"
                                        data_tip={helperTooltips.cpf_cnpj}
                                        name="type"
                                        size_label="2"
                                        size_input="2 smin-w-110"
                                        options={[
                                            {
                                                value: 1,
                                                label: 'CNPJ'
                                            },
                                            {
                                                value: 0,
                                                label: 'CPF'
                                            }
                                        ]}
                                        onChange={(option: any) => {
                                            setValue('cpf_cnpj', '');
                                            reset(getValues());
                                            setValue(
                                                'icms',
                                                option.value === 1 ? 1 : 2
                                            );
                                        }}
                                    />
                                    <div className="col-sm-4 resp-form">
                                        {getValues('type') === 0 ? (
                                            <InputLabelCPF
                                                label=""
                                                name="cpf_cnpj"
                                                size_label=""
                                                size_input="12"
                                                remove_label
                                            />
                                        ) : (
                                            <div className="input-group input-group-sm">
                                                <InputLabelCNPJ
                                                    label=""
                                                    name="cpf_cnpj"
                                                    data_tip={
                                                        helperTooltips.search_cnpj
                                                    }
                                                    size_label="2"
                                                    size_input="12 pr-0"
                                                    remove_label
                                                    cities={cities}
                                                    city_field="city_id"
                                                    setLoading={setLoading}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <InputLabel
                                        required={
                                            getValues('type') === 1 &&
                                            getValues('icms') === 1
                                        }
                                        label="IE"
                                        data_tip={helperTooltips.rg}
                                        onChange={(event) =>
                                            setValue(
                                                'rg_ie',
                                                event.target.value.toUpperCase()
                                            )
                                        }
                                        name="rg_ie"
                                        size_label="1"
                                        size_input="3"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'start'
                                    }}
                                >
                                    <InputLabel
                                        required
                                        label={
                                            getValues('type') === 0
                                                ? 'Nome'
                                                : 'Razão Social'
                                        }
                                        data_tip={
                                            getValues('type') === 0
                                                ? helperTooltips.name_people
                                                : helperTooltips.corporate_name
                                        }
                                        onBlur={(event) => {
                                            if (getValues('name') === '') {
                                                setValue(
                                                    'name',
                                                    event.target.value
                                                );
                                            }
                                        }}
                                        name="corporate_name"
                                        size_label="2"
                                        size_input="8"
                                    />

                                    <InputLabelCheckBox
                                        label="Motorista"
                                        name="driver"
                                        data_tip={helperTooltips.driver_check}
                                        data_tipLabel={
                                            helperTooltips.driver_vehicle
                                        }
                                        size_label="1 min-w-70"
                                        // onClick={() => reset(getValues())}
                                    />
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'start'
                                    }}
                                >
                                    <InputLabel
                                        required
                                        label={
                                            getValues('type') === 0
                                                ? 'Apelido'
                                                : 'Nome Fantasia'
                                        }
                                        data_tip={
                                            getValues('type') === 0
                                                ? helperTooltips.nick_people
                                                : helperTooltips.name
                                        }
                                        name="name"
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <SelectInputLabel
                                        required
                                        label="Situação"
                                        name="active"
                                        data_tip="Situação cadastral (ativo ou inativo)"
                                        size_label="2"
                                        size_input="2"
                                        options={[
                                            {
                                                value: true,
                                                label: 'Ativo'
                                            },
                                            {
                                                value: false,
                                                label: 'Inativo'
                                            }
                                        ]}
                                    />
                                    <InputPhoneLabel
                                        label="Telefone"
                                        name="phone"
                                        data_tip={helperTooltips.phone}
                                        size_label="1"
                                        size_input="3"
                                    />

                                    <InputPhoneLabel
                                        label="Celular"
                                        name="phone_2"
                                        data_tip={helperTooltips.cell_phone}
                                        size_label="1"
                                        size_input="3"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabelOnlyNumbers
                                        label="INSS"
                                        name="inss"
                                        data_tip={helperTooltips.inss}
                                        size_label="2"
                                        size_input="3"
                                    />

                                    <InputLabelOnlyNumbers
                                        label="NIT/PIS"
                                        name="nit_pis"
                                        data_tip={helperTooltips.nit_pis}
                                        size_label="1"
                                        size_input="2"
                                    />

                                    <InputLabelOnlyNumbers
                                        label="Suframa"
                                        name="suframa"
                                        data_tip={helperTooltips.suframa}
                                        size_label="1"
                                        size_input="3"
                                    />
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabel
                                        label="E-mail"
                                        name="email"
                                        data_tip={helperTooltips.email}
                                        size_label="2"
                                        size_input="4"
                                        type="email"
                                    />

                                    <InputLabel
                                        label="Site"
                                        name="site"
                                        data_tip={helperTooltips.website}
                                        size_label="1"
                                        size_input="5"
                                    />
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        justifyContent: 'start'
                                    }}
                                >
                                    <SelectInputLabel
                                        label="Grupo"
                                        name="people_group_id"
                                        size_label="2"
                                        size_input="4"
                                        data_tip={helperTooltips.select_group}
                                        options={groups.map((group) => ({
                                            value: group.id,
                                            label: group.description
                                        }))}
                                        isClearable
                                    />
                                    <div className="col-sm-2 text-right desktop">
                                        <EditIncludeButtons
                                            size="12"
                                            setLoading={setLoading}
                                            setModalData={setGroupModalData}
                                            setModalVisible={
                                                setModalGroupVisible
                                            }
                                            defaultValues={{
                                                desription: '',
                                                form_action: 'include'
                                            }}
                                            route="peopleGroups"
                                            field="people_group_id"
                                            tip="Grupo"
                                        />
                                    </div>
                                    <div className="col-sm-2 text-right mb-3 mobile">
                                        <EditIncludeButtons
                                            size="12"
                                            setLoading={setLoading}
                                            setModalData={setGroupModalData}
                                            setModalVisible={
                                                setModalGroupVisible
                                            }
                                            defaultValues={{desription: ''}}
                                            route="peopleGroups"
                                            field="people_group_id"
                                            tip="Grupo"
                                        />
                                    </div>
                                    <SelectInputLabel
                                        required
                                        label="ICMS"
                                        name="icms"
                                        data_tip={helperTooltips.icms}
                                        size_label="1"
                                        size_input="3"
                                        options={[
                                            {
                                                value: 1,
                                                label: 'Contribuinte ICMS'
                                            },
                                            {
                                                value: 2,
                                                label: 'Isento'
                                            },
                                            {
                                                value: 9,
                                                label: 'Não Contribuinte'
                                            }
                                        ]}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card card-primary card-outline card-outline-tabs mb-0 pb-0">
                                            <div className="card-header p-0 border-bottom-0">
                                                <ul
                                                    className="nav nav-tabs"
                                                    id="custom-tabs-for-tab"
                                                    role="tablist"
                                                >
                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link active"
                                                            id="custom-tabs-for-address-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-for-address"
                                                            role="tab"
                                                            aria-controls="custom-tabs-for-address"
                                                            aria-selected="true"
                                                        >
                                                            Endereço
                                                        </a>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a
                                                            className="nav-link"
                                                            id="custom-tabs-for-obs-tab"
                                                            data-toggle="pill"
                                                            href="#custom-tabs-for-obs"
                                                            role="tab"
                                                            aria-controls="custom-tabs-for-obs"
                                                            aria-selected="false"
                                                        >
                                                            Observações
                                                        </a>
                                                    </li>

                                                    {getValues('driver') && (
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="custom-tabs-for-driver-tab"
                                                                data-toggle="pill"
                                                                href="#custom-tabs-for-driver"
                                                                role="tab"
                                                                aria-controls="custom-tabs-for-driver"
                                                                aria-selected="false"
                                                            >
                                                                Motorista
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>

                                            <div className="card-body pb-2 pl-0 pr-1 min-h-150">
                                                <div className="tab-content">
                                                    <div
                                                        className="tab-pane fade active show"
                                                        id="custom-tabs-for-address"
                                                        role="tabpanel"
                                                        aria-labelledby="custom-tabs-for-address-tab"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between '
                                                            }}
                                                        >
                                                            <InputLabelCEP
                                                                required
                                                                label="CEP"
                                                                name="cep"
                                                                data_tip={
                                                                    helperTooltips.search_cep
                                                                }
                                                                data_tipLabel={
                                                                    helperTooltips.cep
                                                                }
                                                                size_label="2"
                                                                size_input="2"
                                                                cities={cities}
                                                                city_field="city_id"
                                                                setLoading={
                                                                    setLoading
                                                                }
                                                            />

                                                            <InputLabel
                                                                required
                                                                label="Logradouro"
                                                                data_tip={
                                                                    helperTooltips.address
                                                                }
                                                                name="address"
                                                                size_label="2"
                                                                size_input="6"
                                                            />
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >
                                                            <InputLabel
                                                                required
                                                                label="Número"
                                                                data_tip={
                                                                    helperTooltips.number
                                                                }
                                                                name="number"
                                                                size_label="2"
                                                                size_input="2"
                                                            />

                                                            <InputLabel
                                                                required
                                                                label="Bairro"
                                                                data_tip={
                                                                    helperTooltips.district
                                                                }
                                                                name="district"
                                                                size_label="2"
                                                                size_input="6"
                                                            />
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >
                                                            <InputLabel
                                                                label="Complemento"
                                                                name="complement"
                                                                data_tip={
                                                                    helperTooltips.complement
                                                                }
                                                                size_label="2"
                                                                size_input="2"
                                                            />

                                                            <AsyncSelectLabelCities
                                                                required
                                                                menuPlace="top"
                                                                label="Cidade"
                                                                name="city_id"
                                                                data_tip={
                                                                    helperTooltips.city
                                                                }
                                                                size_label="2"
                                                                size_input="6"
                                                                data={cities}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="tab-pane fade"
                                                        id="custom-tabs-for-obs"
                                                        role="tabpanel"
                                                        aria-labelledby="custom-tabs-for-obs-tab"
                                                    >
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >
                                                            <InputLabel
                                                                label="Pessoa p/ Contato"
                                                                name="contact"
                                                                data_tip={
                                                                    helperTooltips.people_contact
                                                                }
                                                                size_label="2"
                                                                size_input="10"
                                                            />
                                                        </div>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >
                                                            <InputLabelTextArea
                                                                label="Observação"
                                                                name="obs"
                                                                data_tip={
                                                                    helperTooltips.observation
                                                                }
                                                                size_label="2"
                                                                size_input="10"
                                                                rows={3}
                                                            />
                                                        </div>
                                                    </div>
                                                    {getValues('driver') && (
                                                        <div
                                                            className="tab-pane fade"
                                                            id="custom-tabs-for-driver"
                                                            role="tabpanel"
                                                            aria-labelledby="custom-tabs-for-driver-tab"
                                                        >
                                                            <div className="row">
                                                                <InputLabel
                                                                    label="CNH"
                                                                    name="cnh"
                                                                    data_tip={
                                                                        helperTooltips.cnh
                                                                    }
                                                                    size_label="2"
                                                                    size_input="4"
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <InputLabel
                                                                    label="Validade"
                                                                    name="expiration"
                                                                    data_tip={
                                                                        helperTooltips.validate_cnh
                                                                    }
                                                                    size_label="2"
                                                                    size_input="4"
                                                                    type="date"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading && <FullPageLoader />}
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            {data.form_action !== 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                    />

                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            )}
                            {data.form_action === 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={hide}
                                    />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {modalGroupVisible && (
                <GroupModal
                    data={groupModalData}
                    hide={() => setModalGroupVisible(false)}
                    refresh={() => setRefreshGroup(Math.random())}
                />
            )}
            <ReactTooltip
                id="help-tip"
                place="top"
                backgroundColor="#42494E"
                effect="solid"
                globalEventOff="click"
                delayShow={1800}
            />
        </Modal>
    );
};

export default ModalForm;
