import React from 'react';
import {Switch} from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import ConfirmUser from '../pages/ConfirmUser';
import Dashboard from '../pages/Dashboard';
import Contract from '../pages/Contract';
import NotFound from '../pages/NotFound';

import MonitorCTE from '../pages/MonitorCTE';
import MonitorMDFE from '../pages/MonitorMDFE';
import MonitorDFE from '../pages/MonitorDFE';
import People from '../pages/People';
import Group from '../pages/Group';
import Insurance from '../pages/Insurance';
import ServiceComposition from '../pages/ServiceComposition';
import Vehicle from '../pages/Vehicle';
import Profile from '../pages/Profile';
import Users from '../pages/Users';
import CFOP from '../pages/CFOP';
import {useAuth} from '../hooks/Auth';
import ContractModule from '../pages/ContractModule';

const Routes: React.FC = () => {
    const {user} = useAuth();
    return (
        <Switch>
            <Route path="/" exact component={SignIn} title="Entrar" />
            <Route
                path="/register"
                exact
                component={Register}
                title="Cadastrar"
            />
            <Route
                path="/forgotPassword"
                exact
                component={ForgotPassword}
                title="Redefinir Senha"
            />
            <Route
                path="/verify/:url"
                exact
                component={ConfirmUser}
                title="Confirmação de Usuário"
            />
            <Route
                path="/dashboard"
                exact
                component={Dashboard}
                isPrivate
                title="Painel de Informações"
            />

            {/* {user && // RETIRADO POIS AINDA NÃO FOI INTEGRADA A API DE PAGAMENTOS
                user.tenant.plan_id !== process.env.REACT_APP_LEGACY_PLAN && (
                    <Route
                        path="/contract"
                        exact
                        component={Contract}
                        isPrivate
                        isAdmin
                        title="Planos"
                    />
                )} */}

            <Route
                path="/monitorCTE"
                exact
                component={
                    user?.tenant?.mudule_cte ? MonitorCTE : ContractModule
                }
                isPrivate
                title="Monitor de CT-e"
            />
            {/* <Route
                path="/monitorCTEOS"
                exact
                component={MonitorCTEOS}
                isPrivate
                title="Monitor de CT-e OS"
            /> */}
            <Route
                path="/monitorDFE"
                exact
                component={
                    user?.tenant?.mudule_dfe ? MonitorDFE : ContractModule
                }
                isPrivate
                title="Monitor de DF-e"
            />
            <Route
                path="/monitorMDFE"
                exact
                component={
                    user?.tenant?.mudule_mdfe ? MonitorMDFE : ContractModule
                }
                isPrivate
                title="Monitor de MDF-e"
            />
            <Route
                path="/people"
                exact
                component={People}
                isPrivate
                title="Pessoas"
            />
            <Route
                path="/peopleGroups"
                exact
                component={Group}
                isPrivate
                title="Grupos"
            />
            <Route
                path="/serviceComposition"
                exact
                component={ServiceComposition}
                isPrivate
                title="Composição de Serviços"
            />
            <Route
                path="/vehicles"
                exact
                component={Vehicle}
                isPrivate
                title="Veículos"
            />
            <Route
                path="/profile"
                exact
                component={Profile}
                isPrivate
                title="Perfil"
            />
            {user?.admin && (
                <Route
                    path="/users"
                    exact
                    component={Users}
                    isPrivate
                    isAdmin
                    title="Usuários"
                />
            )}
            <Route
                path="/insurances"
                exact
                component={Insurance}
                isPrivate
                isAdmin
                title="Seguradoras"
            />
            <Route
                path="/cfops"
                exact
                component={CFOP}
                isPrivate
                isAdmin
                title="CFOP"
            />

            <Route
                component={NotFound}
                isPrivate
                title="Página não encontrada"
            />
        </Switch>
    );
};

export default Routes;
