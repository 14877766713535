import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import {toast} from 'react-toastify';
import {useForm, FormProvider} from 'react-hook-form';
import Button from '../../../components/Button';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';
import FullPageLoader from '../../../components/FullPageLoader';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import {PeopleProps, VehicleProps} from '../../../interfaces';
import InputLabelCurrency from '../../../components/InputLabelCurrency';
import {mdfe_situations} from '../../../data';

interface Props {
    hide: () => void;
}

const MdfReportModal: React.FC<Props> = ({hide}) => {
    const {user, cities} = useAuth();
    const [loading, setLoading] = useState(false);
    const [driver, setDriver] = useState<PeopleProps[]>([]);
    const [vehicle, setVehicle] = useState<VehicleProps[]>([]);

    useEffect(() => {
        setLoading(true);
        try {
            api.get(
                `peopleCombo?order=name&type=asc&driver=true`
            ).then((response) => setDriver(response.data));
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de pessoas');
        }
        setLoading(false);
    }, []); // GET PEOPLE

    useEffect(() => {
        setLoading(true);
        try {
            api.get(
                `vehiclesCombo?order=description&type=asc`
            ).then((response) => setVehicle(response.data));
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de veívulos');
        }
        setLoading(false);
    }, []); // GET VEHICLES

    const methods = useForm({
        defaultValues: {
            environment_inssuer_id: user.environment?.id,
            environment_type: user.environment?.mdfe_environment,
            license_plate: '',
            start_emission: '',
            end_emission: '',
            start_amount: 0,
            end_amount: 0,
            origin: '',
            destination: '',
            driver: '',
            situation: '',
            closed: null
        }
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset({
            environment_inssuer_id: user.environment?.id,
            environment_type: user.environment?.mdfe_environment,
            license_plate: '',
            start_emission: '',
            end_emission: '',
            start_amount: 0,
            end_amount: 0,
            origin: '',
            destination: '',
            driver: '',
            situation: '',
            closed: null
        });
    }, [user]);

    const onSubmit = useCallback(async (dataSubmit) => {
        setLoading(true);
        await api
            .post(`/mdfeReport`, dataSubmit, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/pdf'
                }
            })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            })
            .catch((error) => toast.error('Nenhum resultado encontrado'))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    function handleCancel() {
        hide();
        reset();
    }

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Relatório de Manifestos
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <SelectInputLabel
                                            label="Veículo"
                                            name="vehicle"
                                            data_tip="Veículo do manifesto"
                                            size_label="2"
                                            size_input="7"
                                            options={vehicle.map((item) => ({
                                                value: item.id,
                                                label: `${
                                                    item.license_plate
                                                } - ${item.description}${
                                                    item.driver?.name
                                                        ? ` - ${item.driver.name}`
                                                        : ''
                                                }`
                                            }))}
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <InputLabel
                                            label="Período"
                                            name="start_emission"
                                            data_tip="Data inicial de emissão"
                                            size_label="2"
                                            type="date"
                                            size_input="3"
                                        />
                                        <InputLabel
                                            label="Até"
                                            name="end_emission"
                                            data_tip="Data final de emissão"
                                            size_label="1"
                                            size_input="3"
                                            type="date"
                                        />
                                    </div>
                                    <div className="row">
                                        <InputLabelCurrency
                                            label="Valor"
                                            name="start_amount"
                                            data_tip="Valor mínimo"
                                            size_label="2"
                                            size_input="3"
                                        />
                                        <InputLabelCurrency
                                            label="Até"
                                            name="end_amount"
                                            data_tip="Valor máximo"
                                            size_label="1"
                                            size_input="3"
                                        />
                                    </div>
                                    <div className="row">
                                        <AsyncSelectLabelCities
                                            menuHeight={110}
                                            data={cities}
                                            label="Origem"
                                            name="origin"
                                            data_tip="Cidade de origem"
                                            size_label="2"
                                            size_input="4"
                                            isClearable
                                        />
                                        <AsyncSelectLabelCities
                                            menuHeight={110}
                                            data={cities}
                                            label="Destino"
                                            name="destination"
                                            data_tip="Cidade de destino"
                                            size_label="2"
                                            size_input="4"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuPlace="top"
                                            menuHeight={120}
                                            options={driver.map((item) => ({
                                                value: item.id,
                                                label: item.name_combo || ''
                                            }))}
                                            label="Motorista"
                                            name="driver"
                                            data_tip="Motorista responsável pelo transporte"
                                            size_label="2"
                                            size_input="10"
                                            isClearable
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuPlace="top"
                                            label="Situação"
                                            name="situation"
                                            data_tip="Situação do manifesto"
                                            size_label="2"
                                            size_input="10"
                                            options={mdfe_situations}
                                        />
                                    </div>
                                    <div className="row">
                                        <SelectInputLabel
                                            menuPlace="top"
                                            label="Encerrado"
                                            name="closed"
                                            data_tip="O encerramento marca como finalizado o
                                            transporte da mercadoria"
                                            size_label="2"
                                            size_input="10"
                                            options={[
                                                {
                                                    value: null,
                                                    label: 'QUALQUER'
                                                },
                                                {
                                                    value: true,
                                                    label: 'ENCERRADO'
                                                },
                                                {
                                                    value: false,
                                                    label: 'NÃO ENCERRADO'
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-print"
                                            caption="Gerar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
                {loading && <FullPageLoader />}
            </Modal>
        </>
    );
};

export default MdfReportModal;
