/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import {ColumnsProps, TableOrder} from '../../interfaces';

interface tableProps {
    id: string;
    columns: ColumnsProps[];
    setOrder?: React.Dispatch<React.SetStateAction<TableOrder>>;
    order?: string;
    type?: 'asc' | 'desc';
}

const Table: React.FC<tableProps> = ({
    id,
    columns,
    children,
    setOrder,
    order,
    type
}) => {
    return (
        <>
            <table
                className="table table-hover table-bordered text-nowrap m-0"
                id={id}
            >
                <thead className="bg-color-gray">
                    <tr>
                        {columns.map((column) => (
                            <th
                                key={column.text}
                                className={column.className}
                                style={{width: column.width}}
                                onClick={() => {
                                    if (
                                        column.hasOrder &&
                                        column.name &&
                                        setOrder &&
                                        setOrder
                                    ) {
                                        setOrder({
                                            order: column.name,
                                            type:
                                                type === 'desc' ? 'asc' : 'desc'
                                        });
                                    }
                                }}
                            >
                                {column.hasOrder ? (
                                    <>
                                        {column.text}
                                        <a className="link-button ml-2">
                                            {column.name === order ? (
                                                type === 'desc' ? (
                                                    <i
                                                        className="fas fa-sort-down table-ordered"
                                                        data-tip="Ordernar em ordem decrescente"
                                                        data-for="order-dark-right"
                                                    />
                                                ) : (
                                                    <i
                                                        className="fas fa-sort-up table-ordered"
                                                        data-tip="Ordernar em ordem crescente"
                                                        data-for="order-dark-right"
                                                    />
                                                )
                                            ) : (
                                                <i
                                                    className="fas fa-sort table-ordered disabled"
                                                    data-tip="Ordernar em ordem crescente"
                                                    data-for="order-dark-right"
                                                />
                                            )}
                                        </a>
                                    </>
                                ) : (
                                    column.text
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="table-sm">{children}</tbody>
            </table>

            <ReactTooltip
                id="order-dark-right"
                place="right"
                backgroundColor="#42494E"
                effect="solid"
            />
        </>
    );
};

export default Table;
