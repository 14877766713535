import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import Button from '../../../components/Button';
import InputLabel from '../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';
import FullPageLoader from '../../../components/FullPageLoader';
import helperTooltips from '../../../data/help';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';

interface Props {
    hide: () => void;
}

interface FormProps {
    key: string;
    protocol: string;
    date: string;
    city_id: number;
}

const validations = Yup.object().shape({
    key: Yup.string().required('Campo obrigatório').nullable(),
    protocol: Yup.string().required('Campo obrigatório').nullable(),
    date: Yup.string().required('Campo obrigatório').nullable(),
    city_id: Yup.number()
        .required('Campo obrigatório')
        .min(1, 'Campo obrigatório')
        .nullable()
});

const CloseMdfeModal: React.FC<Props> = ({hide}) => {
    const [loading, setLoading] = useState(false);
    const {user, cities} = useAuth();

    const defaultValues: FormProps = {
        key: '',
        protocol: '',
        date: '',
        city_id: 0
    };

    const methods = useForm<FormProps>({
        resolver: yupResolver(validations),
        defaultValues
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(defaultValues);
    }, []);

    const onSubmit = useCallback(
        async (dataSubmit: FormProps) => {
            setLoading(true);
            await api
                .post('closeThirdPartyMdfe', dataSubmit)
                .then((response) => {
                    toast.success(response.data);
                    hide();
                })
                .catch((error) =>
                    toast.error(
                        error.response?.data || error || 'Erro inesperado'
                    )
                )
                .finally(() => setLoading(false));
        },
        [user]
    );

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="sm" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                Encerrar MDF-e emitido em sistema de terceiro
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Chave"
                                        name="key"
                                        data_tip="Chave do MDF-e a ser encerrado"
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Protocolo"
                                        name="protocol"
                                        data_tip="Protocolo de envio do MDF-e a ser encerrado"
                                        size_label="2"
                                        size_input="4"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Data"
                                        name="date"
                                        data_tip="Data do encerramento do MDF-e"
                                        size_label="2"
                                        size_input="4"
                                        type="date"
                                    />
                                </div>
                                <div className="row">
                                    <AsyncSelectLabelCities
                                        required
                                        noSizeSelect
                                        label="Cidade Término"
                                        name="city_id"
                                        data_tip="Cidade de término do MDF-e (último descarregamento)"
                                        size_label="2"
                                        size_input="6"
                                        data={cities}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-paper-plane"
                                        caption="Enviar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default CloseMdfeModal;
