import React, {useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import Button from '../../components/Button';
import logo from '../../assets/logo_cte.png';
import logo_natal from '../../assets/logo_cte_natal.png';
import api from '../../services/api';
import InputGroup from '../../components/InputGroup';

const ForgotPassword: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const forgotPassword = (values: {email: string}) => {
        setLoading(true);
        api.post('forgotPassword', values)
            .then((response) => {
                toast.info(response.data);
                history.push('/');
            })
            .catch((error) => {
                toast.error(
                    error.response?.data ||
                        error ||
                        'Ocorreu um erro ao redefinir a senha, verifique sua conexão e tente novamente'
                );
            })
            .finally(() => setLoading(false));
    };

    const methods = useForm<{email: string}>({
        resolver: yupResolver(
            Yup.object({
                email: Yup.string()
                    .email('Endereço de e-mail inválido')
                    .required('Insira seu e-mail')
            })
        ),
        defaultValues: {
            email: ''
        }
    });
    const {handleSubmit} = methods;

    return (
        <body className="login-page">
            <div style={{height: '100%', width: '100%', position: 'fixed'}}>
                <svg
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    style={{height: '100%', width: '100%'}}
                >
                    <path
                        d="M-5.92,121.88 C152.09,134.70 405.47,59.70 523.98,108.05 L508.74,167.27 L0.00,150.00 Z"
                        style={{
                            stroke: 'none',
                            fill:
                                new Date().getMonth() === 11
                                    ? '#c0392b'
                                    : '#08f'
                        }}
                    />
                </svg>
            </div>
            <div className="login-box">
                <div className="card">
                    <div className="card-body login-card-body">
                        <div className="text-min-sm mb-4">
                            <Link to="/">
                                <i
                                    className="fas fa-arrow-left"
                                    style={{
                                        color:
                                            new Date().getMonth() === 11
                                                ? '#c0392b'
                                                : ''
                                    }}
                                />
                                <strong
                                    className="tab-left"
                                    style={{
                                        color:
                                            new Date().getMonth() === 11
                                                ? '#c0392b'
                                                : ''
                                    }}
                                >
                                    Retornar
                                </strong>
                            </Link>
                        </div>
                        <div className="login-logo">
                            <img
                                src={
                                    new Date().getMonth() === 11
                                        ? logo_natal
                                        : logo
                                }
                                style={{width: 225}}
                                alt="MS1 Sistemas"
                            />
                        </div>
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(forgotPassword)}
                            >
                                <div className="mt-5 mb-3">
                                    <p className="login-box-msg">
                                        Esqueceu sua senha? Insira seu e-mail
                                        para receber uma nova.
                                    </p>
                                    <InputGroup
                                        name="email"
                                        placeholder="E-mail"
                                        icon="fas fa-envelope"
                                    />
                                </div>
                                <div className="col-13">
                                    <Button
                                        caption="Enviar"
                                        type="submit"
                                        color="primary"
                                        size="btn-block"
                                        border={true}
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </body>
    );
};

export default ForgotPassword;
