const columns = [
  {text: 'CNPJ', width: '15%', className: 'text-max-sm'},
  {text: 'Razão Social', width: "55%", className: 'text-max-sm'},
  {text: 'CT-e', width: "10%", className: 'text-max-sm'},
  {text: 'MDF-e', width: "10%", className: 'text-max-sm'},
  {text: 'Selecionar', width: "10%", className: 'text-max-sm'},
  {text: 'Ações', className: ' text-center text-max-sm'}
];

export default columns;
