import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import SelectInputLabel from '../../../components/SelectInputLabel';
import InputLabel from '../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import Table from '../../../components/Table';
import api from '../../../services/api';
import {correctionLetterGroups} from '../../../data';
import getCorrectionLetterFields from '../../../utils/getCorrectionLetterFields';
import openPdfFromBase64 from '../../../utils/openPdfFromBase64';

interface Props {
    hide: () => void;
    id: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Correction {
    group: string;
    field: string;
    value: string;
    item?: string;
}

interface CorrectionProps extends Correction {
    data: Correction[];
}

const validations = Yup.object().shape({
    group: Yup.string().required('Campo obrigatório'),
    field: Yup.string().required('Campo obrigatório'),
    value: Yup.string().required('Campo obrigatório'),
    item: Yup.string()
        .test(
            'item',
            'Caso preenchido, o identificador do item deve conter no mínimo 2 dígitos. Exemplo: 01, 02, 03...',
            (value) => {
                if (value) {
                    return value?.length >= 2;
                }
                return true;
            }
        )
        .not(
            ['00'],
            '00 é um número inválido, caso o item não possua um número, deixe o campo vazio'
        )
});

const CorrectionLetterModal: React.FC<Props> = ({hide, id, setLoading}) => {
    const [fieldOptions, setFieldOptions] = useState<
        {label: string; value: string}[]
    >([]);

    const methods = useForm<CorrectionProps>({
        resolver: yupResolver(validations),
        defaultValues: {group: '', field: '', value: '', item: '', data: []}
    });
    const {handleSubmit, getValues, setValue, reset} = methods;

    const onSubmit = useCallback((dataSubmit: CorrectionProps) => {
        setValue('data', [
            ...getValues('data'),
            {
                ...dataSubmit
            }
        ]);
    }, []);

    async function sendCorrectionLetter() {
        if (getValues('data').length !== 0) {
            setLoading(true);
            await api
                .post('correctionCtesNS', {
                    id,
                    data: getValues('data')
                })
                .then((response) => {
                    toast.success(response.data.retEvento.xMotivo);
                    openPdfFromBase64(response.data.pdf);
                    hide();
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro ao enviar carta de correção',
                        text: error.response?.data || error
                    });
                })
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Inclua os campos da Carta de Correção antes de enviar'
            });
        }
    }

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Carta de Correção</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Grupo alterado"
                                        data_tip="Grupo de informações do campo que será alterado"
                                        name="group"
                                        noSizeSelect
                                        size_label="2"
                                        size_input="10"
                                        options={correctionLetterGroups.map(
                                            (item) => ({
                                                label: item.label,
                                                value: item.value
                                            })
                                        )}
                                        onChange={(option: any) => {
                                            setFieldOptions(
                                                getCorrectionLetterFields(
                                                    option.value
                                                )
                                            );
                                            setValue('field', '');
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Campo alterado"
                                        name="field"
                                        data_tip="Campo que será alterado"
                                        size_label="2"
                                        size_input="10"
                                        noSizeSelect
                                        options={[
                                            {label: '', value: ''},
                                            ...fieldOptions.map((item) => ({
                                                label: item.label,
                                                value: item.value
                                            }))
                                        ]}
                                        isDisabled={fieldOptions.length === 0}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Valor"
                                        name="value"
                                        data_tip="Novo Valor do campo"
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        label="Item"
                                        name="item"
                                        data_tip="Número do item a ser alterado, deve conter no mínimo 2 dígitos.<br />Para números de apenas uma casa, preencher desta maneira: 01, 02, etc.<br /> Caso o item seja único, deixe o campo vazio."
                                        size_label="2"
                                        size_input="2"
                                    />
                                    <div className="col-sm-8 text-right">
                                        <button
                                            type="submit"
                                            className="btn btn-default btn-sm"
                                        >
                                            <i className="fa fa-plus mr-1 c-success" />
                                            Incluir
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <Table
                                id="mdfe"
                                columns={[
                                    {
                                        text: 'Grupo',
                                        width: '30%',
                                        className: 'text-max-sm'
                                    },
                                    {
                                        text: 'Campo',
                                        width: '30% ',
                                        className: 'text-max-sm'
                                    },
                                    {
                                        text: 'Valor',
                                        width: '40% ',
                                        className: 'text-max-sm'
                                    },
                                    {
                                        text: 'Item',
                                        className: 'text-max-sm'
                                    },
                                    {
                                        text: 'Remover',
                                        className: 'text-center'
                                    }
                                ]}
                            >
                                {getValues('data').map(
                                    (row: any, index: number) => (
                                        <tr>
                                            <td className="pt-2">
                                                {
                                                    correctionLetterGroups.find(
                                                        (item) =>
                                                            item.value ===
                                                            row.group
                                                    )?.label
                                                }
                                            </td>
                                            <td className="pt-2">
                                                {
                                                    getCorrectionLetterFields(
                                                        row.group
                                                    ).find(
                                                        (group) =>
                                                            group.value ===
                                                            row.field
                                                    )?.label
                                                }
                                            </td>
                                            <td className="pt-2">
                                                {row.value}
                                            </td>
                                            <td className="pt-2">{row.item}</td>
                                            <td className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-xs"
                                                    onClick={() => {
                                                        const newData = getValues(
                                                            'data'
                                                        );
                                                        newData.splice(
                                                            index,
                                                            1
                                                        );
                                                        reset({
                                                            ...getValues(),
                                                            data: newData
                                                        });
                                                    }}
                                                >
                                                    <i className="fas fa-trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </Table>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                            setFieldOptions([]);
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Enviar"
                                        onClick={() => {
                                            sendCorrectionLetter();
                                        }}
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default CorrectionLetterModal;
