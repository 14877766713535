import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import cpf from 'cpf';
import {validate} from 'cnpj';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import {CFOPProps, EnvironmentProps} from '../../../interfaces';
import InputLabelMeasurement from '../../../components/InputLabelMeasurement';
import FullPageLoader from '../../../components/FullPageLoader';
import getChanges from '../../../utils/getChanges';
import InputLabelCheckBox from '../../../components/InputLabelCheckBox';
import InputLabelCNPJnoSearch from '../../../components/InputLabelCNPJnoSearch';
import InputLabelCPF from '../../../components/InputLabelCPF';
import PaginatedAsyncSelectLabel from '../../../components/PaginatedAsyncSelectLabel';

interface Props {
    hide: () => void;
}

const validation = Yup.object({
    config_contractor: Yup.string()
        .nullable()
        .when('config_contractor_type', {
            is: 0,
            then: Yup.string()
                .nullable()
                .test('config_contractor', 'CPF inválido.', (value) => {
                    if (value) {
                        return cpf.isValid(value);
                    }
                    return true;
                })
        })
        .when('config_contractor_type', {
            is: 1,
            then: Yup.string()
                .nullable()
                .test('config_contractor', 'CNPJ inválido.', (value) => {
                    if (value) {
                        return validate(value);
                    }
                    return true;
                })
        })
});

const ModalConfig: React.FC<Props> = ({hide}) => {
    const {user, setEnvironment} = useAuth();

    const [cfop, setCFOP] = useState<CFOPProps[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        try {
            api.get(
                `cfops?page=1&limit=total&order=cfop&type=asc&search=`
            ).then((response) => {
                setCFOP(response.data.data);
            });
        } catch (error) {
            toast.error('Não foi possível realizar a consulta de CFOP');
        }
        setLoading(false);
    }, []); // GET CFOP's

    const methods = useForm<EnvironmentProps>({
        defaultValues: user.environment,
        resolver: yupResolver(validation)
    });

    const {handleSubmit, reset, getValues, setValue, watch} = methods;
    watch('config_contractor_type');
    useEffect(() => {
        reset(user.environment);
    }, [user.environment]);

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback(
        (dataSubmit: EnvironmentProps) => {
            setIsLoading(true);
            const log = getChanges(user.environment, dataSubmit);
            api.put(`/environments/${user.environment?.id}`, {
                ...dataSubmit,
                certificate: null,
                logo: null,
                insurance: JSON.stringify(user.environment?.insurance),
                log
            })
                .then(() => {
                    setEnvironment({...user.environment, ...dataSubmit});
                    toast.success('Suas configurações foram alteradas');
                    hide();
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(
                        error.response?.data?.errors[0]?.message ||
                            error.response?.data ||
                            error ||
                            'Erro inesperado'
                    );
                })
                .finally(() => setIsLoading(false));
        },
        [user]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }
    return (
        <Modal show dialogAs="div">
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Configurações de Emissão</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <SelectInputLabel
                                    label="Contratante"
                                    data_tip="CNPJ/CPF de quem contratou o serviço de transporte. <br> Seu próprio CNPJ caso seja carga própia"
                                    name="config_contractor_type"
                                    size_label="2"
                                    size_input="2"
                                    noSizeSelect
                                    options={[
                                        {
                                            value: 1,
                                            label: 'CNPJ'
                                        },
                                        {
                                            value: 0,
                                            label: 'CPF'
                                        }
                                    ]}
                                    onChange={() => {
                                        setValue('config_contractor', '');
                                    }}
                                />
                                <div className="col-sm-8 resp-form">
                                    {getValues('config_contractor_type') ===
                                    1 ? (
                                        <InputLabelCNPJnoSearch
                                            label=""
                                            name="config_contractor"
                                            size_label=""
                                            size_input="12 pr-0"
                                            remove_label
                                        />
                                    ) : (
                                        <InputLabelCPF
                                            label=""
                                            name="config_contractor"
                                            size_label=""
                                            size_input="12"
                                            remove_label
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <PaginatedAsyncSelectLabel
                                    menuHeight={150}
                                    label="Remetente"
                                    name="config_sender"
                                    size_label="2"
                                    size_input="10"
                                    optionSearch="name_search"
                                    optionLabel="name_combo"
                                />
                            </div>
                            <div className="row">
                                <SelectInputLabel
                                    menuHeight={150}
                                    label="CFOP"
                                    name="config_cfop"
                                    size_label="2"
                                    size_input="10"
                                    options={cfop.map((item) => ({
                                        value: item.id,
                                        label: `${item.cfop} - ${item.description}`
                                    }))}
                                />
                            </div>
                            <div className="row">
                                <SelectInputLabel
                                    menuHeight={110}
                                    label="Tomador"
                                    name="config_taker"
                                    size_label="2"
                                    size_input="3"
                                    options={[
                                        {
                                            value: 0,
                                            label: 'Remetente'
                                        },
                                        {
                                            value: 1,
                                            label: 'Expedidor'
                                        },
                                        {
                                            value: 2,
                                            label: 'Recebedor'
                                        },
                                        {
                                            value: 3,
                                            label: 'Destinatário'
                                        },
                                        {
                                            value: 4,
                                            label: 'Outros'
                                        }
                                    ]}
                                    noSizeSelect
                                    isClearable
                                />

                                <InputLabelMeasurement
                                    label="Dias prev. entrega"
                                    name="config_days_deliver"
                                    size_label="2"
                                    size_input="1"
                                    precision={0}
                                />
                                <InputLabelMeasurement
                                    label="Dias vencimento"
                                    name="config_days_due_date"
                                    size_label="2"
                                    size_input="1"
                                    precision={0}
                                />
                            </div>
                            <div className="row">
                                <SelectInputLabel
                                    label="Forma de pgto."
                                    name="config_payment_form"
                                    size_label="2"
                                    size_input="3"
                                    options={[
                                        {
                                            value: 1,
                                            label: 'Pago'
                                        },
                                        {
                                            value: 2,
                                            label: 'À pagar'
                                        },
                                        {
                                            value: 3,
                                            label: 'Outros'
                                        }
                                    ]}
                                    isClearable
                                    noSizeSelect
                                />
                                <SelectInputLabel
                                    label="Unidade de medida"
                                    name="config_measurement_unity"
                                    size_label="2"
                                    size_input="3"
                                    options={[
                                        {value: '00', label: 'M³'},
                                        {value: '01', label: 'KG'},
                                        {value: '02', label: 'TON'},
                                        {value: '03', label: 'UN'},
                                        {value: '04', label: 'LITROS'},
                                        {value: '05', label: 'MMBTU'}
                                    ]}
                                    isClearable
                                    noSizeSelect
                                />
                            </div>
                            <div className="row">
                                <InputLabel
                                    label="Prod. predomin."
                                    name="config_predominant_product"
                                    size_label="2"
                                    size_input="10"
                                />
                            </div>
                            <div className="row">
                                <InputLabelCheckBox
                                    label="Imprimir documentos automaticamente após envio"
                                    name="config_print_documents"
                                    size_label="5"
                                />
                            </div>
                            <div className="row">
                                <InputLabelCheckBox
                                    label="Exibir aba de veículos/motoristas no cadastro de CTE"
                                    name="config_show_motorists_vehicles"
                                    size_label="5"
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left">
                                    <Button
                                        type="button"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default ModalConfig;
