import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import cpf from 'cpf';
import {validate} from 'cnpj';
import Button from '../../../../components/Button';
import {MDFeProps} from '../../../../interfaces';
import SelectInputLabel from '../../../../components/SelectInputLabel';
import InputLabelCNPJnoSearch from '../../../../components/InputLabelCNPJnoSearch';
import InputLabelCPF from '../../../../components/InputLabelCPF';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<MDFeProps>;
    setTableValue: UseFormSetValue<MDFeProps>;
}

interface Contractor {
    contractor_type: number;
    contractor_cpf_cnpj: string;
}

const validations = Yup.object({
    contractor_cpf_cnpj: Yup.string()
        .required('CPF/CNPJ Obrigatório.')
        .when('contractor_type', {
            is: 0,
            then: Yup.string().test('cpf_cnpj', 'CPF inválido.', (value) => {
                if (value && value !== '00.000.000/0000-00') {
                    return cpf.isValid(value);
                }
                return true;
            })
        })
        .when('contractor_type', {
            is: 1,
            then: Yup.string().test('cpf_cnpj', 'CNPJ inválido.', (value) => {
                if (value) {
                    return validate(value);
                }
                return true;
            })
        })
});

const ContractorModal: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue
}) => {
    const methods = useForm<Contractor>({
        resolver: yupResolver(validations),
        defaultValues: {
            contractor_type: 1,
            contractor_cpf_cnpj: ''
        }
    });

    const {handleSubmit, getValues, setValue, watch} = methods;
    watch('contractor_type');

    const onSubmit = useCallback((dataSubmit: Contractor) => {
        let equivalent = false;
        getTableValues('contractor').every((item) => {
            equivalent = item === dataSubmit.contractor_cpf_cnpj;
            return !equivalent;
        });
        if (!equivalent) {
            setTableValue('contractor', [
                ...getTableValues().contractor,
                dataSubmit.contractor_cpf_cnpj
            ]);
            hide();
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'Já existe um documento registrado com esses mesmos dados.'
            });
        }
    }, []);

    return (
        <>
            <Modal show dialogAs="div" centered>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                        id="maxsize__select"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Cadastrar informação de contratante
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <SelectInputLabel
                                            label="CPF/CNPJ (contratante)"
                                            required
                                            data_tip="CNPJ/CPF de quem contratou o serviço de transporte. <br> Seu próprio CNPJ caso seja carga própia"
                                            name="contractor_type"
                                            size_label="3"
                                            size_input="2"
                                            noSizeSelect
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'CNPJ'
                                                },
                                                {
                                                    value: 0,
                                                    label: 'CPF'
                                                }
                                            ]}
                                            onChange={(option: any) => {
                                                setValue(
                                                    'contractor_cpf_cnpj',
                                                    ''
                                                );
                                            }}
                                            isDisabled={
                                                getTableValues(
                                                    'form_action'
                                                ) === 'view'
                                            }
                                        />
                                        {getValues('contractor_type') === 1 ? (
                                            <InputLabelCNPJnoSearch
                                                label=""
                                                name="contractor_cpf_cnpj"
                                                size_label=""
                                                size_input="4"
                                                remove_label
                                                disabled={
                                                    getTableValues(
                                                        'form_action'
                                                    ) === 'view'
                                                }
                                            />
                                        ) : (
                                            <InputLabelCPF
                                                label=""
                                                name="contractor_cpf_cnpj"
                                                size_label=""
                                                size_input="4"
                                                remove_label
                                                disabled={
                                                    getTableValues(
                                                        'form_action'
                                                    ) === 'view'
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={() => hide()}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default ContractorModal;
