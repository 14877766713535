import {toast} from 'react-toastify';
import api from './api';

type legacyTypes =
    | 'legacyPeople'
    | 'legacyGroups'
    | 'legacyCfops'
    | 'legacyVehicles'
    | 'legacyServices';

async function importLegacyJSON(
    json: string,
    type: legacyTypes,
    setLoading: (loading: React.SetStateAction<boolean>) => void,
    refresh: () => void
) {
    setLoading(true);
    api.post(type, {
        legacyData: JSON.parse(json)
    })
        .then((response) => toast.success(response.data))
        .catch(
            (error) => toast.error(error.response?.data) || 'Erro na importação'
        )
        .finally(() => {
            setLoading(false);
            refresh();
        });
}

export default importLegacyJSON;
