/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */

import isEquivalent from './isEquivalent';

/**
 * VERIFICA AS DIFERENÇAS ENTRE DOIS OBJETOS E RETORNA QUAIS CAMPOS FORAM ALTERADOS, VALOR INICIAL E VALOR FINAL (COMPRIMENTO EM CASO DE ARRAY)
 * @param a PRIMEIRO OBJETO
 * @param b SEGUNDO OBJETO
 */
function getChanges(a: any, b: any) {
    const props = Object.getOwnPropertyNames(b); // PEGA AS PRORPIEDADES DO OBJETO
    const changedFields: any[] = []; // ARRAY COM AS MUDANÇAS QUE SERÁ RETORNADO

    for (let i = 0; i < props.length; i++) {
        const prop = props[i]; // RECEBE O NOME DE UMA PROPRIEDADE

        if (
            // VERIFICAÇÃO DE ARRAY PARA CAMPOS MULTIPLOS
            typeof a[prop] === 'object' &&
            typeof b[prop] === 'object' &&
            a[prop] !== null &&
            b[prop] !== null &&
            b[prop] !== undefined
        ) {
            const bigger = // PEGA O TAMANHO DO MAIOR PARA VERIFICAR AS DIFERENÇAS
                a[prop].length > b[prop].length
                    ? a[prop].length
                    : b[prop].length;
            for (let l = 0; l < bigger; l++) {
                if (
                    // VERIFICA SE O CAMPO É UM OBJETO
                    typeof a[prop][l] === 'object' &&
                    typeof b[prop][l] === 'object'
                ) {
                    if (!isEquivalent(a[prop][l], b[prop][l])) {
                        changedFields.push([
                            `${prop}`,
                            `${a[prop].length}`,
                            `${b[prop].length}`
                        ]);
                        break;
                    }
                } else if (a[prop][l] != b[prop][l]) {
                    changedFields.push([
                        `${prop}`,
                        a[prop].length,
                        b[prop].length
                    ]);
                    break;
                }
            }
        } else if (a[prop] != b[prop]) {
            changedFields.push([`${prop}`, a[prop], b[prop]]);
        }
    }
    return changedFields;
}

export default getChanges;
