const columns = [
    {
        text: 'Data',
        className: 'text-max-sm link-button',
        name: 'date',
        hasOrder: true
    },
    {
        text: 'Hora',
        className: 'text-max-sm'
    },
    {
        text: 'Usuário',
        width: '20%',
        className: 'text-max-sm link-button',
        name: 'user_id',
        hasOrder: true
    },
    {
        text: 'Evento',
        width: '15%',
        className: 'text-max-sm link-button',
        name: 'event',
        hasOrder: true
    },
    {
        text: 'Tabela',
        width: '15%',
        className: 'text-max-sm link-button',
        name: 'table',
        hasOrder: true
    },
    {
        text: 'Decrição',
        width: '50%',
        className: 'text-max-sm'
    }
];

export default columns;
