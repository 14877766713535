/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
const brandColor = '#80bdff';

export const styles = {
    control: (base: any, state: {isFocused: any}) => ({
        ...base,
        height: 31,
        minHeight: 31,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? brandColor : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused ? brandColor : base.borderColor
        }
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0
    }),
    clearIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0
    }),
    menuPortal: (base: any) => ({...base, zIndex: 9999})
};

export const stylesMulti = {
    control: (base: any, state: {isFocused: any}) => ({
        ...base,
        minHeight: 31,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? brandColor : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused ? brandColor : base.borderColor
        }
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0
    }),
    clearIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0
    }),
    menuPortal: (base: any) => ({...base, zIndex: 9999})
};
