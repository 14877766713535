import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CityProps} from '../interfaces';
/* eslint-disable @typescript-eslint/no-unused-expressions */

async function searchCEP(
    text: string | undefined,
    setValue: UseFormSetValue<FieldValues>,
    setLoading: (loadind: boolean) => void,
    data: CityProps[],
    cityField: string,
    type?: 'company' | 'responsible'
): Promise<void> {
    setLoading(true);

    if (text !== undefined && text !== null) {
        const cep = text.replace(/[^0-9]/g, '');

        if (cep.length === 8) {
            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then((res) => res.json())
                .then((dataCep) => {
                    if (type === 'responsible') {
                        setValue('responsible_address', dataCep.logradouro);
                        setValue('responsible_district', dataCep.bairro);
                    } else {
                        setValue('address', dataCep.logradouro);
                        setValue('district', dataCep.bairro);
                    }
                    const city = data.find(
                        // eslint-disable-next-line eqeqeq
                        (item) => dataCep.ibge == item.ibge
                    );
                    setValue(cityField, city?.id);
                })
                .catch(() => toast.error('CEP não encontrado'));
        }
    }
    setLoading(false);
}

export default searchCEP;
