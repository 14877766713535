const helperTooltips = {
    cnpj: 'Cadastro Nacional da Pessoas Jurídicas.',
    cpf_cnpj:
        'Selecione o CNPJ para pessoa jurídica <br /> ou CPF para pessoa física.',
    cpf: 'Cadastro de Pessoas Físicas',
    search_cnpj: 'Preencher os campos com o CNPJ informado.',
    ie:
        'Inscrição Estadual. Documento obrigatório para prestadoras<br />de serviço de transporte insterestadual ou intermunicipal.<br />Em caso de isenção, preencha com "ISENTO".',
    im: 'Inscrição Municipal.',
    rg: 'Registro Geral.',
    corporate_name: 'Nome de registro da empresa.',
    name_people: 'Digite seu nome completo.',
    name:
        'Nome de fachada ou marca empresarial <br /> (Nome popular da empresa).',
    nick_people: 'Nome particular, caso não tenha, seu nome.',
    driver_check: 'Clique aqui caso seja um motorista.',
    inss: 'Instituto Nacional do Seguro Social.',
    nit_pis:
        'Número de Registro do Trabalhador <br /> ou Programa de Integração Social.',
    suframa: 'Superintendência da Zona Franca de Manaus.',
    phone: 'Número de telefone fixo com DDD.',
    cell_phone: 'Número de celular com DDD.',
    fax: 'Número do fax.',
    email: 'Email de contato da pessoa/Empresa.',
    website: 'Site da empresa.',
    icms:
        'Imposto sobre Circulação de Mercadorias <br /> e Prestação de Serviços de Transporte Interestadual <br /> e Intermunicipal e de Comunicação.',
    select_group: 'Selecione o grupo referente a pessoa.',
    search_cep: 'Preencher os campos de endereço com o CEP informado.',
    cep: 'Código de Endereçamento Postal.',
    address: 'Endereço completo.',
    number: 'Número do endereço.',
    district: 'Bairro do endereço.',
    complement: 'Número do apartamento, bloco...',
    city: 'Cidade da pessoa ou empresa.',
    people_contact: 'Nome da pessoa responsável para contato.',
    observation: 'Insira informações adicionais.',
    cnh: 'Carteira Nacional de Habilitação.',
    validate_cnh: 'Validade da CNH.',
    description_group: 'Nome do Grupo.',
    action_services: 'Ações dos tipos de cálculos.',
    description_services: 'Nome da composição de serviços.',
    multiply_charge: 'Cálculo multiplicado pela carga.',
    pis: 'Alíquota PIS.',
    cofins: 'Alíquota Cofins.',
    percentage_inc: 'Percentual de acréscimo.',
    calc_services:
        'Porcentagem para cálculo automático sobre <br /> o valor da mercadoria.',
    description_vehicle: 'Veículo onde a carga será transportada',
    renavam: 'Registro Nacional de Veículos Automotores.',
    license_plate: 'Placa do veículo.',
    tare_kg: 'Peso do veículo sem carga em kg.',
    capacity_kg: 'Capacidade de carga do veículo em kg.',
    capacity_m: 'Capacidade de carga do veículo em m³.',
    bodywork:
        'Estrutura montada sobre o chassi de um veículo <br /> que serve para acomodar motorista, passageiros e cargas.',
    wheelset_type: 'Categoria do veículo.',
    type_vehicle: 'Motorização do veículo.',
    owner: 'Proprietário do veículo.',
    rntrc:
        "Registro Nacional dos Transportes Rodoviários de Cargas.<br />Caso não possua, preencher com 'ISENTO'",
    chassi: 'Número do chassi.',
    driver_vehicle: 'Responsável por guiar o veiculo durante o percurso.',
    cnae: 'Classificação Nacional de Atividades Econômicas.',
    crt: 'Certificação de Responsabilidade Técnica.',
    type_company: 'Tipo do serviço de transporte da empresa.',
    description_cfop: 'Descrição do CFOP.',
    cfop: 'Código do CFOP om 4 dígitos.',
    cnpj_insurance: 'CNPJ da seguradora.',
    name_insurance: 'Nome da seguradora.',
    policy: 'Documento emitido pela seguradora onde o seguro é contratado.',
    endorsement:
        'Documento que comprova todas as informações <br /> da carga junto à Seguradora.',
    code_mdfe: 'Código do documento.',
    number_mdfe: 'Número fiscal do documento.',
    serie_mdfe: 'Série do documento fiscal.',
    emission: 'Data de emissão/criação deste documento.',
    date_travel: 'Data de início da viagem.',
    type_mdfe: 'Tipo de Transporte.',
    transporter_mdfe:
        "Tipo do Transportador, informado caso o veículo seja de terceiro: <br /> ETC: Empresa de Transporte de Cargas <br /> TAC: Transportador Autônomo de Cargas <br /> CTC: Cooperativa de Transporte de Cargas.<br />Caso o veículo seja próprio, o transportador selecionado deve ser 'Próprio'.",
    environment_inssuer: 'Empresa emitente.',
    city_origin_mdfe: 'Cidade inicial do percurso',
    cep_load_mdfe: 'Código de Endereço Postal da cidade de carregamento.',
    city_destination_mdfe: 'Cidade final do percurso.',
    cep_unload_mdfe:
        'Código de Endereço Postal da ultima cidade onde haverá descarregamento.',
    route_mdfe:
        'Estados que o veiculo irá atravessar durante o Percurso. <br /> Somente quando as UF de origem e Destino não fizerem fronteira. <br /> Devem ser selecionados na ordem de travessia correta.',
    trailer: 'Veículo que será puxado pelo veículo automotor.',
    key: 'Chave de acesso do documento.',
    taker: 'Responsável pelo pagamento do serviço de transporte.',
    nature: 'Natureza do documento.',
    date_delivery: 'Data de previsão de entrega.',
    select_cfop: 'Código Fiscal de Operações e Prestações.',
    select_taker: 'Responsável pelo pagamento do serviço de transporte.',
    ref_key_cte: 'Chave de acesso do CT-e a ser referenciado.',
    select_sender_people:
        'Responsável pela entrega da mercadoria ao transportador.',
    select_recipient_people:
        'Pessoa física ou jurídica a quem a <br /> mercadoria transportada será entregue.',
    id_cte: 'Código interno do documento no sistema.',
    select_dispatcher_people:
        'Em caso de redespacho, é a empresa que fez a coleta da carga.',
    select_receiver_people:
        'Em caso de redespacho, é a empresa que receberá a mercadoria.',
    select_city_origin: 'Cidade de origem da mercadoria.',
    select_city_destination: 'Cidade de destino da mercadoria.',
    access_key_nfe: 'Chave de acesso da NF-e.',
    nfe_number: 'Número fiscal da NF-e.',
    pin_nfe: 'Número fiscal da NF-e.',
    serie_nfe: 'Série da NF-e.',
    emission_nfe: 'Data de emissão da NF-e.',
    bc_icms: 'Base de cálculo do ICMS.',
    value_icms: 'Valor do ICMS.',
    bc_st: 'Base de cálculo do ICMS de substituição tributária.',
    vlr_st: 'Valor do ICMS de substituição tributária.',
    nf_total_value: 'Valor total da nota.',
    original_document: 'Documento original.',
    description_document: 'Descrição do documento.',
    emission_document: 'Data de emissão do documento.',
    value_document: 'Valor total do documento.',
    people_emission: 'Emissor do documento.',
    commodity_value: 'Valor da mercadoria.',
    predominant_product: 'Descrição do produto.',
    other_features: 'Descrição de outras características.',
    measurement_unit: 'Unidade de medida da carga.',
    measurement_type: 'Tipo de medida da carga.',
    amount_load: 'Quantidade da carga',
    cst: 'Código de Situação Tributária',
    redbc: 'Redução da Base de Cálculo do ICMS',
    taker_cte:
        'Eventual: Prestação de serviço ocasional. Para uma única viagem, por exemplo. <br /> Contínuo: Prestação de serviço, mediante contrato escrito, para um determinado número de viagens.',
    route:
        'Estados que serão atravessados durante o percurso, caso as UFs de inicio e fim não façam fronteira. Devem ser selecionados na ordem de travessia correta.',
    nbs:
        'Código/Descrição do serviço prestado <br/> NBS: Nomenclatura Brasileira de Serviços',
    vlr_cofins:
        'Valor do CSLL <br/> (CSLL: Contribuição Social Sobre Lucro Líquido)',
    nothing: '...'
};

export default helperTooltips;
