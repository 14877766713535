import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import {TextInputMask} from 'tp-react-web-masked-text';
import Label from '../Label';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    required?: boolean;
    name: string;
    label: string;
    data_tip?: string;
    size_label: string;
    size_input: string;
    remove_label?: boolean;
    hideErrors?: boolean;
}

const InputLabelCPF: React.FC<Props> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    remove_label,
    hideErrors,
    data_tip,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            {!remove_label && (
                <Label
                    required={required}
                    respAlt
                    title={label}
                    col={`col-sm-${size_label}`}
                    to={name}
                />
            )}
            <div
                className={`col-sm-${size_input} resp-form`}
                data-tip={data_tip}
                data-for="help__tip--cnpj"
            >
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <TextInputMask
                            className="form-control form-control-sm"
                            disabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            kind="cpf"
                        />
                    )}
                />
                {errors && errors[`${name}`] && !hideErrors && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
            <ReactTooltip
                id="help__tip--cpf"
                place="top"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
            />
        </>
    );
};

export default InputLabelCPF;
