const columns = [
    {
        text: 'Razão Social',
        width: '30%',
        className: 'text-max-sm link-button',
        name: 'UPPER(corporate_name)',
        hasOrder: true
    },
    {
        text: 'Nome Fantasia',
        width: '30%',
        className: 'text-max-sm link-button',
        name: 'UPPER(name)',
        hasOrder: true
    },
    {
        text: 'CPF/CNPJ',
        width: '15% ',
        className: 'text-max-sm link-button',
        name: 'cpf_cnpj',
        hasOrder: true
    },
    {
        text: 'Telefone',
        width: '10% ',
        className: 'text-max-sm link-button',
        name: 'phone',
        hasOrder: true
    },
    {
        text: 'Cidade',
        width: '15%',
        className: 'text-max-sm link-button',
        name: 'description',
        hasOrder: true
    },
    {text: 'Ações', className: 'text-center text-max-sm'}
];

export default columns;
