import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Button from '../../../components/Button';
import InputLabel from '../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';
import FullPageLoader from '../../../components/FullPageLoader';
import SelectInputLabel from '../../../components/SelectInputLabel';
import getDifferenceBetweenDates from '../../../utils/getDifferenceBetweenDates';
import normalizeCpfCnpj from '../../../utils/normalizeCPFCNPJ';
import PaginatedAsyncSelectLabel from '../../../components/PaginatedAsyncSelectLabel';

interface Props {
    hide: () => void;
}

interface FormProps {
    type: string;
    file: string;
    field: string;
    person_id: string;
    start_date: string;
    end_date: string;
    serie?: string;
    start_number?: string;
    end_number?: string;
}

const validations = Yup.object().shape({
    type: Yup.string().required('Campo obrigatório'),
    file: Yup.string().required('Campo obrigatório'),
    start_date: Yup.string().required('Campo obrigatório'),
    end_date: Yup.string().required('Campo obrigatório')
});

const ExportFilesModal: React.FC<Props> = ({hide}) => {
    const [loading, setLoading] = useState(false);
    const {user} = useAuth();

    const defaultValues: FormProps = {
        type: '',
        file: '',
        field: '',
        person_id: '',
        start_date: '',
        end_date: '',
        serie: '',
        start_number: '',
        end_number: ''
    };

    const methods = useForm<FormProps>({
        resolver: yupResolver(validations),
        defaultValues
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(defaultValues);
    }, []);

    const onSubmit = useCallback(
        async (dataSubmit: FormProps) => {
            const start = new Date(dataSubmit.start_date);
            const end = new Date(dataSubmit.end_date);

            if (
                getDifferenceBetweenDates(start, end) >= 0 &&
                getDifferenceBetweenDates(start, end) <= 30
            ) {
                const submit = async () => {
                    await api
                        .post(
                            `${dataSubmit.type}${dataSubmit.file}Report`,
                            {
                                ...dataSubmit,
                                environment_inssuer_id: user.environment?.id,
                                environment_type:
                                    dataSubmit.type === 'cte'
                                        ? user.environment?.cte_environment
                                        : user.environment?.mdfe_environment
                            },
                            {
                                responseType: 'blob',
                                headers: {
                                    'Content-Disposition': 'attachment'
                                }
                            }
                        )
                        .then((response) => {
                            const url = window.URL.createObjectURL(
                                new Blob([response.data])
                            );
                            const link = document.createElement('a');
                            link.href = url;
                            const fileName = `${
                                dataSubmit.type
                            }s_${dataSubmit.file.toLowerCase()}_${normalizeCpfCnpj(
                                user.environment?.cnpj || ''
                            )}_${dataSubmit.start_date
                                .split('-')
                                .reverse()
                                .join('.')}_${dataSubmit.end_date
                                .split('-')
                                .reverse()
                                .join('.')}.zip`;
                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        })
                        .catch(() =>
                            toast.error(
                                'Nenhum registro encontrado para o filtro utilizado.'
                            )
                        );
                };

                if (dataSubmit.file === 'Xml') {
                    setLoading(true);
                    await submit().finally(() => setLoading(false));
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: "Exportar PDF's",
                        html:
                            'Este processo pode levar alguns segundos, devido à quantidade de documentos que serão gerados.<br />Por favor, aguarde até que seja finalizado.',
                        reverseButtons: true,
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Exportar',
                        showLoaderOnConfirm: true,
                        preConfirm: async (result) => {
                            await submit().finally(() => setLoading(false));
                        },
                        allowOutsideClick: false
                    });
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'O período deve compreender de 1 à 31 dias.'
                });
            }
        },
        [user]
    );

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                >
                    <Modal.Dialog scrollable size="sm" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>Exportar Arquivos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <InputLabel
                                        required
                                        label="Emissão"
                                        name="start_date"
                                        data_tip="Data inicial"
                                        size_label="2"
                                        size_input="3"
                                        type="date"
                                    />
                                    <InputLabel
                                        required
                                        label="Até"
                                        name="end_date"
                                        data_tip="Data final"
                                        size_label="1"
                                        size_input="3"
                                        type="date"
                                    />
                                    <span className="col-form-label">
                                        * período de 1 à 31 dias
                                    </span>
                                </div>
                                <div className="row">
                                    <SelectInputLabel
                                        required
                                        label="Tipo"
                                        name="type"
                                        data_tip="Tipo do documento"
                                        size_label="2"
                                        size_input="3"
                                        noSizeSelect
                                        options={[
                                            {label: 'CT-e', value: 'cte'},
                                            {label: 'MDF-e', value: 'mdfe'}
                                        ]}
                                    />
                                    <SelectInputLabel
                                        required
                                        label="Formato"
                                        name="file"
                                        data_tip="Formato do arquivo"
                                        size_label="1"
                                        size_input="3"
                                        noSizeSelect
                                        options={[
                                            {label: 'XML', value: 'Xml'},
                                            {label: 'PDF', value: 'Pdf'}
                                        ]}
                                    />
                                </div>
                                <div className="row">
                                    <PaginatedAsyncSelectLabel
                                        optionSearch="name_search"
                                        optionLabel="name_combo"
                                        label="Pessoa"
                                        name="person_id"
                                        data_tip="Trazer apenas os documentos de uma pessoa específica"
                                        size_label="2"
                                        size_input="10"
                                    />
                                </div>
                                <div className="row">
                                    <SelectInputLabel
                                        options={[
                                            {label: 'QUALQUER', value: ''},
                                            {
                                                label: 'REMETENTE',
                                                value: 'sender_people_id'
                                            },
                                            {
                                                label: 'DESTINATÁRIO',
                                                value: 'recipient_people_id'
                                            },
                                            {
                                                label: 'EXPEDIDOR',
                                                value: 'dispatcher_people_id'
                                            },
                                            {
                                                label: 'RECEBEDOR',
                                                value: 'receiver_people_id'
                                            },
                                            {
                                                label: 'TOMADOR',
                                                value: 'taker_people_id'
                                            }
                                        ]}
                                        label="Campo"
                                        name="field"
                                        data_tip="Campo em que a pessoa foi preenchida"
                                        size_label="2"
                                        size_input="3"
                                        noSizeSelect
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        label="Série"
                                        name="serie"
                                        data_tip="Série fiscal do documento"
                                        size_label="2"
                                        size_input="1"
                                    />
                                    <InputLabelOnlyNumbers
                                        label="Número"
                                        name="start_number"
                                        data_tip="Número fiscal inicial"
                                        size_label="1"
                                        size_input="1"
                                    />
                                    <InputLabelOnlyNumbers
                                        label="Até"
                                        name="end_number"
                                        data_tip="Número fiscal final"
                                        size_label="1"
                                        size_input="1"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-file-download"
                                        caption="Exportar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>

            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default ExportFilesModal;
