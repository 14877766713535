const columns = [
    {
        text: 'Data',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Hora',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Usuário',
        className: 'text-max-sm pl-2'
    },
    {
        text: 'Evento',
        width: '100%',
        className: 'text-max-sm pl-2'
    }
];

export default columns;
