/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import AsyncSelect from 'react-select/async';
import removeAccents from 'remove-accents';
import {Controller, useFormContext} from 'react-hook-form';
import Label from '../Label';
import {CityProps} from '../../interfaces';
import {styles, stylesMulti} from './styles';

interface Option {
    value: string;
    label: string;
}

interface SelectProps {
    required?: boolean;
    name: string;
    label: string;
    size_label: string;
    size_input: string;
    data: CityProps[];
    isMulti?: boolean;
    onChange?: Function;
    isClearable?: boolean;
    data_tip?: string;
    modal_id?: string;
    menuPlace?: 'top' | 'bottom';
    menuHeight?: number;
    noSizeSelect?: boolean;
}

const AsyncSelectLabelCities: React.FC<SelectProps> = ({
    name,
    required,
    label,
    size_input,
    size_label,
    data,
    isMulti,
    onChange,
    isClearable,
    data_tip,
    modal_id,
    menuPlace,
    menuHeight,
    noSizeSelect,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        setValue,
        getValues
    } = useFormContext();

    const filterCities = (inputValue: string) => {
        const filtered = data.filter((item: CityProps) =>
            removeAccents(
                `${item.description} - ${item.state.initials}`.toLowerCase()
            ).startsWith(inputValue.toLowerCase())
        );
        return filtered.slice(0, 30).map((item: CityProps) => ({
            label: `${item.description} - ${item.state.initials}`,
            value: item.id
        }));
    };

    const loadOptions = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        if (inputValue.length >= 3) {
            callback(filterCities(removeAccents(inputValue)));
        }
    };

    const handleChange = (value: Option | null) => {
        setValue(name, value ? value.value : null);
    };
    function handleChangeMulti(option: any) {
        setValue(name, option ? option.map((item: Option) => item.value) : []);
    }

    return (
        <>
            <Label
                required={required}
                respAlt
                title={label}
                data_tip={data_tip}
                col={`col-sm-${size_label}`}
                to={name}
            />
            <div className={`col-sm-${size_input} resp-form`}>
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <AsyncSelect
                            isDisabled={getValues('form_action') === 'view'}
                            {...otherProps}
                            {...field}
                            name={name}
                            value={
                                !isMulti
                                    ? field.value
                                        ? [
                                              {
                                                  label: `${
                                                      data.find(
                                                          (item) =>
                                                              item.id ===
                                                              field.value
                                                      )?.description
                                                  } - ${
                                                      data.find(
                                                          (item) =>
                                                              item.id ===
                                                              field.value
                                                      )?.state.initials
                                                  }`,
                                                  value: field.value
                                              }
                                          ]
                                        : []
                                    : field.value.map(
                                          (itemValue: string | number) => ({
                                              label: `${
                                                  data.find(
                                                      (item) =>
                                                          item.id === itemValue
                                                  )?.description
                                              } - ${
                                                  data.find(
                                                      (item) =>
                                                          item.id === itemValue
                                                  )?.state.initials
                                              }`,
                                              value: itemValue
                                          })
                                      )
                            }
                            cacheOptions
                            loadOptions={loadOptions}
                            placeholder="Digite 3 ou mais caracteres"
                            noOptionsMessage={() => 'Sem resultados'}
                            loadingMessage={() => 'Digite 3 ou mais caracteres'}
                            menuPortalTarget={
                                noSizeSelect ? document.body : undefined
                            }
                            menuPlacement={
                                noSizeSelect ? 'auto' : menuPlace || 'auto'
                            }
                            maxMenuHeight={
                                noSizeSelect ? undefined : menuHeight || 200
                            }
                            styles={isMulti ? stylesMulti : styles}
                            onChange={(value) => {
                                isMulti
                                    ? handleChangeMulti(value)
                                    : handleChange(value);
                                onChange && onChange(value);
                            }}
                            isMulti={isMulti}
                            isClearable={true}
                        />
                    )}
                />
                {errors && errors[`${name}`] && (
                    <span className="span text-danger text-min-sm">
                        {errors[`${name}`].message}
                    </span>
                )}
            </div>
        </>
    );
};

export default AsyncSelectLabelCities;
