/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {format as formatCNPJ} from 'cnpj';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import columns from './schema';
import {DfeProps, TableOrder} from '../../interfaces';
import {useAuth} from '../../hooks/Auth';
import InputLabel from '../../components/InputLabel';
import SelectInputLabel from '../../components/SelectInputLabel';
import InputLabelCurrency from '../../components/InputLabelCurrency';
import setSituationColor from '../../utils/setSituationColor';
import EventsModal from './EventsModal';
import getDFeSituationText from '../../utils/getDFeSituationText';
import formatDate from '../../utils/formatDate';
import openPdfFromBase64 from '../../utils/openPdfFromBase64';

interface SearchProps {
    start_emission: string;
    end_emission: string;
    start_value: string | number;
    end_value: string | number;
    dfe_situation: string;
    sefaz_situation: string;
    issuer: string;
}

const searchDefaultValues = {
    start_emission: '',
    end_emission: '',
    start_value: '',
    end_value: '',
    dfe_situation: '',
    sefaz_situation: '',
    issuer: ''
};

const MonitorDFE: React.FC = () => {
    const {user} = useAuth();

    const [order, setOrder] = useState<TableOrder>({
        order: 'created_at',
        type: 'desc'
    });
    const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dfes, setDfes] = useState<DfeProps[]>([] as DfeProps[]);
    const [eventsModalVisible, setEventsModalVisible] = useState(false);
    const [eventsModalData, setEventsModalData] = useState<any[]>([]);

    // DFEs
    useEffect(() => {
        if (user.environment) {
            setLoading(true);
            api.get(
                `dfesNS?environment_id=${user.environment.id}&order=${order.order}&type=${order.type}&page=${page}&limit=20&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_value=${search.start_value}&end_value=${search.end_value}&dfe_situation=${search.dfe_situation}&sefaz_situation=${search.sefaz_situation}&issuer=${search.issuer}`
            )
                .then((response) => {
                    setDfes(response.data.data);
                    setCount(response.data.meta.total);
                    setPerPage(response.data.meta.per_page);
                    setCurrentPage(response.data.meta.current_page);
                })
                .catch((error) => toast.info(error.response?.data))
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'É necessário cadastrar um ambiente antes de consultar DFEs.'
            });
        }
    }, [page, refresh, user, order]);

    // API DFEs
    const downloadDfes = useCallback(() => {
        if (user.environment) {
            setLoading(true);
            api.get('apiDfesNS')
                .then((response) => {
                    toast.success(response.data);
                    setRefresh((current) => !current);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data || 'Erro ao sincronizar DFEs'
                    });
                })
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'É necessário cadastrar um ambiente antes de consultar DFEs.'
            });
        }
    }, [user]);

    const methods = useForm<SearchProps>({
        defaultValues: searchDefaultValues
    });
    const {handleSubmit, reset} = methods;

    const onSearch = useCallback(
        async (values: SearchProps) => {
            if (values.start_value === 0) {
                values.start_value = '';
            }
            if (values.end_value === 0) {
                values.end_value = '';
            }

            setSearch(values);

            if (page === 1) {
                setRefresh((current) => !current);
            } else {
                setPage(1);
            }

            reset(values);
        },
        [page]
    );

    const EVENT_CONFIRMAR = 210200;
    const EVENT_CIENCIA = 210210;
    const EVENT_DESCONHECIMENTO = 210220;
    const EVENT_NAO_REALIZADA = 210240;

    // DOWNLOAD ONE
    const printDfe = useCallback((id: string) => {
        setLoading(true);
        api.get(`printDfeNS/${id}`)
            .then((response) => {
                openPdfFromBase64(response.data);
            })
            .catch(() => toast.error('Erro ao gerar impressão'))
            .finally(() => setLoading(false));
    }, []);

    const dfePost = useCallback(
        (data: {
            id: string;
            event: 210200 | 210210 | 210220 | 210240;
            justify?: string;
        }) => {
            const {id, event, justify} = data;
            setLoading(true);
            api.post('apiDfesNS', {id, event, justify})
                .then((response) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Operação realizada',
                        text: response?.data
                    });
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text:
                            error.response?.data || 'Erro ao realizar operação'
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setRefresh((current) => !current);
                });
        },
        []
    );

    const dfeEvent = useCallback(
        (id: string, event: 210200 | 210210 | 210220 | 210240) => {
            if (event === EVENT_NAO_REALIZADA) {
                Swal.fire({
                    title: 'Motivo da não realização',
                    input: 'text',
                    inputValidator: (value) => {
                        if (value.length < 15) {
                            return 'Escreva ao menos 15 caracteres';
                        }
                        return null;
                    },
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Enviar',
                    preConfirm: (result) => {
                        dfePost({id, event, justify: result});
                    }
                });
            } else {
                Swal.fire({
                    title: `Realizar a manifestação de ${getDFeSituationText(
                        event
                    )}?`,
                    icon: 'question',
                    showCancelButton: true,
                    showCloseButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.value) {
                        dfePost({id, event});
                    }
                });
            }
        },
        []
    );

    const dfeXML = useCallback((id: string, issuer: string, date: string) => {
        setLoading(true);
        api.get(`dfesXmlNS/${id}`)
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                const fileName = `${issuer}_${date}.xml`;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: error.response?.data
                });
            })
            .finally(() => setLoading(false));
    }, []);

    const showEvents = useCallback((id: string) => {
        setLoading(true);
        api.get(`showDfeEventsNS/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setEventsModalData(response.data);
                    setEventsModalVisible(true);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Este documento não possui eventos'
                    });
                }
            })
            .catch(() => toast.error('Erro ao obter eventos'))
            .finally(() => setLoading(false));
    }, []);

    const mapTable = useMemo(
        () =>
            dfes.map((dfe) => (
                <tr key={Math.random()}>
                    {/* DATA DE EMISSÃO */}
                    <td className="pt-2">
                        {formatDate(dfe.emission_date, true)}
                    </td>
                    {/* NÚMERO */}
                    <td className="pt-2 pl-2">{dfe.nfe_number}</td>
                    {/* EMITENTE */}
                    <td className="pt-2 pl-2 short-td">{dfe.issuer}</td>
                    {/* CNPJ */}
                    <td className="pt-2 pl-2 short-td">
                        {formatCNPJ(dfe.issuer_cnpj)}
                    </td>
                    {/* VALOR */}
                    <td className="pt-2">
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        }).format(dfe.total_value)}
                    </td>
                    {/* CIÊNCIA */}
                    <td className="pt-2 text-center">
                        {dfe.know ? 'SIM' : 'NÃO'}
                    </td>
                    {/* SITUAÇÃO SEFAZ */}
                    <td
                        className={`pt-2 text-center bg-${setSituationColor(
                            dfe.sefaz_situation
                        )}`}
                    >
                        {dfe.sefaz_situation}
                    </td>
                    {/* SITUAÇÃO DFE */}
                    <td className="pt-2 text-center">
                        {getDFeSituationText(dfe.situation)}
                    </td>
                    {/* AÇÕES */}
                    <td className="px-0 text-center">
                        <ActionButton
                            dfe_know={
                                dfe.know
                                    ? undefined
                                    : () => dfeEvent(dfe.id, EVENT_CIENCIA)
                            }
                            dfe_confirm={
                                dfe.know
                                    ? () => dfeEvent(dfe.id, EVENT_CONFIRMAR)
                                    : undefined
                            }
                            dfe_unknow={
                                dfe.know
                                    ? () =>
                                          dfeEvent(
                                              dfe.id,
                                              EVENT_DESCONHECIMENTO
                                          )
                                    : undefined
                            }
                            dfe_not_done={
                                dfe.know
                                    ? () =>
                                          dfeEvent(dfe.id, EVENT_NAO_REALIZADA)
                                    : undefined
                            }
                            dfe_print={
                                dfe.know ? () => printDfe(dfe.id) : undefined
                            }
                            dfe_show_events={
                                dfe.know ? () => showEvents(dfe.id) : undefined
                            }
                            dfe_xml={
                                dfe.know
                                    ? () =>
                                          dfeXML(
                                              dfe.id,
                                              dfe.issuer,
                                              dfe.emission_date
                                          )
                                    : undefined
                            }
                        />
                    </td>
                </tr>
            )),
        [dfes]
    );

    return (
        <>
            <div className="content-header">
                <div className="title-page">
                    <div className="container-fluid pl-1 dc-flex desktop">
                        <TitlePage
                            title="Monitor DF-e"
                            icon="fa-desktop"
                            tabRight
                            pagination
                            minCol
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                        />
                        <div className="tab-min-top col-9 mt-1 pr-1">
                            <Button
                                className="tour-mdfe--01"
                                type="button"
                                color="primary"
                                caption="Sincronizar"
                                onClick={() => {
                                    downloadDfes();
                                }}
                                icon="fa-sync-alt"
                                maxSize
                            />
                        </div>
                    </div>
                    <div className="content-header mobile">
                        <div className="container-fluid pl-1 pr-2">
                            <TitlePage
                                title="Monitor DF-e"
                                icon="fa-desktop"
                                tabRight
                                pagination
                                count={count}
                                currentPage={currentPage}
                                perPage={perPage}
                                mobile
                            />
                            <div className="dc-flex">
                                <div className="col-sm-3 my-2 pt-min">
                                    <Button
                                        type="button"
                                        color="primary"
                                        caption="Sincronizar"
                                        onClick={() => {
                                            downloadDfes();
                                        }}
                                        icon="fa-sync-alt"
                                        maxSize={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="main-content">
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSearch)}
                            >
                                <div className="w-100 dc-flex filter-desktop tour-mdfe--02">
                                    <div className="w-70">
                                        <div className="d-flex">
                                            <section className="d-flex w-100 pt-1 pb-1 px-1">
                                                <InputLabel
                                                    label="Emissão"
                                                    name="start_emission"
                                                    type="date"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                />

                                                <InputLabel
                                                    label="Até"
                                                    name="end_emission"
                                                    type="date"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                />
                                                <SelectInputLabel
                                                    options={[
                                                        {
                                                            value: null,
                                                            label: 'QUALQUER'
                                                        },
                                                        {
                                                            value: EVENT_CONFIRMAR,
                                                            label: 'CONFIRMADO'
                                                        },
                                                        {
                                                            value: EVENT_CIENCIA,
                                                            label: 'CIÊNCIA'
                                                        },
                                                        {
                                                            value: EVENT_DESCONHECIMENTO,
                                                            label:
                                                                'DESCONHECIDO'
                                                        },
                                                        {
                                                            value: EVENT_NAO_REALIZADA,
                                                            label:
                                                                'NÃO REALIZADA'
                                                        }
                                                    ]}
                                                    label="Sit. DF-e"
                                                    name="dfe_situation"
                                                    size_label="1"
                                                    size_input="3"
                                                />

                                                <SelectInputLabel
                                                    options={[
                                                        {
                                                            value: '',
                                                            label: 'QUALQUER'
                                                        },
                                                        {
                                                            value: 'AUTORIZADO',
                                                            label: 'AUTORIZADO'
                                                        },
                                                        {
                                                            value: 'CANCELADO',
                                                            label: 'CANCELADO'
                                                        },
                                                        {
                                                            value: 'REJEITADO',
                                                            label: 'REJEITADO'
                                                        }
                                                    ]}
                                                    label="Sit. Sefaz"
                                                    name="sefaz_situation"
                                                    size_label="1"
                                                    size_input="3"
                                                />
                                            </section>
                                        </div>
                                        <div className="d-flex">
                                            <section className="d-flex w-100 px-1 pb-2">
                                                <InputLabelCurrency
                                                    label="Valor"
                                                    name="start_value"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                />

                                                <InputLabelCurrency
                                                    label="Até"
                                                    name="end_value"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                />

                                                <InputLabel
                                                    label="Emissor"
                                                    name="issuer"
                                                    size_label="1"
                                                    size_input="7"
                                                />
                                            </section>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right mb-2 pr-4 ml-2">
                                        <Button
                                            className="tour-mdfe--03"
                                            type="submit"
                                            color="default"
                                            caption="Pesquisar"
                                            icon="fa-search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                        <div className="container-fluid">
                            <div className="row">
                                <CardBody
                                    size="12"
                                    smallSize="12"
                                    page={page}
                                    count={count}
                                    setPage={setPage}
                                >
                                    <Table
                                        id="mdfe"
                                        columns={columns}
                                        setOrder={setOrder}
                                        order={order.order}
                                        type={order.type}
                                    >
                                        {mapTable}
                                    </Table>
                                </CardBody>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <FullPageLoader />}
            {eventsModalVisible && (
                <EventsModal
                    hide={() => setEventsModalVisible(false)}
                    data={eventsModalData}
                />
            )}
        </>
    );
};

export default MonitorDFE;
