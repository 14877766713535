import React from 'react';

import Header from './Header';
import Footer from './Footer';
import {useAuth} from '../../hooks/Auth';

const Main: React.FC = ({children}) => {
    const {user} = useAuth();

    return user ? (
        <div className="wrapper">
            <Header />
            <div className="content-wrapper">{children}</div>
            <Footer />
        </div>
    ) : (
        <>{children}</>
    );
};

export default Main;
