/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
import {UseFormGetValues, UseFormReset, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {format as formatCPF} from 'cpf';
import {format as formatCNPJ} from 'cnpj';
import {CityProps, CTeProps, CTeFromXML} from '../interfaces';
import getMeasurementType from '../utils/getMeasurementType';
import api from './api';

async function ImportCTeXML(
    data: CTeFromXML,
    setValue: UseFormSetValue<CTeProps>,
    getValues: UseFormGetValues<CTeProps>,
    reset: UseFormReset<CTeProps>,
    cities: CityProps[]
): Promise<void> {
    // VERIFICA SE HÁ CPF OU CNPJ
    let sender;
    try {
        const sender_response = await api.get(
            `peopleCombo?order=name&type=asc&filter=${`cpf_cnpj=${
                data.rem.CNPJ
                    ? formatCNPJ(data.rem.CNPJ)
                    : formatCPF(data.rem.CPF as string)
            }`}`
        );
        sender = sender_response.data[0];
    } catch (error: any) {
        toast.error(error || 'Erro ao obter remetente');
    }

    // VERIFICA SE HÁ CPF OU CNPJ
    let recipient;
    try {
        const sender_response = await api.get(
            `peopleCombo?order=name&type=asc&filter=${`cpf_cnpj=${
                data.dest.CNPJ
                    ? formatCNPJ(data.dest.CNPJ)
                    : formatCPF(data.dest.CPF as string)
            }`}`
        );
        recipient = sender_response.data[0];
    } catch (error: any) {
        toast.error(error || 'Erro ao obter destinatário');
    }

    // VERIFICA SE REMETENTE E DESTINATÁRIO SÃO OS MESMOS QUE ESTÃO NO FORMULÁRIO
    if (
        (!getValues('sender_people_id') ||
            getValues('sender_people_id') === sender?.id) &&
        (!getValues('recipient_people_id') ||
            getValues('recipient_people_id') === recipient?.id)
    ) {
        setValue(
            'commodity_value',
            getValues('commodity_value') +
                parseFloat(data.infCTeNorm.infCarga.vCarga.replace(',', '.'))
        ); // SET COMMODITY_VALUE
        setValue('predominant_product', data.infCTeNorm.infCarga.proPred); // SET PRODOMINANT_PRODUCT

        // SET ORIGIN CITY
        const city_origin_id = cities.find(
            (item) => data.ide.cMunIni === item.ibge.toString()
        )?.id;
        setValue('city_origin_id', city_origin_id || null);

        // SET DESTINATION CITY
        const city_destination_id = cities.find(
            (item) => data.ide.cMunFim === item.ibge.toString()
        )?.id;
        setValue('city_destination_id', city_destination_id || null);

        // SET RNTRC
        setValue('rntrc', data.infCTeNorm.infModal.rodo.RNTRC.toUpperCase());

        const {infNFe, infNF, infOutros}: any = data.infCTeNorm.infDoc; // As PROPRIEDADES INFDOC DA NOTA PODE SER UM ARRAY OU NÃO, ESSA ATRIBUIÇÃO FOI FEITA PARA CONTORNAR ISSO
        if (infNFe) {
            reset({
                ...getValues(),
                nfes: infNFe[0]
                    ? [
                          ...getValues('nfes'),
                          ...infNFe
                              .filter((item: any) =>
                                  getValues('nfes').every(
                                      (nfe) => nfe.access_key !== item.chave
                                  )
                              )
                              .map((item: any) => ({
                                  access_key: item.chave,
                                  nfe_number: parseInt(
                                      item.chave.substring(25, 34),
                                      10
                                  ).toString()
                              }))
                      ]
                    : getValues('nfes').some(
                          (nfe) => nfe.access_key === infNFe.chave
                      )
                    ? getValues('nfes')
                    : [
                          ...getValues('nfes'),
                          {
                              access_key: infNFe.chave,
                              nfe_number: parseInt(
                                  infNFe.chave.substring(25, 34),
                                  10
                              ).toString()
                          }
                      ]
            });
        }

        if (infNF) {
            reset({
                ...getValues(),
                manual_nfs: infNF[0]
                    ? [
                          ...getValues('manual_nfs'),
                          ...infNF.map((item: any) => ({
                              nf_model: item.mod,
                              serie: item.serie,
                              number: item.nDoc,
                              date_emission: item.dEmi,
                              cfop: item.nCFOP,
                              vlr_st: parseFloat(item.vST),
                              bc_icms: parseFloat(item.vBC),
                              bc_st: parseFloat(item.vBCST),
                              nf_total_value: parseFloat(item.vNF),
                              total_value: parseFloat(item.vProd),
                              value_icms: parseFloat(item.vICMS)
                          }))
                      ]
                    : [
                          ...getValues('manual_nfs'),
                          {
                              nf_model: infNF.mod,
                              serie: infNF.serie,
                              number: infNF.nDoc,
                              date_emission: infNF.dEmi,
                              cfop: infNF.nCFOP,
                              vlr_st: parseFloat(infNF.vST),
                              bc_icms: parseFloat(infNF.vBC),
                              bc_st: parseFloat(infNF.vBCST),
                              nf_total_value: parseFloat(infNF.vNF),
                              total_value: parseFloat(infNF.vProd),
                              value_icms: parseFloat(infNF.vICMS)
                          }
                      ]
            });
        }

        if (infOutros) {
            reset({
                ...getValues(),
                others: infOutros[0]
                    ? [
                          ...getValues('others'),
                          ...infOutros.map((item: any) => ({
                              date_emission: item.dEmi,
                              description: item.descOutros,
                              number: item.nDoc,
                              value: parseFloat(item.vDocFisc),
                              original_document: item.tpDoc
                          }))
                      ]
                    : [
                          ...getValues('others'),
                          {
                              date_emission: infOutros.dEmi,
                              description: infOutros.descOutros,
                              number: infOutros.nDoc,
                              value: parseFloat(infOutros.vDocFisc),
                              original_document: infOutros.tpDoc
                          }
                      ]
            });
        }

        const {infQ}: any = data.infCTeNorm.infCarga; // A PROPRIEDADE INFQ DA NOTA PODE SER UM ARRAY OU NÃO, ESSA ATRIBUIÇÃO FOI FEITA PARA CONTORNAR ISSO
        reset({
            ...getValues(),
            loads: data.infCTeNorm.infCarga.infQ[0]
                ? [
                      ...getValues('loads'),
                      ...data.infCTeNorm.infCarga.infQ.map((item) => ({
                          amount: parseFloat(item.qCarga),
                          measurement_unit: item.cUnid,
                          measurement_type: getMeasurementType(item.cUnid)
                      }))
                  ]
                : [
                      ...getValues('loads'),
                      {
                          amount: parseFloat(infQ.qCarga),
                          measurement_unit: infQ.cUnid,
                          measurement_type: getMeasurementType(infQ.cUnid)
                      }
                  ]
        }); // SET LOADS - FOI USADO O RESET POIS SE A CARGA FOR MUDADA PARA UM VALOR INFERIOR AO ATUAL, O FORMULÁRIO NÃO SE COMPORTA ADEQUADAMENTE

        // SET SENDER (CADASTRA UM NOVO CASO NECESSÁRIO)
        if (sender) {
            setValue('sender_people_id', sender.id || '');
        } else {
            const cepRaw: string = data.rem.enderReme.CEP.replace(
                '.',
                ''
            ).replace('-', '');
            const cep = `${cepRaw.substring(0, 5)}-${cepRaw.substring(5)}`;
            await api
                .post('people', {
                    corporate_name: data.rem.xNome,
                    name: data.rem.xFant,
                    cpf_cnpj: data.rem.CNPJ
                        ? formatCNPJ(data.rem.CNPJ)
                        : formatCPF(data.rem.CPF as string),
                    rg_ie: data.rem.IE || 'ISENTO',
                    type: data.rem.CNPJ ? 1 : 0,
                    driver: false,
                    phone: data.rem.enderReme.fone,
                    address: data.rem.enderReme.xLgr,
                    district: data.rem.enderReme.xBairro,
                    cep,
                    number: data.rem.enderReme.nro,
                    city_id: cities.find(
                        (item) =>
                            data.rem.enderReme.cMun === item.ibge.toString()
                    )?.id,
                    log: {
                        name: data.rem.xFant || data.rem.xNome,
                        cpf_cnpj: data.rem.CNPJ
                            ? formatCNPJ(data.rem.CNPJ)
                            : formatCPF(data.rem.CPF as string)
                    }
                })
                .then((response) => {
                    setValue('sender_people_id', response.data.id);
                })
                .catch((error) =>
                    toast.error(
                        error.response?.data.errors[0]?.message ||
                            error.response.data ||
                            'Erro ao cadastrar remetente da NFe'
                    )
                );
        }

        // SET RECIPIENT (CADASTRA UM NOVO CASO NECESSÁRIO)
        if (recipient) {
            // VERIFICA SE JÁ EXISTE UM CADASTRO, SE NÃO EXISTIR REALIZA UM NOVO
            setValue('recipient_people_id', recipient.id || '');
        } else {
            const cepRaw: string = data.dest.enderDest.CEP.replace(
                '.',
                ''
            ).replace('-', '');
            const cep = `${cepRaw.substring(0, 5)}-${cepRaw.substring(5)}`;
            await api
                .post('people', {
                    corporate_name: data.dest.xNome,
                    name: data.dest.xFant || data.dest.xNome,
                    cpf_cnpj: data.dest.CNPJ
                        ? formatCNPJ(data.dest.CNPJ)
                        : formatCPF(data.dest.CPF as string),
                    rg_ie: data.dest.IE || 'ISENTO',
                    type: data.dest.CNPJ ? 1 : 0,
                    driver: false,
                    phone: data.dest.enderDest.fone,
                    address: data.dest.enderDest.xLgr,
                    district: data.dest.enderDest.xBairro,
                    cep,
                    number: data.dest.enderDest.nro,
                    city_id: cities.find(
                        (item) =>
                            data.dest.enderDest.cMun === item.ibge.toString()
                    )?.id,
                    log: {
                        name: data.dest.xFant || data.dest.xNome,
                        cpf_cnpj: data.dest.CNPJ
                            ? formatCNPJ(data.dest.CNPJ)
                            : formatCPF(data.dest.CPF as string)
                    }
                })
                .then((response) => {
                    setValue('recipient_people_id', response.data.id);
                })
                .catch((error) =>
                    toast.error(
                        error.response?.data.errors[0]?.message ||
                            error.response.data ||
                            'Erro ao cadastrar destinatário da NFe'
                    )
                );
        }

        toast.info('CT-e importado');
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Não foi possível importar o XML',
            text:
                'O remetente e destinatário desse documento não correspondem aos que já estão selecionados no CTe'
        });
    }
}

export default ImportCTeXML;
