import React from 'react';

import {Link} from 'react-router-dom';
import TitlePage from '../../components/TitlePage';

const ContractModule: React.FC = () => {
    return (
        <>
            <div className="content-header desktop">
                <div className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Módulo não habilitado"
                            icon="fa-exclamation-triangle"
                            tabRight={true}
                            pagination={false}
                            description="Agradecemos seu interesse"
                        />
                    </div>
                </div>
            </div>
            <div className="content-header mobile">
                <div className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Módulo não habilitado"
                            icon="fa-exclamation-triangle"
                            tabRight={true}
                            pagination={false}
                            description="Agradecemos seu interesse"
                            mobile={true}
                        />
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="error-page">
                        <h2 className="headline text-warning tab-top">500</h2>
                        <div className="error-content">
                            <h3>
                                <i className="fas fa-exclamation-triangle text-warning tab-right" />
                                Oooops! Este módulo não está disponível para a
                                sua empresa.
                            </h3>
                            <p>
                                Caso tenha interesse em utilizar este recurso,
                                entre em contato com a{' '}
                                <strong>MS1 Sistemas</strong> através do
                                telefone: <strong>(44) 3056-2747</strong>, ou
                                <strong> WhatsApp: (44) 98434-3258</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContractModule;
