/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Tour from 'reactour';
import ReactTooltip from 'react-tooltip';
import {useForm, FormProvider} from 'react-hook-form';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import ModalForm from './ModalForm';
import columns from './schema';
import {PeopleProps, TableOrder} from '../../interfaces';
import {peopleDefaultValues} from '../../data';
import {tourStepsPeople} from '../../data/tour';
import {useAuth} from '../../hooks/Auth';
import SaveOrderButton from '../../components/SaveOrderButton';
import TaxationModal from '../Modals/Taxation';
import RecoverModal from './RecoverModal';
import SelectInputLabel from '../../components/SelectInputLabel';
import importLegacyJSON from '../../services/importLegacyJSON';

const People: React.FC = () => {
    const {user, updateUser, tablesOrder} = useAuth();
    const tableOrder = tablesOrder.find((item) => item.table === 'people');
    const [isTourOpen, setIsTourOpen] = useState(user.tour_tables || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };
    const [order, setOrder] = useState<TableOrder>({
        order: tableOrder?.field || 'created_at',
        type: tableOrder?.type || 'desc'
    });
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [people, setPeople] = useState<PeopleProps[]>([]);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<PeopleProps>({} as PeopleProps);
    const [recoverModalVisible, setRecoverModalVisible] = useState(false);

    const [taxationModalVisible, setTaxationModalVisible] = useState(false);
    const [taxationPersonId, setTaxationPersonId] = useState<
        string | undefined
    >('');

    useEffect(() => {
        setLoading(true);
        api.get(
            `people?page=${page}&limit=20&order=${order.order}&type=${order.type}&search=${search}&active=${active}`
        )
            .then((response) => {
                setPeople(response.data.data);
                setCount(response.data.meta.total);
                setPerPage(response.data.meta.per_page);
                setCurrentPage(response.data.meta.current_page);
            })
            .catch(() => toast.error('Não foi possível efetuar a consulta'))
            .finally(() => setLoading(false));
    }, [page, order, refresh, active]); // GET PEOPLE

    const resetData = useCallback(() => {
        setModalData(peopleDefaultValues);
    }, [peopleDefaultValues]);

    const showPeople = useCallback(
        (id: string, form_action: 'view' | 'edit') => {
            setLoading(true);
            api.get(`people/${id}`)
                .then((response) => {
                    setModalData({
                        ...response.data,
                        form_action
                    });
                    setModalVisible(true);
                })
                .catch(() => toast.error('Não foi possível efetuar a consulta'))
                .finally(() => setLoading(false));
        },
        []
    );

    const handleDelete = useCallback(
        (id: string, name: string, cpf_cnpj: string) => {
            Swal.fire({
                title: 'Confirmar exclusão do registro?',
                text:
                    'Este cadastro poderá ser recuperado posteriormente em "Restaurar Pessoas excluídas", ao lado do botão "Incluir"',
                icon: 'question',
                showCancelButton: true,
                showCloseButton: true,
                reverseButtons: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then(async (result) => {
                if (result.value) {
                    setLoading(true);
                    api.delete(`people/${id}?name=${name}&cpf_cnpj=${cpf_cnpj}`)
                        .then(() => {
                            setRefresh(Math.random());
                            toast.success('Registro excluído com sucesso');
                        })
                        .catch((error) =>
                            toast.error(
                                error.response?.data ||
                                    'Ocorreu um erro ao excluir o registro'
                            )
                        )
                        .finally(() => setLoading(false));
                }
            });
        },
        []
    );

    const mapTable = useMemo(
        () =>
            people.map((data) => (
                <tr key={data.id}>
                    <td className="pt-2 break-td">{data.corporate_name}</td>
                    <td className="pt-2 break-td">{data.name}</td>
                    <td className="pt-2">{data.cpf_cnpj}</td>
                    <td className="pt-2">{data.phone}</td>
                    <td className="pt-2">
                        {`${data.description} - ${data.initials}`}
                    </td>
                    <td className="pl-4">
                        <ActionButton
                            view={async () => {
                                await showPeople(data.id || '', 'view');
                            }}
                            edit={async () => {
                                await showPeople(data.id || '', 'edit');
                            }}
                            delete={() => {
                                handleDelete(
                                    data.id || '',
                                    data.name,
                                    data.cpf_cnpj
                                );
                            }}
                            taxation={() => {
                                setTaxationPersonId(data.id);
                                setTaxationModalVisible(true);
                            }}
                        />
                    </td>
                </tr>
            )),
        [people]
    );

    const methods = useForm({defaultValues: {active: true}});

    return (
        <>
            {/* TITLE PAGE ( DESKTOP ) */}
            <div className="content-header desktop">
                <section className="title-page">
                    <FormProvider {...methods}>
                        <form autoComplete="off">
                            <div className="container-fluid pl-1 pr-2 d-flex">
                                <TitlePage
                                    title="Pessoas"
                                    icon="fa-user"
                                    pagination={true}
                                    count={count}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    customCol="4"
                                />

                                <div className="tab-min-top col-3 text-right mt-1 row">
                                    <SaveOrderButton
                                        id={tableOrder?.id as string}
                                        table="people"
                                        field={order.order}
                                        type={order.type}
                                        setOrder={setOrder}
                                    />

                                    <Link to="#">
                                        <Button
                                            onClick={() => {
                                                resetData();
                                                setModalVisible(true);
                                                setIsTourOpen(false);
                                            }}
                                            className="tour-people--01"
                                            type="button"
                                            color="success"
                                            caption="Incluir"
                                            icon="fa-plus"
                                            maxSize={true}
                                        />
                                    </Link>

                                    <Link
                                        to="#"
                                        className="ml-2"
                                        data-tip="Restaurar Pessoas excluídas"
                                        data-for="people-main-page-tooltip"
                                    >
                                        <Button
                                            onClick={() => {
                                                setRecoverModalVisible(true);
                                            }}
                                            type="button"
                                            color="default"
                                            icon="fa-trash-restore-alt"
                                            notTab
                                            maxSize={true}
                                        />
                                    </Link>

                                    <label
                                        htmlFor="json_input"
                                        id="btn-import"
                                        className="cursor-pointer btn btn-default"
                                        data-tip="Importar cadastros via JSON"
                                        data-for="people-main-page-tooltip"
                                        style={{marginLeft: 6}}
                                    >
                                        <i className="fas fa-download" />
                                    </label>

                                    <input
                                        id="json_input"
                                        type="file"
                                        accept=".json"
                                        style={{display: 'none'}}
                                        onChange={(event) => {
                                            const reader = new FileReader();
                                            reader.onload = async function (e) {
                                                const json = e.target
                                                    ?.result as string;
                                                await importLegacyJSON(
                                                    json,
                                                    'legacyPeople',
                                                    setLoading,
                                                    () =>
                                                        setRefresh(
                                                            Math.random()
                                                        )
                                                );
                                            };
                                            reader.readAsText(
                                                event.target.files
                                                    ? (event.target
                                                          .files[0] as Blob)
                                                    : ('' as any),
                                                'ISO-8859-1'
                                            );
                                            event.target.value = '';
                                        }}
                                    />
                                </div>
                                <div className="col-sm-3 col-xs-12 tab-min-top">
                                    <div className="input-group tab-max-left tour-people--02">
                                        <input
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (page === 1) {
                                                        setRefresh(
                                                            Math.random()
                                                        );
                                                    } else {
                                                        setPage(1);
                                                    }
                                                }
                                            }}
                                            type="text"
                                            placeholder="Buscar por razão, fantasia ou CPF/CNPJ"
                                            className="form-control"
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                        <span className="input-group-append">
                                            <Button
                                                type="button"
                                                color="default"
                                                onClick={() => {
                                                    if (page === 1) {
                                                        setRefresh(
                                                            Math.random()
                                                        );
                                                    } else {
                                                        setPage(1);
                                                    }
                                                }}
                                                icon="fa-search"
                                                notTab={true}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-xs-12 tab-min-top row">
                                    <SelectInputLabel
                                        label="Situação"
                                        name="active"
                                        data_tip="Situação cadastral (ativo ou inativo)"
                                        size_label="4"
                                        size_input="8"
                                        options={[
                                            {
                                                value: true,
                                                label: 'Ativo'
                                            },
                                            {
                                                value: false,
                                                label: 'Inativo'
                                            }
                                        ]}
                                        onChange={(op: any) =>
                                            setActive(op.value)
                                        }
                                    />
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </section>
            </div>

            <div className="content-header mobile">
                <section className="title-page">
                    <div className="container-fluid pl-1 pr-2">
                        <TitlePage
                            title="Pessoas"
                            icon="fa-user"
                            pagination={true}
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                            mobile={true}
                        />

                        <div className="d-flex">
                            <div className="col-sm-4 my-2 pt-min">
                                <SaveOrderButton
                                    id={tableOrder?.id as string}
                                    table="people"
                                    field={order.order}
                                    type={order.type}
                                    setOrder={setOrder}
                                />
                                <Button
                                    onClick={() => {
                                        resetData();
                                        setModalVisible(true);
                                    }}
                                    type="button"
                                    color="success"
                                    caption="Incluir"
                                    icon="fa-plus"
                                    maxSize={true}
                                />
                                <Link
                                    to="#"
                                    className="ml-2"
                                    data-tip="Restaurar Pessoas excluídas"
                                    data-for="people-main-page-tooltip"
                                >
                                    <Button
                                        onClick={() => {
                                            setRecoverModalVisible(true);
                                        }}
                                        type="button"
                                        color="default"
                                        icon="fa-trash-restore-alt"
                                        notTab
                                        maxSize={true}
                                    />
                                </Link>
                                <label
                                    htmlFor="json_input"
                                    id="btn-import"
                                    className="cursor-pointer btn btn-default"
                                    data-tip="Importar cadastros via JSON"
                                    data-for="people-main-page-tooltip"
                                    style={{marginLeft: 6}}
                                >
                                    <i className="fas fa-download" />
                                </label>

                                <input
                                    id="json_input"
                                    type="file"
                                    accept=".json"
                                    style={{display: 'none'}}
                                    onChange={(event) => {
                                        const reader = new FileReader();
                                        reader.onload = async function (e) {
                                            const json = e.target
                                                ?.result as string;
                                            await importLegacyJSON(
                                                json,
                                                'legacyPeople',
                                                setLoading,
                                                () => setRefresh(Math.random())
                                            );
                                        };
                                        reader.readAsText(
                                            event.target.files
                                                ? (event.target
                                                      .files[0] as Blob)
                                                : ('' as any),
                                            'ISO-8859-1'
                                        );
                                        event.target.value = '';
                                    }}
                                />
                            </div>
                            <div className="col-sm-8 col-xs-12 my-2">
                                <div className="input-group tab-max-left">
                                    <input
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }
                                        }}
                                        type="text"
                                        placeholder="Buscar por nome fantasia, razão social ou CPF/CNPJ"
                                        className="form-control"
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                    <span className="input-group-append">
                                        <Button
                                            type="button"
                                            color="default"
                                            onClick={() => {
                                                if (page === 1) {
                                                    setRefresh(Math.random());
                                                } else {
                                                    setPage(1);
                                                }
                                            }}
                                            icon="fa-search"
                                            notTab={true}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <CardBody
                            size="12"
                            smallSize="12"
                            page={page}
                            count={count}
                            setPage={setPage}
                        >
                            <Table
                                id="cfop"
                                columns={columns}
                                setOrder={setOrder}
                                order={order.order}
                                type={order.type}
                            >
                                {mapTable}
                            </Table>
                        </CardBody>
                    </div>
                </div>
            </section>

            {modalVisible && (
                <ModalForm
                    data={modalData}
                    refresh={() => setRefresh(Math.random())}
                    hide={() => setModalVisible(false)}
                />
            )}

            {taxationModalVisible && (
                <TaxationModal
                    hide={() => setTaxationModalVisible(false)}
                    person_id={taxationPersonId}
                />
            )}

            {recoverModalVisible && (
                <RecoverModal
                    hide={() => setRecoverModalVisible(false)}
                    refresh={() => setRefresh(Math.random)}
                />
            )}

            {loading ? (
                <FullPageLoader />
            ) : (
                <Tour
                    steps={tourStepsPeople}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_tables) {
                            api.post('tourTables');
                            updateUser({
                                ...user,
                                tour_tables: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}

            <ReactTooltip
                id="people-main-page-tooltip"
                place="left"
                effect="solid"
                backgroundColor="#42494E"
                globalEventOff="click"
                delayShow={150}
            />
        </>
    );
};

export default People;
