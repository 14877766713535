import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: string;
    placeholder: string;
}

const InputGroup: React.FC<Props> = ({
    name,
    icon,
    placeholder,
    ...otherProps
}) => {
    const {
        control,
        formState: {errors},
        getValues
    } = useFormContext();

    return (
        <>
            <div className="input-group">
                <Controller
                    name={`${name}`}
                    control={control}
                    render={({field}) => (
                        <input
                            disabled={getValues('form_action') === 'view'}
                            className={`form-control ${
                                errors && errors[`${name}`]
                                    ? 'is-invalid'
                                    : null
                            }`}
                            placeholder={placeholder}
                            {...otherProps}
                            {...field}
                        />
                    )}
                />

                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className={icon} />
                    </div>
                </div>
            </div>

            {errors && errors[`${name}`] && (
                <span className="span text-danger text-min-sm">
                    {errors[`${name}`].message}
                </span>
            )}
        </>
    );
};

export default InputGroup;
