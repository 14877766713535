import React, {useCallback, useEffect, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {validate} from 'cnpj';
import Swal from 'sweetalert2';
import {useAuth} from '../../hooks/Auth';
import noUser from '../../assets/empty-user.jpg';
import Button from '../../components/Button';
import FullPageLoader from '../../components/FullPageLoader';
import api from '../../services/api';
import InputLabel from '../../components/InputLabel';
import InputPhoneLabel from '../../components/InputLabelPhone';
import ModalPassword from './ModalPassword';
import InputLabelCNPJ from '../../components/InputLabelCNPJ';
import InputLabelCEP from '../../components/InputLabelCEP';
import AsyncSelectLabelCities from '../../components/AsyncSelectLabelCities';
import ModalConfig from './ModalConfig';
import InputLabelFile from '../../components/InputLabelFile';
import getChanges from '../../utils/getChanges';

interface ProfileProps {
    name: string;
    phone: string;
    company_name: string;
    cnpj?: string;
    ie?: string;
    corporate_name?: string;
    cep?: string;
    address?: string;
    number?: string;
    district?: string;
    complement?: string;
    company_city_id?: number;
    company_phone?: string;
    cpf?: string;
    rg?: string;
    dt_birth?: string;
    responsible_city_id?: number;
}
const userValidation = Yup.object().shape({
    name: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres!')
        .required('Campo obrigatório'),
    phone: Yup.string()
        .min(14, 'Telefone inválido')
        .required('Campo obrigatório'),
    company_name: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres')
        .nullable(),
    corporate_name: Yup.string()
        .required('Campo obrigatório')
        .max(120, 'Deve conter no máximo 120 caracteres')
        .nullable(),
    cnpj: Yup.string()
        .required('Campo obrigatório')
        .test('cnpj', 'CNPJ inválido!', (value) => {
            if (value && value !== '00.000.000/0000-00') {
                return validate(value);
            }
            return false;
        })
        .nullable(),
    ie: Yup.string().nullable().max(30, 'Deve conter no máximo 30 caracteres'),
    company_phone: Yup.string()
        .min(14, 'Telefone inválido!')
        .required('Campo obrigatório')
        .nullable(),
    cep: Yup.string()
        .required('Campo obrigatório')
        .length(9, 'CEP inválido')
        .nullable(),
    address: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres')
        .required('Campo obrigatório')
        .nullable(),
    number: Yup.string()
        .max(30, 'Deve conter no máximo 30 caracteres!')
        .required('Campo obrigatório')
        .nullable(),
    district: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres!')
        .required('Campo obrigatório')
        .nullable(),
    complement: Yup.string()
        .max(120, 'Deve conter no máximo 120 caracteres')
        .nullable(),
    company_city_id: Yup.number().required('Campo obrigatório').nullable()
});

const Profile: React.FC = () => {
    const {user, updateUser, cities} = useAuth();
    const [loading, setLoading] = useState(false);
    const [modalPasswordVisible, setModalPasswordVisible] = useState(false);
    const [modalConfigVisible, setModalConfigVisible] = useState(false);

    const defaultValues: ProfileProps = {
        name: user.name,
        phone: user.phone,
        company_name: user.tenant.company_name,
        cnpj: user.tenant.cnpj,
        ie: user.tenant.ie,
        corporate_name: user.tenant.corporate_name,
        cep: user.tenant.cep,
        address: user.tenant.address,
        number: user.tenant.number,
        district: user.tenant.district,
        complement: user.tenant.complement,
        company_city_id: user.tenant.company_city_id,
        company_phone: user.tenant.company_phone,
        rg: user.tenant.rg,
        cpf: user.tenant.cpf,
        dt_birth: user.tenant.dt_birth?.split('T')[0]
    };
    const methodsUser = useForm<ProfileProps>({
        resolver: yupResolver(userValidation),
        defaultValues
    });

    const {handleSubmit, reset} = methodsUser;
    useEffect(() => {
        reset(defaultValues);
    }, [user]);
    const onSubmit = useCallback(
        async (dataSubmit: ProfileProps) => {
            setLoading(true);
            try {
                const updatedUser = getChanges(
                    {
                        name: dataSubmit.name,
                        phone: dataSubmit.phone
                    },
                    {name: user.name, phone: user.phone}
                );
                await api.put(`/user/${user.id}`, {
                    id: user.id,
                    name: dataSubmit.name,
                    phone: dataSubmit.phone,
                    log: updatedUser
                });
                const updatedTenant = getChanges(defaultValues, dataSubmit);
                await api.put(`/tenants/${user.tenant_id}`, {
                    id: user.tenant_id,
                    ...dataSubmit,
                    log: updatedTenant
                });
                updateUser({
                    ...user,
                    name: dataSubmit.name,
                    phone: dataSubmit.phone,
                    tenant: {
                        ...user.tenant,
                        ...dataSubmit
                    }
                });
                toast.success('Perfil atualizado com sucesso');
            } catch (err: any) {
                setLoading(false);
                toast.error(err.response?.data?.errors[0]?.message);
            }
            setLoading(false);
        },
        [user]
    );

    const methodsAvatar = useForm();
    const {setValue} = methodsAvatar;

    const onSubmitAvatar = useCallback(async (dataSubmit, userData) => {
        const formData = new FormData();
        formData.append('avatar', dataSubmit[0]);

        api.post('avatar', formData)
            .then((response) => {
                updateUser({
                    ...userData,
                    tenant: {...userData.tenant, avatar_url: ''}
                });
                updateUser({
                    ...userData,
                    tenant: {...userData.tenant, avatar_url: response.data}
                });
            })
            .catch((error) => {
                setValue('avatar', '');
                if (error.response?.data) {
                    switch (error.response?.data) {
                        case 'size':
                            toast.error(
                                'O tamanho da imagem não deve ultrapassar 2 mb.'
                            );
                            break;
                        case 'extname':
                            toast.error(
                                'O formato da imagem deve ser .PNG, .JPG ou .JPEG'
                            );
                            break;
                        default:
                            toast.error(
                                'Imagem inválida, verifique o formato e o tamanho do arquivo'
                            );
                    }
                } else {
                    toast.error(
                        'Erro ao alterar imagem, verifique sua conexão e tente novamente'
                    );
                }
            });
    }, []);

    return (
        <div className="card-body">
            <FormProvider {...methodsAvatar}>
                <form autoComplete="off" style={{display: 'none'}}>
                    <InputLabelFile
                        label="avatar"
                        name="avatar"
                        size_label="1"
                        size_input="2"
                        accept=".png, .jpg, .jpeg"
                        onChange={async (event) => {
                            if (event.target.files && event.target.files[0]) {
                                await onSubmitAvatar(event.target.files, user);
                            }
                        }}
                    />
                </form>
            </FormProvider>
            <div className="row">
                <div className="col-md-3">
                    {/* Profile Image */}
                    <div className="card card-outline">
                        <div className="card-body box-profile">
                            <div className="text-center">
                                <img
                                    className="profile__user--img"
                                    src={
                                        user.tenant.avatar_url
                                            ? `${process.env.REACT_APP_API_URL}/images/${user.tenant.avatar_url}`
                                            : noUser
                                    }
                                    alt="User"
                                />
                            </div>
                            <h3 className="profile-username text-center up-username">
                                {user.name}
                            </h3>
                            {user.admin && (
                                <span className="badge badge-success navbar-badge">
                                    Administrador
                                </span>
                            )}
                            <p className="text-muted text-center">
                                {user.email}
                            </p>
                            <br />
                            {user.admin && (
                                <>
                                    <label
                                        className="m-0 cursor-pointer btn btn-default btn-block"
                                        htmlFor="avatar"
                                    >
                                        Alterar Logo da Empresa
                                    </label>

                                    <br />
                                </>
                            )}

                            <>
                                <a
                                    href="#"
                                    className="btn btn-default btn-block"
                                    onClick={() => {
                                        if (user.environment) {
                                            setModalConfigVisible(true);
                                        } else {
                                            Swal.fire({
                                                icon: 'warning',
                                                title:
                                                    'Selecione um ambiente para acessar as configurações'
                                            });
                                        }
                                    }}
                                >
                                    <b>Configurações de Emissão</b>
                                </a>
                                <br />
                            </>

                            <button
                                type="button"
                                className="btn btn-default btn-block"
                                onClick={() => setModalPasswordVisible(true)}
                            >
                                <b>Alterar Senha</b>
                            </button>
                        </div>
                        {/* /.card-body */}
                    </div>
                </div>
                {/* /.col */}
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header p-2">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        href="#user"
                                        data-toggle="tab"
                                    >
                                        Usuário
                                    </a>
                                </li>
                                {user.admin && (
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href="#company"
                                            data-toggle="tab"
                                        >
                                            Empresa
                                        </a>
                                    </li>
                                )}
                                {/* {user.admin && (
                                    <li className="nav-item">
                                        <a
                                            className="nav-link"
                                            href="#responsible"
                                            data-toggle="tab"
                                        >
                                            Responsável
                                        </a>
                                    </li>
                                )} */}
                            </ul>
                        </div>
                        {/* /.card-header */}

                        <FormProvider {...methodsUser}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <div className="tab-content">
                                    <div className="tab-pane active" id="user">
                                        <div className="card-body">
                                            <div className="form-group row">
                                                <InputLabel
                                                    required
                                                    label="Nome"
                                                    name="name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputPhoneLabel
                                                    required
                                                    label="Telefone/Celular"
                                                    name="phone"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="company">
                                        <div className="card-body pb-0">
                                            <div className="form-group row">
                                                <InputLabelCNPJ
                                                    required
                                                    label="CNPJ"
                                                    name="cnpj"
                                                    size_label="2"
                                                    size_input="4"
                                                    cities={cities}
                                                    city_field="company_city_id"
                                                    setLoading={setLoading}
                                                />

                                                <InputLabel
                                                    label="IE"
                                                    name="ie"
                                                    size_label="2"
                                                    size_input="4"
                                                />
                                            </div>

                                            <div className="form-group row">
                                                <InputLabel
                                                    required
                                                    label="Nome da Empresa"
                                                    name="company_name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabel
                                                    required
                                                    label="Razão Social"
                                                    name="corporate_name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabelCEP
                                                    required
                                                    label="CEP"
                                                    name="cep"
                                                    size_label="2"
                                                    size_input="2"
                                                    cities={cities}
                                                    city_field="company_city_id"
                                                    setLoading={setLoading}
                                                />

                                                <InputLabel
                                                    required
                                                    label="Logradouro"
                                                    name="address"
                                                    size_label="2"
                                                    size_input="6"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabel
                                                    required
                                                    label="Número"
                                                    name="number"
                                                    size_label="2"
                                                    size_input="1"
                                                />

                                                <InputLabel
                                                    required
                                                    label="Bairro"
                                                    name="district"
                                                    size_label="1"
                                                    size_input="4"
                                                />

                                                <InputLabel
                                                    label="Complemento"
                                                    name="complement"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <AsyncSelectLabelCities
                                                    required
                                                    label="Cidade"
                                                    name="company_city_id"
                                                    size_label="2"
                                                    size_input="6"
                                                    data={cities}
                                                />

                                                <InputPhoneLabel
                                                    required
                                                    label="Telefone"
                                                    name="company_phone"
                                                    size_label="1"
                                                    size_input="3"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane" id="responsible">
                                        <div className="card-body">
                                            <div className="form-group row">
                                                <InputLabel
                                                    label="Nome"
                                                    name="responsible_name"
                                                    size_label="2"
                                                    size_input="10"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabelCPF
                                                    label="CPF"
                                                    name="cpf"
                                                    size_label="2"
                                                    size_input="2"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="RG"
                                                    name="rg"
                                                    size_label="1"
                                                    size_input="2"
                                                />

                                                <InputLabel
                                                    label="Data de Nascimento"
                                                    name="dt_birth"
                                                    size_label="2"
                                                    size_input="3"
                                                    type="date"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabelCEP
                                                    label="CEP"
                                                    name="responsible_cep"
                                                    size_label="2"
                                                    size_input="2"
                                                    cities={cities}
                                                    city_field="responsible_city_id"
                                                    setLoading={setLoading}
                                                    type="responsible"
                                                />

                                                <InputLabel
                                                    label="Logradouro"
                                                    name="responsible_address"
                                                    size_label="2"
                                                    size_input="6"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <InputLabel
                                                    label="Número"
                                                    name="responsible_number"
                                                    size_label="2"
                                                    size_input="1"
                                                />

                                                <InputLabel
                                                    label="Bairro"
                                                    name="responsible_district"
                                                    size_label="1"
                                                    size_input="4"
                                                />

                                                <InputLabel
                                                    label="Complemento"
                                                    name="responsible_complement"
                                                    size_label="2"
                                                    size_input="2"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <AsyncSelectLabelCities
                                                    label="Cidade"
                                                    name="responsible_city_id"
                                                    size_label="2"
                                                    size_input="6"
                                                    data={cities}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="card-footer text-right">
                                        <Button
                                            type="submit"
                                            color="success"
                                            size="btn btn-primary"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                        {/* /.tab-content */}
                    </div>
                    {/* /.card */}
                </div>
                {/* /.col */}
            </div>
            {/* /.row */}

            {modalPasswordVisible && (
                <ModalPassword hide={() => setModalPasswordVisible(false)} />
            )}

            {modalConfigVisible && (
                <ModalConfig hide={() => setModalConfigVisible(false)} />
            )}

            {loading && <FullPageLoader />}
        </div>
    );
};

export default Profile;
