/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from '../../../components/Button';
import api from '../../../services/api';
import {GroupProps} from '../../../interfaces';
import InputLabel from '../../../components/InputLabel';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';

interface Props {
    data: GroupProps;
    hide: () => void;
    refresh: () => void;
}

const validations = Yup.object().shape({
    description: Yup.string()
        .min(3, 'A descrição precisa conter no mínimo 3 caracteres!')
        .max(120, 'A descrição precisa conter no máximo 120 caracteres!')
        .required('Campo obrigatório!')
});

const ModalGroup: React.FC<Props> = ({data, hide, refresh}) => {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<GroupProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(data);
    }, [data]);

    const onSubmit = useCallback(
        async (dataSubmit) => {
            setIsLoading(true);
            try {
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/peopleGroups', {
                            ...dataSubmit,
                            log: {
                                description: dataSubmit.description
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/peopleGroups/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                setIsLoading(false);
                refresh();
                hide();
            } catch (err: any) {
                setIsLoading(false);
                toast.error(err.response?.data?.errors[0]?.message);
            }
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }
    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar Grupo'
                                    : data.form_action === 'edit'
                                    ? 'Alterar Grupo'
                                    : 'Visualizar Grupo'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 d-flex">
                                        <InputLabel
                                            required
                                            label="Descrição"
                                            name="description"
                                            data_tip={
                                                helperTooltips.description_group
                                            }
                                            size_label="2"
                                            size_input="10"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                {data.form_action !== 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                            isLoading={isLoading}
                                            disabled={isLoading}
                                        />
                                    </div>
                                )}
                                {data.form_action === 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={hide}
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalGroup;
