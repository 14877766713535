/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
import React from 'react';

interface TitlePageProps {
    title: string;
    icon?: string;
    tabRight?: boolean;
    count?: number;
    currentPage?: number;
    perPage?: number;
    pagination?: boolean;
    mobile?: boolean;
    minCol?: boolean;
    customCol?: string;
    description?: string;
    contractPlans?: boolean;
    isCentered?: boolean;
}
const TitlePage: React.FC<TitlePageProps> = ({
    title,
    icon,
    tabRight,
    pagination,
    count,
    currentPage,
    perPage,
    mobile,
    minCol,
    customCol,
    description,
    contractPlans,
    isCentered
}) => {
    return (
        <div
            className={` d-flex tab-right ${mobile && 'col-12'} ${
                minCol ? 'col-3' : customCol ? `col-${customCol}` : 'col-5'
            }`}
        >
            <h5>
                {icon && (
                    <i
                        className={`nav-icon fas ${icon} ${
                            !isCentered && 'tab-top'
                        } ${tabRight && 'tab-right'}`}
                        style={{width: 20}}
                    />
                )}
            </h5>

            {isCentered ? (
                <h5 className="tab-min-top">{title}</h5>
            ) : (
                icon && (
                    <h5 className="tab-left tour-cte--05 tour-mdfe--05">
                        {title}
                        <div className="text-sm font-weight-light">
                            {contractPlans
                                ? 'Há 3 planos de contratos disponíveis'
                                : pagination
                                ? count && currentPage && perPage
                                    ? `Exibindo de ${
                                          currentPage === 1
                                              ? `1 até ${
                                                    count < perPage
                                                        ? count
                                                        : perPage
                                                }`
                                              : `${currentPage * 20 - 19} até ${
                                                    perPage * currentPage >
                                                    count
                                                        ? count
                                                        : perPage * currentPage
                                                }`
                                      } de um total de ${count} ${
                                          count <= 1
                                              ? 'registro.'
                                              : 'registros.'
                                      }`
                                    : 'Não há registros no momento.'
                                : description}
                        </div>
                    </h5>
                )
            )}
        </div>
    );
};

export default TitlePage;
