/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {validate} from 'cnpj';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {Stepper} from 'react-form-stepper';
import logo from '../../assets/logo_cte_tutorial.png';
import {useAuth} from '../../hooks/Auth';
import {EnvironmentProps} from '../../interfaces';
import api from '../../services/api';
import Button from '../../components/Button';
import LastEmission from '../../components/LastEmission';
import InputLabelCNPJ from '../../components/InputLabelCNPJ';
import InputLabel from '../../components/InputLabel';
import InputLabelCEP from '../../components/InputLabelCEP';
import AsyncSelectLabelCities from '../../components/AsyncSelectLabelCities';
import InputPhoneLabel from '../../components/InputLabelPhone';
import InputLabelOnlyNumbers from '../../components/InputLabelOnlyNumbers';
import SelectInputLabel from '../../components/SelectInputLabel';
import InputLabelFile from '../../components/InputLabelFile';
import helperTooltips from '../../data/help';
import getChanges from '../../utils/getChanges';

interface Company {
    company_name: string;
    cnpj?: string;
    ie?: string;
    corporate_name?: string;
    cep?: string;
    address?: string;
    number?: string;
    district?: string;
    complement?: string;
    company_city_id?: number;
    company_phone?: string;
    company_email?: string;
    crt: number;
    cnae?: string;
    rntrc?: string;
    type: number;
}

const Tutorial: React.FC = () => {
    const {user, updateUser, setEnvironment, cities} = useAuth();
    const step1 = user.tenant.cnpj;
    function saveSteps() {
        return step1 === null ? 0 : 2;
    }
    const [loading, setLoading] = useState(false);
    const [isCte, setIsCte] = useState(false);
    const history = useHistory();
    const [stepper, setStepper] = useState(saveSteps);
    const [certificateFile, setCertificateFile] = useState<string>();

    const companyDefaultValues: Company = {
        company_name: user.tenant.company_name,
        cnpj: user.tenant.cnpj,
        ie: user.tenant.ie,
        corporate_name: user.tenant.corporate_name,
        cep: user.tenant.cep,
        address: user.tenant.address,
        number: user.tenant.number,
        district: user.tenant.district,
        complement: user.tenant.complement,
        company_city_id: user.tenant.company_city_id,
        company_phone: user.phone,
        company_email: user.email,
        type: 1,
        crt: 0,
        cnae: '',
        rntrc: ''
    };
    const companyValidation = Yup.object().shape({
        company_name: Yup.string()
            .required('Campo obrigatório')
            .max(120, 'Deve conter no máximo 120 caracteres')
            .nullable(),
        corporate_name: Yup.string()
            .required('Campo obrigatório')
            .max(120, 'Deve conter no máximo 120 caracteres')
            .nullable(),
        ie: Yup.string()
            .required('Campo obrigatório')
            .max(120, 'Deve conter no máximo 120 caracteres')
            .nullable(),
        cnpj: Yup.string()
            .required('Campo obrigatório')
            .test('cnpj', 'CNPJ inválido!', (value) => {
                if (value && value !== '00.000.000/0000-00') {
                    return validate(value);
                }
                return false;
            })
            .nullable(),
        company_phone: Yup.string()
            .min(14, 'Telefone inválido!')
            .required('Campo obrigatório')
            .nullable(),
        cep: Yup.string()
            .required('Campo obrigatório')
            .length(9, 'CEP inválido')
            .nullable(),
        address: Yup.string()
            .max(120, 'Deve conter no máximo 120 caracteres')
            .required('Campo obrigatório')
            .nullable(),
        number: Yup.string()
            .max(30, 'Deve conter no máximo 30 caracteres!')
            .required('Campo obrigatório')
            .nullable(),
        district: Yup.string()
            .max(120, 'Deve conter no máximo 120 caracteres!')
            .required('Campo obrigatório')
            .nullable(),
        complement: Yup.string()
            .max(120, 'Deve conter no máximo 120 caracteres!')
            .nullable(),
        company_city_id: Yup.number().required('Campo obrigatório').nullable(),
        company_email: Yup.string()
            .required('Campo Obrigatório')
            .email('E-mail inválido'),
        rntrc: Yup.string()
            .nullable()
            .test(
                'rntrc',
                'Caso preenchido, o RNTRC deve conter 8 dígitos ou ser "ISENTO".',
                (value) => {
                    if (
                        value !== undefined &&
                        value !== null &&
                        value !== 'ISENTO' &&
                        value?.length > 0
                    ) {
                        return !!value?.match('^[0-9]{8}$');
                    }
                    return true;
                }
            )
    });
    const methodsCompany = useForm<Company>({
        resolver: yupResolver(companyValidation),
        defaultValues: companyDefaultValues
    });
    const {handleSubmit: handleSubmitCompany} = methodsCompany;

    const methodsEnvironment = useForm<EnvironmentProps>({
        defaultValues: {
            cnpj: user.tenant.cnpj,
            corporate_name: user.tenant.corporate_name,
            company_name: user.tenant.company_name,
            address: user.tenant.address,
            number: user.tenant.number,
            city_id: user.tenant.company_city_id,
            crt: user.tenant.crt,
            cte_environment: 2,
            cte_emission: 1,
            district: user.tenant.district,
            complement: user.tenant.complement,
            mdfe_environment: 2,
            mdfe_emission: 1,
            insurance_responsible: 0,
            ie: user.tenant.ie,
            im: '',
            cep: user.tenant.cep,
            phone: user.tenant.company_phone,
            tax_transparency_rate: 0,
            cte_number: 0,
            cte_serie: 1,
            mdfe_number: 0,
            mdfe_serie: 1,
            certificate_name: `Certificado - ${user.tenant.cnpj}`,
            certificate_email: user.tenant.company_email,
            certificate: '',
            certificate_password: '',
            fax: '',
            type: 1,
            cnae: '',
            rntrc: '',
            email: user.tenant.company_email,
            responsible_name: '',
            responsible_cpf: '',
            cte_last_cte_svcsp: '',
            cte_obs: '',
            auto_email: 1,
            email_config: false,
            mdfe_obs: '',
            insurance_id: '',
            policy: '',
            endorsement: ''
        }
    });
    const {
        handleSubmit: handleSubmitEnvironment,
        reset: resetEnvironment,
        setValue
    } = methodsEnvironment;

    const handleCancel = useCallback(() => {
        Swal.fire({
            title: 'Deseja realmente pular o tutorial?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                api.post('tutorial')
                    .then(() => {
                        updateUser({
                            ...user,
                            tutorial: false
                        });
                        history.push('/dashboard');
                    })
                    .catch((error) =>
                        toast.error(error.response?.data?.errors[0]?.message)
                    );
            }
        });
    }, [user]);

    const onSubmitCompany = useCallback(async (dataSubmit: Company) => {
        setLoading(true);
        const log = getChanges(companyDefaultValues, dataSubmit);
        api.put(`/tenants/${user.tenant_id}`, {...dataSubmit, log})
            .then(() => {
                updateUser({
                    ...user,
                    tenant: {
                        ...user.tenant,
                        ...dataSubmit
                    }
                });
                setIsCte(false);
                resetEnvironment({
                    cnpj: dataSubmit.cnpj,
                    corporate_name: dataSubmit.corporate_name,
                    company_name: dataSubmit.company_name,
                    address: dataSubmit.address,
                    number: dataSubmit.number,
                    city_id: dataSubmit.company_city_id,
                    crt: dataSubmit.crt,
                    cte_environment: 2,
                    cte_emission: 1,
                    district: dataSubmit.district,
                    complement: dataSubmit.complement,
                    mdfe_environment: 2,
                    mdfe_emission: 1,
                    insurance_responsible: 0,
                    ie: dataSubmit.ie?.toUpperCase(),
                    im: '',
                    cep: dataSubmit.cep,
                    phone: dataSubmit.company_phone,
                    tax_transparency_rate: 0,
                    cte_number: 0,
                    cte_serie: 1,
                    mdfe_number: 0,
                    mdfe_serie: 1,
                    certificate_name: `Certificado - ${dataSubmit.cnpj}`,
                    certificate_email: dataSubmit.company_email,
                    certificate: '',
                    certificate_password: '',
                    fax: '',
                    type: dataSubmit.type,
                    cnae: dataSubmit.cnae,
                    rntrc: dataSubmit.rntrc,
                    email: dataSubmit.company_email,
                    responsible_name: '',
                    responsible_cpf: '',
                    cte_last_cte_svcsp: '',
                    cte_obs: '',
                    auto_email: 1,
                    email_config: false,
                    mdfe_obs: '',
                    insurance_id: '',
                    policy: '',
                    endorsement: ''
                });
                setStepper((step) => step + 1);
            })
            .catch((error) => {
                toast.error(error.response?.data?.errors[0]?.message);
            })
            .finally(() => setLoading(false));
    }, []);

    const onSubmitEnvironment = useCallback(
        async (dataSubmit, certificate) => {
            if (dataSubmit.certificate_password && certificate) {
                setLoading(true);
                delete dataSubmit.certificateFile;
                dataSubmit.certificate = certificate;
                const formData = new FormData();
                Object.keys(dataSubmit).forEach((key) =>
                    formData.append(
                        key,
                        dataSubmit[key] === null ? '' : dataSubmit[key]
                    )
                );
                formData.append(
                    'log',
                    JSON.stringify({
                        corporate_name: dataSubmit.corporate_name,
                        cnpj: dataSubmit.cnpj
                    })
                );
                api.post('/environments', formData)
                    .then((response) => {
                        setEnvironment({
                            ...dataSubmit,
                            id: response.data.id,
                            certificate_validity:
                                response.data.certificate_validity || null
                        });
                        api.put(`changeEnvironment/${response.data.id}`).then(
                            () => {
                                api.post('tutorial')
                                    .then(() => {
                                        updateUser({
                                            ...user,
                                            environment_id: response.data.id,
                                            tutorial: false
                                        });
                                        toast.success(
                                            'Tutorial finalizado com sucesso'
                                        );
                                    })
                                    .catch(() =>
                                        toast.error(
                                            'Não foi possível finalizar o tutorial'
                                        )
                                    );
                            }
                        );
                    })
                    .catch((error) =>
                        toast.error(
                            error.response?.data?.errors[0]?.message ||
                                error.response?.data ||
                                error ||
                                'Não foi possível cadastrar o ambiente'
                        )
                    )
                    .finally(() => {
                        setLoading(false);
                        window.location.reload();
                    });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Informe o certificado digital e a senha'
                });
            }
        },
        [user]
    );

    return (
        <>
            <div className="col-md-12 pt-3 px-5">
                <div className="card">
                    <div className="card-header col col-md-12 card-graphic p-0">
                        <div className="col col-sm-12 p-0 stepper-head resp-h-100">
                            <div className="col col-sm-10">
                                <Stepper
                                    steps={[
                                        {label: 'Primeiros Passos'},
                                        {label: 'Cadastro de Empresa'},
                                        {label: 'Últimos Passos'}
                                    ]}
                                    styleConfig={{
                                        activeBgColor: '#007BFF',
                                        activeTextColor: '#ffffff',
                                        inactiveBgColor: '#e0e0e0',
                                        inactiveTextColor: '#ffffff',
                                        completedBgColor: '#28a745',
                                        completedTextColor: '#ffffff',
                                        size: '2.5em',
                                        borderRadius: '50%',
                                        circleFontSize: '1rem',
                                        fontWeight: 500,
                                        labelFontSize: '0.875rem'
                                    }}
                                    connectorStyleConfig={{
                                        activeColor: '#007BFF',
                                        completedColor: '#28a745',
                                        size: 1,
                                        disabledColor: '#e0e0e0',
                                        style: 'solid',
                                        stepSize: '2em'
                                    }}
                                    activeStep={stepper}
                                    connectorStateColors
                                />
                            </div>
                            <div className="col-sm-2 d-flex pl-5 tutorial-pc">
                                <a
                                    onClick={handleCancel}
                                    className="sign-link text-nowrap"
                                >
                                    Pular Tutorial
                                    <i className="fas fa-arrow-right pl-2" />
                                </a>
                            </div>
                            <div className="col-sm-2 d-flex tutorial-mb">
                                <a
                                    onClick={handleCancel}
                                    className="sign-link text-nowrap"
                                >
                                    <h6>
                                        <i className="fas fa-times" />
                                    </h6>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center align-items-center pl-0">
                        <div className="card-body card-graphic px-0 py-2">
                            <div className="row">
                                <div className="col-md-12">
                                    {stepper === 0 && (
                                        <>
                                            <div className="card-body py-0 min-h-415">
                                                <h3 className="text-center text-dark p-4">
                                                    Seja bem-vindo{' '}
                                                    <strong className="up-username">
                                                        {user.name}
                                                    </strong>
                                                    !
                                                </h3>
                                                <div className="login-logo mt-4">
                                                    <img
                                                        src={logo}
                                                        style={{width: 500}}
                                                        alt="MS1 Sistemas"
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent text-right d-flex justify-content-center">
                                                <a
                                                    className="btn btn-block btn-primary w-40 m-0 p-2"
                                                    onClick={() =>
                                                        setStepper(stepper + 1)
                                                    }
                                                >
                                                    Começar
                                                    <i className="fas fa-arrow-right pl-2" />
                                                </a>
                                            </div>
                                        </>
                                    )}
                                    {stepper === 1 && (
                                        <FormProvider {...methodsCompany}>
                                            <form
                                                autoComplete="off"
                                                onSubmit={handleSubmitCompany(
                                                    onSubmitCompany
                                                )}
                                            >
                                                <div className="card-body pb-0 min-h-415">
                                                    <div className="form-group row">
                                                        <InputLabelCNPJ
                                                            required
                                                            label="CNPJ"
                                                            name="cnpj"
                                                            data_tip={
                                                                helperTooltips.search_cnpj
                                                            }
                                                            data_tipLabel={
                                                                helperTooltips.cnpj
                                                            }
                                                            size_label="2"
                                                            size_input="3"
                                                            cities={cities}
                                                            setLoading={
                                                                setLoading
                                                            }
                                                            city_field="company_city_id"
                                                        />

                                                        <InputLabel
                                                            required
                                                            label="IE"
                                                            data_tip={
                                                                helperTooltips.ie
                                                            }
                                                            onChange={(event) =>
                                                                setValue(
                                                                    'ie',
                                                                    event.target.value.toUpperCase()
                                                                )
                                                            }
                                                            name="ie"
                                                            size_label="2"
                                                            size_input="4"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <InputLabel
                                                            required
                                                            label="Nome Fantasia"
                                                            name="company_name"
                                                            data_tip={
                                                                helperTooltips.name
                                                            }
                                                            size_label="2"
                                                            size_input="9"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <InputLabel
                                                            required
                                                            label="Razão Social"
                                                            name="corporate_name"
                                                            data_tip={
                                                                helperTooltips.corporate_name
                                                            }
                                                            size_label="2"
                                                            size_input="9"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <InputLabelCEP
                                                            required
                                                            label="CEP"
                                                            name="cep"
                                                            data_tip={
                                                                helperTooltips.search_cep
                                                            }
                                                            data_tipLabel={
                                                                helperTooltips.cep
                                                            }
                                                            size_label="2"
                                                            size_input="2"
                                                            cities={cities}
                                                            setLoading={
                                                                setLoading
                                                            }
                                                            city_field="company_city_id"
                                                        />

                                                        <InputLabel
                                                            required
                                                            label="Logradouro"
                                                            name="address"
                                                            data_tip={
                                                                helperTooltips.address
                                                            }
                                                            size_label="2"
                                                            size_input="5"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <InputLabel
                                                            required
                                                            label="Número"
                                                            name="number"
                                                            data_tip={
                                                                helperTooltips.number
                                                            }
                                                            size_label="2"
                                                            size_input="1"
                                                        />

                                                        <InputLabel
                                                            required
                                                            label="Bairro"
                                                            name="district"
                                                            data_tip={
                                                                helperTooltips.district
                                                            }
                                                            size_label="1"
                                                            size_input="3"
                                                        />

                                                        <InputLabel
                                                            placeholder="(Opcional)"
                                                            label="Complemento"
                                                            name="complement"
                                                            data_tip={
                                                                helperTooltips.complement
                                                            }
                                                            size_label="1"
                                                            size_input="3"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <AsyncSelectLabelCities
                                                            required
                                                            label="Cidade"
                                                            name="company_city_id"
                                                            data_tip={
                                                                helperTooltips.city
                                                            }
                                                            size_label="2"
                                                            size_input="5"
                                                            data={cities}
                                                        />

                                                        <InputPhoneLabel
                                                            required
                                                            label="Telefone"
                                                            name="company_phone"
                                                            data_tip={
                                                                helperTooltips.phone
                                                            }
                                                            size_label="2"
                                                            size_input="2"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <InputLabel
                                                            required
                                                            label="E-mail"
                                                            name="company_email"
                                                            data_tip={
                                                                helperTooltips.email
                                                            }
                                                            size_label="2"
                                                            size_input="3"
                                                        />

                                                        <InputLabel
                                                            label="RNTRC"
                                                            name="rntrc"
                                                            data_tip={
                                                                helperTooltips.rntrc
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                const text: string = event as any;
                                                                setValue(
                                                                    'rntrc',
                                                                    text.toUpperCase()
                                                                );
                                                            }}
                                                            placeholder="(Opcional)"
                                                            size_label="1"
                                                            size_input="2"
                                                        />

                                                        <InputLabelOnlyNumbers
                                                            label="CNAE"
                                                            name="cnae"
                                                            data_tip={
                                                                helperTooltips.cnae
                                                            }
                                                            placeholder="(Opcional)"
                                                            size_label="1"
                                                            size_input="2"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <SelectInputLabel
                                                            required
                                                            label="Tipo"
                                                            name="type"
                                                            data_tip={
                                                                helperTooltips.type_company
                                                            }
                                                            size_label="2"
                                                            size_input="4"
                                                            options={[
                                                                {
                                                                    value: 1,
                                                                    label:
                                                                        'Transportadora'
                                                                },
                                                                {
                                                                    value: 2,
                                                                    label:
                                                                        'Carga própria'
                                                                }
                                                            ]}
                                                        />

                                                        <SelectInputLabel
                                                            required
                                                            label="CRT"
                                                            name="crt"
                                                            data_tip={
                                                                helperTooltips.crt
                                                            }
                                                            size_label="1"
                                                            size_input="4"
                                                            options={[
                                                                {
                                                                    value: 0,
                                                                    label:
                                                                        'Simples nacional'
                                                                },
                                                                {
                                                                    value: 1,
                                                                    label:
                                                                        'Simples excesso nacional'
                                                                },
                                                                {
                                                                    value: 2,
                                                                    label:
                                                                        'Regime normal'
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-transparent text-right d-flex justify-content-center">
                                                    <a
                                                        className="btn btn-block btn-default w-40 my-0 mr-3 p-2"
                                                        onClick={() =>
                                                            setStepper(
                                                                stepper - 1
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-arrow-left pr-2" />
                                                        Retornar
                                                    </a>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        size="btn btn-block btn-success w-40 my-0 mr-3 p-2"
                                                        icon="fa fa-save"
                                                        caption="Salvar"
                                                        isLoading={loading}
                                                        disabled={loading}
                                                    />
                                                </div>
                                            </form>
                                        </FormProvider>
                                    )}
                                    {stepper === 2 && (
                                        <FormProvider {...methodsEnvironment}>
                                            <form
                                                autoComplete="off"
                                                onSubmit={handleSubmitEnvironment(
                                                    (submitData) =>
                                                        onSubmitEnvironment(
                                                            submitData,
                                                            certificateFile
                                                        )
                                                )}
                                            >
                                                <div className="card-body pb-0 min-h-415">
                                                    <div className="pb-3">
                                                        <h5 className="text-center text-dark">
                                                            Certificado Digital:
                                                        </h5>
                                                        <div
                                                            className="row mb-1"
                                                            style={{
                                                                justifyContent:
                                                                    'start'
                                                            }}
                                                        >
                                                            <InputLabelFile
                                                                label="Certificado"
                                                                name="certificateFile"
                                                                size_label="2 min-w-160"
                                                                size_input="5"
                                                                accept=".cer, .pfx"
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    if (
                                                                        event
                                                                            .target
                                                                            .files
                                                                    ) {
                                                                        const reader = new FileReader();
                                                                        reader.readAsDataURL(
                                                                            event
                                                                                .target
                                                                                .files[0]
                                                                        );
                                                                        reader.onload = function () {
                                                                            setCertificateFile(
                                                                                reader.result as string
                                                                            );
                                                                        };
                                                                    }
                                                                }}
                                                            />

                                                            <InputLabel
                                                                label="Senha do Certificado"
                                                                name="certificate_password"
                                                                size_label="2 min-w-160"
                                                                size_input="2"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div // 3 - CTE-e
                                                        className="mt-3 py-4"
                                                    >
                                                        {!isCte && (
                                                            <LastEmission
                                                                title="Possui algum
                                                                      sistema de
                                                                      conhecimento de
                                                                      Transporte Eletrônico?"
                                                                click={() =>
                                                                    setIsCte(
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        )}

                                                        {isCte && (
                                                            <>
                                                                <h5 className="text-center text-dark">
                                                                    <i
                                                                        className="fas fa-info-circle info-circle mr-2"
                                                                        data-tip="Preencha os campos caso já tenha emitido um CT-E em um outro sistema"
                                                                        data-for="dark"
                                                                    />
                                                                    CT-E:
                                                                </h5>
                                                                <ReactTooltip
                                                                    id="dark"
                                                                    place="top"
                                                                    backgroundColor="#343a40"
                                                                />
                                                                <div
                                                                    className="row mb-1 pt-2"
                                                                    style={{
                                                                        justifyContent:
                                                                            'start'
                                                                    }}
                                                                >
                                                                    <InputLabelOnlyNumbers
                                                                        label="Último CT-e"
                                                                        name="cte_number"
                                                                        size_label="3"
                                                                        size_input="2"
                                                                    />

                                                                    <InputLabelOnlyNumbers
                                                                        label="Série"
                                                                        name="cte_serie"
                                                                        size_label="2"
                                                                        size_input="2"
                                                                    />
                                                                </div>
                                                                <div className="pt-4">
                                                                    <>
                                                                        <h5 className="text-center text-dark">
                                                                            <i
                                                                                className="fas fa-info-circle info-circle mr-2"
                                                                                data-tip="Preencha os campos caso já tenha emitido um MDF-E em um outro sistema"
                                                                                data-for="dark"
                                                                            />
                                                                            MDF-E:
                                                                        </h5>
                                                                        <ReactTooltip
                                                                            id="dark"
                                                                            place="top"
                                                                            backgroundColor="#343a40"
                                                                        />
                                                                        <div
                                                                            className="row mb-1 pt-2"
                                                                            style={{
                                                                                justifyContent:
                                                                                    'start'
                                                                            }}
                                                                        >
                                                                            <InputLabelOnlyNumbers
                                                                                label="Último MDF-e"
                                                                                name="mdfe_number"
                                                                                size_label="3"
                                                                                size_input="2"
                                                                            />

                                                                            <InputLabelOnlyNumbers
                                                                                label="Série"
                                                                                name="mdfe_serie"
                                                                                size_label="2"
                                                                                size_input="2"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="card-footer text-right bg-transparent  d-flex justify-content-center">
                                                    <a
                                                        className="btn btn-block btn-default w-40 my-0 mr-3 p-2"
                                                        onClick={() =>
                                                            setStepper(
                                                                stepper - 1
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-arrow-left pr-2" />
                                                        Retornar
                                                    </a>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        size="btn btn-block btn-success w-40 my-0 mr-3 p-2"
                                                        icon="fa fa-save"
                                                        caption="Salvar e Finalizar Tutorial"
                                                        isLoading={loading}
                                                        disabled={loading}
                                                    />
                                                </div>
                                            </form>
                                        </FormProvider>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tutorial;
