/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {toast} from 'react-toastify';
import Tour from 'reactour';
import Swal from 'sweetalert2';
import ActionButton from '../../components/ActionButton';
import Button from '../../components/Button';
import CardBody from '../../components/CardBody';
import FullPageLoader from '../../components/FullPageLoader';
import Table from '../../components/Table';
import TitlePage from '../../components/TitlePage';
import api from '../../services/api';
import ModalMDF from './ModalForm';
import columns from './schema';
import {
    CIOT,
    CteMdfeEventsProps,
    CteNfe,
    MDFeProps,
    TableOrder
} from '../../interfaces';
import {useAuth} from '../../hooks/Auth';
import setSituationColor from '../../utils/setSituationColor';
import InputLabel from '../../components/InputLabel';
import InputLabelOnlyNumbers from '../../components/InputLabelOnlyNumbers';
import InputLabelPlate from '../../components/InputLabelPlate';
import SelectInputLabel from '../../components/SelectInputLabel';
import InputLabelCurrency from '../../components/InputLabelCurrency';
import {tourStepsMdfe} from '../../data/tour';
import {mdfe_situations} from '../../data';
import CteMdfeEventsModal from '../Modals/CteMdfeEventsModal';
import openPdfFromBase64 from '../../utils/openPdfFromBase64';

interface SearchProps {
    start_emission: string;
    end_emission: string;
    start_amount: string | number;
    end_amount: string | number;
    closed: string | boolean;
    number: string;
    license_plate: string;
    key: string;
    situation: string;
}

const searchDefaultValues = {
    start_emission: '',
    end_emission: '',
    start_amount: '',
    end_amount: '',
    closed: '',
    number: '',
    license_plate: '',
    key: '',
    situation: ''
};

const MonitorMDFE: React.FC = () => {
    const {user, updateUser} = useAuth();

    const [isTourOpen, setIsTourOpen] = useState(user.tour_monitors || false);
    const [overflow, setOverflow] = useState(true);
    const disabledOverflow = () => {
        document.body.style.overflow = overflow ? 'hidden' : 'unset';
    };

    const [order, setOrder] = useState<TableOrder>({
        order: 'cod',
        type: 'desc'
    });

    // VALORES PADRÃO PARA ABRIR O FORMULÁRIO
    const defaultValues: MDFeProps = {
        serie: user.environment?.mdfe_serie || 1,
        date_emission: new Date()
            .toLocaleString()
            .substring(0, 10)
            .split('/')
            .reverse()
            .join('-'),
        date_travel: '',
        type: user.environment?.type || 1,
        transporter: user.environment?.type === 2 ? null : 1,
        environment_inssuer_id: user.environment?.id || '',
        // PERCURSO
        city_origin_id: null,
        city_destination_id: null,
        route: [] as number[],
        cep_load: '',
        cep_unload: '',
        // VEICULO DE TRAÇÃO
        rntrc: '',
        trailer: [] as string[],
        vehicle_id: null,
        driver: [] as string[],
        // CARREGAMENTO
        load: [] as number[],
        // DESCARREGAMENTO
        unload: [] as number[],
        citiesCteNfe: [],
        // CTE/NFE
        cte_nfe: [] as CteNfe[],
        // SEGURO
        insurance: user.environment?.insurance || [],
        // CIOT
        ciot: [] as CIOT[],
        // CONTRACTOR
        contractor: user.environment?.config_contractor
            ? [user.environment?.config_contractor]
            : ([] as string[]),
        // PRODUCT
        load_type: '05',
        product_key: user.environment?.config_predominant_product || '',
        ean: '',
        ncm: '',
        // TOTAL
        amount: 0,
        total_weight: 0,
        measurement: '01',
        // OBS
        obs: user.environment?.mdfe_obs || '',
        // READ ONLY
        form_action: 'include',
        situation: 'EM DIGITAÇÃO',
        closed: false
    };
    const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [mdfes, setMdfes] = useState<MDFeProps[]>([] as MDFeProps[]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalData, setModalData] = useState<MDFeProps>(defaultValues);
    const [eventsModalVisible, setEventsModalVisible] = useState(false);
    const [eventsModalData, setEventsModalData] = useState<
        CteMdfeEventsProps[]
    >([]);

    // GET MDF-e's
    useEffect(() => {
        if (user.environment) {
            setLoading(true);
            api.get(
                `mdfes?order=${order.order}&type=${order.type}&page=${page}&limit=20&environment_inssuer_id=${user.environment.id}&mdfe_environment=${user.environment.mdfe_environment}&filter=true&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_amount=${search.start_amount}&end_amount=${search.end_amount}&closed=${search.closed}&number=${search.number}&license_plate=${search.license_plate}&key=${search.key}&situation=${search.situation}`
            )
                .then((response) => {
                    setMdfes(response.data.data);
                    setCount(response.data.meta.total);
                    setPerPage(response.data.meta.per_page);
                    setCurrentPage(response.data.meta.current_page);
                })
                .catch((error) =>
                    toast.error('Não foi possível consultar os manifestos')
                )
                .finally(() => setLoading(false));
        }
    }, [page, refresh, user, order]);

    const methods = useForm<SearchProps>({
        defaultValues: searchDefaultValues
    });
    const {handleSubmit, reset} = methods;

    const onSearch = useCallback(
        async (values: SearchProps) => {
            if (values.start_amount === 0) {
                values.start_amount = '';
            }
            if (values.end_amount === 0) {
                values.end_amount = '';
            }

            setSearch(values);

            if (page === 1) {
                setRefresh(Math.random());
            } else {
                setPage(1);
            }

            reset(values);
        },
        [page]
    );

    function resetData() {
        setModalData(defaultValues);
    }

    // ABRIR MODAL DO FORMULÁRIO
    function openModal() {
        if (user.environment) {
            resetData();
            setModalVisible(true);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text:
                    'É necessário cadastrar um ambiente antes de realizar esta ação.'
            });
        }
    }

    // VISUALIZAR, EDITAR OU CLONAR
    const showMDF = useCallback(
        (id: string, form_action: 'include' | 'view' | 'edit') => {
            setLoading(true);
            api.get(`mdfes/${id}`)
                .then((response) => {
                    setModalData({
                        ...response.data.mdfe,
                        cod:
                            form_action === 'include'
                                ? ''
                                : response.data.mdfe.cod,
                        number:
                            form_action === 'include'
                                ? ''
                                : response.data.mdfe.number,
                        serie:
                            form_action === 'include'
                                ? ''
                                : response.data.mdfe.serie,
                        date_emission:
                            form_action === 'include'
                                ? new Date()
                                      .toLocaleString()
                                      .substring(0, 10)
                                      .split('/')
                                      .reverse()
                                      .join('-')
                                : response.data.mdfe.date_emission.split(
                                      'T'
                                  )[0],
                        date_travel:
                            form_action === 'include'
                                ? new Date()
                                      .toLocaleString()
                                      .split(' ')[0]
                                      .split('/')
                                      .reverse()
                                      .join('-')
                                : response.data.mdfe.date_travel.split('T')[0],
                        amount: parseFloat(response.data.mdfe.amount),
                        ciot: response.data.ciot.map((ciot: CIOT) => ({
                            cpf_cnpj_service: ciot.cpf_cnpj_service,
                            ciot: ciot.ciot
                        })),
                        contractor: response.data.contractor.map(
                            (contractor: any) => contractor.contractor_cpf_cnpj
                        ),
                        cte_nfe: response.data.cte_nfe.map((item: any) => ({
                            cte_city_id: item.cte_city_id,
                            access_key: item.access_key
                        })),
                        driver: response.data.driver.map(
                            (driver: any) => driver.driver_people_id
                        ),
                        insurance: response.data.insurance,
                        load: response.data.load_city.map(
                            (load: any) => load.load_city_id
                        ),
                        trailer: response.data.mdfe_trailer.map(
                            (trailer: any) => trailer.trailer_vehicle_id
                        ),
                        route: response.data.routes.map(
                            (route: any) => route.route_uf_id
                        ),
                        unload: response.data.unload_city.map(
                            (unload: any) => unload.unload_city_id
                        ),
                        citiesCteNfe: response.data.unload_city.map(
                            (unload: any) => ({
                                label: `${unload.city.description} - ${unload.city.state.initials}`,
                                value: unload.unload_city_id
                            })
                        ),
                        form_action,
                        situation: 'EM DIGITAÇÃO',
                        closed: false
                    });
                    setModalVisible(true);
                })
                .catch(() => toast.error('Não foi possível efetuar a consulta'))
                .finally(() => setLoading(false));
        },
        []
    );

    // EXCLUIR
    const handleDelete = useCallback(
        (id: string, situation: string, cod: string, number?: number) => {
            if (situation === 'EM DIGITAÇÃO' && !number) {
                Swal.fire({
                    title: 'Confirmar exclusão do registro?',
                    icon: 'question',
                    showCancelButton: true,
                    showCloseButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (result) => {
                    if (result.value) {
                        setLoading(true);
                        try {
                            await api.delete(
                                `mdfes/${id}?environment_inssuer_id=${user.environment?.id}&cod=${cod}`
                            );
                            setRefresh(Math.random());
                            toast.success('Registro excluído com sucesso');
                        } catch (error: any) {
                            toast.error(
                                error.response?.data ||
                                    'Ocorreu um erro ao excluir o registro'
                            );
                        }
                        setLoading(false);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'Não é possível excluir um documento que já foi enviado'
                });
            }
        },
        [user]
    );

    // IMPRIMIR
    const printMdfe = useCallback(async (id: string, situation: string) => {
        if (situation === 'CANCELADO' || situation === 'AUTORIZADO') {
            setLoading(true);
            await api
                .get(`/apiMdfesNS/${id}`)
                .then(async (response) => {
                    openPdfFromBase64(response.data.pdf);
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: `Erro ao imprimir: ${error.response?.data}`
                    });
                })
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Só é possivel imprimir um documento autorizado.'
            });
        }
    }, []);

    // ENVIAR
    const sendMDFe = useCallback(
        async (id: string, situation: string, cod: string) => {
            if (situation === 'EM DIGITAÇÃO' || situation === 'ENVIADO') {
                setLoading(true);
                await api
                    .post(`apiMdfesNS`, {
                        id,
                        print: user.environment?.config_print_documents
                    })
                    .then(async (response) => {
                        toast.success(response.data.xMotivo);
                        if (
                            user.environment?.config_print_documents &&
                            response.data.pdf
                        ) {
                            openPdfFromBase64(response.data.pdf);
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            text:
                                error.response?.data ||
                                'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status'
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                        setRefresh(Math.random());
                    });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Só é possível enviar um documento em digitação.'
                });
            }
        },
        [user]
    );

    // CANCELAR
    const cancelMDF = useCallback(
        async (id: string, situation: string, closed: boolean, cod: string) => {
            if (situation === 'AUTORIZADO' && closed === false) {
                Swal.fire({
                    title: 'Motivo do cancelamento',
                    input: 'text',
                    inputValidator: (value) => {
                        if (value.length < 15) {
                            return 'Escreva ao menos 15 caracteres';
                        }

                        return null;
                    },
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Enviar',
                    showLoaderOnConfirm: true,
                    preConfirm: async (result) => {
                        setLoading(true);
                        await api
                            .delete(
                                `apiMdfesNS/${id}?justify_cancellation=${result}&cod=${cod}&environment_inssuer_id=${user.environment?.id}`
                            )
                            .then(() => {
                                setRefresh(Math.random());
                                toast.success('Cancelado com sucesso');
                            })
                            .catch((error) => {
                                setLoading(false);
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Oops...',
                                    text:
                                        error.response?.data ||
                                        'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status ou tente cancelar novamente'
                                });
                            });
                        setLoading(false);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        'Só é possível cancelar um documento autorizado que ainda não foi encerrado.'
                });
            }
        },
        [user]
    );

    // ENVIAR POR EMAIL
    const emailMDF = useCallback((id: string, situation: string) => {
        if (
            situation === 'CANCELADO' ||
            situation === 'AUTORIZADO' ||
            situation === 'AUTORIZADO EM CONTINGÊNCIA'
        ) {
            Swal.fire({
                title: 'Enviar por e-mail',
                input: 'email',
                inputPlaceholder: 'Insira o e-mail do destinatário',
                validationMessage: 'Endereço de email inválido',
                reverseButtons: true,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Enviar',
                showLoaderOnConfirm: true,
                preConfirm: async (result) => {
                    setLoading(true);
                    await api
                        .post(`emailMdfeNS`, {
                            email: result,
                            id
                        })
                        .then(() => toast.success('E-mail enviado com sucesso'))
                        .catch((error) => {
                            setLoading(false);
                            Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text:
                                    error.response?.data ||
                                    'Ocorreu um erro, tente novamente'
                            });
                        });
                    setLoading(false);
                }
            });
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Só é possível enviar por e-mail um documento autorizado.'
            });
        }
    }, []);

    // ENCERRAR
    const closeMdf = useCallback(
        async (id: string, situation: string, closed: boolean, cod: string) => {
            if (situation === 'AUTORIZADO' && !closed) {
                Swal.fire({
                    title: 'Deseja encerrar este MDF-e?',
                    icon: 'question',
                    showCancelButton: true,
                    showCloseButton: true,
                    reverseButtons: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then(async (result) => {
                    if (result.value) {
                        setLoading(true);
                        try {
                            await api.post(
                                `closeMdfeNS/${id}?cod=${cod}&environment_inssuer_id=${user.environment?.id}`
                            );
                            setRefresh(Math.random());
                            toast.success('Encerrado com sucesso');
                        } catch (error: any) {
                            setLoading(false);
                            Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text:
                                    error.response?.data ||
                                    'Ocorreu um erro na comunicação com o servidor, consulte a nota para atualizar o status ou tente encerrar novamente'
                            });
                        }
                        setLoading(false);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: `${
                        closed
                            ? 'Esta nota já está encerrada.'
                            : 'Só é possivel encerrar uma nota autorizada.'
                    }`
                });
            }
        },
        [user]
    );
    // CONSULTAR
    const searchMDF = useCallback((id: string, key: string | undefined) => {
        setLoading(true);
        api.get(`searchMdfeNS/${id}`)
            .then((response) => {
                Swal.fire({
                    icon: 'info',
                    title: 'Consulta',
                    text: response.data
                });
                setRefresh(Math.random());
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text:
                        error.response?.data ||
                        'Ocorreu um erro, tente novamente'
                });
            })
            .finally(() => setLoading(false));
    }, []);

    // VISUALIZAR EVENTOS
    const showEvents = useCallback((id: string) => {
        setLoading(true);
        api.get(`mdfeEvents/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setEventsModalData(response.data);
                    setEventsModalVisible(true);
                } else {
                    Swal.fire({
                        icon: 'info',
                        title: 'Este documento não possui eventos'
                    });
                }
            })
            .catch(() => toast.error('Erro ao obter eventos'))
            .finally(() => setLoading(false));
    }, []);

    // BAIXAR XML
    const mdfeFile = useCallback(
        (id: string, key: string, type: 'tx2' | 'xml' | 'json') => {
            setLoading(true);
            api.get(`mdfeFile?id=${id}&type=${type}`)
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    const fileName = `MDFe_${key}.${type}`;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    navigator.clipboard.writeText(`${response.data}`);
                    toast.info('Exportado com sucesso.');
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text:
                            error.response?.data ||
                            'Ocorreu um erro ao copiar os dados'
                    });
                })
                .finally(() => setLoading(false));
        },
        []
    );

    // CORPO DA TABELA
    const mapTable = useMemo(
        () =>
            mdfes.map((mdfe) => (
                <tr key={Math.random()}>
                    {/* CÓDIGO */}
                    <td className="pt-2 pl-2 ">{mdfe.cod}</td>
                    {/* NÚMERO MDFE */}
                    <td className="pt-2 pl-2 ">{mdfe.number}</td>
                    {/* DATA DE EMISSÃO */}
                    <td className="pt-2">
                        {mdfe.date_emission
                            ?.split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                    </td>
                    {/* QTD. CTE */}
                    <td className="pt-2">{mdfe.cte_count}</td>
                    {/* QTD. NFE */}
                    <td className="pt-2">{mdfe.nfe_count}</td>
                    {/* VALOR DA CARGA */}
                    <td className="pt-2">{`R$ ${new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                    }).format(mdfe.amount)}`}</td>
                    {/* CHAVE */}
                    <td className="pt-2">{mdfe.key}</td>
                    {/* SITUAÇÃO */}
                    <td
                        className={`pt-2 text-center bg-${setSituationColor(
                            mdfe.situation
                        )}`}
                    >
                        {mdfe.situation}
                    </td>
                    {/* ENCERRADA */}
                    <td className="text-center pt-2">
                        {`${mdfe.closed === true ? 'SIM' : 'NÃO'}`}
                    </td>
                    {/* IMPRIMIR */}
                    <td>
                        <button
                            type="button"
                            className="btn btn-default btn-sm btn-block"
                            onClick={async () => {
                                await printMdfe(mdfe.id || '', mdfe.situation);
                            }}
                        >
                            <i className="fas fa-print" />
                        </button>
                    </td>
                    {/* ENVIAR */}
                    <td>
                        <button
                            type="button"
                            className="btn btn-default btn-sm btn-block"
                            onClick={async () => {
                                await sendMDFe(
                                    mdfe.id || '',
                                    mdfe.situation,
                                    mdfe.cod || ''
                                );
                            }}
                        >
                            <i className="fas fa-paper-plane" />
                        </button>
                    </td>
                    {/* AÇÕES */}
                    <td className="px-0 text-center">
                        <ActionButton
                            // QUALQUER SITUAÇÃO
                            view={() => {
                                showMDF(mdfe.id || '', 'view');
                            }}
                            clone={() => {
                                showMDF(mdfe.id || '', 'include');
                            }}
                            consult={() => {
                                searchMDF(mdfe.id || '', mdfe.key);
                            }}
                            events={() => showEvents(mdfe.id || '')}
                            //
                            // EM DIGITAÇÃO
                            edit={
                                mdfe.situation === 'EM DIGITAÇÃO'
                                    ? () => {
                                          if (
                                              mdfe.situation === 'EM DIGITAÇÃO'
                                          ) {
                                              showMDF(mdfe.id || '', 'edit');
                                          } else {
                                              Swal.fire({
                                                  icon: 'warning',
                                                  title: 'Oops...',
                                                  text:
                                                      'Só é possível editar um documento em digitação.'
                                              });
                                          }
                                      }
                                    : undefined
                            }
                            //
                            // DIGITAÇÃO SEM NUMERAÇÃO
                            delete={
                                mdfe.situation === 'EM DIGITAÇÃO' &&
                                !mdfe.number
                                    ? () =>
                                          handleDelete(
                                              mdfe.id || '',
                                              mdfe.situation,
                                              mdfe.cod || '',
                                              mdfe.number
                                          )
                                    : undefined
                            }
                            //
                            // AUTORIZADO E NÃO ENCERRADO
                            shutdown={
                                mdfe.situation === 'AUTORIZADO' && !mdfe.closed
                                    ? () => {
                                          closeMdf(
                                              mdfe.id || '',
                                              mdfe.situation,
                                              mdfe.closed,
                                              mdfe.cod || ''
                                          );
                                      }
                                    : undefined
                            }
                            cancel={
                                mdfe.situation === 'AUTORIZADO' && !mdfe.closed
                                    ? () => {
                                          cancelMDF(
                                              mdfe.id || '',
                                              mdfe.situation,
                                              mdfe.closed,
                                              mdfe.cod || ''
                                          );
                                      }
                                    : undefined
                            }
                            //
                            // AUTORIZADO OU CANCELADO
                            email={
                                mdfe.situation === 'AUTORIZADO' ||
                                mdfe.situation === 'CANCELADO'
                                    ? () => {
                                          emailMDF(
                                              mdfe.id || '',
                                              mdfe.situation
                                          );
                                      }
                                    : undefined
                            }
                            xml={
                                mdfe.situation === 'AUTORIZADO' ||
                                mdfe.situation === 'CANCELADO'
                                    ? () =>
                                          mdfeFile(
                                              mdfe.id || '',
                                              mdfe.key || '',
                                              'xml'
                                          )
                                    : undefined
                            }
                            //
                            // json={async () =>
                            //     mdfeFile(mdfe.id || '', mdfe.key || '', 'json')
                            // }
                        />
                    </td>
                </tr>
            )),
        [mdfes]
    );

    return (
        <div>
            <div className="content-header">
                <div className="title-page">
                    <div className="container-fluid pl-1 dc-flex desktop">
                        <TitlePage
                            title={`Monitor MDF-e ${
                                user.environment
                                    ? ` | ${
                                          user.environment.mdfe_environment ===
                                          1
                                              ? 'Produção'
                                              : 'Homologação'
                                      }`
                                    : ''
                            }`}
                            icon="fa-desktop"
                            tabRight
                            pagination
                            count={count}
                            currentPage={currentPage}
                            perPage={perPage}
                        />
                        <div className="tab-min-top col-7 text-left mt-1 pr-1">
                            <Button
                                className="tour-mdfe--01"
                                type="button"
                                color="success"
                                caption="Incluir"
                                icon="fa-plus"
                                maxSize
                                onClick={() => openModal()}
                            />
                        </div>
                        {/* <div className="col-6 text-right col-form-label">
                            <strong className="text-primary tour-mdfe--04">
                                Servidor da receita:{' '}
                                <span className="text-dark">
                                    {
                                        setStatusData(status.ModoOperacaoSaaS)
                                            .status
                                    }
                                    <i
                                        className={`fas fa-circle text-${
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).colorClass
                                        } pl-1`}
                                        data-tip={
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).tip
                                        }
                                        data-for="status"
                                    />
                                    <ReactTooltip
                                        id="status"
                                        place="bottom"
                                        backgroundColor={
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).color
                                        }
                                    />
                                </span>
                            </strong>
                        </div> */}
                    </div>
                    <div className="content-header mobile">
                        <div className="container-fluid pl-1 pr-2">
                            <TitlePage
                                title={`Monitor MDF-e ${
                                    user.environment
                                        ? ` | ${
                                              user.environment
                                                  .mdfe_environment === 1
                                                  ? 'Produção'
                                                  : 'Homologação'
                                          }`
                                        : ''
                                }`}
                                icon="fa-desktop"
                                tabRight
                                pagination
                                count={count}
                                currentPage={currentPage}
                                perPage={perPage}
                                mobile
                            />
                            <div className="dc-flex">
                                <div className="col-sm-3 my-2 pt-min">
                                    <Button
                                        type="button"
                                        color="success"
                                        caption="Incluir"
                                        icon="fa-plus"
                                        maxSize={true}
                                        onClick={() => openModal()}
                                    />
                                </div>
                                {/* <div className="col-sm-9 col-xs-12 my-2">
                                    <strong className="text-primary">
                                        Servidor da receita:{' '}
                                    </strong>
                                    <span>
                                        {
                                            setStatusData(
                                                status.ModoOperacaoSaaS
                                            ).status
                                        }
                                        <i
                                            className={`fas fa-circle text-${
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).colorClass
                                            } pl-1`}
                                            data-tip={
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).tip
                                            }
                                            data-for="status"
                                        />
                                        <ReactTooltip
                                            id="status"
                                            place="bottom"
                                            backgroundColor={
                                                setStatusData(
                                                    status.ModoOperacaoSaaS
                                                ).color
                                            }
                                        />
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="main-content">
                        <FormProvider {...methods}>
                            <form
                                autoComplete="off"
                                onSubmit={handleSubmit(onSearch)}
                            >
                                <div className="w-100 dc-flex filter-desktop tour-mdfe--02">
                                    <div className="w-70">
                                        <div className="d-flex">
                                            <section className="d-flex w-100 pt-1 pb-1 px-1">
                                                <InputLabel
                                                    label="Emissão"
                                                    name="start_emission"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                    type="date"
                                                />

                                                <InputLabel
                                                    label="Até"
                                                    name="end_emission"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                    type="date"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="N° MDF-e"
                                                    name="number"
                                                    size_label="1"
                                                    size_input="1"
                                                />

                                                <InputLabelPlate
                                                    label="Placa"
                                                    name="license_plate"
                                                    size_label="1"
                                                    size_input="2"
                                                />

                                                <SelectInputLabel
                                                    label="Tipo"
                                                    name="closed"
                                                    size_label="1"
                                                    size_input="3 z-2"
                                                    noSizeSelect
                                                    options={[
                                                        {
                                                            value: '',
                                                            label: 'QUALQUER'
                                                        },
                                                        {
                                                            value: true,
                                                            label: 'ENCERRADO'
                                                        },
                                                        {
                                                            value: false,
                                                            label:
                                                                'NÃO ENCERRADO'
                                                        }
                                                    ]}
                                                />
                                            </section>
                                        </div>
                                        <div className="d-flex">
                                            <section className="d-flex w-100 px-1 pb-2">
                                                <InputLabelCurrency
                                                    label="Valor"
                                                    name="start_amount"
                                                    size_label="1"
                                                    size_input="2 w-date"
                                                />

                                                <InputLabelCurrency
                                                    label="Até"
                                                    name="end_amount"
                                                    size_label=""
                                                    size_input="2 w-date"
                                                />

                                                <InputLabelOnlyNumbers
                                                    label="Chave"
                                                    name="key"
                                                    size_label="1"
                                                    size_input="4"
                                                />

                                                <SelectInputLabel
                                                    label="Situação"
                                                    name="situation"
                                                    size_label="1"
                                                    size_input="3 z-2"
                                                    options={mdfe_situations}
                                                />
                                            </section>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right mb-2 pr-4 ml-2">
                                        <Button
                                            className="tour-mdfe--03"
                                            type="submit"
                                            color="default"
                                            caption="Pesquisar"
                                            icon="fa-search"
                                        />
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                        <div className="container-fluid">
                            <div className="row">
                                <CardBody
                                    size="12"
                                    smallSize="12"
                                    page={page}
                                    count={count}
                                    setPage={setPage}
                                >
                                    <Table
                                        id="mdfe"
                                        columns={columns}
                                        setOrder={setOrder}
                                        order={order.order}
                                        type={order.type}
                                    >
                                        {mapTable}
                                    </Table>
                                </CardBody>
                            </div>
                        </div>
                        <div />

                        {modalVisible && (
                            <ModalMDF
                                hide={() => setModalVisible(false)}
                                refresh={() => setRefresh(Math.random())}
                                data={modalData}
                            />
                        )}

                        {eventsModalVisible && (
                            <CteMdfeEventsModal
                                hide={() => setEventsModalVisible(false)}
                                data={eventsModalData}
                                type="MDF-e"
                            />
                        )}
                        <div />
                    </div>
                </div>
            </div>
            {loading ? (
                <FullPageLoader />
            ) : (
                <Tour
                    steps={tourStepsMdfe}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                    accentColor="#007BFF"
                    maskSpace={8}
                    rounded={20}
                    startAt={0}
                    onAfterOpen={() => {
                        setOverflow(false);
                        disabledOverflow();
                    }}
                    onBeforeClose={() => {
                        if (user.tour_monitors) {
                            api.post('tourMonitors');
                            updateUser({
                                ...user,
                                tour_monitors: false
                            });
                        }
                        setOverflow(true);
                        disabledOverflow();
                    }}
                />
            )}
        </div>
    );
};

export default MonitorMDFE;
