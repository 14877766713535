import React from 'react';
import ReactTooltip from 'react-tooltip';

interface LabelProps {
    title: string;
    col: string;
    respAlt: boolean;
    respGlobal?: boolean;
    to: string;
    required?: boolean;
    remove_colon?: boolean;
    data_tip?: string;
}

const Label: React.FC<LabelProps> = ({
    title,
    col,
    respAlt,
    respGlobal,
    to,
    required,
    remove_colon,
    data_tip
}) => {
    return (
        <>
            <label
                htmlFor={`${to}`}
                className={`${col} col-form-label responsive-label text-nowrap ${
                    respGlobal && 'responsive-forms'
                } ${respAlt && 'resp-form'}`}
            >
                {required && (
                    <strong
                        className="text-danger"
                        data-tip="Campo obrigatório"
                        data-for="danger"
                    >
                        *
                    </strong>
                )}{' '}
                <strong data-tip={data_tip} data-for="help__tip">
                    {title}
                </strong>
                {`${!remove_colon ? ':' : ''}`}
            </label>
            <ReactTooltip id="danger" place="left" backgroundColor="#DC3545" />
            <ReactTooltip
                id="help__tip"
                place="left"
                backgroundColor="#42494E"
                globalEventOff="click"
                multiline
                delayShow={250}
            />
        </>
    );
};

export default Label;
