import {
    correctionLetterIde,
    correctionLetterToma4,
    correctionLetterEnderToma,
    correctionLetterCompl,
    correctionLetterObsCont,
    correctionLetterObsFisco,
    correctionLetterEmit,
    correctionLetterEnderEmit,
    correctionLetterRem,
    correctionLetterEnderRem,
    correctionLetterDest,
    correctionLetterEnderDest,
    correctionLetterExped,
    correctionLetterEnderExped,
    correctionLetterReceb,
    correctionLetterEnderReceb,
    correctionLetterVPrest,
    correctionLetterComp,
    correctionLetterInfCarga,
    correctionLetterInfQ,
    correctionLetterInfNF,
    correctionLetterInfNFe,
    correctionLetterInfOutros,
    correctionLetterDocAnt,
    correctionLetterIdDocAntPap,
    correctionLetterIdDocAntEle,
    correctionLetterRodo
} from '../data';

function getCorrectionLetterFields(value: string) {
    switch (value) {
        case 'ide':
            return correctionLetterIde;
        case 'toma4':
            return correctionLetterToma4;
        case 'enderToma':
            return correctionLetterEnderToma;
        case 'compl':
            return correctionLetterCompl;
        case 'ObsCont':
            return correctionLetterObsCont;
        case 'ObsFisco':
            return correctionLetterObsFisco;
        case 'emit':
            return correctionLetterEmit;
        case 'enderEmit':
            return correctionLetterEnderEmit;
        case 'rem':
            return correctionLetterRem;
        case 'enderRem':
            return correctionLetterEnderRem;
        case 'dest':
            return correctionLetterDest;
        case 'enderDest':
            return correctionLetterEnderDest;
        case 'exped':
            return correctionLetterExped;
        case 'enderExped':
            return correctionLetterEnderExped;
        case 'receb':
            return correctionLetterReceb;
        case 'enderReceb':
            return correctionLetterEnderReceb;
        case 'vPrest':
            return correctionLetterVPrest;
        case 'Comp':
            return correctionLetterComp;
        case 'infCarga':
            return correctionLetterInfCarga;
        case 'infQ':
            return correctionLetterInfQ;
        case 'infNF':
            return correctionLetterInfNF;
        case 'infNFe':
            return correctionLetterInfNFe;
        case 'infOutros':
            return correctionLetterInfOutros;
        case 'docAnt':
            return correctionLetterDocAnt;
        case 'idDocAntPap':
            return correctionLetterIdDocAntPap;
        case 'idDocAntEle':
            return correctionLetterIdDocAntEle;
        case 'rodo':
            return correctionLetterRodo;
        default:
            return [];
    }
}

export default getCorrectionLetterFields;
