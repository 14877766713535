import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import Button from '../../../../components/Button';
import InputLabel from '../../../../components/InputLabel';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import {CTeProps, Cte_NFe} from '../../../../interfaces';
import helperTooltips from '../../../../data/help';
import api from '../../../../services/api';
import FullPageLoader from '../../../../components/FullPageLoader';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<CTeProps>;
    setTableValue: UseFormSetValue<CTeProps>;
}

const validations = Yup.object().shape({
    access_key: Yup.string()
        .required('Campo obrigatório!')
        .length(44, 'A chave deve conter 44 caracteres'),
    nfe_number: Yup.string().required('Campo obrigatório!')
});

const ModalNfe: React.FC<Props> = ({hide, getTableValues, setTableValue}) => {
    const [loading, setLoading] = useState(false);
    const methods = useForm<Cte_NFe>({
        resolver: yupResolver(validations),
        defaultValues: {access_key: '', nfe_number: '', pin: ''}
    });
    const {handleSubmit, reset, setValue} = methods;

    const onSubmit = useCallback(async (dataSubmit: Cte_NFe) => {
        let equivalent = false;
        getTableValues().nfes.every((item) => {
            equivalent = item.access_key === dataSubmit.access_key;
            return !equivalent;
        });
        if (!equivalent) {
            setLoading(true);
            await api
                .get(`verifyNfeKey/${dataSubmit.access_key}`)
                .then((response) => {
                    if (response.data.length > 0) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Chave já importada',
                            showCancelButton: true,
                            confirmButtonText: 'Continuar mesmo assim',
                            cancelButtonText: 'Cancelar inclusão',
                            html: `Esta NF-e está presente nos seguintes CT-e's:<br/><br/> ${response.data
                                .map(
                                    (item: any) =>
                                        `Código: ${item.cod}, Número: ${
                                            item.number || 'EM DIGITAÇÃO'
                                        }`
                                )
                                .join('<br/>')}`
                        }).then(() => {
                            setTableValue('nfes', [
                                ...getTableValues().nfes,
                                dataSubmit
                            ]);
                            reset();
                            hide();
                        });
                    } else {
                        setTableValue('nfes', [
                            ...getTableValues().nfes,
                            dataSubmit
                        ]);
                        reset();
                        hide();
                    }
                })
                .catch((error) =>
                    toast.warning(
                        error.response?.data ||
                            error ||
                            'Não foi possível verificar a chave informada em outros documentos'
                    )
                )
                .finally(() => setLoading(false));
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Já existe um documento registrado com essa chave.'
            });
        }
    }, []);

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>NF-e</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Chave"
                                        name="access_key"
                                        data_tip={helperTooltips.access_key_nfe}
                                        size_label="2"
                                        size_input="10"
                                        digits={44}
                                        autoFocus
                                        onChange={(event) => {
                                            const text: string = event as any;
                                            if (text.length === 44) {
                                                setValue(
                                                    'nfe_number',
                                                    parseInt(
                                                        text.substring(25, 34),
                                                        10
                                                    ).toString()
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <InputLabelOnlyNumbers
                                        required
                                        label="Número"
                                        name="nfe_number"
                                        data_tip={helperTooltips.nfe_number}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                                <div className="row">
                                    <InputLabel
                                        label="PIN"
                                        name="pin"
                                        data_tip={helperTooltips.pin_nfe}
                                        size_label="2"
                                        size_input="3"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            <div className="float-left">
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={() => {
                                            hide();
                                            reset();
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
            {loading && <FullPageLoader />}
        </Modal>
    );
};

export default ModalNfe;
