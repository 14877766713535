import React from 'react';
import ReactTooltip from 'react-tooltip';

interface infoBoxProps {
    title: string;
    hasTour?: any;
}

const InfoBox: React.FC<infoBoxProps> = ({title, hasTour}) => {
    return (
        <div className="card-header">
            <div className="col col-sm-12 d-flex justify-content-between align-items-center">
                <div className="col-sm-10 d-flex">
                    <div className="card-title text-nowrap min-w-140">
                        {`${title} (autorizados)`}
                    </div>
                </div>
                <div className="card-tools text-nowrap">
                    <button
                        type="button"
                        className="btn btn-tool tour-dashboard--06"
                    >
                        <a className="sign-link" onClick={() => hasTour(true)}>
                            <i
                                className="fas fa-question"
                                data-tip="Precisa de ajuda?"
                                data-for="info-dark-left"
                            />
                            <ReactTooltip
                                id="info-dark-left"
                                place="left"
                                backgroundColor="#42494E"
                                effect="solid"
                            />
                        </a>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InfoBox;
