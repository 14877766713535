/* eslint-disable no-nested-ternary */
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import Button from '../../../components/Button';
import api from '../../../services/api';
import FullPageLoader from '../../../components/FullPageLoader';
import {UserFormProps} from '../../../interfaces';
import InputLabel from '../../../components/InputLabel';
import InputPhoneLabel from '../../../components/InputLabelPhone';
import getChanges from '../../../utils/getChanges';
import InputLabelCheckBox from '../../../components/InputLabelCheckBox';

interface Props {
    data: UserFormProps;
    hide: () => void;
    refresh: () => void;
}

const validations = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    phone: Yup.string()
        .required('Campo obrigatório')
        .min(14, 'Telefone inválido'),
    email: Yup.string().when('form_action', {
        is: 'include',
        then: Yup.string()
            .max(120, 'Deve conter no máximo 120 caracteres')
            .required('Campo obrigatório')
            .email('E-mail inválido')
    }),
    password: Yup.string().when('form_action', {
        is: 'include',
        then: Yup.string()
            .required('Campo obrigatório')
            .min(6, 'A senha deve conter no mínimo 6 caracteres')
    })
});

const ModalForm: React.FC<Props> = ({data, hide, refresh}) => {
    const methods = useForm<UserFormProps>({
        resolver: yupResolver(validations)
    });
    const {handleSubmit, reset} = methods;
    useEffect(() => {
        reset(data);
    }, [data]);

    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(
        async (dataSubmit: UserFormProps) => {
            setLoading(true);
            try {
                switch (dataSubmit.form_action) {
                    case 'include':
                        await api.post('/users', {
                            ...dataSubmit,
                            log: {
                                name: dataSubmit.name,
                                email: dataSubmit.email
                            }
                        });
                        toast.success('Usuário cadastrado com sucesso');
                        break;
                    case 'edit': {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`/additionalUsers/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Usuário alterado com sucesso');
                        break;
                    }
                    default:
                        toast.error('Erro ao efetuar cadastro');
                        break;
                }
                setLoading(false);
                refresh();
                hide();
            } catch (err: any) {
                setLoading(false);
                toast.error(err.response?.data?.errors[0]?.message);
            }
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            showCloseButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                hide();
            }
        });
    }

    return (
        <Modal show dialogAs="div" centered>
            <FormProvider {...methods}>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    className="responsive-modal"
                    id="maxsize__select"
                >
                    <Modal.Dialog scrollable size="lg" centered>
                        <Modal.Header className="bg-primary">
                            <Modal.Title>
                                {data.form_action === 'include'
                                    ? 'Cadastrar Usuário'
                                    : data.form_action === 'edit'
                                    ? 'Alterar Usuário'
                                    : 'Visualizar Usuário'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="card-body p-0">
                                <div
                                    className="row mb-1"
                                    style={{
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <InputLabel
                                        required
                                        label="Nome"
                                        data_tip="Nome do usuário."
                                        name="name"
                                        size_label="1"
                                        size_input="7"
                                    />

                                    <InputPhoneLabel
                                        required
                                        label="Telefone"
                                        data_tip="Telefone do usuário."
                                        name="phone"
                                        size_label="1 pl-0"
                                        size_input="3"
                                    />
                                </div>
                                {data.form_action === 'include' && (
                                    <div
                                        className="row mb-1"
                                        style={{
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <InputLabel
                                            required
                                            label="Email"
                                            name="email"
                                            data_tip="Email do usuário."
                                            size_label="1"
                                            size_input="5"
                                        />

                                        <InputLabel
                                            required
                                            label="Senha"
                                            data_tip="Senha do usuário."
                                            name="password"
                                            size_label="1"
                                            size_input="5"
                                        />
                                    </div>
                                )}
                                <div className="row mb-1">
                                    <InputLabelCheckBox
                                        label="Administrador"
                                        name="admin"
                                        size_label="2"
                                        data_tip="Selecione caso deseje conceder permissões de administrador a este usuário"
                                        data_tipLabel="Selecione caso deseje conceder permissões de administrador a este usuário"
                                    />
                                </div>
                            </div>
                            {loading && <FullPageLoader />}
                        </Modal.Body>
                        <Modal.Footer
                            style={{
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        >
                            {data.form_action !== 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Cancelar"
                                        onClick={handleCancel}
                                    />

                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="btn btn-success"
                                        icon="fa fa-save"
                                        caption="Salvar"
                                        isLoading={loading}
                                        disabled={loading}
                                    />
                                </div>
                            )}
                            {data.form_action === 'view' && (
                                <div className="float-left responsive-button">
                                    <Button
                                        type="button"
                                        color="danger"
                                        size="btn btn-default"
                                        icon="fa fa-arrow-circle-left"
                                        caption="Retornar"
                                        onClick={hide}
                                    />
                                </div>
                            )}
                        </Modal.Footer>
                    </Modal.Dialog>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ModalForm;
