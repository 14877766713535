/* eslint-disable no-unneeded-ternary */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

interface AppButtonProps {
    type: 'button' | 'submit' | 'reset';
    size?: string;
    border?: boolean;
    color?: string;
    icon?: string;
    caption?: string;
    isLoading?: boolean;
    disabled?: boolean;
    tabRight?: boolean;
    notTab?: boolean;
    maxSize?: boolean;
    fullSize?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

const AppButton: React.FC<AppButtonProps> = ({
    type,
    size,
    border,
    color,
    icon,
    caption,
    isLoading,
    disabled,
    tabRight,
    notTab,
    maxSize,
    fullSize,
    onClick,
    children,
    className
}) => {
    let spinnerTemplate;
    let iconTemplate;

    if (isLoading) {
        spinnerTemplate = (
            <Spinner
                className="ml-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        );
    }

    if (icon) {
        iconTemplate = (
            <i className={`fa ${icon} ${!notTab ? 'mr-2' : 'mr-0'} `} />
        );
    }

    return (
        <Button
            type={type}
            disabled={isLoading || disabled}
            className={`btn btn-${color} ${size && size} ${
                border && 'btn-login'
            } ${tabRight && 'tab-max-right'}${maxSize && 'px-4'}${
                fullSize && 'col-11 mb-2'
            } ${className}`}
            onClick={onClick}
        >
            {iconTemplate}
            {caption}
            {children}
            {spinnerTemplate}
        </Button>
    );
};
export default AppButton;
